import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
//material-ui
import {
  Paper,
  Button,
  Grid,
  FormControl,
  TextField,
  Table,
  IconButton,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Typography,
  FormHelperText,
  InputAdornment,
  Divider,
  Dialog,
  LinearProgress,
  TableCell,
} from "@material-ui/core";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactSelect from "react-select";
import { components } from "react-select";
import { makeStyles } from "@material-ui/core/styles";
//icons
import { Search, Today, Add, Delete } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ahmis from "../../../assets/ahmis.png";
import leaf from "../../../assets/leaf.png";
import { SHOW_SNACKBAR } from "../../../actions/Type";
import { restrict } from "../../../component/restrict";
import axiosInstance from "../../../api/Instance";
import InventoryInstance from "../../../api/InventoryInstance";
import "../style.css";
import useTable from "../../../component/useTable";
import { StyledTableRow, StyledTableCell } from "../../Inventory-CDS/utils";
import { useTheme } from "@material-ui/core/styles";
import _ from "lodash";
//icons
import { faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import Controls from "../../../component/controls/Controls";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

let headCellsSubmitted = [
  { id: "id", label: "Sl. No." },
  { id: "orderDate", label: "Requested Date" },
  { id: "purchaseOrderId", label: "Purchase Order ID" },
  { id: "vendorName", label: "Vendor (M/S)" },
  { id: "expectedDate", label: "Expected Date" },
  { id: "totalBill", label: "Total Bill Amount (₹)" },
  { id: "verify", label: "Approved By" },
  { id: "approvalDate", label: "Approval Date" },
  { id: "reorder", label: "Reorder" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action", disableSorting: true },
];

let headCellsDraft = [
  { id: "id", label: "S.No." },
  { id: "draft", label: "Draft" },
  { id: "actions", label: "Actions", disableSorting: true },
];
//css defined
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  pageContent: {
    padding: 16,
    marginBottom: 16,
  },
  btn_height: {
    width: 24,
    height: 24,
    textTransform: "capitalize",
  },
  select: {
    minWidth: 180,
    marginTop: 3,
  },
  minWidth: {
    minWidth: 120,
  },
  margin: {
    padding: 15,
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnPending: {
    backgroundColor: "rgb(231 108 25)",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
  inputFontThead: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  alignRight: {
    textAlign: "right",
  },
}));

const style = {
  cellPadding: 1, // a number, array or object (see margin below)
  fontSize: 8,
  font: "helvetica", // helvetica, times, courier
  lineWidth: 0.01,
  lineColor: 127,
  fontStyle: "normal", // normal, bold, italic, bolditalic
  overflow: "linebreak", // visible, hidden, ellipsize or linebreak
  fillColor: false, // false for transparent or a color as described below
  textColor: 0,
  halign: "left", // left, center, right
  valign: "middle", // top, middle, bottom
  cellWidth: "auto", // 'auto', 'wrap' or a number
};

const defaultArray = {
  drugType: "",
  drugName: "",
  singleUnit: "",
  uomName: "",
  requiredQty: "",
  reduction: "",
  singleQtyPrice: "",
  price: "",
};
//validation
const drugTypeSchema = yup.object().shape({
  id: yup.string(),
  typeName: yup.string(),
});

const drugNameSchema = yup.object().shape({
  id: yup.string(),
  MedicineName: yup.string(),
  uomName: yup.string(),
});
const vendorNameSchema = yup.object().shape({
  id: yup.string(),

  vendorName: yup.string(),
});

const arraySchema = yup.object().shape({
  drugType: drugTypeSchema.nullable().required("Type is Required"),
  drugName: drugNameSchema.nullable().required("Name is required"),
  //singleUnit: yup.string().required("SingleUnit is required").matches(/^[1-9][0-9]*$/, "Can not start with 0"),
  requiredQty: yup
    .string()
    .required("RequiredQty is required")
    .matches(/^[1-9][0-9]*$/, "Can not start with 0"),
  singleQtyPrice: yup
    .string()
    .required("SingleQtyPrice is required")
    .matches(/^\d{0,5}(\.\d{1,2})?$/, "Invalid value"),
  uomName: yup.string(),

  // reduction: yup.string().nullable().required("Reduction is required").matches(/^[1-9][0-9]*$/, "Can not start with 0"),
});

const schema = yup.object().shape({
  array: yup.array().of(arraySchema),

  // department: yup.string().nullable().required("Department is required"),
  vendorName: vendorNameSchema.nullable().required("Vendor is required"),
});

const [W, H] = [210, 297];
const margin = 10;

const customStyles = {
  // For the select itself (not the options)
  control: (styles, { isDisabled }) => {
    return {
      ...styles,

      backgroundColor: isDisabled ? "#f5f7f5 " : "unset",
      opacity: 1,
    };
  },
  option: (styles, { data }) => {
    // to change the bg color of essential medicines
    return {
      ...styles,
      backgroundColor: data.isEssential ? "#dafed6" : "white",
      color: "black",
      ":hover": {
        backgroundColor: styles.backgroundColor,
      },
    };
  },
  singleValue: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? "#474444 " : "#474444",
      // fontStyle: isDisabled ? "italic" : "normal",
      opacity: 1,
    };
  },
};

const generatePdf = (report, data, staffData, filterData) => {
  const org = staffData.orgName;

  const now = moment(new Date()).format("DD-MM-YYYY");
  const time = new Date().toLocaleTimeString();

  const pdfData = data.purchaseOrderDetailsDtos.map((item) => [
    item.drugTypeName,
    item.drugName,
    item.uomName,
    item.singleUnit,
    item.requiredQty,
    item.approvedQty === undefined ? "N/A" : item.approvedQty,
    item.reduction,
    item.singleQtyPrice,
    item.price,
  ]);

  const pdf = new jsPDF("p", "mm", [W, H], true);
  let Y = margin;
  pdf.addImage(ahmis, "PNG", margin, Y + 2, 15, 15);
  pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
  pdf.setFontSize(14);
  let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
  pdf.text(splitTitle, W / 2, Y, { align: "center" });
  pdf.setFontSize(13);
  pdf.text(report, W / 2, Y + 15, "center");
  Y += 30;
  pdf.setFontSize(10);
  pdf.text(`Purchase Order No.: ${data.purchaseOrderNo}`, margin, Y);
  pdf.text(`Status: ${filterData[0]?.approvalStatus},`, margin, Y + 5);
  pdf.text(`Vendor (M/S): ${data?.vendorName},`, margin, Y + 11);
  pdf.text(`GST No.: ${data?.vendorGstNo},`, margin, Y + 17);
  // pdf.text(`CGST: ${data?.vendorCgst}`, W - margin, Y + 11, "right");
  // pdf.text(`SGST: ${data?.vendorSgst}`, W - margin, Y + 17, "right");
  pdf.text(
    `Approved By: ${
      filterData[0]?.approvedByName !== undefined
        ? filterData[0]?.approvedByName
        : "N/A"
    }`,
    margin,
    Y + 23
  );

  pdf.text(
    `Approved On: ${
      filterData[0]?.approvedDate !== undefined
        ? filterData[0]?.approvedDate
        : "N/A"
    }`,
    W - margin,
    Y + 23,
    "right"
  );
  Y += 26;

  pdf.autoTable({
    head: [
      [
        "Drug Type",
        "Drug Name",
        "UOM",
        "Single Unit",
        "Required Qty",
        "Approved Qty",
        "Reduction (%)",
        "Single Unit Price(Rs.)",
        "Total Price (Rs.)",
      ],
    ],
    body: pdfData,

    foot: [
      [
        {
          content: `Total Amount (Rs.): ${data.totalBillAmount}`,
          colSpan: 9,
          styles: {
            halign: "right",
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
          },
        },
      ],
    ],
    startY: Y,
    margin,
    headStyles: {
      ...style,
      fillColor: [11, 156, 171],
      textColor: 255,
      lineColor: 255,
      fontSize: 8,
      fontWeight: 500,
    },
    bodyStyles: { ...style },
    footStyles: { ...style },
  });

  pdf.save(`Purchase Request-${now}, ${time}.pdf`);
};

const PurchaseOrderRequest = () => {
  const MUItheme = useTheme();
  const reactSelectTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: MUItheme.palette.primary.main,
    },
  });
  var totalAmount = 0;
  const classes = useStyles();
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.loginReducer.staffLogin);
  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);
  // console.log(staff,"Staff name")
  const orgId = staff?.orgId;
  const staffId = staff?.staffId;
  const storeId = staff?.storeId;
  const [netAmount, setNetAmount] = useState(0);

  const defaultValues = {
    array: [defaultArray],
    vendorName: "",
    gst: "",
    cgstNo: "",
    sgstNo: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    control,
    formState,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: "array",
  });
  //useState defined
  const [drugTypes, setDrugTypes] = React.useState([]);
  const [drugNames, setDrugNames] = React.useState([]);
  const [approvalStatus, setApprovalStatus] = React.useState();
  const [vendorName, setVendorName] = React.useState();
  const [vendorNameId, setVendorNameID] = React.useState();
  const [viewMode, setViewMood] = React.useState(false);
  const [vendorData, setVendordata] = React.useState([]);
  const [receiptStatus, setReceiptSatus] = React.useState("");
  const [format, setFormat] = React.useState([]);

  const [filterFnSubmitted, setFilterFnSubmitted] = React.useState({
    fn: (items) => items,
  });

  const [recordsSubmitted, setRecordsSubmitted] = React.useState([]);

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [meds, setMeds] = React.useState([]);
  const [reOrder, setReorder] = React.useState(false);
  //Submitted
  const {
    TblContainer,
    TblHead: TblHeadSubmitted,
    TblPagination: TblPaginationSubmitted,
    recordsAfterPagingAndSorting: recordsAfterPagingAndSortingSubmitted,
  } = useTable(recordsSubmitted, headCellsSubmitted, filterFnSubmitted);

  const getDrugType = () => {
    axiosInstance
      .get("medicineType/getAllMedicineTypeBySom/" + somId)
      .then((response) => {
        setDrugTypes(response.data);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const getDrugNames = (selectedObj) => {
    axiosInstance
      .get("medicineName/getByMedTypeNOrgId/" + selectedObj.id + "/" + orgId)
      .then((res) => {
        setDrugNames((ps) => ({ ...ps, [selectedObj.id]: res.data }));
      })
      .catch(() => {
        console.log("error");
      });
  };
  const medicineChange = useWatch({
    control,
    name: "array",
  });
  const fetchReorderMed = (e) => {
    console.log(getValues());
    console.log(medicineChange);
    medicineChange?.forEach((i, index) => {
      const t = i.drugType.id;
      console.log(t);

      axiosInstance
        .get("medicineName/getByMedTypeNOrgId/" + t + "/" + orgId)
        .then((res) => {
          setValue(
            `array[${index}].drugName`,
            res.data?.find((dn) => +dn.id === i.drugNameId)
          );
          setDrugNames((ps) => ({ ...ps, [t]: res.data }));
        });
      // dispatch(fetchComplexMedicineName(t)).then((name) => {

      //   setDrugNames((ps) => ({ ...ps, [t]: name }));
      //   console.log(drugNames,"drugName");
      //   console.log(name,"name");
      // });
    });
    setValue(
      "vendorName",
      vendorData.find((el) => +el.id === +vendorNameId)
    );
  };

  const vendorNames = () => {
    InventoryInstance.get("vendorDetails/fetchActive/" + orgId)
      .then((res) => {
        setVendordata(res.data);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const fetchPurchaseOrder = () => {
    setLoading(true);

    InventoryInstance.get("purchaseOrdReq/fetchOrdReqByOrg/" + orgId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setRecordsSubmitted(data);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const calculateNetAmount = () => {
    let amt = 0;
    getValues()?.array?.forEach((row, index) => {
      if (row.price !== undefined) {
        const rowAmount = +row.price;
        amt += rowAmount;
      }
    });
    setNetAmount(amt);
  };

  const calculatePrice = () => {
    getValues()?.array?.forEach((row, index) => {
      var totalPrice = 0;
      totalPrice = +row?.requiredQty * +row?.singleQtyPrice;
      setValue(`array[${index}].price`, totalPrice);
    });
  };
  React.useEffect(() => {
    getDrugType();
    fetchPurchaseOrder();
    vendorNames();
  }, []);

  const handleSearchSubmitted = (e) => {
    let val = e.target.value;
    setFilterFnSubmitted({
      fn: (items) => {
        if (val === "") return items;
        return items.filter(
          (x) =>
            x.purchaseOrderNo?.toLowerCase().includes(val.toLowerCase()) ||
            x.approvalStatusName?.toLowerCase().includes(val.toLowerCase())
        );
      },
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onFormSubmit = (data) => {
    setOpen(true);
  };

  const handleReset = () => {
    reset(defaultValues);
    setSelectedDate(new Date());
    setIsEditMode(false);
    setViewMood(false);
    setNetAmount("");
    setMeds([]);
    setReorder(false);
    setApprovalStatus("");
  };

  const handleViewRecord = (e, id) => {
    setIsEditMode(true);
    setViewMood(true);
    window.scroll(0, 0);
    InventoryInstance.get(`purchaseOrdReq/fetch/${orgId}/${id}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setApprovalStatus(data?.approvalStatusName);
        setReceiptSatus(data?.receiptStatus);

        setNetAmount(data?.totalBillAmount);
        var date = new Date(data?.expectedDate);
        var dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        setSelectedDate(dateString);

        setVendorName(data?.vendorName);
        setVendorNameID(data?.vendorNameId);

        reset({
          array: data?.purchaseOrderDetailsDtos.map((el) => ({
            ...el,
            drugType: drugTypes.find(
              (drugType) => +el.drugTypeId === +drugType.id
            ),
          })),
          gst: data.vendorGstNo,
          cgstNo: data.vendorCgst,
          sgstNo: data.vendorSgst,
        });
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const printDocument = (id) => {
    const reportName = "Purchase Request";
    const filterData = recordsSubmitted.filter((item) => item.purOrdId === id);

    InventoryInstance.get(`purchaseOrdReq/fetch/${orgId}/${id}`)
      .then((res) => {
        generatePdf(
          reportName,

          res.data,
          staff,
          filterData
        );
      })
      .catch(() => {
        console.log("error");
      });
  };

  const handleClose = (e, type) => {
    setOpen(false);
  };

  const handleClosesave = (e) => {
    e.preventDefault();
    setOpen(false);
    var totalAmount = 0;

    getValues().array.forEach((item) => {
      totalAmount = totalAmount + +item.price;
    });
    setNetAmount(totalAmount);

    if (reOrder) {
      const req = {
        approvalStatus: 2,

        organizationId: orgId,
        vendorNameId: getValues().vendorName.id,
        expectedDate: moment(selectedDate).format("YYYY-MM-DD"),
        totalBillAmount: totalAmount,
        createdBy: staffId,
        subStockStoreId: storeId,
        reorder: true,
        purchaseOrderDetailsDtos: getValues().array.map((el) => ({
          drugTypeId: el.drugType.id,
          drugNameId: el.drugName.id,

          singleUnit: el.singleUnit === "" ? null : el.singleUnit,
          requiredQty: +el.requiredQty,
          reduction: el.reduction === "" ? null : el.reduction,
          singleQtyPrice: el.singleQtyPrice,
          price: el.price,
        })),
      };

      InventoryInstance.post(`purchaseOrdReq/save`, req)
        .then(async (response) => {
          const dbresponse = await response.data;
          if (dbresponse === "success") {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "success",
                msg: "Record Added successfully",
                openSnackbar: true,
              },
            });

            setIsEditMode(false);
            fetchPurchaseOrder();
            setReorder(false);
            reset(defaultValues);
            setValue("array", [defaultArray]);
            handleReset();
          } else {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "error",
                msg: "Something went wrong",
                openSnackbar: true,
              },
            });
          }
        })
        .catch(() => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              msg: "Something went wrong",
              openSnackbar: true,
            },
          });
        });
    } else {
      const req = {
        approvalStatus: 2,

        organizationId: orgId,
        vendorNameId: getValues().vendorName.id,
        expectedDate: moment(selectedDate).format("YYYY-MM-DD"),
        totalBillAmount: totalAmount,
        createdBy: staffId,
        subStockStoreId: storeId,
        reorder: false,
        purchaseOrderDetailsDtos: getValues().array.map((el) => ({
          drugTypeId: el.drugType.id,
          drugNameId: el.drugName.id,

          singleUnit: el.singleUnit === "" ? null : el.singleUnit,
          requiredQty: +el.requiredQty,
          reduction: el.reduction === "" ? null : el.reduction,
          singleQtyPrice: el.singleQtyPrice,
          price: el.price,
        })),
      };

      InventoryInstance.post(`purchaseOrdReq/save`, req)
        .then(async (response) => {
          const dbresponse = await response.data;
          if (dbresponse === "success") {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "success",
                msg: "Record Added successfully",
                openSnackbar: true,
              },
            });

            setIsEditMode(false);
            fetchPurchaseOrder();

            reset(defaultValues);
            setValue("array", [defaultArray]);
            handleReset();
          } else {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "error",
                msg: "Something went wrong",
                openSnackbar: true,
              },
            });
          }
        })
        .catch(() => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              msg: "Something went wrong",
              openSnackbar: true,
            },
          });
        });
    }
  };

  const maxYear = () => {
    var today = new Date();
    var mm = today.getMonth() + 1;
    var dd = today.getDate();
    var yy = today.getFullYear() + 50;
    if (mm < 10) {
      mm = "0" + mm.toString();
    }
    if (dd < 10) {
      dd = "0" + dd.toString();
    }
    return yy + "-" + mm + "-" + dd;
  };

  // React.useEffect(() => {
  //   if(reOrder){
  //     console.log("active reorder");
  //   medicineChange?.forEach((i) => {
  //     i.rows.forEach((j) => {
  //       const t = j.drugType;
  //       console.log(t)
  //       if (t) {
  //         if (!(t.toString() in drugNames)) {
  //           dispatch(fetchComplexMedicineName(t)).then((name) => {
  //             setDrugNames((drugNames) => ({
  //               ...drugNames, // reuse exising data in medicineNames
  //               [t?.toString()]: name,
  //             }));
  //           });
  //         }
  //       }
  //     });
  //   });
  // }
  // }, [medicineChange]);

  return (
    <>
      {/* <ConcomitantMedicine/> */}

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          component={Paper}
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={11}>
            <TableContainer component={Paper}>
              <Table className={`${classes.table}`} aria-label="simple table">
                <TableHead>
                  <TableRow
                    align="center"
                    style={{
                      backgroundColor: myOwnColor,
                    }}
                  >
                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      Drug Type
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      Drug Name
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      UoM
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      Single Unit
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      Required Quantity
                    </StyledTableCell>
                    {viewMode ? (
                      approvalStatus === "Pending" ? null : (
                        <StyledTableCell
                          align="center"
                          className={classes.inputFontThead}
                        >
                          Approved Qty
                        </StyledTableCell>
                      )
                    ) : null}

                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      Reduction (%)
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      Single Unit Price (₹)
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      Total Price (₹)
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.inputFontThead}
                    >
                      <IconButton
                        disabled={viewMode || reOrder}
                        onClick={() => {
                          append(defaultArray);
                        }}
                        style={{ color: "white", padding: 4 }}
                      >
                        <Add />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((_, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          align="center"
                          className={classes.select}
                        >
                          {viewMode ? (
                            <Tooltip
                              title={
                                <Typography style={{ fontSize: "14px" }}>
                                  {getValues()?.array[index].drugTypeName}
                                </Typography>
                              }
                              arrow
                            >
                              <TextField
                                style={{ width: "200", fontSize: "14px" }}
                                {...register(`array[${index}].drugTypeName`)}
                                placeholder="Drug Type Name"
                                size="small"
                                fullWidth
                                disabled={viewMode || reOrder}
                                InputProps={{
                                  className: classes.inputFont,
                                }}
                                onInput={restrict.number}
                                variant="outlined"
                              />
                            </Tooltip>
                          ) : (
                            <FormControl fullWidth>
                              <Controller
                                name={`array[${index}].drugType`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <ReactSelect
                                    theme={reactSelectTheme}
                                    styles={customStyles}
                                    openMenuOnClick={false}
                                    maxMenuHeight={200}
                                    isClearable={true}
                                    isDisabled={reOrder}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={15}
                                        />
                                      ),
                                    }}
                                    onChange={(val) => {
                                      onChange(val);
                                      // setValue(`array[${index}].drugName`, "");
                                      if (val) {
                                        getDrugNames(val);
                                      }
                                      //  else {
                                      //   setDrugNames([]);
                                      // }
                                    }}
                                    value={value}
                                    // isOptionDisabled={option => array.map(el => el.symptomName).includes(option.signSymptomName)}
                                    menuPosition="fixed"
                                    options={drugTypes}
                                    getOptionLabel={(option) => option.typeName}
                                    getOptionValue={(option) => option.id}
                                  />
                                )}
                              />
                              <FormHelperText
                                error={!!errors?.array?.[index]?.drugType}
                              >
                                {errors?.array?.[index]?.drugType?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          className={classes.select}
                        >
                          {viewMode ? (
                            <Tooltip
                              title={
                                <Typography style={{ fontSize: "14px" }}>
                                  {getValues()?.array[index].drugName}
                                </Typography>
                              }
                              arrow
                            >
                              <TextField
                                style={{ width: "200", fontSize: "14px" }}
                                {...register(`array[${index}].drugName`)}
                                placeholder="Drug name"
                                size="small"
                                fullWidth
                                disabled={viewMode || reOrder}
                                InputProps={{
                                  className: classes.inputFont,
                                }}
                                onInput={restrict.number}
                                variant="outlined"
                              />
                            </Tooltip>
                          ) : (
                            <FormControl fullWidth>
                              <Controller
                                name={`array[${index}].drugName`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <ReactSelect
                                    theme={reactSelectTheme}
                                    openMenuOnClick={false}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    isClearable={true}
                                    isDisabled={reOrder}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={15}
                                        />
                                      ),
                                    }}
                                    onChange={(val) => {
                                      onChange(val);
                                      const value = val?.id;
                                      const data = [...meds];

                                      data[index] = value;
                                      setMeds(data);
                                      setValue(
                                        `array[${index}].uomName`,
                                        getValues(
                                          `array[${index}].drugName.uomName`
                                        )
                                      );
                                    }}
                                    value={value}
                                    isOptionDisabled={(option) =>
                                      meds?.includes(option.id)
                                    }
                                    // isOptionDisabled={option => array.map(el => el.symptomName).includes(option.signSymptomName)}
                                    menuPosition="fixed"
                                    options={
                                      drugNames[
                                        watch(`array[${index}].drugType.id`)
                                      ]
                                    }
                                    getOptionLabel={(option) =>
                                      option.MedicineName
                                    }
                                    getOptionValue={(option) => option.id}
                                  />
                                )}
                              />

                              <FormHelperText
                                error={!!errors?.array?.[index]?.drugName}
                              >
                                {errors?.array?.[index]?.drugName?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          className={classes.minWidth}
                        >
                          <TextField
                            {...register(`array[${index}].uomName`)}
                            placeholder="UOM"
                            size="small"
                            fullWidth
                            variant="outlined"
                            disabled
                            style={{ width: "130px", fontSize: "14px" }}
                            InputProps={{
                              className: classes.inputFont,
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {(() => {
                            const singleUnit = register(
                              `array.[${index}].singleUnit`
                            );
                            return (
                              <TextField
                                disabled={viewMode || reOrder}
                                style={{
                                  width: "120px",
                                  fontSize: "14px",
                                }}
                                {...singleUnit}
                                onInput={restrict.digits}
                                placeholder="Single Unit"
                                onChange={(e) => {
                                  singleUnit.onChange(e);
                                  calculatePrice();
                                  calculateNetAmount();
                                }}
                                // onChange={()=>calculatePrice()}
                                size="small"
                                fullWidth
                                inputProps={{
                                  className: viewMode
                                    ? `${classes.alignRight} ${classes.inputFont}`
                                    : `${classes.inputFont}`,
                                  maxLength: 6,
                                }}
                                InputProps={{
                                  className: classes.inputFont,
                                  maxLength: 6,
                                }}
                                variant="outlined"
                                error={!!errors?.array?.[index]?.singleUnit}
                                helperText={
                                  errors?.array?.[index]?.singleUnit?.message
                                }
                              />
                            );
                          })()}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {(() => {
                            const requiredQty = register(
                              `array.[${index}].requiredQty`
                            );
                            return (
                              <TextField
                                style={{ width: 150, fontSize: "14px" }}
                                {...requiredQty}
                                placeholder="Required Qty"
                                inputProps={{
                                  className: viewMode
                                    ? `${classes.alignRight} ${classes.inputFont}`
                                    : `${classes.inputFont}`,
                                  maxLength: 7,
                                }}
                                InputProps={{
                                  className: classes.inputFont,
                                  maxLength: 6,
                                }}
                                onChange={(e) => {
                                  requiredQty.onChange(e);
                                  calculatePrice();
                                  calculateNetAmount();
                                }}
                                onInput={restrict.digits}
                                size="small"
                                fullWidth
                                required
                                variant="outlined"
                                disabled={viewMode}
                                error={!!errors?.array?.[index]?.requiredQty}
                                helperText={
                                  errors?.array?.[index]?.requiredQty?.message
                                }
                              />
                            );
                          })()}
                        </StyledTableCell>
                        {viewMode ? (
                          approvalStatus === "Pending" ? null : (
                            <StyledTableCell>
                              <TextField
                                style={{ width: 130, fontSize: "14px" }}
                                {...register(`array[${index}].approvedQty`)}
                                placeholder="Approved Qty"
                                inputProps={{
                                  className: viewMode
                                    ? `${classes.alignRight} ${classes.inputFont}`
                                    : `${classes.inputFont}`,
                                  maxLength: 7,
                                }}
                                InputProps={{
                                  className: classes.inputFont,
                                }}
                                size="small"
                                fullWidth
                                required
                                variant="outlined"
                                disabled={viewMode}
                              />
                            </StyledTableCell>
                          )
                        ) : null}

                        <StyledTableCell align="center">
                          <Controller
                            name={`array[${index}].reduction`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                disabled={viewMode}
                                style={{ width: 130, fontSize: "14px" }}
                                onInput={restrict.digits}
                                value={value}
                                title={value}
                                placeholder="Reduction"
                                inputProps={{
                                  className: viewMode
                                    ? `${classes.alignRight} ${classes.inputFont}`
                                    : `${classes.inputFont}`,
                                  maxLength: 6,
                                }}
                                InputProps={{
                                  className: classes.inputFont,
                                  maxLength: 6,
                                }}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                size="small"
                                fullWidth
                                variant="outlined"
                                required
                                error={!!errors?.array?.[index]?.reduction}
                                helperText={
                                  errors?.array?.[index]?.reduction?.message
                                }
                              />
                            )}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {(() => {
                            const singleQtyPrice = register(
                              `array.[${index}].singleQtyPrice`
                            );
                            return (
                              <TextField
                                style={{ width: 130, fontSize: "14px" }}
                                {...singleQtyPrice}
                                placeholder="Single Unit Price(₹)"
                                inputProps={{
                                  className: viewMode
                                    ? `${classes.alignRight} ${classes.inputFont}`
                                    : `${classes.inputFont}`,
                                  maxLength: 7,
                                }}
                                InputProps={{
                                  className: classes.inputFont,
                                  maxLength: 7,
                                }}
                                onChange={(e) => {
                                  singleQtyPrice.onChange(e);
                                  calculatePrice();
                                  calculateNetAmount();
                                }}
                                // onChange={() => calculatePrice()}
                                //  onInput={restrict.digits}
                                size="small"
                                fullWidth
                                required
                                variant="outlined"
                                disabled={viewMode}
                                error={!!errors?.array?.[index]?.singleQtyPrice}
                                helperText={
                                  errors?.array?.[index]?.singleQtyPrice
                                    ?.message
                                }
                              />
                            );
                          })()}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <TextField
                            style={{ width: 150, fontSize: "14px" }}
                            {...register(`array[${index}].price`)}
                            placeholder="Price(₹)"
                            size="small"
                            fullWidth
                            disabled
                            inputProps={{
                              className: viewMode
                                ? `${classes.alignRight} ${classes.inputFont}`
                                : `${classes.inputFont}`,
                            }}
                            InputProps={{
                              className: classes.inputFont,
                            }}
                            variant="outlined"
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {index !== 0 && (
                            <IconButton
                              style={{
                                cursor: viewMode ? "not-allowed" : "pointer",
                                pointerEvents: viewMode ? "none" : "unset",
                              }}
                              aria-label="delete"
                              onClick={() => {
                                remove(index);
                                const medArr = [...meds];

                                const filtered = medArr.filter(
                                  (e) => e != meds[index]
                                );
                                setMeds(filtered);
                                calculateNetAmount();
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={11}>
            <Grid alignItems="center" container spacing={2}>
              <Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
                <Grid alignItems="center" container spacing={2}>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <span>Total Amount (₹)</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`totalAmount`)}
                      placeholder="Total Amount(₹)"
                      size="small"
                      disabled
                      inputProps={{
                        className: viewMode
                          ? `${classes.alignRight} ${classes.inputFont}`
                          : `${classes.inputFont}`,
                      }}
                      InputProps={{
                        className: classes.inputFont,
                      }}
                      variant="outlined"
                      //  name="amount"
                      value={+netAmount}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>
                      <Typography component={"span"}>Vendor (M/S)</Typography>
                    </span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3} align="left">
                    <FormControl>
                      {viewMode ? (
                        <TextField
                          {...register(`vendorName`)}
                          placeholder="Vendor (M/S)"
                          size="small"
                          disabled={viewMode}
                          InputProps={{
                            className: classes.inputFont,
                          }}
                          value={vendorName}
                          variant="outlined"
                        />
                      ) : (
                        <FormControl
                          style={{ minWidth: "170px", width: "100%" }}
                        >
                          <Controller
                            name={`vendorName`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <ReactSelect
                                theme={reactSelectTheme}
                                fullWidth
                                openMenuOnClick={false}
                                maxMenuHeight={200}
                                placeholder="Select..."
                                isClearable={true}
                                onChange={(val) => {
                                  onChange(val);
                                  setValue(
                                    `gst`,
                                    getValues(`vendorName.gstNo`)
                                  );
                                  setValue(
                                    `cgstNo`,
                                    getValues(`vendorName.cgst`)
                                  );
                                  setValue(
                                    `sgstNo`,
                                    getValues(`vendorName.sgst`)
                                  );
                                }}
                                value={value}
                                // isOptionDisabled={option => array.map(el => el.symptomName).includes(option.signSymptomName)}
                                menuPosition="fixed"
                                options={vendorData}
                                getOptionLabel={(option) => option.vendorName}
                                getOptionValue={(option) => option.id}
                              />
                            )}
                          />
                          <FormHelperText error={!!errors.vendorName}>
                            {errors?.vendorName?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>
                      <Typography component={"span"}>GST No.</Typography>{" "}
                    </span>
                  </Grid>

                  <Grid item xs={6} sm={3} md={3} lg={3} align="left">
                    <span>
                      <TextField
                        disabled
                        {...register(`gst`)}
                        placeholder="GST No"
                        maxRows={4}
                        size="small"
                        InputProps={{
                          className: classes.inputFont,
                        }}
                        variant="outlined"
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={4} xl={4}></Grid>

              <Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
                <Grid alignItems="center" container spacing={2}>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <span>Expected Date</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <span>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          disablePast
                          autoOk
                          disabled={viewMode}
                          variant="inline"
                          value={selectedDate}
                          format="dd/MM/yyyy"
                          margin="dense"
                          maxDate={maxYear()}
                          InputProps={{
                            endAdornment: (
                              <IconButton size="small">
                                <Today color="action" />
                              </IconButton>
                            ),
                          }}
                          size="small"
                          inputVariant="outlined"
                          onChange={handleDateChange}
                          error={false}
                          helperText={null}
                        />
                      </MuiPickersUtilsProvider>
                    </span>
                  </Grid>

                  {/* <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <Typography component={"span"}>SGST</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3} align="left">
                    <TextField
                      {...register(`sgstNo`)}
                      placeholder="SGST No."
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: classes.inputFont,
                      }}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>
                      <Typography component={"span"}>CGST</Typography>
                    </span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3} align="left">
                    <span>
                      <TextField
                        {...register(`cgstNo`)}
                        placeholder="CGST No"
                        maxRows={4}
                        size="small"
                        disabled
                        InputProps={{
                          className: classes.inputFont,
                        }}
                        variant="outlined"
                      />
                    </span>
                  </Grid> */}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                <span style={{ float: "right", marginTop: 10 }}>
                  {viewMode || reOrder ? null : (
                    <>
                      <span className={classes.margin}>
                        <Button
                          onClick={handleSubmit((data) => onFormSubmit(data))}
                          variant="contained"
                          style={{ backgroundColor: myOwnColor, color: "#fff" }}
                        >
                          save
                        </Button>
                      </span>
                    </>
                  )}
                  <span className={classes.margin}>
                    <Button onClick={handleReset} variant="contained">
                      Reset
                    </Button>
                  </span>
                  {/* {viewMode ?
                   approvalStatus === "Pending" ?
                   null :
                   
                  <span className={classes.margin}>
                    <Button onClick={(e)=>{setViewMood(false);setApprovalStatus("");setSelectedDate(new Date());setReorder(true); fetchReorderMed(e)}} variant="contained">
                      Reorder
                    </Button>
                  </span>:null} */}

                  {viewMode ? (
                    receiptStatus === "Completed" ? (
                      <span className={classes.margin}>
                        <Button
                          onClick={(e) => {
                            setViewMood(false);
                            setApprovalStatus("");
                            setSelectedDate(new Date());
                            setReorder(true);
                            fetchReorderMed(e);
                          }}
                          variant="contained"
                        >
                          Reorder
                        </Button>
                      </span>
                    ) : null
                  ) : null}
                  {reOrder ? (
                    <span className={classes.margin}>
                      <Button
                        onClick={handleSubmit((data) => onFormSubmit(data))}
                        variant="contained"
                        style={{
                          backgroundColor: myOwnColor,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Submit for Approval
                      </Button>
                    </span>
                  ) : null}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10} align="right">
          <TextField
            fullWidth
            style={{ maxWidth: "300px" }}
            size="small"
            variant="outlined"
            onChange={handleSearchSubmitted}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 50 }}
            label="Search"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={11}>
          <TableContainer style={{ maxHeight: "500px" }}>
            {loading && <LinearProgress />}
            <Table stickyHeader size="small">
              <TblHeadSubmitted />
              {!!recordsAfterPagingAndSortingSubmitted()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSortingSubmitted()?.map(
                    (item, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{item.srNo}</StyledTableCell>
                        <StyledTableCell>{item.orderDate}</StyledTableCell>
                        <StyledTableCell>{item.purOrdNo}</StyledTableCell>
                        <StyledTableCell>{item.vendorName}</StyledTableCell>
                        <StyledTableCell>{item.expectedDate}</StyledTableCell>
                        <StyledTableCell>{item.totalAmount}</StyledTableCell>

                        <StyledTableCell>
                          {"approvedByName" in item
                            ? item.approvedByName
                            : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {"approvedDate" in item
                            ? moment(item.approvalDate).format("DD-MM-YYYY")
                            : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reorder === false ? "No" : "Yes"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Controls.Button
                            size="small"
                            className={
                              item.approvalStatus === "Pending"
                                ? classes.btnPending
                                : item.approvalStatus === "Approved"
                                ? classes.btnActive
                                : classes.btnInActive
                            }
                            text={item.approvalStatus}
                          ></Controls.Button>
                        </StyledTableCell>

                        <StyledTableCell>
                          <IconButton
                            color="primary"
                            onClick={(e) => {
                              reset(defaultValues);
                              setReorder(false);
                              handleViewRecord(e, item.purOrdId);
                            }}
                          >
                            <FontAwesomeIcon
                              title="view"
                              style={{ color: "#1957b3", fontSize: "13px" }}
                              icon={faEye}
                            />
                          </IconButton>

                          <IconButton
                            color="primary"
                            onClick={() =>
                              format === printDocument(item.purOrdId)
                            }
                          >
                            <FontAwesomeIcon
                              title="download pdf"
                              style={{ color: "#f55140", fontSize: "15px" }}
                              icon={faFilePdf}
                            />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <StyledTableCell colSpan={8} align="center">
                      No Records Found
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TblPaginationSubmitted />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        justifyContent="center"
        s
      >
        <DialogTitle
          id="alert-dialog-title"
          align="center"
          style={{ width: 300 }}
        >
          <p
            style={{
              fontWeight: 400,
              lineHeight: "26px",
              margin: "0px",
              fontSize: 24,
            }}
          >
            Are you sure.?
          </p>
        </DialogTitle>
        {/* <DialogContent></DialogContent> */}
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            onClick={(e) => {
              handleClosesave(e);
            }}
            color="primary"
            variant="contained"
            size="small"
          >
            Yes
          </Button>
          <Button
            onClick={(e) => {
              handleClose();
            }}
            variant="contained"
            size="small"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PurchaseOrderRequest;
