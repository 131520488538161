import { 
  FETCH_COMPLIANCE_HISTORY,
  FETCH_PROGNOSIS_HISTORY,
  FETCH_PROGNOSIS,
  SAVE_PROGNOSIS,
  SAVE_COMPLIANCE
  } from "../actions/Type";
  
  const initialState = {
        complianceHistory :[],
        prognosisHistory:[],
        complaints:[],
        // uniqueCities:[],
        // departments:[],
        // apptId: String
    };

    const CompliancePrognosisReducer = (state = initialState, action) => {
        switch (action.type) {
        
              case FETCH_COMPLIANCE_HISTORY:
              return {
                  ...state,
                  complianceHistory: action.payload,
                
              };

              case FETCH_PROGNOSIS_HISTORY:
              return {
                  ...state,
                  prognosisHistory: action.payload,
                
              };

              case FETCH_PROGNOSIS:
                return {
                    ...state,
                    complaints: action.payload,
                  
                };

              
          default:
            return state;
        }
      };
      
      export default CompliancePrognosisReducer;