import {
    FETCH_FEEDBACK_LIST,
    FETCH_ALL_FEEDBACK,
    FETCH_ALL_HEALTH_FACILITIES_LIST
  } from "../actions/Type";
  
  const initialState = {
    goodFeedBack: [],
     allFeedBack: [],
     allHealthFacilitiesList: [],
    // staff: [],
    // saveDutyRoster:[],
    // delDutyRoster: []
    // apptId: String
  };
  
  const FeedBackReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_FEEDBACK_LIST:
        return {
          ...state,
          goodFeedBack: action.payload,
  
        };
  
  
      case FETCH_ALL_FEEDBACK:
        return {
          ...state,
          allFeedBack: action.payload
        };
      case FETCH_ALL_HEALTH_FACILITIES_LIST:
        return {
          ...state,
          allHealthFacilitiesList: action.payload
        };
    //   case FETCH_STAFF:
    //     return {
    //       ...state,
    //       staff: action.payload
    //     };
  
    //   case DELETE_DUTYROSTER:
    //     return {
    //       ...state,
    //       delDutyRoster: action.payload,
  
    //     };
    //     case SAVE_DUTYROSTER:
    //       return {
    //           ...state,
    //           saveDutyRoster: action.payload,
    //       };
  
      default:
        return state;
    }
  };
  
  export default FeedBackReducer;