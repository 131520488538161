import React from "react";
import { withStyles, makeStyles, alpha } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
//material-ui
import { Grid, IconButton, InputBase, Tooltip, Typography } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { Controller, useWatch, useFieldArray } from "react-hook-form";

import { useDispatch } from "react-redux";
import { restrict } from "../../../component/restrict";
import { defaultComplexRow } from "./NewEntry";
import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
//css defined here
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  cell: {
    padding: "5px",
  },
  input: {
    height: 35,
    width: 130,
  },
  input2: {
    height: 35,
    width: 120,
    [theme.breakpoints.down("lg")]: {
      width: 75,
    },
  },
  input3: {
    height: 35,
    width: 105,
  },
  input5: {
    height: 35,
    width: 200,
    [theme.breakpoints.down("lg")]: {
      width: 100,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    color: "inherit",
    border: "1px solid #AAAAAA",
    borderRadius: 4,
    height: 35,
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  icon: {
    color: "#ff6600",
    fontSize: "16px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "16px",
  },
  addIcon: {
    padding: 5,
    marginTop: "-10px",
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    height: 22,
    width: 120,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "5px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    [theme.breakpoints.down("lg")]: {
      width: 100,
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const StyledTableCell = withStyles((theme) => ({
  head: {
    // color: theme.palette.common.white,
    color: "111",
    // padding: "12px",
    padding: "1px",
    fontSize: "14px",
    fontWeight: "none",
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function GroupedMedicineTable({
  remove,
  control,
  register,
  index: mainIndex,
  errors,
  getValues,
  setValue,
  setError,
  watch,
  complexMedicineTypes,
  complexMedicines,
  drugIntervals,
  vehicleNames,
  editData,
  viewData,
  reset,
  viewMood,
  pendingApproval,
  CheckError,
  deleted,
  medicine,
  isReceipt,
}) {
  const classes = useStyles();
  const [meds, setMeds] = React.useState([]);
  const [errosMsg, errorMsg] = React.useState("");
  const dispatch = useDispatch();
  console.log(isReceipt, "isReceipt");
  const rows = useFieldArray({
    control,
    name: `complex[${mainIndex}].rows`,
  });

  const calculateTotalQuantity = () => {
    const interval = getValues(`complex.${mainIndex}.drugIntervalId`);
    const noDays = getValues(`complex[${mainIndex}].noDays`);
    const rows = getValues(`complex.${mainIndex}.rows`);
    rows.forEach((row, index) => {
      const intervalEntry = drugIntervals?.find((d) => d.id === interval);
      if (intervalEntry) {
        const intervalValue = intervalEntry?.drugIntervalValue;
        const intervalName = intervalEntry?.shortName;
        // const totalQuantity = noDays * row.dosage * intervalValue;
        // console.log("total", noDays, rows.dosage, totalQuantity);
        if (intervalName === "SOS") {
          setValue(`complex[${mainIndex}].rows[${index}].totalQuantity`, row.dosage);
        } else {
          const totalQuantity = noDays * row.dosage * intervalValue;
          if (!isNaN(totalQuantity)) {
            setValue(`complex[${mainIndex}].rows[${index}].totalQuantity`, totalQuantity);
          }
        }
      }
    });
  };

  const ValidateQty = (e, val, index, mainIndex) => {
    e.preventDefault();
    const getApprovedQty = getValues(`complex.${mainIndex}.approvedQty`);

    const rows = getValues(`complex.${mainIndex}.rows`);

    let amt = 0;
    rows?.forEach((row, index) => {
      const rowAmount = +row.qty;
      amt += rowAmount;
    });

    setValue(`complex.${mainIndex}.remainingQty`, getApprovedQty - amt);
    if (amt > getApprovedQty) {
      if (pendingApproval) {
        errorMsg("Must be <= pending qty");

        CheckError(e, "Must be <= pending qty");
      } else {
        errorMsg("Must be <= approved qty");

        CheckError(e, "Must be <= approved qty");
      }
      //  setError(`complex.${mainIndex}.rows.${index}.qty`, { type: 'text', message: 'Quantity should  be less than approved qty' });
    } else {
      errorMsg("");
      CheckError(e, "");
    }
  };
  console.log(rows, "medicine");

  return (
    <>
      <TableBody>
        {rows.fields.map((row, index) => (
          <TableRow key={row.id}>
            {index + 1 === 1 ? (
              <TableCell
                align="center"
                className={classes.cell}
                style={{ borderRight: "1px solid lightgrey" }}
                rowSpan={index + 1 === 1 ? rows?.fields?.length : null}
              >
                {mainIndex + 1}.
              </TableCell>
            ) : null}

            <TableCell className={classes.cell}>
              <FormControl style={{ minWidth: "150px" }}>
                {index === 0 && (
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "14px" }}>
                        {getValues()?.complex[mainIndex]?.drugTypeName}
                      </Typography>
                    }
                    arrow
                  >
                    <TextField
                      style={{ width: "150px" }}
                      disabled
                      InputProps={{
                        className: classes.inputFont,
                      }}
                      {...register(`complex.${mainIndex}.drugTypeName`)}
                      placeholder="Drug Type"
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  </Tooltip>
                )}
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl style={{ minWidth: "150px" }}>
                {index === 0 && (
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "14px" }}>{getValues()?.complex[mainIndex]?.drugName}</Typography>
                    }
                    arrow
                  >
                    <TextField
                      disabled
                      style={{ width: "150px", fontSize: "14px" }}
                      {...register(`complex.${mainIndex}.drugName`)}
                      placeholder="Drug Type"
                      InputProps={{
                        className: classes.inputFont,
                      }}
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  </Tooltip>
                )}
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  style={{ width: "80px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.uomName`)}
                  placeholder="UOM"
                  size="small"
                  fullWidth
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                  disabled
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  disabled
                  style={{ width: "120px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.singleUnit`)}
                  onInput={restrict.number}
                  placeholder="Single Unit"
                  size="small"
                  fullWidth
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              <Controller
                control={control}
                name={`complex.${mainIndex}.rows.${index}.batchNo`}
                render={({ field: { onChange, value } }) => (
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "14px" }}>
                        {getValues()?.complex[mainIndex]?.rows[index]?.batchNo}
                      </Typography>
                    }
                    arrow
                  >
                    <TextField
                      InputProps={{
                        className: classes.input2,
                      }}
                      classes={{
                        root: classes.customTextField,
                      }}
                      inputProps={{
                        className: `${classes.alignRight} ${classes.inputFont}`,
                      }}
                      onChange={(e, value) => {
                        onChange(e, value);
                      }}
                      value={getValues(`complex.${mainIndex}.rows.${index}.batchNo`)}
                      disabled={viewMood}
                      error={!!errors?.rows?.[index]?.batchNo}
                      helperText={errors?.rows?.[index]?.batchNo?.message}
                      placeholder="Batch No"
                      size="small"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Tooltip>
                )}
              />
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl>
                <Controller
                  control={control}
                  name={`complex.${mainIndex}.rows.${index}.qty`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={getValues(`complex.${mainIndex}.rows.${index}.qty`)}
                      onChange={(e, value) => {
                        onChange(e, value);

                        ValidateQty(e, value, index, mainIndex);
                      }}
                      InputProps={{
                        className: classes.input2,
                      }}
                      classes={{
                        root: classes.customTextField,
                      }}
                      inputProps={{
                        className: `${classes.alignRight} ${classes.inputFont}`,
                        maxLength: 7,
                      }}
                      onInput={restrict.number}
                      placeholder="Quantity"
                      disabled={viewMood}
                      required
                      error={!!errors?.rows?.[index]?.qty || !!errosMsg.length}
                      helperText={errors?.rows?.[index]?.qty?.message || errosMsg}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.cell}>
              <FormControl>
                <Controller
                  control={control}
                  name={`complex.${mainIndex}.rows.${index}.mfgDate`}
                  render={({ field: { onChange, value } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableFuture
                        autoOk
                        fullWidth
                        inputVariant="outlined"
                        size="small"
                        onKeyDown={(e) => e.preventDefault()}
                        disabled={viewMood}
                        format="dd/MM/yyyy"
                        // name="dob"
                        value={getValues(`complex.${mainIndex}.rows.${index}.mfgDate`)}
                        onChange={(date) => onChange(date)}
                        error={!!errors?.rows?.[index]?.mfgDate}
                        helperText={errors?.rows?.[index]?.mfgDate?.message}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl>
                <Controller
                  control={control}
                  name={`complex.${mainIndex}.rows.${index}.expDate`}
                  render={({ field: { onChange, value } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disabled={viewMood}
                        autoOk
                        fullWidth
                        inputVariant="outlined"
                        size="small"
                        onKeyDown={(e) => e.preventDefault()}
                        format="dd/MM/yyyy"
                        // name="dob"
                        value={getValues(`complex.${mainIndex}.rows.${index}.expDate`)}
                        onChange={(date) => onChange(date)}
                        error={!!errors?.rows?.[index]?.expDate}
                        helperText={errors?.rows?.[index]?.expDate?.message}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  {...register(`complex.${mainIndex}.approvedQty`)}
                  InputProps={{
                    className: classes.input2,
                  }}
                  classes={{
                    root: classes.customTextField,
                  }}
                  placeholder="Approved Qty"
                  size="small"
                  variant="outlined"
                  disabled
                  fullWidth
                  required
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  {...register(`complex.${mainIndex}.singleQtyPrice`)}
                  InputProps={{
                    className: classes.input5,
                  }}
                  // onInput={(e) => {
                  //   restrict.remarks(e);
                  //   inputTrim(e);
                  // }}

                  classes={{
                    root: classes.customTextField,
                  }}
                  placeholder="Remarks"
                  size="small"
                  variant="outlined"
                  disabled
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  fullWidth
                />
              )}
            </TableCell>
            <TableCell>
              {index === 0 && (
                <TextField
                  {...register(`complex.${mainIndex}.price`)}
                  InputProps={{
                    className: classes.input5,
                  }}
                  disabled
                  // onInput={(e) => {
                  //   restrict.remarks(e);
                  //   inputTrim(e);
                  // }}

                  classes={{
                    root: classes.customTextField,
                  }}
                  placeholder="Remarks"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  fullWidth
                />
              )}
            </TableCell>
            {!isReceipt && (
              <TableCell className={classes.cell}>
                {index === 0 ? (
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Add Row" arrow>
                      <IconButton
                        disabled={viewMood}
                        onClick={() => {
                          rows.append(defaultComplexRow());
                          const data = [...meds];
                          data.push(0);
                          setMeds(data);
                        }}
                        style={{ height: "20px", width: "20px" }}
                      >
                        <Add style={{ color: "#0B9CAB" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Complex Medicine" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          remove(mainIndex);
                          deleted(false);
                        }}
                        size="small"
                        disabled={viewMood}
                      >
                        <Delete title="Delete" color="error" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip title="Delete Row" arrow>
                    <IconButton
                      disabled={viewMood}
                      onClick={() => {
                        rows.remove(index);
                        const medArr = [...meds];
                        // console.log("popped meds", meds[index]);
                        const filtered = medArr.filter((e) => e != meds[index]);
                        setMeds(filtered);
                      }}
                      size="small"
                    >
                      <Delete title="Delete" color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

function ComplexMedicineTable(
  {
    control,
    register,
    watch,
    reset,
    setValue,
    setError,
    getValues,
    errors,
    uncheckHistory,
    complexMedicines,
    setComplexMedicines,
    editData,
    viewData,
    viewMood,
    pendingApproval,
    CheckError,
    deleted,
    isReceipt,
  },
  ref
) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const complexValue = useFieldArray({
    control,
    name: "complex",
  });

  React.useImperativeHandle(ref, () => ({
    add: (data, mainIndex, index) => {
      const newData = { ...data, history: `${mainIndex}|${index}` };
      complexValue.append(newData);
    },
    remove: (mainIndex, index) => {
      const history = `${mainIndex}|${index}`;
      // console.log(history,"complex")
      const values = getValues("complex");
      const newValues = values.filter((x) => x.history !== history);
      setValue("complex", newValues);
    },
  }));

  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);

  let complexMedicineTypes = useSelector((state) => state.complexMedicineReducer.complexMedicineTypes);

  let vehicleNames = useSelector((state) => state.simpleMedicineReducer.vehicleName);

  let drugIntervals = useSelector((state) => state.simpleMedicineReducer.drugIntervals);

  const medicineChange = useWatch({
    control,
    name: "complex",
  });

  React.useEffect(() => {
    if (pendingApproval) {
      if (editData) {
        reset({
          complex: editData?.purchaseOrderDetailsDtos.map((el) => ({
            ...el,
            rows: [{ batchNo: "", qty: "", mfgDate: "", expDate: "" }],

            drugTypeName: el.drugTypeName,
            drugName: el.drugName,
            uomName: el.uomName,
            singleUnit: el.singleUnit,
            approvedQty: el.remainingQty,
            singleQtyPrice: el.singleQtyPrice,
            price: el.price,
            dtoId: el.id,
          })),
        });

        setValue("gst", editData?.vendorGstNo);
        setValue("vendorName", editData?.vendorName);
        setValue("totalBillAmount", editData?.totalBillAmount);
        setValue("expectedDate", moment(viewData?.expectedDate).format("DD-MM-YYYY"));
      }
    } else {
      if (editData) {
        reset({
          complex: editData?.purchaseOrderDetailsDtos.map((el) => ({
            rows: [{ batchNo: "", qty: "", mfgDate: "", expDate: "" }],

            drugTypeName: el.drugTypeName,
            drugName: el.drugName,
            uomName: el.uomName,
            singleUnit: el.singleUnit,
            approvedQty: el.approvedQty,
            singleQtyPrice: el.singleQtyPrice,
            price: el.price,
            dtoId: el.id,
          })),
        });
        setValue("gst", editData?.vendorGstNo);
        setValue("vendorName", editData?.vendorName);
        setValue("totalBillAmount", editData?.totalBillAmount);
        setValue("expectedDate", moment(viewData?.expectedDate).format("DD-MM-YYYY"));
      }
    }
  }, [editData]);

  React.useEffect(() => {
    if (viewData) {
      reset({
        complex: viewData?.purchaseOrderDetailsDtos?.map((el, index) => ({
          drugTypeName: el.drugTypeName,
          drugName: el.drugName,
          uomName: el.uomName,
          singleUnit: el.singleUnit,
          approvedQty: el.approvedQty,
          singleQtyPrice: el.singleQtyPrice,
          price: el.price,
          dtoId: el.id,

          rows: el?.entryDetailsDrugDtl?.map((ml, index) => ({
            batchNo: ml.serialNo,
            qty: ml.receivedQty,
            mfgDate: moment(ml.manufactureDate).format("YYYY-MM-DD"),
            expDate: moment(ml.expiryDate).format("YYYY-MM-DD"),
          })),
        })),
      });

      setValue("gst", viewData?.vendorGstNo);
      setValue("vendorName", viewData?.vendorName);
      setValue("totalBillAmount", viewData?.totalBillAmount);
      setValue("billNo", viewData?.billNo);
      setValue("expectedDate", moment(viewData?.expectedDate).format("DD-MM-YYYY"));
    }
  }, [reset, setValue, viewData]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={11}></Grid>

        <Grid item xs={12}>
          {/* <input {...register(`complex.${index}.history`)} type="hidden" /> */}
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <StyledTableRow align="center" style={{ backgroundColor: "#caf7fb" }}>
                  <StyledTableCell align="center"> S. No</StyledTableCell>
                  <StyledTableCell align="center">Drug Type </StyledTableCell>
                  <StyledTableCell align="center"> Drug Name</StyledTableCell>
                  <StyledTableCell align="center"> UoM</StyledTableCell>
                  <StyledTableCell align="center">Single Unit</StyledTableCell>
                  <StyledTableCell align="center">Batch No.</StyledTableCell>
                  <StyledTableCell align="center">Qty</StyledTableCell>
                  <StyledTableCell align="center">Mfg. Date</StyledTableCell>
                  <StyledTableCell align="center">Exp. Date</StyledTableCell>
                  <StyledTableCell align="center"> {pendingApproval ? "Pending Qty" : "Approved Qty"}</StyledTableCell>
                  <StyledTableCell align="center">Single Unit Price(₹)</StyledTableCell>
                  <StyledTableCell align="center">Price(₹)</StyledTableCell>
                  {!isReceipt && (<StyledTableCell align="center">Action</StyledTableCell> )}
                  {/* <StyledTableCell align="center"> </StyledTableCell> */}
                </StyledTableRow>
              </TableHead>

              {complexValue.fields.map((medicine, index) => (
                <GroupedMedicineTable
                  errors={errors?.[index]}
                  remove={() => {
                    const history = getValues(`complex[${index}].history`);
                    if (typeof history === "string") {
                      const split = history.split("|");
                      const mainIndex = split[0];
                      const index = split[1];
                      if (mainIndex !== undefined && index !== undefined) {
                        uncheckHistory(mainIndex, index);
                      }
                    }
                    complexValue.remove(index);
                  }}
                  {...{
                    index,
                    complexValue,
                    control,
                    register,
                    watch,
                    reset,
                    medicine,
                    complexMedicineTypes,
                    complexMedicines,
                    drugIntervals,
                    vehicleNames,
                    getValues,
                    setValue,
                    setError,
                    viewMood,
                    pendingApproval,
                    CheckError,
                    deleted,
                    isReceipt,
                  }}
                />
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <br />
    </>
  );
}

const EntryForm = React.forwardRef(ComplexMedicineTable);
export default React.memo(EntryForm);
