import { showSnackbar } from "./Notification";
import { FETCH_ADVICE_LIST, FETCH_PHARMACY_DO_DONT } from "./Type";
import axiosInstance from "../api/Instance";
import ipdOpdInstance from "../api/IpdOpdInstance";

export const fetchAdviceDetails = (somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`advice/getAllAdvices/${somId}`);
    dispatch({
      type: FETCH_ADVICE_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchPharmacyDoDont = (consultationId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(
      `dosDonts/fetchSavedAdvices/${consultationId}`
    );
    dispatch({
      type: FETCH_PHARMACY_DO_DONT,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveAdviceDetails = (adviceDetails) => async (dispatch) => {
  try {
    const url = "advice/saveAdvice";

    const response = await axiosInstance.post(url, adviceDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      if (adviceDetails.id == 0) {
        responseObj.message = "Advice details added successfully";
      } else {
        responseObj.message = "Advice details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const deleteAdvice = (adviceId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`advice/deleteAdvice/${adviceId}`);

    console.log("deleteAdvice", res.data);
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res.data == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Advice details deleted successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveDosDonts = (dosDonts) => async (dispatch) => {
  try {
    const url = "dosDonts/saveDosDonts";

    const response = await ipdOpdInstance.post(url, dosDonts);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      responseObj.message = "Do's & Dont's added successfully.";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveAdvicesForPatient = (dosDonts) => async (dispatch) => {
  try {
    const url = "dosDonts/saveAdvices";
    const response = await ipdOpdInstance.post(url, dosDonts);
    const res = response.data;

    console.log("save advice response", res);
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};
