import { createTheme } from "@material-ui/core/styles";

import { changeThemes } from "../actions/Index";

const theme1 = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      light:"#caf7fb",
      main: "#2E9683",
      dark:"#07A0B0"
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#25b233",
    },
    warning: {
      main: "#f2f0f2",
    },
    myColor: {
      main: "#CDF5FA",
    },
  },
});

const theme2 = createTheme({
  palette: {
    primary: {
      main: "#ba000d",
    },
    secondary: {
      main: "#0fb7bd",
    },
    warning: {
      main: "#f2f0f2",
    },
    myColor: {
      main: "#d30fd6",
    },
  },

  // typography: {
  //   h5: {
  //     fontSize: "10px",
  //   },
  // },
});

const theme3 = createTheme({
  palette: {
    primary: {
      main: "#712a80",
    },
    secondary: {
      main: "#2515d1",
    },
    warning: {
      main: "#f2f0f2",
    },
    myColor: {
      main: "#0e9c9c",
    },
  },

});

const initialState = {
  selectedTheme: theme1,
  selectedColor: "#07A0B0",
  selectedMenuTheme: '#CAF7FB'
};

const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_THEME":
      return {
        ...state,
        selectedTheme: action.themeName,
      };
    case "CHANGE_COLOR":
      return {
        ...state,
        selectedColor: action.selectedColor,
        //m : action.selectedColor,
      };
      case "CHANGE_MENU_COLOR":
        return {
          ...state,
          selectedMenuTheme: action.selectedMenuTheme,
        };
    default:
      return state;
  }
};
export default MainReducer;

export const changeLang = (language) => {
  return function (dispatch) {
    // return i18n.changeLanguage(language);
  };
};

export const changeTheme = (themeName) => {
  return function (dispatch) {
    if (themeName === "theme1") {
      dispatch(changeThemes(theme1));
    } else if (themeName === "theme2") {
      dispatch(changeThemes(theme2));
    } else if (themeName === "theme3") {
      dispatch(changeThemes(theme3));
    } else {
      dispatch(changeThemes(theme3));
    }
  };
};
