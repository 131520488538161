import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//material-ui
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  InputAdornment,
  IconButton,
  TableBody,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import _ from "lodash";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InventoryInstance from "../../../api/InventoryInstance";
//icons
import { Search } from "@material-ui/icons";
import PurchaseReturnForm from "./PurchaseReturnForm";
import { SHOW_SNACKBAR } from "../../../actions/Type";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NotificationSuccess from "../../../component/Notification/NotificationSuccess";
import moment from "moment";
import Controls from "../../../component/controls/Controls";
import useTable from "../../../component/useTable";
import { faEdit, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import DialogActions from "@material-ui/core/DialogActions";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ahmis from "../../../assets/ahmis.png";
import leaf from "../../../assets/leaf.png";
const headCells = [
  { id: "srNo", label: "Sl No." },
  { id: "purchaseOrderId", label: "Purchase Order ID" },
  { id: "vendorName", label: "Vendor Name" },
  { id: "totalBillAmount", label: "Total Bill Amount (₹)" },

  { id: "billNo", label: "Bill No." },
  { id: "receiptDate", label: "Receipt Date" },

  { id: "returnNo", label: "Return No." },
  { id: "action", label: "Action", disableSorting: true },
];
//css defined
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const AccordionSummary = withStyles(theme => ({
  root: {
    color: "white",
    minHeight: "40px",
    "&$expanded": {
      minHeight: "40px",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },

  expanded: {},
}))(MuiAccordionSummary);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "0.5%",
    marginLeft: "-0.5%",
  },

  icon: {
    color: "white",
  },
  content: {
    backgroundColor: "white",
    color: "black",
    display: "block",
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnPending: {
    backgroundColor: "rgb(231 108 25)",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
}));

export const defaultComplexRow = () => ({
  batchNo: "",
  qty: "",
  mfgDate: null,
  expDate: null,
  returnedQty: "",
  stockQty: "",
  isSelect: false,
  id: 0,
});

export const defaultComplex = () => ({
  drugTypeName: "",
  drugName: "",
  uomName: "",
  singleUnit: "",
  approvedQty: "",
  singleQtyPrice: "",
  price: "",
  id: 0,
  remark: "",
  purchaseDetId: 0,
  rows: [defaultComplexRow()],
});

const [W, H] = [210, 297];
const margin = 10;

const style = {
  cellPadding: 1, // a number, array or object (see margin below)
  fontSize: 8,
  font: "helvetica", // helvetica, times, courier
  lineWidth: 0.01,
  lineColor: 127,
  fontStyle: "normal", // normal, bold, italic, bolditalic
  overflow: "linebreak", // visible, hidden, ellipsize or linebreak
  fillColor: false, // false for transparent or a color as described below
  textColor: 0,
  halign: "left", // left, center, right
  valign: "middle", // top, middle, bottom
  cellWidth: "auto", // 'auto', 'wrap' or a number
};

function Inner() {
  const classes = useStyles();
  const myOwnColor = useSelector(state => state.MainReducer.selectedColor);
  const dispatch = useDispatch();

  const complexRowSchema = yup.object().shape({
    batchNo: yup.string(),

    stockQty: yup.string(),
    qty: yup.string(),
    mfgDate: yup.string(),

    expDate: yup.string(),
    id: yup.string(),
    isSelect: yup.boolean(),

    returnedQty: yup.string().when(["isSelect", "qty"], (isSelect, qty) => {
      if (isSelect === true) {
        return yup.number().nullable().typeError("This field  is required").max(qty, "Must be <=  received Qty");
      }
    }),
  });
  //validation
  const complexSchema = yup.object().shape({
    drugTypeName: yup.string(),
    drugName: yup.string(),
    uomName: yup.string(),
    singleUnit: yup.string(),
    approvedQty: yup.string(),
    singleQtyPrice: yup.string(),
    price: yup.string(),
    id: yup.string(),
    purchaseDetId: yup.string(),
    remark: yup.string(),
    rows: yup.array().of(complexRowSchema).required("Must have fields"),
  });

  const schema = yup.object().shape({
    billNo: yup.string(),
    complex: yup.array().of(complexSchema).required("Must have fields"),
  });

  const defaultValues = {
    complex: [],
    billNo: "",
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    setValue,
    setError,
    getValues,
    trigger,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const complexRef = React.useRef(null);

  const staff = useSelector(state => state.loginReducer.staffLogin);

  const orgId = staff?.orgId;
  const staffId = staff?.staffId;
  const storeId = staff?.storeId;

  const [editData, setDataEdit] = React.useState(null);
  const [viewData, setdata] = React.useState(null);
  const [overAllData, setOverAllData] = React.useState(null);

  const [createdDate, setCreateddate] = React.useState();
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [viewMood, setViewMood] = React.useState(false);

  const [VendorName, setVendorName] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [entryId, setEntryId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [orderID, setOrderId] = React.useState("");
  const [totalBillAmount, setTotalBillAmount] = React.useState();
  const [records, setRecords] = React.useState([]);
  const [purchaseOrderId, setPurchaseOrderId] = useState("");
  const [filterFn, setFilterFn] = React.useState({
    fn: items => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn);

  const handleSearch = e => {
    let value = e.target.value;

    setFilterFn({
      fn: items => {
        if (value === "") {
          return items;
        }
        return items.filter(
          x =>
            x.billNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.purchaseOrderNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.vendorName?.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
  };

  const fetchpurchaseReceipt = () => {
    setLoading(true);
    InventoryInstance.get("purchaseReturn/fetchOrdByOrg/" + orgId)
      .then(res => {
        var mydatais = res.data.sort((a, b) => a.id - b.id);

        setRecords(mydatais);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchBillData = async (orderId, entryId) => {
    setIsEditMode(true);
    setEntryId(entryId);
    setOrderId(orderId);
    try {
      const { data } = await InventoryInstance.get(`purchaseReturn/fetchByEntryId/${orderId}/${entryId}`);
      setdata(data);

      setCreateddate(data.createdDatetimeString);
      setTotalBillAmount(data.totalBillAmount);
      setPurchaseOrderId(data.purchaseOrderNo);
    } catch (error) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          msg: "Something went wrong",
          openSnackbar: true,
        },
      });
    }
    //  InventoryInstance.get(`purchaseReturn/fetchByEntryId/${orderId}/${entryId}`)
    //     .then((response) => {
    //       return response.data;
    //     })
    //     .then((data) => {
    //       setdata(data);

    //       setCreateddate(data.createdDatetimeString);
    //       setTotalBillAmount(data.totalBillAmount);

    //     })
    //     .catch(() => {
    //       dispatch({
    //         type: SHOW_SNACKBAR,
    //         payload: {
    //           type: "error",
    //           msg: "Something went wrong",
    //           openSnackbar: true,
    //         },
    //       });
    //     });
  };

  const fetchpdfdata = (orderId, entryId) => {
    setEntryId(entryId);
    setOrderId(orderId);

    InventoryInstance.get(`purchaseReturn/fetchByEntryId/${orderId}/${entryId}`)
      .then(response => {
        return response.data;
      })
      .then(data => {
        const reportName = "Purchase Return Report";

        generatePdf(reportName, data, staff);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const generatePdf = (report, data, staffData) => {
    console.log(data);
    const org = staffData.orgName;

    const now = moment(new Date()).format("DD-MM-YYYY");
    const time = new Date().toLocaleTimeString();

    const filterData = data.purchaseOrderDetailsDtos.filter(item => item.entryDetailsDrugDtl.length);
    console.log(filterData, "filterData");
    let amt = 0;
    filterData.forEach(item => {
      if (item.returnAmount !== undefined) {
        const price = +item.returnAmount;
        amt += price;
      }
    });
    const body = [
      ...filterData
        .map((d, index) => [
          [
            {
              content: d.drugTypeName,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d.drugName,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d.uomName,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d?.singleUnit === undefined ? "" : d?.singleUnit,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "center" },
            },
            {
              content: d.requiredQty,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "center" },
            },
            {
              content: d.approvedQty,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "center" },
            },
            {
              content: d.returnAmount,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "center" },
            },
            moment(d.entryDetailsDrugDtl[0].manufactureDate).format("DD-MM-YYYY"),
            moment(d.entryDetailsDrugDtl[0].expiryDate).format("DD-MM-YYYY"),
            d.entryDetailsDrugDtl[0].serialNo,
            d.entryDetailsDrugDtl[0].returnQty,
          ],
          ...d.entryDetailsDrugDtl.slice(1).map(ml => [moment(ml.manufactureDate).format("DD-MM-YYYY"), moment(ml.expiryDate).format("DD-MM-YYYY"), ml.serialNo, ml.returnQty]),
        ])
        .flat(),
    ];
    console.log(body, "body");
    const pdf = new jsPDF("p", "mm", [W, H], true);
    let Y = margin;
    pdf.addImage(ahmis, "PNG", margin, Y + 2, 15, 15);
    pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
    pdf.setFontSize(14);
    let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
    pdf.text(splitTitle, W / 2, Y, { align: "center" });
    pdf.setFontSize(13);
    pdf.text(report, W / 2, Y + 15, "center");
    Y += 30;
    pdf.setFontSize(10);
    pdf.text(`Purchase Order No.: ${data.purchaseOrderNo}`, margin, Y);
    // pdf.text(`Status: ${filterData[0]?.entryStatus},`, margin, Y + 5);
    pdf.text(`Vendor Name: ${data?.vendorName},`, margin, Y + 11);
    pdf.text(`GST No.: ${data?.vendorGstNo},`, margin, Y + 17);
    pdf.text(`CGST: ${data?.vendorCgst}`, W - margin, Y + 11, "right");
    pdf.text(`SGST: ${data?.vendorSgst}`, W - margin, Y + 17, "right");

    Y += 26;

    pdf.autoTable({
      head: [["Drug Type", "Drug Name", "UOM", "Single Unit", "Required Qty", "Approved Qty", "Amount(₹)", "Mfg. Date", "Exp. Date", "Batch No.", "Return Qty"]],
      body: body,

      foot: [
        [
          {
            content: `Total Amount(Rs).: ${amt}`,
            colSpan: 11,
            styles: {
              halign: "right",
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
          },
        ],
      ],
      startY: Y,
      margin,
      headStyles: {
        ...style,
        fillColor: [11, 156, 171],
        textColor: 255,
        lineColor: 255,
        fontSize: 8,
        fontWeight: 500,
      },
      bodyStyles: { ...style },
      footStyles: { ...style },
    });

    pdf.save(`Purchase Return Report-${now}, ${time}.pdf`);
  };

  const onFormSubmit = data => {
    console.log(data.complex, "form Data");

    const result = data.complex
      .map(item => ({
        ...item,
        rows: item.rows.filter(child => child.isSelect === true),
      }))
      .filter(item => item.rows.length > 0);

    const req = {
      purchaseEntryId: entryId,
      retTotalAmount: result.totalBillAmount,
      organizationId: orgId,
      createdBy: staffId,
      storeId: storeId,
      approvalStatus: 1,
      purchaseReturnDetailsDtos: result.map((el, index) => ({
        remarks: el.remark,
        purchaseEntryDetId: +el.purchaseDetId,
        amount: el.price,
        retrunDetailsDrugDtos: result[index].rows.map((ml, index) => ({
          returnQty: ml.returnedQty,
          entryDetDruId: ml.id,
          batchNo: ml.batchNo,
          expDate: ml.expDate,
        })),
      })),
    };

    if (result.length !== 0) {
      InventoryInstance.post(`purchaseReturn/save`, req)
        .then(response => {
          if (response.data === "success") {
            setOpen(false);
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "success",
                msg: "Record Updated successfully",
                openSnackbar: true,
              },
            });
            // reset(defaultValues);
            setIsEditMode(false);

            fetchpurchaseReceipt();
          } else {
            setOpen(false);
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "error",
                msg: "Something went wrong",
                openSnackbar: true,
              },
            });
          }
        })
        .catch(() => {
          setOpen(false);
          console.log("error");
        });
    } else {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          msg: "Please at least one Drug Type",
          openSnackbar: true,
        },
      });
    }
  };
  console.log(errors);
  const handleReceipt = () => {
    const req = {
      purchaseOrderId: orderID,
      organizationId: orgId,
      createdBy: staffId,
      approvalStatus: 1,
      billNo: viewData.billNo,
      purchaseEntryId: entryId,

      purchaseOrderNo: viewData.purchaseOrderNo,
      vendorNameId: viewData.vendorNameId,
      totalBillAmount: viewData.totalBillAmount,
      expectedDate: moment(viewData.expectedDate).format("YYYY-MM-DD"),
    };

    InventoryInstance.post(`purchaseReceipt/save`, req)
      .then(response => {
        if (response.data === "success") {
          setOpen(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "success",
              msg: "Record Updated successfully",
              openSnackbar: true,
            },
          });
          reset(defaultValues);
          setIsEditMode(false);
          // setViewMood(false);

          fetchpurchaseReceipt();
        } else {
          setOpen(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              msg: "Something went wrong",
              openSnackbar: true,
            },
          });
        }
      })
      .catch(() => {
        setOpen(false);
        console.log("error");
      });
  };

  const handleClose = (e, type) => {
    setOpen(false);
  };
  React.useEffect(() => {
    fetchpurchaseReceipt();
  }, []);

  const initialValue = 0;

  const getSum = arr => {
    if (Array.isArray(arr)) {
      return arr.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
    }
  };

  const getlength = overAllData && overAllData.purchaseOrderDetailsDtos.map((d, index) => getSum(d.entryDetDtoSet.map(a => a.entryDetailsDrugDtos.length)));

  const CheckError = (e, childData) => {
    e.preventDefault();
  };

  // console.log(getValues().map(()))

  return (
    <>
      <div className={classes.root}>
        <NotificationSuccess />
        {isEditMode ? (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={3} style={{ marginTop: "1%" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={11}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>Purchase ID</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`date`)}
                      placeholder="Purchase Id"
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont}`,
                      }}
                      variant="outlined"
                      value={purchaseOrderId}
                      //  name="amount"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>Total Amount(₹)</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`totalBillAmount`)}
                      placeholder="Total Bill Amount"
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont} ${classes.inputWidth}`,
                      }}
                      value={totalBillAmount}
                      variant="outlined"
                      //  name="amount"
                    />
                  </Grid>

                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>Vendor Name</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`vendorName`)}
                      placeholder="Vendor Name"
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont} ${classes.inputWidth}`,
                      }}
                      variant="outlined"
                      //  name="amount"
                      value={VendorName}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>CGST</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`cgst`)}
                      placeholder="CGST"
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont} ${classes.inputWidth}`,
                      }}
                      variant="outlined"
                      //  name="amount"
                    />
                  </Grid>

                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>Bill No.</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`billNo`)}
                      placeholder="Bill No."
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont}`,
                      }}
                      variant="outlined"
                      //  name="amount"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>Purchase Entry Date</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`date`)}
                      placeholder="Purchase Entry Date"
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont} ${classes.inputWidth}`,
                      }}
                      variant="outlined"
                      //  name="amount"
                      value={moment(createdDate).format("DD-MM-YYYY")}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>GST NO.</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`gst`)}
                      placeholder="GST NO."
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont} ${classes.inputWidth}`,
                      }}
                      variant="outlined"
                      //  name="amount"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                    <span>SGST No.</span>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                    <TextField
                      {...register(`sgst`)}
                      placeholder="SGST No."
                      maxRows={4}
                      size="small"
                      disabled
                      InputProps={{
                        className: `${classes.inputFont} ${classes.inputWidth}`,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid items xs={12} align="center">
                <PurchaseReturnForm
                  viewMood={viewMood}
                  ref={complexRef}
                  control={control}
                  editData={editData}
                  viewData={viewData}
                  watch={watch}
                  setError={setError}
                  reset={reset}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors.complex}
                  CheckError={CheckError}
                />
              </Grid>
              <Grid items xs={12} align="right">
                {!viewMood ? (
                  <Button onClick={handleSubmit(data => onFormSubmit(data))} variant="contained" style={{ margin: "5px" }} color="primary" disabled={isSubmitting}>
                    Submit
                  </Button>
                ) : null}
                <Button
                  onClick={() => {
                    setIsEditMode(false);
                    setViewMood(false);

                    reset(defaultValues);
                  }}
                  variant="contained"
                  style={{ margin: "5px" }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : null}
      </div>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={10} align="right">
          <Controls.Input
            style={{ maxWidth: 300, marginTop: "20px" }}
            label="Search"
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <TableContainer style={{ maxHeight: "570px" }}>
            {loading && <LinearProgress />}
            <Table size="small" stickyHeader>
              <TblHead />
              {recordsAfterPagingAndSorting()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map(item => (
                    <>
                      <TableRow key={item.id}>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.srNo}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.purchaseOrderNo}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.vendorName}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.totalBillAmount}</TableCell>
                      </TableRow>
                      {item.entryDet.map(subItem => (
                        <TableRow key={item.id}>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {subItem.billNo}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {moment(subItem.receiptDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {subItem.returnNo}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {subItem.returnNo === "NA" ? (
                              <IconButton
                                color="primary"
                                onClick={e => {
                                  reset(defaultValues);
                                  setViewMood(false);
                                  setIsEditMode(true);
                                  setPurchaseOrderId("");
                                  setdata(null);
                                  fetchBillData(item.orderId, subItem.entryId);
                                }}
                              >
                                <FontAwesomeIcon title="Edit" style={{ color: "#ff6600", fontSize: "13px" }} icon={faEdit} />
                              </IconButton>
                            ) : (
                              <>
                                <IconButton
                                  color="primary"
                                  onClick={e => {
                                    reset(defaultValues);
                                    setViewMood(true);
                                    setIsEditMode(true);
                                    setDataEdit(null);
                                    setPurchaseOrderId("");
                                    fetchBillData(item.orderId, subItem.entryId);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    title="View"
                                    style={{
                                      color: "#1957b3",
                                      fontSize: "13px",
                                    }}
                                    icon={faEye}
                                  />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  onClick={e => {
                                    reset(defaultValues);
                                    setViewMood(false);
                                    fetchpdfdata(item.orderId, subItem.entryId);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    title="Print Pdf"
                                    style={{
                                      color: "#f55140",
                                      fontSize: "15px",
                                    }}
                                    icon={faFilePdf}
                                  />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <StyledTableCell colSpan={8} align="center">
                      No Records Found
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TblPagination />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" justifyContent="center">
        <DialogTitle id="alert-dialog-title" align="center" style={{ width: 300 }}>
          <p
            style={{
              fontWeight: 400,
              lineHeight: "26px",
              margin: "0px",
              fontSize: 24,
            }}
          >
            Are you sure?
          </p>
        </DialogTitle>
        {/* <DialogContent></DialogContent> */}
        <DialogActions style={{ justifyContent: "center", paddingBottom: "20px" }}>
          <Button
            onClick={e => {
              handleReceipt(e);
            }}
            color="primary"
            variant="contained"
            size="small"
          >
            Yes
          </Button>
          <Button
            onClick={e => {
              handleClose();
            }}
            variant="contained"
            size="small"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const PurchaseorderReturn = React.forwardRef(Inner);
export default PurchaseorderReturn;
