import { FETCH_PATIENTCATEGORY_LIST_BY_ORG } from "../actions/Type";

const initialState = {
  patientCategory: [],
};

const PatientCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENTCATEGORY_LIST_BY_ORG:
      return {
        ...state,
        patientCategory: action.payload,
      };

    default:
      return state;
  }
};

export default PatientCategoryReducer;
