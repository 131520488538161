import { FETCH_ALL_SURGICAL_PROCEDURES, FETCH_ALL_ACTIVE_PROCEDURES } from "../actions/Type";

const initialState = {
  surgicalProcedureList: [],
  activeProcedureList: [],
};

const SurgicalProcedureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_SURGICAL_PROCEDURES:
      return {
        ...state,
        surgicalProcedureList: action.payload,
      };

      case FETCH_ALL_ACTIVE_PROCEDURES:
        return {
          ...state,
          activeProcedureList: action.payload,
        };
    default:
      return state;
  }
};

export default SurgicalProcedureReducer;
