import {
    PREVIOUS_TREND_PULSE,
    PREVIOUS_TREND_SPO2,
    PREVIOUS_TREND_TEMP,
    PREVIOUS_TREND_BP
} from "../actions/Type";

const initialState = {
   pluseRate:[],
   spo2:[],
    temp:[],
    bp:[]

   
};

const GraphReducer = (state = initialState, action) => {
    switch (action.type) {
        case PREVIOUS_TREND_PULSE:
            return {
                ...state,
                pluseRate: action.payload,
            };
            case PREVIOUS_TREND_SPO2:
            return {
                ...state,
                spo2: action.payload,
            };
            case PREVIOUS_TREND_TEMP:
                return {
                    ...state,
                    temp: action.payload,
                };
                case PREVIOUS_TREND_BP:
                    return {
                        ...state,
                        bp: action.payload,
                    };
           
        default:
            return state;
    }
};

export default GraphReducer;