import { faUsers } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Avatar,
  DialogActions,
  FormControl,
  Grid,
  Popover,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MoreIcon from "@material-ui/icons/MoreVert";
import Axios from "axios";
import clsx from "clsx";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../../App.css";
import { saveConsultationRoomForDoctor } from "../../../actions/ConsultationRoomAction";
import { changeMenuThemeColor, changeThemeColor } from "../../../actions/Index";
import { staffLogout } from "../../../actions/LoginAction";
import configData from "../../../config.json";
import EventReadOnly from "../../Duty/DutyEvents/EventReadOnly";
import UpdatePassword from "../../Layout/UpdatePassword";
import ThemeSettings from "../../ThemeSettings";
import PatientList from "./PatientTables";
import Tabs from "./TabsConsultation";
// import { showSnackbar } from "../../../actions/Notification";
import { IPD_TOP_BAR_SELECT, SELETED_DIAGNOSIS_TAB, SET_PATIENT_TYPE_ID } from "../../../actions/Type";
import SessionTimeout from "../../SessionTimeOut/SessionTimeout";
import { PatientDetails2 } from "./PatientDetails2";

const drawerWidth = 350;

const Dialog1 = withStyles((theme) => ({
  root: {
    maxWidth: "900px !important",
    maxHeight: "820px !important",
    [theme.breakpoints.only("xs")]: {
      transform: "translate(1vw, 10vh)",
    },
    [theme.breakpoints.only("sm")]: {
      transform: "translate(2vw, 6vh)",
    },
    [theme.breakpoints.only("md")]: {
      transform: "translate(10vw, 6vh)",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      transform: "translate(27vw, 1vh)",
    },
  },
}))(MuiDialog);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: "80px",
    position: "absolute",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      padding: "16px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  icon: {
    fontSize: "25px",
    marginTop: "3%",
    marginRight: "%",
  },
  icon2: {
    fontSize: "25px",
    marginTop: "%",
    marginRight: "130%",
    position: "static",
    backgroundColor: "#CAF7FB",
  },
  button: {
    borderRadius: "0.3em",
    backgroundColor: "#CAF7FB",
    marginTop: "90px",
    position: "fixed",
    zIndex: 1,
  },
  grow: {
    flexGrow: 1,
    display: "flex",
  },
  cardcss: {
    width: "12.5%",
    borderRadius: 10,
    margin: "4px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginBottom: "10%",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  patientDetails: {
    fontSize: "1.0rem",
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    height: 24,
    width: 220,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "5px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function PersistentDrawerLeft({ history }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [base64Photo, setBase64Photo] = React.useState(null);
  const [base64PatientPhoto, setBase64PatientPhoto] = React.useState(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [unit1, setUnit1] = React.useState("");
  const [selectedDept, setSelectedDept] = React.useState("");

  const StaffLogin = useSelector((state) => state.loginReducer.staffLogin);
  let docDepartment = useSelector(
    (state) => state.consultationReducer.docDepartment
  );
  const staffDetails = [
    {
      action: "logout",
      staffId: StaffLogin?.staffId,
    },
  ];
  const logout = {
    userName: StaffLogin.username,
  };
  const handleMenuCloseSignOut = () => {
    dispatch(staffLogout(logout));

    dispatch(changeMenuThemeColor("#CAF7FB"));
    dispatch({
      type: SELETED_DIAGNOSIS_TAB,
      payload: "icd 10",
    });

    dispatch(saveConsultationRoomForDoctor(staffDetails)).then((res) => {
      setAnchorEl(null);
      handleMobileMenuClose();
    });
  };
  useEffect(() => {
    const photo1 = StaffLogin.orgPhoto;
    if (photo1 != undefined) {
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(StaffLogin.orgPhoto))
      );
      setBase64Photo("data:image/png;base64," + base64String);
    }
  }, [StaffLogin]);

  useEffect(() => {
    setSelectedDept(docDepartment);
  }, [docDepartment]);

  useEffect(() => {
    const patientPhoto = selectedPatient.photo;
    if (patientPhoto != undefined) {
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(selectedPatient.photo))
      );
      setBase64PatientPhoto("data:image/png;base64," + base64String);
    }
  }, [selectedPatient]);

  const handleChange1 = (event) => {
    setUnit1(event.target.value);
    if (event.target.value === "10") {
      history.push("/receptionist");
    } else if (event.target.value === "20") {
      dispatch({
        type: SET_PATIENT_TYPE_ID,
        payload: 2,
      });
      history.push("/ConsultationLayout");
    } else if (event.target.value === "30") {
      history.push("/VitalSigns");
    } else if (event.target.value === "40") {
      history.push("/master");
    } else if (event.target.value === "50") {
      history.push("/Dashboard");
    } else if (event.target.value === "60") {
      history.push("/LandingMasterReports");
    } else if (event.target.value === "80") {
      history.push("/consultationToken");
    } else if (event.target.value === "videotutorials") {
      history.push("/videotutorials");
    } else if (event.target.value === "ipdConsultation") {
      history.push("/ipd/consultation");
      dispatch({ type: IPD_TOP_BAR_SELECT, payload: event.target.value });
    } else if (event.target.value === "adverseDrugReaction") {
      history.push("/ipd/adverseDrugReaction");
    }
  };

  let staffLogin = useSelector((state) => state.loginReducer.staffLogin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open5, setOpen5] = React.useState(false);

  const handleClickOpen5 = () => {
    setOpen5(true);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleClose1 = () => {
    setOpen2(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const handleProfile = (event) => {
    setAnchorProfile(event.currentTarget);
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const openProfile = Boolean(anchorProfile);
  const id = openProfile ? "simple-popover" : undefined;

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  let staffPhoto = "";
  let photoStr = btoa(
    String.fromCharCode(...new Uint8Array(staffLogin.staffPhoto))
  );
  staffPhoto = "data:image/png;base64," + photoStr;

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: "2%" }}
    >
      <MenuItem
        style={{ color: "green", fontWeight: 500, wordBreak: "break-word" }}
      >
        {StaffLogin.staffName}
      </MenuItem>

      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings label="Theme Settings" />
      </MenuItem>

      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings label="Menu Theme Settings" type="CHANGE_MENU_COLOR" />
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleMenuClose();
          dispatch(changeThemeColor("#0B9CAB"));
          dispatch(changeMenuThemeColor("#CAF7FB"));
        }}
      >
        Default Settings
      </MenuItem>

      <MenuItem onClick={handleProfile}>Profile</MenuItem>

      <Popover
        id={id}
        open={openProfile}
        anchorEl={anchorProfile}
        onClose={handleProfileClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 68, left: 1500 }}
        PaperProps={{
          style: { overflowY: "hidden", width: "22%" },
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={11} align="left"></Grid>
          <Grid item xs={1} align="left">
            <IconButton
              onClick={handleProfileClose}
              edge="start"
              color="primary"
              arialabel="menu"
              style={{ marginLeft: "-84%" }}
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Avatar
          style={{
            width: 74,
            height: 74,
            margin: "auto",
            backgroundColor: "#07a0b0",
            fontSize: "26px",
            textTransform: "uppercase",
          }}
          alt={staffLogin.staffName}
          src={staffPhoto}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} align="center">
            {StaffLogin.staffName} - {StaffLogin.username}
          </Grid>
          <Grid item xs={12} align="center">
            <Typography style={{ fontSize: "14px" }}>
              {StaffLogin.orgName}
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              style={{
                color: "rgb(7, 160, 176)",
                width: "80%",
              }}
              onClick={handleClickOpen5}
            >
              Change Password
            </Button>
            <Dialog1
              open={open5}
              onClose={handleClose5}
              aria-labelledby="form-dialog-title"
              maxWidth="xs"
            >
              <Grid container spacing={0}>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <DialogTitle>
                    <Typography style={{ color: "black" }}>
                      Change password
                    </Typography>
                  </DialogTitle>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <IconButton
                    onClick={handleClose5}
                    edge="start"
                    color="primary"
                    arialabel="menu"
                  >
                    <CloseIcon style={{ color: "black" }} />
                  </IconButton>
                </Grid>
              </Grid>

              <DialogContent>
                <UpdatePassword />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog1>
          </Grid>
        </Grid>
      </Popover>
      <Dialog1
        open={open2}
        disableEnforceFocus
        disableScrollLock="false"
        hideBackdrop
        disableBackdropClick
        maxWidth="lg"
      >
        <Grid container spacing={0}>
          <Grid item xs={11} align="left"></Grid>
          <Grid item xs={1} align="left">
            <IconButton
              onClick={handleClose1}
              edge="start"
              color="primary"
              arialabel="menu"
              style={{ marginLeft: "15%" }}
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText>
            <EventReadOnly />
          </DialogContentText>
        </DialogContent>
      </Dialog1>
      <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
        <MenuItem onClick={handleMenuCloseSignOut}>Sign Out</MenuItem>
      </Link>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ marginTop: "2%" }}
    >
      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings />
      </MenuItem>

      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings label="Menu Theme Settings" type="CHANGE_MENU_COLOR" />
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleMenuClose();
          dispatch(changeThemeColor("#0B9CAB"));
        }}
      >
        Default Settings
      </MenuItem>
      <MenuItem onClick={handleProfile}>Profile</MenuItem>

      {/* <MenuItem onClick={handleMenuClose2}>My Visit</MenuItem> */}
      <Dialog1
        open={open2}
        disableEnforceFocus
        disableScrollLock="false"
        hideBackdrop
        disableBackdropClick
        maxWidth="lg"
      >
        <Grid container spacing={0}>
          <Grid item xs={11} align="left"></Grid>
          <Grid item xs={1} align="left">
            <IconButton
              onClick={handleClose1}
              edge="start"
              color="primary"
              arialabel="menu"
              style={{ marginLeft: "15%" }}
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText>
            <EventReadOnly />
          </DialogContentText>
        </DialogContent>
      </Dialog1>
      <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
        <MenuItem onClick={handleMenuCloseSignOut}>Sign Out</MenuItem>
      </Link>
    </Menu>
  );
  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const selectedPatient = useSelector(
    (state) => state.consultationReducer.patient
  );
  const vitalsDetails = useSelector(
    (state) => state.pulseReducer.vitalsDetails
  );
  const registrationID = useSelector(
    (state) => state.consultationReducer.registrationId
  );
  const [age, setAge] = useState("");

  React.useEffect(() => {
    (async () => {
      const date = moment(selectedPatient?.dob).format("YYYY-MM-DD");
      const res = await Axios.get(
        configData.MASTER_SERVICE_URL + `age/calculate/${date}`
      );
      if (res.data[0] === 0 && res.data[1] == 0 && res.data[2] == 0) {
        const finalAge = "";
        setAge(finalAge);
      } else if (res.data[0] === 0) {
        const finalAge = `${res.data[0]} Years ${res.data[1]} Months ${res.data[2]} Days`;
        setAge(finalAge);
      } else {
        const finalAge = `${res.data[0]} Years`;
        setAge(finalAge);
      }
    })();
  }, [selectedPatient]);

  const [isAuthenticated, setAuth] = useState(true);
  const handleLogOut = () => {
    setAuth(!isAuthenticated);
    history.push("/home");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      event.returnValue = `Are you sure you want to leave?`;
      dispatch(staffLogout(logout));
    });
  }, []);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ backgroundColor: myOwnColor, height: "60px" }}
      >
        <Toolbar style={{ width: "100%" }}>
          {base64Photo != null ? (
            <img
              src={base64Photo}
              width={40}
              height={40}
              edge="start"
              alt="start"
            />
          ) : (
            <div></div>
          )}
          {StaffLogin?.opdDepartment?.length !== 0 &&
            StaffLogin?.opdDepartment != undefined &&
            StaffLogin?.hospitalShortName != undefined ? (
            <>
              <Typography
                variant="h6"
                noWrap
                style={{
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                {`${StaffLogin?.hospitalShortName}
            (${selectedDept?.deptName})`}
              </Typography>
              <Typography style={{ textTransform: "uppercase" }}>&nbsp;- {StaffLogin.roleName}</Typography>
            </>
          ) : StaffLogin?.hospitalShortName != undefined ? (
            <>
              <Typography
                variant="h6"
                noWrap
                style={{
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                {`${StaffLogin?.hospitalShortName}`}
              </Typography>
              <Typography style={{ textTransform: "uppercase" }}>&nbsp;
              
              - {StaffLogin.roleName}</Typography>
            </>
          ) : null}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Grid container spacing={0}>
              <Grid items xs={12} sm={12} lg={12} md={12} xl={12}>
                <FormControl className={classes.margin}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={unit1}
                    onChange={handleChange1}
                    input={
                      <BootstrapInput
                        style={{
                          width: 200,
                          marginRight: "180%",
                        }}
                      />
                    }
                    InputProps={{
                      className: classes.input,
                    }}
                  >
                    {StaffLogin.roleName == "MoA-Admins" ? (
                      <>
                        <option disabled>Select Department</option>
                        <option value={40}>Master</option>
                        <option value={60}>Reports</option>
                        <option value={"videotutorials"}>
                          Video Tutorials
                        </option>
                      </>
                    ) : StaffLogin.roleName == "RC Admin" ? (
                      <>
                        <option disabled>Select Department</option>
                        <option value={40}>Master</option>
                        <option value={60}>Reports</option>
                        <option value={"videotutorials"}>
                          Video Tutorials
                        </option>
                      </>
                    ) : StaffLogin.roleName == "NI Admin" ? (
                      <>
                        <option disabled>Select Department</option>
                        <option value={40}>Master</option>
                        <option value={60}>Reports</option>
                        <option value={"videotutorials"}>
                          Video Tutorials
                        </option>
                      </>
                    ) : StaffLogin.roleName == "PI/PU Admin" ? (
                      <>
                        <option disabled>Select Department</option>
                        <option value={40}>Master</option>
                        <option value={60}>Reports</option>
                        <option value={"videotutorials"}>
                          Video Tutorials
                        </option>
                      </>
                    ) : StaffLogin.roleName == "Receptionist" ? (
                      <>
                        <option disabled>Select Department</option>
                        <option value={10}>Registration</option>
                        <option value={60}>Reports</option>
                        <option value={80}>Consultation Token</option>
                        <option value={"videotutorials"}>
                          Video Tutorials
                        </option>
                      </>
                    ) : StaffLogin.roleName == "Doctor" ? (
                      <>
                        <option disabled>Select Department</option>
                        <option value={20}>OPD Consultation</option>
                        <option value={50}>Dashboard</option>
                        <option value={30}>Vital Signs</option>
                        <option value={80}>Consultation Token</option>
                        <option value={"ipdConsultation"}>
                          IPD Consultation
                        </option>
                        <option value={60}>Reports</option>
                        <option value={"adverseDrugReaction"}>
                          Adverse Drug Reaction
                        </option>
                        <option value={"videotutorials"}>
                          Video Tutorials
                        </option>
                      </>
                    ) : StaffLogin.roleName == "Demo User Role" ? (
                      <>
                        <option disabled>Select Department</option>
                        <option value={20}>Consultation</option>
                        <option value={30}>Vital Signs</option>
                        <option value={60}>Reports</option>
                        <option value={50}>Dashboard</option>
                        <option value={10}>Registration</option>
                        <option value={40}>Master</option>
                        <option value={"videotutorials"}>
                          Video Tutorials
                        </option>
                      </>
                    ) : null}
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#fafafa",
                  color: "#07a0b0",
                  textTransform: "uppercase",
                }}
                alt={staffLogin.staffName}
                src={staffPhoto}
              />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <Button
        variant="contained"
        className={classes.button}
        style={{ height: 37 }}
        onClick={handleDrawerOpen}
      >
        <FontAwesomeIcon
          className={classes.icon}
          style={{ color: myOwnColor }}
          icon={faUsers}
        />
      </Button>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid container spacing={0}>
          <Grid items xs={11} sm={11} lg={11} md={11} xl={10}>
            <PatientList close={handleDrawerClose} department={selectedDept} />
          </Grid>
          <Grid items xs={1} sm={1} lg={1} md={1} xl={1} align="left">
            <IconButton style={{ width: "0.5px" }} onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <FontAwesomeIcon
                  className={classes.icon2}
                  style={{ color: myOwnColor }}
                  icon={faUsers}
                />
              ) : (
                <FontAwesomeIcon
                  style={{ color: myOwnColor }}
                  className={classes.icon2}
                  icon={faUsers}
                />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <PatientDetails2
          history={history}
          selectedPatient={selectedPatient}
          base64PatientPhoto={base64PatientPhoto}
          registrationID={registrationID}
          age={age}
        />

        <div>
          <Grid items xs={12} sm={12} lg={12} md={12} xl={12}>
            <Tabs />
          </Grid>
        </div>
      </main>
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleLogOut} />
    </div>
  );
}
