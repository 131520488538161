import {
  SET_PATIENT_DATA,
  FETCH_LAB_CONSULTATION_PATIENT_DETAILS,
  FETCH_CONSULTATION_PATIENT_DETAILS,
  SET_REGISTRATION_ID,
  SET_REGISTRATION_PATIENT,
  REMOVE_REGISTRATION_ID,
  FETCH_VITAL_PATIENT_DETAILS,
  FETCH_PATIENT_DETAILS_BY_PATIENT_ID,
  SET_REGISTRATION_PATIENT_REG_ID,
  SET_DEPARTMENT,
  SET_LABCONSULTATION_DATE
} from "../actions/Type";

const initialState = {
  consultationPatients: [],
  patient: {},
  registrationId: "",
  registrationPatient: {},
  patientForVitals: {},
  patientDetails: {},
  docDepartment: [],
  patientLab: {},
  labConsultationDate: null
};

const ConsultationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONSULTATION_PATIENT_DETAILS:
      return {
        ...state,
        patient: action.payload,
      };

    case FETCH_VITAL_PATIENT_DETAILS:
      return {
        ...state,
        patientForVitals: action.payload,
      };

    case SET_PATIENT_DATA:
      return {
        ...state,
        consultationPatients: action.payload,
      };

    case REMOVE_REGISTRATION_ID:
      const id = +action.payload;
      console.log("consultation", state.consultationPatients);
      const consultationPatients = state.consultationPatients.filter(
        (c) => c.id !== id
      );
      return {
        ...state,
        consultationPatients,
        //patient: action.payload
      };

    case SET_REGISTRATION_ID:
      return {
        ...state,
        registrationId: action.payload,
      };

    case SET_REGISTRATION_PATIENT:
      return {
        ...state,
        registrationPatient: action.payload,
      };
    case SET_REGISTRATION_PATIENT_REG_ID:
      return {
        ...state,
        registrationPatient: {
          registrationId: action.payload,
        },
      };
    case FETCH_PATIENT_DETAILS_BY_PATIENT_ID:
      return {
        ...state,
        patientDetails: action.payload,
      };
    case SET_DEPARTMENT:
      return {
        ...state,
        docDepartment: action.payload,
      };
    case FETCH_LAB_CONSULTATION_PATIENT_DETAILS:
      return {
        ...state,
        patientLab: action.payload,
      };
    
      case SET_LABCONSULTATION_DATE:
        return {
          ...state,
          labConsultationDate: action.payload,
        };
    default:
      return state;
  }
};

export default ConsultationReducer;
