import { FETCH_WARD_TYPES, FETCH_WARD_BY_BUILDINGID, FETCH_WARD_BY_ORGID } from "../actions/Type";

const initialState = {
  wardTypes: [],
  wardsByOrgID: [],
  wardsByBuildingId: [],
};

const ipdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WARD_TYPES:
      return {
        ...state,
        wardTypes: action.payload,
      };
    case FETCH_WARD_BY_ORGID:
      return {
        ...state,
        wardsByOrgID: action.payload,
      };
    case FETCH_WARD_BY_BUILDINGID:
      return {
        ...state,
        wardsByBuildingId: action.payload,
      };

    default:
      return state;
  }
};

export default ipdReducer;
