import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
const StyledButton = withStyles({
  root: {
    background: "rgb(248 65 64)",
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      backgroundColor: "rgb(255 30 30)",
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "red",
    },
  },
})(Button);

export  function CloseButton(props) {
  const { children = "Close" } = props;

  return (
    <StyledButton {...props} color="inherit" variant="contained">
      {children}
    </StyledButton>
  );
}
