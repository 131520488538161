import {
  FETCH_ICD_CODE_LIST,
  FETCH_ICD_CODE_LIST_FOR_PRES,
  FETCH_DIAGNOSIS_LIST_FOR_ICD11,
} from "../actions/Type";

const initialState = {
  icdCodes: [],
  icdCodesForPres: [],
  icdCodes11: []
};

const DianosisICDReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ICD_CODE_LIST:
      return {
        ...state,
        icdCodes: action.payload,
      };
    case FETCH_ICD_CODE_LIST_FOR_PRES:
      return {
        ...state,
        icdCodesForPres: action.payload,
      };
    case FETCH_DIAGNOSIS_LIST_FOR_ICD11:
      return {
        ...state,
        icdCodes11: action.payload,
      };
    default:
      return state;
  }
};

export default DianosisICDReducer;
