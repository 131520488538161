import { extend } from "@syncfusion/ej2-base";
import {
  Agenda,
  Day,
  Inject,
  Month,
  Resize,
  ScheduleComponent,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import dataSources from "./data.json";
import { SampleBase } from "./sample-base";

//Events class defined  here
export default class Events extends SampleBase {
  constructor() {
    super(...arguments);
    this.data = extend([], dataSources.scheduleData, null, true);
  }

  onPopupOpen(args) {
    args.cancel = true;
  }

  render() {
    return (
      <ScheduleComponent
        ref={(schedule) => (this.scheduleObj = schedule)}
        width="100%"
        // height="650px"
        selectedDate={new Date(2019, 0, 10)}
        eventSettings={{ dataSource: this.data }}
        popupOpen={this.onPopupOpen.bind(this)}
      >
        <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize]} />
      </ScheduleComponent>
    );
  }
}
