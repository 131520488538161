import { Avatar, Button, Dialog, Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";
import moment from "moment";
import React, { lazy, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../../Ipd_Layout/Components/Utils";
import { SHOW_SNACKBAR } from "../../../actions/Type";
import { fetchSavedVitals } from "../../../actions/VitalSignAction";
import ConsentDialog from "../../../pages/Ndhm/ConsentDialog";
import { CloseButton } from "../../utils/Button";
// import Slide from '@material-ui/core/Slide';

const PreviousConsulationTabs = lazy(() => import("./PreviousConsulationTabs"));

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#07a0b0",
    display: "inline",
    fontSize: "1.0rem",
  },
  avatar: {
    width: 70,
    height: 70,
    borderRadius: "5px ",
    align: "right",
    fontSize: "2.25rem",
  },
  previousVisitButton: {
    position: "absolute",
    right: 20,
    top: -23,
  },
}));

export function PatientDetails2(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    // history,
    selectedPatient,
    base64PatientPhoto,
    registrationID,
    age,
    hidePreviousVisits = false,
    labelforVitalSigns,
  } = props;

  const staffLogin = useSelector((state) => state.loginReducer.staffLogin);
  const PatientRegistrationID = useSelector(
    (state) => state.consultationReducer.registrationPatient.registrationId
  );
  const vitalsDetails = useSelector(
    (state) => state.pulseReducer.vitalsDetails
  );

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="left" ref={ref} {...props} />;
  // });

  const [open, setOpen] = React.useState(false);
  const [pulseRate, setPulseRate] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [temperature, setTemperature] = useState("");
  const [spo2, setSpo2] = useState("");
  const [respiratoryRate, setRespiratoryRate] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [openPreviousConsultation, setOpenPreviousConsultation] = useState(
    false
  );

  const handlePreviousVisits = (e) => {
    if (selectedPatient?.ayushId !== undefined) {
      setOpenPreviousConsultation(true);
    } else {
      const msg = {
        message: "Please select a patient",
        type: "error",
      };
      dispatch({
        type: SHOW_SNACKBAR,
        payload: { type: msg.type, msg: msg.message, openSnackbar: true },
      });
    }
  };

  const handleConsentSubmit = (e) => {
    setOpen(true);
  };

  useEffect(() => {
    if (PatientRegistrationID) {
      dispatch(fetchSavedVitals(PatientRegistrationID));
    }
  }, [PatientRegistrationID]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      vitalsDetails.vitalsignDtos !== undefined &&
      vitalsDetails.vitalsignDtos.length !== 0
    ) {
      vitalsDetails.vitalsignDtos.map((vitalSignData) => {
        if (vitalSignData.vitalSignTypeId === 2) {
          setPulseRate(vitalSignData.vitalSignValue);
        } else if (vitalSignData.vitalSignTypeId === 3) {
          setSpo2(vitalSignData.vitalSignValue);
        } else if (vitalSignData.vitalSignTypeId === 4) {
          setTemperature(vitalSignData.vitalSignValue);
        } else if (vitalSignData.vitalSignTypeId === 5) {
          setBloodPressure(vitalSignData.vitalSignValue);
        } else if (vitalSignData.vitalSignTypeId === 6) {
          setHeartRate(vitalSignData.vitalSignValue);
        } else if (vitalSignData.vitalSignTypeId === 7) {
          setRespiratoryRate(vitalSignData.vitalSignValue);
        } else if (vitalSignData.vitalSignTypeId === 9) {
          setWeight(vitalSignData.vitalSignValue);
        } else if (vitalSignData.vitalSignTypeId === 8) {
          setHeight(vitalSignData.vitalSignValue);
        }
      });
    } else {
      setPulseRate(null);
      setSpo2(null);
      setTemperature(null);
      setBloodPressure(null);
      setHeartRate(null);
      setRespiratoryRate(null);
      setHeight(null);
      setWeight(null);
    }
  }, [vitalsDetails]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ position: "relative" }}
      >
        {!hidePreviousVisits && (
          <div className={classes.previousVisitButton}>
            <Button
              variant="contained"
              onClick={handlePreviousVisits}
              color="primary"
              endIcon={<ArrowForwardIos />}
            >
              Previous Visits
            </Button>
            <Button
              variant="contained"
              onClick={handleConsentSubmit}
              color="primary"
              endIcon={<ArrowForwardIos />}
              style={{ margin: "5px" }}
            >
              {/* New Consent Request */}
              ABHA Consent Request
            </Button>
          </div>
        )}
        <Grid item xs={6} sm={4} md={1} align="left">
          <Avatar
            className={classes.avatar}
            alt={"profile"}
            src={base64PatientPhoto}
          >
            {(selectedPatient?.patientName &&
              selectedPatient?.patientName[0]) ||
              null}
          </Avatar>
        </Grid>

        <Grid item xs={6} sm={4} md={4} lg={2}>
          <Typography align="left">
            <Typography className={classes.label}>Name:&nbsp;</Typography>
            {selectedPatient.patientName}
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>
              Date of Birth:&nbsp;
            </Typography>
            {selectedPatient.dob !== undefined &&
              moment(selectedPatient.dob).format("DD-MM-YYYY")}
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>
              Age / Gender:&nbsp;
            </Typography>
            {age !== "" ? age : null} / {selectedPatient.genderName}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={4} md={4} lg={2}>
          <Typography align="left">
            <Typography className={classes.label}>Ayush ID:&nbsp;</Typography>
            {selectedPatient.ayushId}
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>
              ABHA Number:&nbsp;
            </Typography>
            {/* {selectedPatient.uhid ? selectedPatient.uhid : "N/A"} */}
            {selectedPatient.uhid}
          </Typography>
          <Typography align="left">
            {/* -----this "labelforVitalSigns" props is used to show OP ID / IP ID labels in VitalSigns---------   */}
            {labelforVitalSigns ? (
              <Typography className={classes.label}>
                OP ID / IP ID:&nbsp;
              </Typography>
            ) : (
              <Typography className={classes.label}>OP ID:&nbsp;</Typography>
            )}
            {/* <Typography className={classes.label}>OP ID / IP ID:&nbsp;</Typography> */}
            {registrationID}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={4} md={4} lg={2}>
          <Typography align="left">
            <Typography className={classes.label}>Pulse Rate:</Typography>
            {pulseRate} bpm
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>
              Blood Pressure:&nbsp;
            </Typography>
            {bloodPressure} mmhg
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>
              Temperature:&nbsp;
            </Typography>
            {temperature}°F
          </Typography>
        </Grid>

        <Grid item xs={6} sm={4} md={4} lg={2}>
          <Typography align="left">
            <Typography className={classes.label}>
              SpO<sub>2</sub>:&nbsp;
            </Typography>
            {spo2}%
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>
              Respiratory Rate:&nbsp;
            </Typography>
            {respiratoryRate} rpm
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>Heart Rate:&nbsp;</Typography>
            {heartRate} bpm
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={1}>
          <Typography align="left">
            <Typography className={classes.label}>Height:&nbsp;</Typography>
            {height} cm
          </Typography>
          <Typography align="left">
            <Typography className={classes.label}>Weight:&nbsp;</Typography>
            {weight} Kg
          </Typography>
        </Grid>
        <ConsentDialog
          open={open}
          onClose={handleClose}
          staffName={staffLogin?.staffName}
        />
      </Grid>
      <Dialog
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        open={openPreviousConsultation}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={() => setOpenPreviousConsultation(false)}
        >
          Previous Consultation
        </DialogTitle>
        <DialogContent>
          <PreviousConsulationTabs />
        </DialogContent>
        <DialogActions>
          <CloseButton onClick={() => setOpenPreviousConsultation(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
}
