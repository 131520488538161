import { IPD_TOP_BAR_SELECT } from "../actions/Type";


export const  IpdTopBarReducer = (state = "", action) => {
  switch (action.type) {
    case IPD_TOP_BAR_SELECT:
      return action.payload

    default:
      return state;
  }
};