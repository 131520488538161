import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import CloseIcon from "@material-ui/icons/Close";
import ChartBot from "../../ChatbotAPI";
import BotMessage from "../ChatsNew/ChatbotNew/BotMessage";
import Header from "../ChatsNew/ChatbotNew/Header";
import Input from "../ChatsNew/ChatbotNew/Input";
import Messages from "../ChatsNew/ChatbotNew/Messages";
import UserMessage from "../ChatsNew/ChatbotNew/UserMessage";
import "./styles.css";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

export default function Chatbot() {
  const classes = useStyles();
  const [showBot, setBot] = useState(false);
  function handleBot() {
    const botState = !showBot;
    setBot(botState);
  }

  const [messages, setMessages] = useState([]);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    async function loadWelcomeMessage() {
      setMessages([
        <BotMessage
          key="0"
          fetchMessage={async () =>
            await ChartBot.GetChatbotResponse(
              "Welcome to A-HMIS. If you need any help simply reply to this message, we are online and ready to help.(For examples:registration,book appointment,contact us,fee..)"
            )
          }
        />,
      ]);
    }
    loadWelcomeMessage();
  }, []);

  const handleClose = () => {
    setBot(false);
  };
  const send = async text => {
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage key={messages.length + 2} fetchMessage={async () => await ChartBot.GetChatbotResponse(text)} />
    );
    setMessages(newMessages);
  };
  return (
    <>
      {/* <Button className="app-chatbot-button" onClick={handleBot}>
        Click
      </Button> */}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleBot}
        className={classes.fab}
        style={{
          backgroundColor: "#07a0b0",
          position: "fixed",
          bottom: "50px",
          right: 0,
        }}
      >
        <ChatIcon className={classes.fab} />
      </Fab>
      {showBot && (
        <div
          className="chatbot"
          style={{
            position: "fixed",
            zIndex: 999,
            right: 0,
            bottom: "60px",
            marginRight: " 25px",
          }}
        >
           <Grid container spacing={0}>
           <Grid item xs={12} sm={10} >
          <Header />
          </Grid>
            <Grid item xs={2} sm={2} style={{ backgroundColor: "#07a0b0" }} align="right">
              <IconButton onClick={handleClose} edge="start"  aria-label="menu">
                <CloseIcon style={{color:"white"}} />
              </IconButton >
            </Grid>
          </Grid>
          <Messages messages={messages} />
          <Input onSend={send} />
        </div>
      )}
    </>
  );
}
