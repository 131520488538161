import { FETCH_DR_ROUND_ID, COMPLAINT_DIAGNOSIS } from "../actions/Type";

const initialState = {
  drRoundId: "",
  diagnosisId:null
};

const ipdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DR_ROUND_ID:
      return {
        ...state,
        drRoundId: action.payload,
      };
      case COMPLAINT_DIAGNOSIS:
        return {
          ...state,
          diagnosisId: action.payload,
        };

    default:
      return state;
  }
};

export default ipdReducer;
