import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import HomeoLatest from "../assets/HomeoLatest.webp";
import ayurveda from "../assets/ayurveda.webp";
import siddha from "../assets/siddha.webp";
import unani from "../assets/unani.webp";
import yoga from "../assets/yoga.webp";

const useStyles = makeStyles(theme => ({
  img: {
    width: "100%",
  },
  card: {
    margin: "0 40px",
    padding: 20,
  },
}));

export default function Ahmis() {
  const classes = useStyles();
  return (
    <Paper className={classes.card}>
      <Grid container spacing={3} justifyContent="space-evenly" alignItems="center">
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <img className={classes.img} src={ayurveda} alt="logo" />

          <Typography align="justify">
            Ayurveda is conceived as the union of body, senses, mind and soul. The living man is a conglomeration of three humours, seven basic tissues and the waste products of
            the body.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <img className={classes.img} src={yoga} alt="logo" />

          <Typography align="justify">
            Yoga is a discipline to improve or develop one's inherent power in a balanced manner. It offers the means to attain complete self-realization.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <img className={classes.img} src={unani} alt="logo" />

          <Typography align="justify">
            Unani system is based upon the well-known four humour theory of Hippocrates. This presupposes the presence, in the body, of four humours.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <img className={classes.img} src={siddha} alt="logo" />

          <Typography align="justify">
            Siddha system is one of the oldest systems of medicine in India. The term Siddha means achievements and Siddhars were saintly persons who achieved results in medicine.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <img className={classes.img} src={HomeoLatest} alt="logo" />

          <Typography align="justify">
            Homoeopathy is treating diseases with remedies, prescribed in minute doses, which are capable of producing symptoms similar to the disease when taken by healthy people.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
