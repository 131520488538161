import React, { useState, useEffect } from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { cyan } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import ScrollUpHomePage from "../../component/ScrollUpHomePage";
import { restrict, inputToUpper } from "../../component/restrict";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ReactSelect, { components } from "react-select";
import Checkbox from "../../component/controls/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  TextField,
  DialogContentText,
  Button,
  IconButton,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import configData from "../../config.json";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { showSnackbar } from "../../actions/Notification";
import axiosIpdOpdInstance from "../../api/IpdOpdInstance";
import { Link } from "react-router-dom";
import FhirDisplay from "./FhirDisplay";

const theme = createTheme({
  palette: {
    primary: {
      main: cyan[700],
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        fontSize: "14px",
      },
      input: {
        height: 26,
        boxSizing: "border-box",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "16px",
        fontWeight: "500",
        color: "black",
      },
      outlined: {
        transform: "translate(14px, 19px) scale(1)",
      },
    },
  },
});

const useStyles = makeStyles({
  input: {
    height: 35,
    width: "100%",
    maxWidth: 400,
  },
  dateField: {
    border: "1px solid lightgrey",
    padding: "0px 5px 0px 5px",
    borderRadius: "5px",
  },
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon color="black" fontSize="small" />
    </components.DropdownIndicator>
  );
};

export default function ConsentDialog(props) {
  const dispatch = useDispatch();
  const today = moment(new Date()).toISOString();
  const classes = useStyles();
  const nextDay = moment(today)
    .add(1, "day")
    .toISOString();

  const registrationID = useSelector(
    (state) => state.consultationReducer.registrationId
  );

  const defaultValues = {
    requestId: uuidv4(),
    timestamp: today,
    consent: {
      purpose: {
        text: "",
        code: "",
      },
      patient: {
        id: "",
      },
      hip: {
        id: configData.hipId,
      },
      // careContexts: [
      //   {
      //     patientReference: "",
      //     careContextReference: registrationID,
      //   },
      // ],
      hiu: {
        id: configData.hiuId,
      },
      requester: {
        name: props?.staffName,
      },
      hiTypes: [],
      permission: {
        accessMode: "VIEW",
        dateRange: {
          from: today,
          to: today,
        },
        dataEraseAt: nextDay,
        frequency: {
          unit: "HOUR",
          value: 1,
          repeats: 0,
        },
      },
    },
  };

  const schema = yup.object().shape({
    consent: yup.object().shape({
      purpose: yup.object().shape({
        text: yup.string().required("Purpose is required"),
        code: yup.string().required("Purpose is required"),
      }),
      patient: yup.object().shape({
        id: yup.string().required("Patient ABHA ID is required"),
      }),
      hiTypes: yup.array().min(1, "Health Information Types is required"),
      permission: yup.object().shape({
        dateRange: yup.object().shape({
          from: yup.string().required("From Date is required"),
          to: yup.string().required("To Date is required"),
        }),
        dataEraseAt: yup.string().required("Consent Expiry Date is required"),
      }),
    }),
  });

  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const clientId = useSelector((state) => state.registrationReducer.clientId);

  const consStatus = [
    { text: "Care Management", code: "CAREMGT" },
    { text: "Break the Glass", code: "BTG" },
    { text: "Public Health", code: "PUBHLTH" },
    { text: "Disease Specific HealthCare Research", code: "DSRCH" },
    { text: "Self Requested", code: "PATRQT" },
    { text: "Healthcare Payment", code: "HPAYMT" },
  ];

  const [typeChecked, setTypeChecked] = useState({
    OPConsultation: false,
    Prescription: false,
    DiagnosticReport: false,
    WellnessRecord: false,
    DischargeSummary: false,
    ImmunizationRecord: false,
    HealthDocumentRecord: false,
  });

  const [openFhirDisplay, setOpenFhirDisplay] = useState(false);

  useEffect(() => {
    const keys = Object.keys(typeChecked);
    const finalArr = [];
    keys.forEach((key) => {
      if (typeChecked[key]) {
        if (!finalArr.includes(key)) {
          finalArr.push(key);
        }
      } else {
        if (finalArr.includes(key)) {
          finalArr.pop(key);
        }
      }
    });
    setValue(`consent.hiTypes`, finalArr);
  }, [typeChecked, setValue]);

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     console.log("updates", value, name, type);
  //     console.log("errors", errors);
  //   });
  //   return () => subscription.unsubscribe();
  // }, [errors, watch]);

  const handleRequestConsent = async (data) => {
    // data.consent.careContexts[0].patientReference = getValues(
    //   "consent.patient.id"
    // ).concat("@sbx");
    // data.consent.careContexts[0].careContextReference = "2203041404862166";
    data.consent.patient.id = getValues("consent.patient.id").concat(configData?.cm_id);
    data.consent.permission.dateRange.from = moment(
      getValues("consent.permission.dateRange.from")
    ).toISOString();
    data.consent.permission.dateRange.to = moment(
      getValues("consent.permission.dateRange.to")
    ).toISOString();
    data.consent.permission.dataEraseAt = moment(
      getValues("consent.permission.dataEraseAt")
    ).toISOString();
    console.log("values", data);
    try {
      const url = `ndhm/m3/consent-requests/init/${clientId}`;
      const response = await axiosIpdOpdInstance.post(url, data);
      console.log("response", response);
      let responseObj = {
        message: "",
        messageInfoType: "",
      };
      if (response?.status == 202) {
        responseObj.message = "Consent Request generated successfully!";
        responseObj.messageInfoType = "success";
        dispatch(showSnackbar(responseObj));
      } else {
        responseObj.message = "Consent Request could not be generated";
        responseObj.messageInfoType = "error";
        dispatch(showSnackbar(responseObj));
      }
    } catch (error) {
      console.log("init failed", error);
    }

    reset();
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Grid Container spacing={0} align="center">
          <Dialog
            maxWidth="sm"
            open={props.open}
            onClose={() => props.onClose()}
            disableBackdropClick
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText>
                <Grid container spacing={0}>
                  <Grid item xs={11} sm={11} md={11}>
                    <Typography style={{ color: myOwnColor }}>
                      <b>ABDM Consent Request Form</b>
                    </Typography>
                  </Grid>

                  <Grid item xs={1} sm={1} md={1} align="right">
                    <IconButton
                      onClick={() => props.onClose()}
                      edge="start"
                      color="primary"
                      aria-label="menu"
                      size="small"
                    >
                      <CloseIcon style={{ color: "black" }} />
                    </IconButton>
                  </Grid>
                  <hr
                    style={{
                      color: "#D3D3D3",
                      backgroundColor: "lightgreen",
                      height: 0.1,
                      width: "100%",
                    }}
                  />
                  <Grid container spacing={0} style={{ marginTop: "1%" }}>
                    <Grid item xs={12} sm={4}>
                      <Typography>Patient Identifier</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        {...register(`consent.patient.id`)}
                        inputProps={{
                          maxLength: 200,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {configData?.cm_id}
                            </InputAdornment>
                          ),
                        }}
                        placeholder="ABHA ID"
                        onInput={(e) => {
                          restrict.alphanumerichypen(e);
                        }}
                        fullWidth
                        variant="outlined"
                        required
                        error={!!errors.consent?.patient?.id}
                        helperText={errors.consent?.patient?.id?.message}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} style={{ marginTop: "1%" }}>
                    <Grid item xs={12} sm={4}>
                      <Typography>Purpose of request</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormControl fullWidth>
                        <Controller
                          name={`consent.purpose.code`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              maxMenuHeight={200}
                              components={{ DropdownIndicator }}
                              isClearable={true}
                              onChange={(data) => {
                                setValue(`consent.purpose.code`, data?.code);
                                setValue(`consent.purpose.text`, data?.text);
                              }}
                              value={
                                consStatus &&
                                consStatus.find(
                                  (el) =>
                                    el.code ===
                                    getValues(`consent.purpose.code`)
                                )
                              }
                              menuPosition="fixed"
                              options={consStatus}
                              placeholder={"Select Purpose of request"}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.code}
                            />
                          )}
                        />
                        <FormHelperText
                          error={!!errors?.consent?.purpose?.code}
                        >
                          {errors?.consent?.purpose?.code?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} style={{ marginTop: "1%" }}>
                    <Grid item xs={12} sm={4}>
                      <Typography>Health Info From</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        control={control}
                        name={`consent.permission.dateRange.from`}
                        render={({ field: { onChange, value } }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              InputProps={{
                                className: classes.dateField,
                              }}
                              fullWidth
                              autoOk
                              error={
                                !!errors.consent?.permission?.dateRange?.from
                              }
                              helperText={
                                errors.consent?.permission?.dateRange?.from
                                  ?.message
                              }
                              value={value}
                              onChange={(date) => {
                                const fromDate = moment(date)?.toISOString();
                                onChange(fromDate);
                              }}
                              disableFuture
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} style={{ marginTop: "1%" }}>
                    <Grid item xs={12} sm={4}>
                      <Typography>Health Info To</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        control={control}
                        name={`consent.permission.dateRange.to`}
                        render={({ field: { onChange, value } }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              InputProps={{
                                className: classes.dateField,
                              }}
                              fullWidth
                              autoOk
                              error={
                                !!errors.consent?.permission?.dateRange?.to
                              }
                              helperText={
                                errors.consent?.permission?.dateRange?.to
                                  ?.message
                              }
                              value={value}
                              onChange={(date) => {
                                const toDate = moment(date)?.toISOString();
                                onChange(toDate);
                              }}
                              disableFuture
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} style={{ marginTop: "1%" }}>
                    <Grid item xs={12} sm={4}>
                      <Typography>Health Info Type</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Checkbox
                        checked={typeChecked?.OPConsultation}
                        onChange={(event) => {
                          setTypeChecked({
                            ...typeChecked,
                            OPConsultation: event.target.value,
                          });
                        }}
                        label="OP Consultation"
                      />
                      <Checkbox
                        checked={typeChecked?.DiagnosticReport}
                        onChange={(event) => {
                          setTypeChecked({
                            ...typeChecked,
                            DiagnosticReport: event.target.value,
                          });
                        }}
                        label="Diagnostics Reports"
                      />
                      <Checkbox
                        checked={typeChecked.Prescription}
                        onChange={(event) => {
                          setTypeChecked({
                            ...typeChecked,
                            Prescription: event.target.value,
                          });
                        }}
                        label="Prescription"
                      />
                      <Checkbox
                        checked={typeChecked.HealthDocumentRecord}
                        onChange={(event) => {
                          setTypeChecked({
                            ...typeChecked,
                            HealthDocumentRecord: event.target.value,
                          });
                        }}
                        label="Record artifact"
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Checkbox
                        checked={typeChecked.DischargeSummary}
                        onChange={(event) => {
                          setTypeChecked({
                            ...typeChecked,
                            DischargeSummary: event.target.value,
                          });
                        }}
                        label="Discharge Summary"
                      />
                      <Checkbox
                        checked={typeChecked.ImmunizationRecord}
                        onChange={(event) => {
                          setTypeChecked({
                            ...typeChecked,
                            ImmunizationRecord: event.target.value,
                          });
                        }}
                        label="Immunization Record"
                      />
                      <Checkbox
                        checked={typeChecked.WellnessRecord}
                        onChange={(event) => {
                          setTypeChecked({
                            ...typeChecked,
                            WellnessRecord: event.target.value,
                          });
                        }}
                        label="Wellness Record"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} style={{ marginTop: "1%" }}>
                    <Grid item xs={12} sm={4}>
                      <Typography>Consent Expiry</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        control={control}
                        name={`consent.permission.dataEraseAt`}
                        render={({ field: { onChange, value } }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              InputProps={{
                                className: classes.dateField,
                              }}
                              fullWidth
                              autoOk
                              error={!!errors.consent?.permission?.dataEraseAt}
                              helperText={
                                errors.consent?.permission?.dataEraseAt?.message
                              }
                              value={value}
                              onChange={(date) => {
                                const expiryDate = moment(date)?.toISOString();
                                onChange(expiryDate);
                              }}
                              disablePast
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid item xs={12} align="center">
                <Button
                  style={{ backgroundColor: myOwnColor, color: "white" }}
                  onClick={handleSubmit(handleRequestConsent)}
                >
                  Request Consent
                </Button>
                {/* <Link to="/fhirDisplay"> */}
                <Button
                  style={{
                    backgroundColor: myOwnColor,
                    color: "white",
                    marginLeft: "10px",
                  }}
                  onClick={() => setOpenFhirDisplay(true)}
                >
                  View Patient Data
                </Button>
                {/* </Link> */}
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
        <Dialog
          maxWidth="xl"
          fullWidth
          open={openFhirDisplay}
          onClose={() => setOpenFhirDisplay(false)}
        >
          <FhirDisplay closeDialog={() => setOpenFhirDisplay(false)} />
        </Dialog>
        <div>
          <ScrollUpHomePage />
        </div>
      </div>
    </ThemeProvider>
  );
}
