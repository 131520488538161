import {
  FETCH_MOA_STREAM_STATISTICS_DASHBOARD,
  FETCH_MOA_INSTITIUTE_STATISTICS_DASHBOARD,
  FETCH_MOA_COUNCIL_STATISTICS_DASHBOARD,
  FETCH_COUNT_BY_ORGID,
  FETCH_TOP10_DISEASE
} from "../actions/Type";

const initialState = {
  streamDashboardStatistics: [],
  instituteDashboardStatistics: [],
  councilDashboardStatistics: [],
  instituteWiseCountByOrgId:[],
  topDisease:[]
};

const MoaDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MOA_STREAM_STATISTICS_DASHBOARD:
      return {
        ...state,
        streamDashboardStatistics: action.payload,
      };
    case FETCH_MOA_INSTITIUTE_STATISTICS_DASHBOARD:
      return {
        ...state,
        instituteDashboardStatistics: action.payload,
      };
    case FETCH_MOA_COUNCIL_STATISTICS_DASHBOARD:
      return {
        ...state,
        councilDashboardStatistics: action.payload,
      };
      case FETCH_COUNT_BY_ORGID:
        return {
          ...state,
          instituteWiseCountByOrgId: action.payload,
        };
        case FETCH_TOP10_DISEASE:
        return {
          ...state,
          topDisease: action.payload,
        };
    default:
      return state;
  }
};

export default MoaDashboardReducer;
