import { FETCH__PAYMENTMODE_LIST_BY_ORG} from "../actions/Type";

const initialState = {
  PaymentMode: [],
};

const PaymentModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH__PAYMENTMODE_LIST_BY_ORG:
      return {
        ...state,
        PaymentMode: action.payload,
      };

    default:
      return state;
  }
};

export default PaymentModeReducer;
