import { Card, Grid } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from "react-router-dom";


const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    backgroundColor: '#FCFAFA',
    margin:40,
    marginBottom:0
  },
  content: {
    fontSize: 22,
    color: '#0B9CAB',
    fontWeight: 'bold',
    fontFamily: 'helvetica',
    marginLeft: '2%'
  },
  content1: {
    fontSize: 18,
    color: '#103A4D',
    fontFamily: 'helvetica',
    marginLeft: '2%',
    marginRight: '2%',
    marginTop:"1%",
    textAlign:"justify"
  },
  button: {
    color: '#1F49D4',
    fontFamily: 'helvetica',
    fontSize: 14,
    align: 'right',
 
    // marginLeft:'80%'
  }
});

export default function SimpleCard() {
  const classes = useStyles();


  return (

    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.content} gutterBottom>
          ABOUT A-HMIS
        </Typography>
        <Typography className={classes.content1} gutterBottom>
        A-HMIS is a comprehensive IT platform to effectively manage all functions of health care delivery systems and patient care in Ayush facilities. THERAN (THE Research Application Nexus) - Hospital Management Information System developed by Siddha Central Research Institute, Chennai under Central Council for Research in Siddha, Ministry of Ayush was near to the requirement to give complete solution of HMIS amongst various HMIS present in Ayush Institutions of Govt. of India, hence it was decided to upscale and customize it as per systems in Ayush.
        </Typography>
      </CardContent>

      <CardActions>
        <Grid container spacing={0}>
          <Grid item xs={11} align="right">

            <Link to="/aboutcontent" style={{ textDecoration: 'none' }} >
              <Typography className={classes.button}>Read More ...</Typography>
            </Link>
          </Grid>
        </Grid>

      </CardActions>
    </Card>

  );
}
