import { SHOW_HOME_OVERALL_STATISTICS, SHOW_HOME_TODAY_STATISTICS } from "../actions/Type";

const initialState = {
  overall:false,
  today:false
}

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HOME_OVERALL_STATISTICS:
      return {
        ...state,
        overall:true
      };
    case SHOW_HOME_TODAY_STATISTICS:
      return {
        ...state,
        today:true
      };
    default:
      return state;
  }
};
