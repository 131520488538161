import { Card, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Appbar from "./Appbar";
import Footer from "./Footer";
import Header from "./Header2";

const useStyles = makeStyles({
  root: {
    // backgroundColor: '#FCFAFA',
    width: "96%",
    marginTop: "2%",
    marginLeft: "2%",
  },
  content: {
    fontSize: 15,
    color: "#103A4D",
    fontFamily: "helvetica",
    marginLeft: "6%",
    marginTop: "1%",
    textAlign: "justify",
  },
  content1: {
    fontSize: 16,
    color: "#103A4D",
    fontFamily: "unset",
    textAlign: "justify",
    marginLeft: "2%",
    marginTop: "2%",
  },
  content2: {
    color: "#0B9CAB",
    fontWeight: "bold",
    fontFamily: "unset",
    fontSize: "18px",
    marginLeft: "4.4%",
    marginTop: "2%",
  },
  content3: {
    position: "fixed",
    left: "0px",
    right: "0px",
    bottom: "0px",
    marginBottom: "0px",
    width: "100%",
    backgroundColor: "#302b2b",
    zIndex: "999",
  },
});

export default function OutlinedCard() {
  const classes = useStyles();

  return (
    <>
      <Header />

      <Appbar />

      <div style={{ backgroundColor: "#eff7fa", height: "calc(100vh - 200px)" }}>
        <Grid container spacing={0}>
          <Card className={classes.root}>
            <Typography className={classes.content2}>CONTACT US</Typography>

            <Grid container spacing={0}>
              <Grid items xs={12} sm={9} style={{}}>
                <Typography style={{}} className={classes.content}>
                  <b>Ministry of Ayush</b>
                  <br />
                  Ayush Bhawan, B Block,
                  <br />
                  GPO Complex, INA,
                  <br />
                  New Delhi – 110023
                  <br />
                  Email- itconsultant-ayush@nic.in
                  <br />
                  srconsultant-ayush@nic.in
                </Typography>
              </Grid>
            </Grid>
            <br />
            <br />
          </Card>
        </Grid>
      </div>
      <div  style={{position: "fixed",
    left: "0px",
    right: "0px",
    bottom: "0px",
    marginBottom: "0px",
    width: "100%",
    backgroundColor: "#302b2b",
    zIndex: "999",}} >
        <Footer />
      </div>
    </>
  );
}
