import {
  FETCH_ROLE,
  FETCH_DURATION_TYPE,
  FETCH_ALL_DUTYROSTER,
  FETCH_STAFF,
  DELETE_DUTYROSTER,
  FETCH_NURSE_DUTY_LIST,
} from "./Type";
import { showSnackbar } from "./Notification";
import axiosInstance from "../api/Instance";

export const fetchRoles = () => async (dispatch) => {
  const NON_ADMIN_ROLES = 2;
  try {
    const res = await axiosInstance.get(`role/getAllNonAdminRoles/${NON_ADMIN_ROLES}`);
    console.log(" res ->", res);
    dispatch({
      type: FETCH_ROLE,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchDutyRoster = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`dutyRoster/getAllDutyRosterDetails/${orgId}`);

    dispatch({
      type: FETCH_ALL_DUTYROSTER,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchDurationType = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`durationType/getAllDurationTypes`);
    dispatch({
      type: FETCH_DURATION_TYPE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchStaff = (role_id, orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`staff/getStaffByRoleNOrgId/${role_id}/${orgId}`);

    dispatch({
      type: FETCH_STAFF,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchNurseDutyList = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`nurseDutyRosterMap/getDataByOrgId/${orgId}`);

    dispatch({
      type: FETCH_NURSE_DUTY_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteDutyRosterDetails = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`dutyRoster/delete/${id}`);
    dispatch({
      type: DELETE_DUTYROSTER,
      payload: res.data,
    });
    console.log(" delete dutyRoster ", res.data);
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res.data == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Duty Roster details deleted successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveDutyRosterDetails = (dutyRoster) => async (dispatch) => {
  try {
    const url = "dutyRoster/saveDutyRoster";

    const response = await axiosInstance.post(url, dutyRoster);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res.message == "success") {
      if (dutyRoster.id == 0) {
        responseObj.message = "Duty Roster added successfully";
      } else {
        responseObj.message = "Duty Roster updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveNurseDutyDetails = (dutyRoster) => async (dispatch) => {
  try {
    const url = "nurseDutyRosterMap/saveNurseDutyRosterMap";

    const response = await axiosInstance.post(url, dutyRoster);

    console.log("res", response);
    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      if (dutyRoster.id === 0) {
        responseObj.message = "Duty Roster added successfully";
      } else {
        responseObj.message = "Duty Roster updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};
