import {
  FETCH_DIET_TYPES,
  FETCH_DIET_ARTICLE,
  FETCH_MEAL_TYPE,
  FETCH_FOOD_ITEMS,
  FETCH_FOOD_MEAL_TYPE_MAP,
  FETCH_FOOD_DIET_ARTICLE_MAP,
} from "../actions/Type";

const initialState = {
  dietTypes: [],
  dietArticle: [],
  meals: [],
  foodNames :[],
  foodMealTypeMap: {},
  foodDietArticleMap: {},
};

const DietReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DIET_TYPES:
      return {
        ...state,
        dietTypes: action.payload,
      };
    case FETCH_DIET_ARTICLE:
      return {
        ...state,
        dietArticle: action.payload,
      };
    case FETCH_MEAL_TYPE:
      return {
        ...state,
        meals: action.payload,
      };

      case FETCH_FOOD_ITEMS:
      return {
        ...state,
        foodNames: action.payload,
      };
    case FETCH_FOOD_MEAL_TYPE_MAP:
      return {
        ...state,
        foodMealTypeMap: action.payload,
      };

    case FETCH_FOOD_DIET_ARTICLE_MAP:
      return {
        ...state,
        foodDietArticleMap: action.payload,
      };
    default:
      return state;
  }
};

export default DietReducer;
