import { Container, Paper, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../component/AyushFacilitiesUseTable";
import "../stickyTable.css";
import Appbar from "./Appbar";
import Footer from "./Footer";
import Header from "./Header2";
import ScrollUpHomePage from "./ScrollUpHomePage";

import { InputAdornment, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@material-ui/core";
import { fetchAllState, fetchCitiesByStateID, fetchHealthFacilities } from "../actions/GraphAction";
import { fetchSom } from "../actions/SimpleMedicineAction";
import "../stickyTable.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  pageContent: {
    backgroundColor: "white",
    width: "95%",
    marginLeft: "2.5%",
    marginTop: "1%",
  },
  table: {
    minWidth: "100%",
    backgroundColor: "white",
  },

  select: {
    width: "100%",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    height: 26,
  },
  Content1: {
    fontWeight: 500,
    fontSize: "15px",
    color: "#0B9CAB",
  },

  inputPadding: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0 !important",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0 !important",
    },
  },
  margin: {
    marginTop: "3%",
  },
  searchInput: {
    // marginTop: "60px",
    alignContent: "center",
    justifyItems: "center",
    width: "85%",
  },
  icon: {
    color: "#585858",
    fontSize: "13px",
  },
}));
const headCells = [
  {
    id: "healthFacilityName",
    label: "HEALTH FACILITY NAME",
    disableSorting: true,
  },
  { id: "stream", label: "STREAM", disableSorting: true },
  { id: "state", label: "STATE", disableSorting: true },
  { id: "city", label: "CITY", disableSorting: true },
  { id: "address", label: "ADDRESS", disableSorting: true },
  { id: "contact", label: "CONTACT NO", disableSorting: true },
];

export default function Feedback() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const states = useSelector((state) => state.graphReducer.states);
  const cities = useSelector((state) => state.graphReducer.cities);
  const healthList = useSelector((state) => state.graphReducer.healthList);
  // const [row,setRow] =useSelector(healthList)

  const [records, setRecords] = useState([]);

  const [statess, setStatess] = React.useState("-999");
  const [citiess, setCitiess] = useState("-999");
  const [som, setSom] = useState("-999");

  useEffect(() => {
    console.log("-----------------------------------fetchHealthFacilities--------------------------");
    dispatch(fetchSom());
    console.log(soms);

    dispatch(fetchHealthFacilities(statess, citiess, som));
    dispatch(fetchAllState());
  }, []);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {}, [healthList]);

  const handleChangeState = (event) => {
    setStatess(event.target.value);
    setCitiess("-999");
    setSom("-999");
    console.log("ee", event.target.value);
    if (event.target.value != -999) {
      dispatch(fetchCitiesByStateID(event.target.value));
    }
  };
  useEffect(() => {
    dispatch(fetchHealthFacilities(statess, citiess, som));
  }, [statess, citiess, som]);

  useEffect(() => {
    setRecords(healthList);
  }, [healthList]);

  const handleChangeCity = (event) => {
    setCitiess(event.target.value);
    setSom("-999");
  };

  const handleChangeSom = (event) => {
    setSom(event.target.value);
    setCitiess(citiess);
  };

  let soms = useSelector((state) => state.simpleMedicineReducer.som);

  const requestSearch = (event) => {
    const searchedVal = event.target.value;
    console.log("searchedVal", searchedVal);
    const filteredRows =
      healthList &&
      healthList.filter((row) => {
        return (
          row.stream.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.healthFacilityName.toLowerCase().includes(searchedVal.toLowerCase())
        );
      });
    setRecords(filteredRows);
  };

  const { TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn);

  return (
    <>
      <Header />
      <Appbar />

      <Container maxWidth="xl" style={{ padding: 40 }}>
        <Grid container spacing={3} alignItems="center" justifyContent="space-evenly">
          <Grid item xs={12}>
            <Typography className={classes.Content1}>
              Note : It is an exhaustive list of Ayush health facilities registered in A-HMIS.
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3} md={2} lg={1} xl={1} align="right">
            <Typography>State</Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={9} lg={2} xl={2} align="left">
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                native
                id="insitute"
                size="small"
                value={statess}
                onChange={handleChangeState}
                variant="outlined"
                placeholder="State"
              >
                <option
                  label="Select State"
                  style={{
                    color: "grey",
                  }}
                  value={-999}
                />
                {states &&
                  states.length !== 0 &&
                  states.map((state) => {
                    return <option value={state.id}>{state.stateName}</option>;
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={3} md={2} lg={1} xl={1} align="right">
            <Typography>City</Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={9} lg={2} xl={2} align="left">
            <FormControl variant="outlined" size="small" fullWidth>
              <Select native value={citiess} onChange={handleChangeCity} variant="outlined">
                <option
                  label="Select City"
                  style={{
                    color: "grey",
                  }}
                  value={-999}
                />
                {statess !== -999 &&
                  cities &&
                  cities.length !== 0 &&
                  cities.map((state) => {
                    return (
                      <option className={classes.menuInput} value={state.id}>
                        {state.cityName}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3} md={2} lg={1} xl={1} align="right">
            <Typography>Stream</Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={9} lg={2} xl={2} align="left">
            <FormControl variant="outlined" size="small" fullWidth>
              <Select native size="small" value={som} onChange={handleChangeSom} variant="outlined" placeholder="State">
                <option
                  label="Select Stream"
                  style={{
                    color: "grey",
                  }}
                  value={-999}
                />
                {soms &&
                  soms?.length !== 0 &&
                  soms?.map((state) => {
                    return (
                      <option className={classes.menuInput} value={state.id}>
                        {state.streamName}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={2} xl={3}>
            <TextField
              fullWidth
              label="Search "
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={requestSearch}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container component={Paper}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <TableContainer style={{ maxHeight: "540px" }}>
                  <Table stickyHeader size="small">
                    <TblHead />
                    {recordsAfterPagingAndSorting()?.length !== 0 ? (
                      <TableBody>
                        {recordsAfterPagingAndSorting()?.map((row, id) => (
                          <TableRow key={row.id}>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "14px",
                                width: "35%",
                              }}
                            >
                              {row.healthFacilityName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "14px",
                                width: "5%",
                              }}
                            >
                              {row.stream}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "14px",
                                width: "15%",
                              }}
                            >
                              {row.state}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "14px",
                                width: "5%",
                              }}
                            >
                              {row.city}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "14px",
                                width: "30%",
                              }}
                            >
                              {row.address}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "14px",
                                width: "10%",
                              }}
                            >
                              {row.contact}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No Records Found
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <TblPagination />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <ScrollUpHomePage />

      <div
        style={{
          position: "fixed",
          left: "0px",
          right: "0px",
          bottom: "0px",
          marginBottom: "0px",
          width: "100%",
          backgroundColor: "#302b2b",
          zIndex: "999",
        }}
      >
        <Footer />
      </div>
    </>
  );
}
