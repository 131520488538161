import { FETCH_ADVICE_LIST ,FETCH_PHARMACY_DO_DONT} from "../actions/Type";

const initialState = {
  advices: [],
  pharmacyDoDont: []
};

const AdviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADVICE_LIST:
      return {
        ...state,
        advices: action.payload,
      };
      case FETCH_PHARMACY_DO_DONT:
      return {
        ...state,
        pharmacyDoDont: action.payload,
      };

    default:
      return state;
  }
};

export default AdviceReducer;
