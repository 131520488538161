import { STAFF_LOGOUT,SET_DEPARTMENT } from "./Type";
import { showSnackbar } from "../actions/Notification";
import axiosUserInstance from "../api/UserInstance";
import axiosMasterInstance from "../api/Instance";

export const staffLogout = (logout) => async (dispatch) => {
  const url = "user/logout";
  try {
    const response = await axiosUserInstance.post(url, logout);
    // ------Restore All the Reducers State to initial State------ 
    dispatch({ type: STAFF_LOGOUT, payload: response.data });
    dispatch({type: SET_DEPARTMENT,payload: []});
    localStorage.clear();
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const updatePassword = (data) => async (dispatch) => {
  try {
    const url = "user/changePassword";

    const response = await axiosMasterInstance.post(url, data);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      responseObj.message = "Password Updated successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      return true;
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};
