import {
  FETCH_PAID_MEDICINE_TYPE,
  FETCH_PAID_MEDICINE_NAME,
  // FETCH_VEHICLE_NAME,
} from "../actions/Type";

const initialState = {
  paidMedicineTypes: [],
  paidMedicineNames: [],
  // vehicleName: [],

  // uniqueCities:[],
  // departments:[],
  // apptId: String
};

const PaidMedicineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAID_MEDICINE_TYPE:
      return {
        ...state,
        paidMedicineTypes: action.payload,
      };

    case FETCH_PAID_MEDICINE_NAME:
      return {
        ...state,
        paidMedicineNames: action.payload,
      };

    // case FETCH_VEHICLE_NAME:
    //   return {
    //     ...state,
    //     vehicleName: action.payload,
    //   };

    default:
      return state;
  }
};

export default PaidMedicineReducer;
