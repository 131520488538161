import axiosInstance from "../../api/Instance"
import ipdOpdInstance  from "../../api/IpdOpdInstance"

import { FETCH_LAB_TEST_UNITS, FETCH_INVESTIGATION_TESTS } from "../Type";

export const fetchLabTestUnitDetails = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("labTestUnit/getAllLabTestUnits");
    dispatch({
      type: FETCH_LAB_TEST_UNITS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchInvestigationSummary = (con_id,dateString) => async (dispatch) => {
    try {
      const res = await ipdOpdInstance.get(`patientLabTest/getInvestigationSummary/${con_id}/${dateString}`);
      dispatch({
        type: FETCH_INVESTIGATION_TESTS,
        payload: res.data,
      });
      return res.data;
          
    } catch (error) { 
        console.log(error)
        return "EMPTY"
    } 
  };
