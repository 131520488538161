import { showSnackbar } from "../actions/Notification";
import axiosInstance from "../api/Instance";
import ipdOpdInstance from "../api/IpdOpdInstance";

import {
  FETCH_PULSE_LIST,
  FETCH_SUB_PULSE_LIST,
  FETCH_NABZ_MURAKKAB_LIST,
} from "./Type";

export const fetchPulseDetails = (somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      `pulseType/getActivePulseBySOM/` + somId
    );
    dispatch({
      type: FETCH_PULSE_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const savePulseDetails = (pulseDetails) => async (dispatch) => {
  try {
    const url = "pulseType/savePulse";

    const response = await axiosInstance.post(url, pulseDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "success") {
      if (pulseDetails.id == 0) {
        responseObj.message = "Pulse details added successfully";
      } else {
        responseObj.message = "Pulse details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      if (res == "failure") {
        responseObj.message = "Request failed. Plz try again.";
      } else {
        responseObj.message = res;
      }
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const deletePulse = (pulseId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`pulseType/deletePulse/${pulseId}`);
    console.log("deletePulse", res.data);
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res.data == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Pulse details deleted successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchSubPulseDetails = (pulseTypeId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      `subPulseType/getSubPulsesByPulseTypeId/` + pulseTypeId
    );
    dispatch({
      type: FETCH_SUB_PULSE_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchNabzMurakkab = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`pulseNabzMurakkab/getNabzMurakkab/`);
    dispatch({
      type: FETCH_NABZ_MURAKKAB_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveUnaniPulse = (pulseDetails) => async (dispatch) => {
  try {
    const url = "nabzDetails/saveNabzDetails";

    const response = await ipdOpdInstance.post(url, pulseDetails);
    const res = response.data;
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};
