import React from "react";
// import { useTranslation } from 'react-i18next'
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ahmis from "../assets/ahmis.webp";
import leaf from "../assets/leaf.png";
import logo from "../assets/logo.webp";

const useStyles = makeStyles((theme) => ({ 
  typography: {
    fontFamily: "helvetica",
    color: "#103A4D",
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      fontWeight: 500,
      color: "#103A4D",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      fontWeight: 500,
      color: "#103A4D",
    },
  },
  image: {
    [theme.breakpoints.up("md")]: {
      height: 75,
    },

    [theme.breakpoints.down("md")]: {
      height: 55,
    },
    [theme.breakpoints.down("sm")]: {
      height: 35,
    },
  },
}));

function SampleApp() {
  const classes = useStyles();
  // const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={2} sm={1} align="center" style={{paddingLeft:8}} >
        <img src={logo} alt="logo" className={classes.image} />
      </Grid>

      <Grid item xs={8} sm={10}>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item>
            <img src={ahmis} alt="logo" className={classes.image} />
          </Grid>
          <Grid item align="center">
            <Typography
              className={classes.typography}              
            >
              {/*   <div>{t("Ayush")}</div> */}
              <div>Ayush</div>
              <div>&nbsp;Hospital Management Information System</div>
              <div>(A-HMIS)</div>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2} sm={1} align="right" style={{paddingRight:8}}  >
        <img src={leaf} alt="logo" className={classes.image} />
      </Grid>
    </Grid>
  );
}
export default SampleApp;
