export function inputRestrict(e, removeListRegex) {
  const input = e.target;
  const start = input.selectionStart,
    end = input.selectionEnd;
  const initialLen = input.value.length;
  input.value = input.value.replace(removeListRegex, "");
  const lengthDifference = input.value.length - initialLen;
  if (lengthDifference) {
    const newStart = start + lengthDifference;
    const newEnd = end + lengthDifference;
    input.setSelectionRange(newStart, newEnd);
  }
}

export function inputToUpper(e) {
  const input = e.target,
    start = input.selectionStart,
    end = input.selectionEnd;
  input.value = input.value.toLocaleUpperCase();
  input.value = input.value.endsWith(" ") ? input.value.trim() + " " : input.value.trim();
  input.setSelectionRange(start, end);
}
export function inputTrim(e) {
  const input = e.target,
    start = input.selectionStart,
    end = input.selectionEnd;
  input.value = input.value.endsWith(" ") ? input.value.trim() + " " : input.value.trim();
  input.setSelectionRange(start, end);
}

export function inputTrimUnderScoreDot(e) {
  const input = e.target,
    start = input.selectionStart,
    end = input.selectionEnd;
  let trimmedValue = input.value.trim();
  let underscoreEntered = false;
  let dotEntered = false;
  trimmedValue = trimmedValue
    .split('')
    .map((char) => {
      if (/^[a-zA-Z0-9]$/.test(char)) {
        return char;
      } else if (char === '_' && !underscoreEntered) {
        underscoreEntered = true;
        return char;
      } else if (char === '.' && !dotEntered) {
        dotEntered = true;
        return char;
      } else {
        return '';
      }
    })
    .join('');
  trimmedValue = input.value.endsWith(' ') ? trimmedValue + ' ' : trimmedValue;
  input.value = trimmedValue;
  input.setSelectionRange(start, end);
}

export function inputPhoneNumber(num) {
  const first = num % 10;
  while (num) {
    if (num % 10 !== first) return false;
    num = Math.floor(num / 10);
  }
  return true;
}

export const restrict = {
  email(e) {
    //alphanumericdotampersand
    inputRestrict(e, /[^@a-zA-Z-_.\d]/g);
  },
  alphaAddress(e) {
    inputRestrict(e, /^(?=.*[a-zA-Z])(?=(?:[^._]*[._]?[^._]*)*$)[a-zA-Z0-9._]+$/);
  },
  remarks(e) {
    inputRestrict(e, /[^-a-zA-Z(),&.+ \d]/g);
  },
  onlyCharacter(e) {
    inputRestrict(e, /[^a-zA-Z ]/g);
  },
  dot(e) {
    inputRestrict(e, /[^\w.a-zA-Z\d]/g);
  },
  address(e) {
    inputRestrict(e, /[^-/a-zA-Z.#/,() \d,]/g);
  },
  alphanumerichypenbrackets(e) {
    inputRestrict(e, /[^-a-zA-Z()\d]/g);
  },
  alphanumerichypenbracketsspace(e) {
    inputRestrict(e, /[^-a-zA-Z() \d]/g);
  },
  alphanumerichypenbracketscommaspace(e) {
    inputRestrict(e, /[^-a-zA-Z(), \d]/g);
  },
  alphahypenbracketscommaunderscorespace(e) {
    inputRestrict(e, /[^-a-zA-Z(),_ ]/g);
  },
  alphahypenbracketscommaunderscoredotspace(e) {
    inputRestrict(e, /[^-a-zA-Z(),_. ]/g);
  },
  alphanumerichypendotspace(e) {
    inputRestrict(e, /[^-a-zA-Z. \d]/g);
  },
  alphanumerichypen(e) {
    inputRestrict(e, /[^-a-zA-Z \d]/g);
  },
  alphanumerichypenbracketscommaspacedot(e) {
    inputRestrict(e, /[^-a-zA-Z(),. \d]/g);
  },
  alphanumerichypenbracketscommaspacedotampersand(e) {
    inputRestrict(e, /[^-a-zA-Z(),&. \d]/g);
  },
  alphanumerichypenspaceampersand(e) {
    inputRestrict(e, /[^-a-zA-Z& \d]/g);
  },
  alphanumerichypenbracketsspacedot(e) {
    inputRestrict(e, /[^-a-zA-Z(). \d]/g);
  },
  alphanumerichypenbracketsspacedotplus(e) {
    inputRestrict(e, /[^-a-zA-Z().+ \d]/g);
  },
  alphanumerichypenbracketscommaspacedotbigbrackets(e) {
    inputRestrict(e, /[^-a-zA-Z.(), \d,\[\]]/g);
  },
  alphanumerichypenbracketscommaspacedotunderscore(e) {
    inputRestrict(e, /[^-a-zA-Z._(), \d,]/g);
  },
  batchNo(e) {
    inputRestrict(e, /[^-a-zA-Z_/  \d]/g);
  },
  name(e) {
    inputRestrict(e, /[^a-zA-Z ]/g);
  },
  alphanumericspace(e) {
    inputRestrict(e, /[^a-zA-Z\d ]/g);
  },
  alphanumerichypen(e) {
    inputRestrict(e, /[^-a-zA-Z\d]/g);
  },
  alphanumericunderscore(e) {
    inputRestrict(e, /[^_a-zA-Z\d]/g);
  },
  alphanumericunderscoredot(e) {
    inputRestrict(e, /[^_a-zA-Z.\d]/g);
  },
  alphanumeric(e) {
    inputRestrict(e, /[^a-zA-Z\d]/g);
  },
  digits(e) {
    inputRestrict(e, /\D/g);
  },
  floorNo(e) {
    inputRestrict(e, /[^\d.-]/g);
  },
  dose(e) {
    inputRestrict(e, /[^\d.]/g);
  },
  number(e) {
    inputRestrict(e, /[^\d.-]/g);
  },
  numberDot(e) {
    inputRestrict(e, /[^\d.]/g);
  },
  normalValueRange(e) {
    inputRestrict(e, /[^a-zA-Z\d%<>=,.:\-()\s/]/g);
  },
  labnames(e) {
    inputRestrict(e, /[^a-zA-Z\d,()\-/\s]/g);
  },
  commentsValid(e) {
    inputRestrict(e, /[^a-zA-Z\d,.&%()\-/\s]/g);
  },
  staffRegNo(e) {
    inputRestrict(e, /[^a-zA-Z\d\-\s/]/g);
  },
  checkForUnicode(e) {
    inputRestrict(e, /[^a-zA-Z $&+`:;=?@#|'<>{}^*%!~-]/g);
  },
  checkForUomName(e) {
    inputRestrict(e, /[$&+:;=?@#|'<>{}\[\]^*%!~-]/g);
  },
  checkForMedName(e) {
    inputRestrict(e, /[$&+:;=?@#|'<>{}\[\]^*%!~]/g);
  },
  labFinding(e) {
    inputRestrict(e, /[^-a-zA-Z(),&./:;<>=""'' \d]/g);
  },
  positiveInteger(e) {
    inputRestrict(e, /[^\d]/g);
  },
  notFirstZero(e) {
    inputRestrict(e, /[^(?!(0))[0-9]]/g);
  },
  discharge(e) {
    inputRestrict(e, /[^-/a-zA-Z.@/,() \d,]/g);
  },
  treatmentgiven(e) {
    inputRestrict(e, /[^-/a-zA-Z.@/,() \d,]/g);
  },
  charactersWithSpecialCharacters(e) {
    inputRestrict(e, /[^a-zA-Z %<>=,.""'':\-()\s/]/g);
  },
  complaintsExaminationNotes(e) {
    inputRestrict(e, /[^-/a-zA-Z./,() \d,]/g);
  },
  newRemarks(e) {
    inputRestrict(e, /[^-/a-zA-Z./,() \d]/g);
  },
  dischargeSummaryTreatmentGiven(e) {
    inputRestrict(e, /[^-/a-zA-Z./,+&:;%() \d]/g);
  },
};
