import {
  FETCH_EXTERNAL_MEDICINE_TYPE,
  FETCH_EXTERNAL_MEDICINE_NAME,
} from "../actions/Type";

const initialState = {
  externalMedicineTypes: [],
  externalMedicineNames: [],
};

const ExternalMedicineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXTERNAL_MEDICINE_TYPE:
      return {
        ...state,
        externalMedicineTypes: action.payload,
      };

    case FETCH_EXTERNAL_MEDICINE_NAME:
      return {
        ...state,
        externalMedicineNames: action.payload,
      };

    default:
      return state;
  }
};

export default ExternalMedicineReducer;
