import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "../App.css";
import about from "../assets/about1.webp";
import benefits from "../assets/benefits.webp";
import features from "../assets/features.webp";
import objective from "../assets/objective1.webp";
import Appbar from "./Appbar";
import Footer from "./Footer";
import Header from "./Header2";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    marginLeft: "2%",
    marginTop: "2%",
    justifySelf: "center",
  },
  content: {
    fontSize: 18,
    color: "#103A4D",
    fontFamily: "helvetica",
    textAlign: "justify",
    padding: theme.spacing(1),
  },
  contentTitle: {
    color: "#0B9CAB",
    fontWeight: "bold",
    fontFamily: "helvetica",
    fontSize: "22px",
    textAlign: "center",
    marginTop: "2%",
    padding: "10px",
  },
  logo: {
    height: "260px",
    marginTop: "1%",
    maxWidth: "-webkit-fill-available",
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <>
     
        <Header />
     
        <Appbar />
    
      <Grid container spacing={0} style={{ backgroundColor: "#eff7fa" }}>
        <Grid items xs={12} className={classes.margin}>
          
            <Card style={{ width: "98%" }}>
              <Typography className={classes.contentTitle}>
                ABOUT A-HMIS
              </Typography>
              <br />
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  items
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ alignSelf: "center" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img className={classes.logo} src={about} alt="logo" />
                </Grid>
                <Grid
                  items
                  xs={12}
                  sm={8}
                  md={9}
                  style={{ marginTop: "0%", alignSelf: "center" }}
                >
                  <Typography
                    className={classes.content}
                    gutterBottom
                    style={{ marginRight: "1%" }}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    A-HMIS is a comprehensive IT platform to effectively manage
                    all functions of health care delivery systems and patient
                    care in Ayush facilities. THERAN (THE Research Application
                    Nexus) - Hospital Management Information System developed by
                    Siddha Central Research Institute, Chennai under Central
                    Council for Research in Siddha, Ministry of Ayush was near
                    to the requirement to give complete solution of HMIS amongst
                    various HMIS present in Ayush Institutions of Govt. of
                    India, hence it was decided to upscale and customize it as
                    per systems in Ayush.
                    <br />
                    <br />
                    The Digital India Movement initiated by Govt. of India has
                    made significant changes in IT sector. We are witnessing
                    tremendous improvement in application of Information
                    Technology (IT) in every Ministry and its organizations.
                    Health is a major concern in India. Implementation of IT in
                    healthcare is being accelerated to provide effective and
                    better care for citizens of India.
                    <br />
                    <br />
                    Ministry of Ayush has taken initiative to get onboard in
                    healthcare Information technology implementation projects.
                    In this regard, Ministry of Ayush has created Ayush GRID to
                    get all the IT projects under one umbrella. It is
                    integration of IT projects exclusively meant for improvement
                    and facilitation of Ayush pan India.
                    <br />
                    <br />
                    The initiative is a poised to emerge as a game changer as it
                    is the first of its kind citizen centric service from
                    Government of India to provide Electronic Health Record and
                    Personal Health Record facility to the recipients of Ayush
                    Health Services catered through Research Councils, National
                    Institutes and other related agencies under Ministry of
                    Ayush, Government of India.
                    <br />
                    <br />
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </Card>
         
        </Grid>
        <Grid items xs={12} className={classes.margin}>
         
          <Card style={{ width: "98%" }}>
            <Typography className={classes.contentTitle}>
              OBJECTIVES OF A-HMIS
            </Typography>
            <br />
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                items
                xs={12}
                sm={8}
                md={9}
                style={{ marginTop: "1%", alignSelf: "center" }}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.content} gutterBottom>
                  <ul>
                    <li>To improve patient care in Ayush</li>
                    <li>To improve work efficiency in Ayush</li>
                    <li>Efficient management of Ayush hospital</li>
                    <li>Effective documentation of Ayush</li>
                    <li>
                      To derive comprehensive data of all Ayush hospital, which
                      helps in effective Ayush health policy
                    </li>
                    <li>To improve research in Ayush</li>
                    <li>
                      To eliminate the chances of errors in Ayush hospital
                    </li>
                    <li>To collect data on National Ayush morbidities</li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                items
                xs={12}
                sm={4}
                md={3}
                style={{ alignSelf: "center" }}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img className={classes.logo} src={objective} alt="logo" />
              </Grid>
            </Grid>
            <br />
          </Card>
        </Grid>
        {/* =================================================== */}

        <Grid items xs={12} className={classes.margin}>
         
            <Card style={{ width: "98%" }}>
              <Typography className={classes.contentTitle}>
                SPECIAL FEATURES OF A-HMIS
              </Typography>
              <br />
              <Grid container spacing={0}>
                <Grid
                  items
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ padding: "1%", alignSelf: "center" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img className={classes.logo} src={features} alt="logo" />
                </Grid>
                <Grid
                  items
                  xs={12}
                  sm={8}
                  md={9}
                  style={{ marginTop: "1%", alignSelf: "center" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography className={classes.content} gutterBottom>
                    <ul>
                      <li>
                        It has an exclusive dashboard for citizens to interact
                        and engage with Ayush Health Services across India.
                      </li>
                      <li>It captures the data in real time</li>
                      <li> It is easy to use</li>
                      <li> It is highly secured</li>
                      <li>It integrates all the Ayush systems</li>
                      <li>
                        Compliance with E.H.R Standards -2016 [MoH&FW] and other
                        standards viz., HL7, LOINC etc
                      </li>
                      <li>It has inbuilt standards – dual coding system</li>
                      <ul>
                        <li>National Ayush Morbidity Code</li>
                        <li>ICD 10</li>
                      </ul>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </Card>
          
        </Grid>

        <Grid
          items
          xs={12}
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          
            <Card style={{ width: "98%" }}>
              <Typography className={classes.contentTitle}>
                BENEFITS OF A-HMIS
              </Typography>
              <br />
              <Grid container spacing={0}>
                <Grid
                  items
                  xs={12}
                  sm={8}
                  md={9}
                  style={{ marginTop: "1%", alignSelf: "center" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography className={classes.content} gutterBottom>
                    <ul>
                      <li>Effective Clinical documentation</li>
                      <li>
                        Effective data management including medical data
                        retrieval
                      </li>
                      <li>
                        Adoptive new features of dual coding ICD 10, and
                        National Ayush Morbidity Code
                      </li>
                      <li>
                        Supports full range of Data capture and Data management
                        functions.
                      </li>
                      <li>State of art report generation.</li>
                      <li>Boon for getting the NABH and NABL accreditation</li>
                      <li>
                        Comprehensive collection of National Ayush Morbidity
                        codes
                      </li>
                    </ul>
                  </Typography>
                </Grid>
                <Grid
                  items
                  xs={12}
                  sm={3}
                  md={3}
                  align="left"
                  style={{ alignSelf: "center" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img className={classes.logo} src={benefits} alt="logo" />
                </Grid>
              </Grid>
              <br />
            </Card>
         
        </Grid>
      </Grid>
      <div style={{ width: "100%", backgroundColor: "#302b2b" }}>
        <Footer />
      </div>
    </>
  );
}
