import {
  FETCH_LAB_TEST_NAME_LIST,
  FETCH_ACTIVE_LAB_TEST_NAME_LIST,
  FETCH_LAB_TEST_TYPE_LIST,
  FETCH_LAB_TEST_METHOD_LIST,
  FETCH_ACTIVE_LAB_TEST_METHOD_LIST,
  FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST,
  FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST2,
  FETCH_LAB_ACTIVE_TEST_NORMAL_VALUES,
  FETCH_LAB_TEST_GROUP_LIST,
  FETCH_LAB_SAMPLE_TYPE_LIST,
  FETCH_LAB_ACTIVE_SAMPLE_TYPE_LIST,
  FETCH_LAB_RADIOLOGY_LIST,
  FETCH_LAB_ACTIVE_RADIOLOGY_LIST,
  FETCH_LAB_RADIOLOGY_VIEW_LIST,
  FETCH_LAB_TEST_UNITS,
  FETCH_LAB_RADIOLOGY_ASSO_LIST,
  FETCH_LAB_SAMPLE_ASSO_LIST,
  FETCH_LAB_SAMPLE_ASSO_LIST_FOR_SAMPLE,
  FETCH_GROUP_SAMPLE_ASSO_LIST_FOR_SAMPLE,
  FETCH_PATHOLOGY_LAB_TEST_NAME_DETAILS,
  FETCH_RADIOLOGY_LAB_TEST_NAME_DETAILS,
  FETCH_SPECIFIC_LAB_GROUP,
  FETCH_LAB_RADIOLOGY_LAB_TEST_DETAILS_LIST,
  FETCH_SPECIFIC_XRAY_LAB_GROUP,
  FETCH_RADIOLOGY_LAB_TEST_NAME_LIST,
  FETCH_LAB_TEST_NAMES_AND_GROUP_NAMES_LIST,
  FETCH_LAB_TEST_NAME_LIST_BY_GROUP,
  FETCH_LAB_SAMPLE_TEST_GROUP_LIST,
  FETCH_LAB_INV_SAMPLE_TEST_GROUP_LIST,
  FETCH_SPECIFIC_SAMPLE_TEST_GROUP,
  FETCH_SPECIFIC_RADIOLOGY_LAB_TEST_AND_GROUP,
  FETCH_LAB_TEST_GROUP_LIST_2,
  FETCH_LAB_TEST_AND_GROUP,
  FETCH_LAB_TEST_AND_GROUP_COMPLETED,
  FETCH_SAMPLE_LAB_TEST_AND_GROUP,
  FETCH_TEST_BY_CONSULTATION,
  FETCH_INVESTIGATION_TESTS,
  FETCH_LAB_STATISTICS,
  FETCH_LAB_SAMPLE_STATISTICS,
  FETCH_LAB_WAITING_FOR_SAMPLE,
  FETCH_LAB_PARTIAL_SAMPLE_COLLECTED,
  FETCH_LAB_SAMPLE_COLLECTED,  
  FETCH_LAB_TEST_RESULT_ENTRY,
  FETCH_SAMPLE_LAB_TEST_AND_GROUP_EDIT,
  FETCH_RADIOLOGY_WAITING_FOR_SCAN,
  FETCH_RADIOLOGY_SCAN_COMPLETED,
  FETCH_RADIOLOGY_STATISTICS,
  FETCH_RADIOLOGY_BODY_PARTS,
  FETCH_RADIOLOGY_BODY_PARTS_EDIT,
  FETCH_RADIOLOGY_INVESTIGATION,
  FETCH_PATIENT_DETAILS_BY_OPAYUSHID
  
} from "../actions/Type";

const initialState = {
  labTestNameDetails: [],
  labTestTypes: [],
  labTestMethods: [],
  singleTestNormalValues: [],
  singleTestNormalValues2: [],
  labTestGroupDetails: [],
  labActiveTestMethods: [],
  labSampleTypes: [],
  labActiveSampleTypes: [],
  labRadiologyNames: [],
  labActiveRadiologyNames: [],
  radiologyViews: [],
  labTestUnits: [],
  labRadiologyAssoDetails: [],
  labSampleAssoDetails: [],
  labInvSampleAssoDetails: [],
  sampleTestAssociations: [],
  groupSampleAssociations: [],
  labPathologyLabTestNameDetails: [],
  labRadiologyLabTestNameDetails: [],
  labRadiologyLabTestDetails: [],
  labSpecificLabGroup: [],
  labSpecificXrayLabGroup: [],
  radiologyLabTestName: [],
  labTestNameAndGroupNameList: [],
  groupTestAssosiations: [],
  labSampleTestGroups: [],
  labSpecificSampleTestGroups: [],
  specificRadiologyLabTestAndGroup: [],
  labGroupNormalValues: [],
  labTestGroup: [],
  labTestsForConsultation: [],
  investigationTests: [],
  labActiveTestNormalValues: [],
  labStatistics: [],
  labPathologywaitingForSample: [],
  labPathologyPartiallySampleCollected: [],
  labPathologySampleCollected: [],
  labPathologyTestsResults: [],
  labSampleTestGroupEdit: [],
  radiologyWaitingforScan: [],
  radiologyScanCompleted: [],
  radiologyStatistics: [],
  radiologybodyParts: [],
  radiologybodyPartsEdit: [],
  radiologyInvestigation: [],
  labpatientDetails: []
};

const LabMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LAB_TEST_NAME_LIST:
      return {
        ...state,
        labTestNameDetails: action.payload,
      };
    case FETCH_ACTIVE_LAB_TEST_NAME_LIST:
      return {
        ...state,
        labActiveTestNameDetails: action.payload,
      };
    case FETCH_SPECIFIC_RADIOLOGY_LAB_TEST_AND_GROUP:
      return {
        ...state,
        specificRadiologyLabTestAndGroup: action.payload,
      };
    case FETCH_RADIOLOGY_LAB_TEST_NAME_LIST:
      return {
        ...state,
        radiologyLabTestName: action.payload,
      };
    case FETCH_LAB_SAMPLE_ASSO_LIST:
      return {
        ...state,
        labSampleAssoDetails: action.payload,
      };
    case FETCH_LAB_INV_SAMPLE_TEST_GROUP_LIST:
      return {
        ...state,
        labInvSampleAssoDetails: action.payload,
      };
    case FETCH_LAB_TEST_NAMES_AND_GROUP_NAMES_LIST:
      return {
        ...state,
        labTestNameAndGroupNameList: action.payload,
      };
    case FETCH_LAB_TEST_TYPE_LIST:
      return {
        ...state,
        labTestTypes: action.payload,
      };
    case FETCH_ACTIVE_LAB_TEST_METHOD_LIST:
      return {
        ...state,
        labActiveTestMethods: action.payload,
      };
    case FETCH_LAB_TEST_METHOD_LIST:
      return {
        ...state,
        labTestMethods: action.payload,
      };
    case FETCH_LAB_SAMPLE_TYPE_LIST:
      return {
        ...state,
        labSampleTypes: action.payload,
      };
    case FETCH_LAB_ACTIVE_SAMPLE_TYPE_LIST:
      return {
        ...state,
        labActiveSampleTypes: action.payload,
      };
    case FETCH_LAB_SAMPLE_ASSO_LIST_FOR_SAMPLE:
      return {
        ...state,
        sampleTestAssociations: action.payload,
      };
    case FETCH_GROUP_SAMPLE_ASSO_LIST_FOR_SAMPLE:
      return {
        ...state,
        groupSampleAssociations: action.payload,
      };
    case FETCH_LAB_RADIOLOGY_LIST:
      return {
        ...state,
        labRadiologyNames: action.payload,
      };
      case FETCH_LAB_ACTIVE_RADIOLOGY_LIST:
        return {
          ...state,
          labActiveRadiologyNames: action.payload,
        };
      case FETCH_LAB_RADIOLOGY_VIEW_LIST:
        return {
          ...state,
          radiologyViews: action.payload,
        };
    case FETCH_LAB_RADIOLOGY_ASSO_LIST:
      return {
        ...state,
        labRadiologyAssoDetails: action.payload,
      };
    case FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST:
      return {
        ...state,
        singleTestNormalValues: action.payload,
      };
    case FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST2:
      return {
        ...state,
        singleTestNormalValues2: action.payload,
      };
    case FETCH_LAB_ACTIVE_TEST_NORMAL_VALUES:
      return {
        ...state,
        labActiveTestNormalValues: action.payload,
      };
    case FETCH_PATHOLOGY_LAB_TEST_NAME_DETAILS:
      return {
        ...state,
        labPathologyLabTestNameDetails: action.payload,
      };
    case FETCH_SPECIFIC_LAB_GROUP:
      return {
        ...state,
        labSpecificLabGroup: action.payload,
      };
    case FETCH_SPECIFIC_XRAY_LAB_GROUP:
      return {
        ...state,
        labSpecificXrayLabGroup: action.payload,
      };
    case FETCH_RADIOLOGY_LAB_TEST_NAME_DETAILS:
      return {
        ...state,
        labRadiologyLabTestNameDetails: action.payload,
      };
    case FETCH_LAB_RADIOLOGY_LAB_TEST_DETAILS_LIST:
      return {
        ...state,
        labRadiologyLabTestDetails: action.payload,
      };
    case FETCH_LAB_TEST_GROUP_LIST:
      return {
        ...state,
        labTestGroupDetails: action.payload,
      };
    case FETCH_LAB_TEST_GROUP_LIST_2:
      return {
        ...state,
        labGroupNormalValues: action.payload,
      };

    case FETCH_LAB_TEST_UNITS:
      return {
        ...state,
        labTestUnits: action.payload,
      };
    case FETCH_LAB_SAMPLE_TEST_GROUP_LIST:
      return {
        ...state,
        labSampleTestGroups: action.payload,
      };
    case FETCH_SPECIFIC_SAMPLE_TEST_GROUP:
      return {
        ...state,
        labSpecificSampleTestGroups: action.payload,
      };
    case FETCH_LAB_TEST_NAME_LIST_BY_GROUP:
      return {
        ...state,
        groupTestAssosiations: action.payload,
      };
    case FETCH_LAB_TEST_AND_GROUP:
      return {
        ...state,
        labTestGroup: action.payload,
      };
    case FETCH_LAB_TEST_AND_GROUP_COMPLETED:
      return {
        ...state,
        labCompletedTestGroup: action.payload,
      };
    case FETCH_SAMPLE_LAB_TEST_AND_GROUP:
      return {
        ...state,
        labSampleTestGroup: action.payload,
      };
    case FETCH_SAMPLE_LAB_TEST_AND_GROUP_EDIT:
      return {
        ...state,
        labSampleTestGroupEdit: action.payload,
      };
    case FETCH_TEST_BY_CONSULTATION:
      return {
        ...state,
        labTestsForConsultation: action.payload,
      };
      case FETCH_INVESTIGATION_TESTS:
        return {
          ...state,
          investigationTests: action.payload,
        };
      case FETCH_LAB_STATISTICS:
        return {
          ...state,
          labStatistics: action.payload,
      };
      case FETCH_LAB_SAMPLE_STATISTICS:
        return {
          ...state,
          labSampleStatistics: action.payload,
      };
      case FETCH_LAB_WAITING_FOR_SAMPLE:
        return {
          ...state,
          labPathologywaitingForSample: action.payload,
      };
      case FETCH_LAB_PARTIAL_SAMPLE_COLLECTED:
        return {
          ...state,
          labPathologyPartiallySampleCollected: action.payload,
      };
      case FETCH_LAB_SAMPLE_COLLECTED:
        return {
          ...state,
          labPathologySampleCollected: action.payload,
      };
      case FETCH_LAB_TEST_RESULT_ENTRY:
        return {
          ...state,
          labPathologyTestsResults: action.payload,
      };
      case FETCH_RADIOLOGY_WAITING_FOR_SCAN:
        return {
          ...state,
          radiologyWaitingforScan: action.payload,
      };
      case FETCH_RADIOLOGY_SCAN_COMPLETED:
        return {
          ...state,
          radiologyScanCompleted: action.payload,
      };
      case FETCH_RADIOLOGY_STATISTICS:
        return {
          ...state,
          radiologyStatistics: action.payload,
      };
      case FETCH_RADIOLOGY_BODY_PARTS:
        return {
          ...state,
          radiologybodyParts: action.payload,
      };
      case FETCH_RADIOLOGY_BODY_PARTS_EDIT:
        return {
          ...state,
          radiologybodyPartsEdit: action.payload,
      };
      case FETCH_RADIOLOGY_INVESTIGATION:
        return {
          ...state,
          radiologyInvestigation: action.payload,
      };
      case FETCH_PATIENT_DETAILS_BY_OPAYUSHID:
        return {
          ...state,
          labpatientDetails: action.payload,
      };
    default:
      return state;
  }
};

export default LabMasterReducer;
