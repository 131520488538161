import { makeStyles } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchStaff } from "../actions/DutyRoasterAction";

export function useForm(initialFValues, validateOnChange = false, validate) {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});
  const [typeName, setTypeName] = React.useState("");
  const [activeStatus, setActiveStatus] = React.useState("");
  const [value, setValue] = useState();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const DrugHandleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      morning: "",
      noon: "",
      night: "",
      evening: "",
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });

    if (validateOnChange) validate({ [name]: checked });
  };

  const handleCdsStore = (e, dispensaryStore, subStock) => {
    const { name, checked } = e.target;
    console.log(dispensaryStore, subStock, name, checked, "====");

    if (dispensaryStore || subStock) {
      setValues({
        ...values,
        dispensaryStore: false,
        subStock: false,
        [name]: true,
      });
    } else {
      setValues({
        ...values,
        [name]: checked,
      });
    }
    if (validateOnChange) validate({ [name]: checked });
  };

  const handleDispensaryStore = (e, cdsStore, subStock) => {
    const { name, checked } = e.target;
    if (cdsStore || subStock) {
      setValues({
        ...values,
        cdsStore: false,
        subStock: false,
        [name]: true,
      });
    } else {
      setValues({
        ...values,
        [name]: checked,
      });
    }
    if (validateOnChange) validate({ [name]: checked });
  };

  const handleSubStockStore = (e, cdsStore, dispensaryStore) => {
    const { name, checked } = e.target;
    if (cdsStore || dispensaryStore) {
      setValues({
        ...values,
        cdsStore: false,
        dispensaryStore: false,
        [name]: true,
      });
    } else {
      setValues({
        ...values,
        [name]: checked,
      });
    }
    if (validateOnChange) validate({ [name]: checked });
  };

  function onChange(val) {
    if (val != null && val.length > 0) {
      setValue(val);

      console.log("timestamp", val[0]._d);
      var event = new Date(val[0]._d);
      var event2 = new Date(val[1]._d);
      let date1 = JSON.stringify(event);
      let date2 = JSON.stringify(event2);
      date1 = date1.slice(1, 11);
      console.log("date1", date1);
      date2 = date2.slice(1, 11);
      console.log("date2", date2);
      const week = date1 + " to " + date2;
      setValues({
        ...values,
        duration: week,
      });
    }
  }

  const onChange1 = (date) => {
    if (date != null) {
      console.log("value", date);
      var event = new Date(date);

      let date1 = JSON.stringify(event);

      date1 = date1.slice(1, 8);
      console.log("date1", date1);

      setValues({
        ...values,
        duration: date1,
      });
    }
  };

  const onChange2 = (date) => {
    if (date != null) {
      console.log("value", date);
      var event = new Date(date);

      let date1 = JSON.stringify(event);

      date1 = date1.slice(1, 11);
      console.log("day", date1);

      setValues({
        ...values,
        duration: date1,
      });
    }
  };

  const onChange11 = (date) => {
    if (date != null) {
      console.log("value", date);
      var event = new Date(date);

      let date1 = JSON.stringify(event);

      date1 = date1.slice(1, 11);
      console.log("date11", date1);

      setValues({
        ...values,
        effect_from: date1,
      });
    }
  };

  const onChangeStartDate = (date) => {
    if (date != null) {
      console.log("value", date);
      var event = new Date(date);

      let date1 = JSON.stringify(event);

      date1 = date1.slice(1, 11);
      console.log("date1", date1);

      setValues({
        ...values,
        startDate: date1,
      });
    }
  };

  const onChangeEndDate = (date) => {
    if (date != null) {
      console.log("value", date);
      var event = new Date(date);

      let date1 = JSON.stringify(event);

      date1 = date1.slice(1, 11);
      console.log("date1", date1);

      setValues({
        ...values,
        endDate: date1,
      });
    }
  };

  const handleApptDate = (val) => {
    if (val != null) {
      console.log("value", val._d);
      var event = new Date(val._d);

      let date1 = JSON.stringify(event);

      date1 = date1.slice(1, 11);
      console.log("date1", date1);

      setValues({
        ...values,
        effect_from: date1,
      });
    }
  };

  const handleDateChange = (e) => {
    setValues({
      ...values,
      startTime: e,
    });
  };

  const handleDeptTypeChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: +value,
    });

    setTypeName(event.target.value);
  };
  const handleRoleTypeChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });

    setTypeName(event.target.value);
    dispatch(fetchStaff(event.target.value));
  };

  const handleStaffTypeChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });

    setTypeName(event.target.value);
  };

  const handlePrivilegeNameChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });

    setTypeName(event.target.value);
  };

  const handleDurationTypeChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });

    setTypeName(event.target.value);
  };
  const handleShiftTypeChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });

    setTypeName(event.target.value);
  };

  const handleActiveStatusChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    setActiveStatus(value);
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  const handleChangeShiftStartTime = (e) => {
    setValues({
      ...values,
      startTime: moment(e).format("HH:mm"),
    });
    if (validateOnChange) validate({ startTime: moment(e).format("HH:mm") });
  };
  const handleChangeShiftEndTime = (e) => {
    setValues({
      ...values,
      endTime: moment(e).format("HH:mm"),
    });
    if (validateOnChange) validate({ endTime: moment(e).format("HH:mm") });
  };

  return {
    values,
    setValues,

    errors,
    setErrors,
    handleInputChange,
    DrugHandleInputChange,
    handleCheckBoxChange,
    handleCdsStore,
    handleDispensaryStore,
    resetForm,
    handleDeptTypeChange,
    handleActiveStatusChange,
    handleDateChange,
    handleShiftTypeChange,
    handleDurationTypeChange,
    handleRoleTypeChange,
    handlePrivilegeNameChange,
    handleApptDate,
    onChange,
    onChange1,
    onChange2,
    onChangeStartDate,
    onChange11,
    onChangeEndDate,
    handleStaffTypeChange,
    handleChangeShiftStartTime,
    handleChangeShiftEndTime,
    handleSubStockStore,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
}));

export function Form(props) {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <form className={classes.root} autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
