import { FETCH_PROGNOSIS_BY_INSTITUTE,
    FETCH_PROGNOSIS_BY_DISEASE,FETCH_PROGNOSIS_BY_COUNCIL } from "../actions/Type";

const initialState = {
    prognosisReport: [],
    prognosisReportByCouncil:[],
    prognosisReportByDisease:[]
};

const PrognosisReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROGNOSIS_BY_INSTITUTE:
            return {
                ...state,
                prognosisReport: action.payload,
            };
            case FETCH_PROGNOSIS_BY_COUNCIL:
                return {
                    ...state,
                    prognosisReportByCouncil: action.payload,
                };
                case FETCH_PROGNOSIS_BY_DISEASE:
                    return {
                        ...state,
                        prognosisReportByDisease: action.payload,
                    };
        default:
            return state;
    }
};

export default PrognosisReducer;