import {
  FETCH_ALL_BILL_DETAILS,
  FETCH_TODAY_BILL_DETAILS,
  FETCH_TODAY_IPD_BILLS,
  SAVE_BILL,
  FETCH_ADMITTED_PATIENT_BY_ORG,
  FETCH_ADMISSION_BILLS,
  FETCH_ADMISSION_BED_CHARGES,
  FETCH_PAYMENT_MODE,
  FETCH_TODAY_BILL_DETAILS_BY_ORGID,
  FETCH_FEE_DETAILS_BY_BILL_ID,
} from "../actions/Type";

const initialState = {
  billsToday: [],
  billAll: [],
  todayIpdBills: [],
  billId: String,
  admittedPatients: [],
  admissionBills: [],
  admissionBedCharges: [],
  paymentMode: [],
  billDetailsByOrgId: [],
  feeDetailsByBillId: [],
  // departments:[],
  // apptId: String
};

const BillDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_BILL_DETAILS:
      return {
        ...state,
        billsAll: action.payload,
      };

    case FETCH_ADMITTED_PATIENT_BY_ORG:
      return {
        ...state,
        admittedPatients: action.payload,
      };
    case FETCH_PAYMENT_MODE:
      return {
        ...state,
        paymentMode: action.payload,
      };

    case FETCH_ADMISSION_BED_CHARGES:
      return {
        ...state,
        admissionBedCharges: action.payload,
      };

    case FETCH_TODAY_BILL_DETAILS:
      return {
        ...state,
        billsToday: action.payload,
      };

    case SAVE_BILL:
      return {
        ...state,
        billId: [action.payload],
      };
    case FETCH_TODAY_IPD_BILLS:
      return {
        ...state,
        todayIpdBills: action.payload,
      };
    case FETCH_ADMISSION_BILLS:
      return {
        ...state,
        admissionBills: action.payload,
      };
    case FETCH_TODAY_BILL_DETAILS_BY_ORGID:
      return {
        ...state,
        billDetailsByOrgId: action.payload,
      };
    case FETCH_FEE_DETAILS_BY_BILL_ID:
      return {
        ...state,
        feeDetailsByBillId: action.payload,
      };

    default:
      return state;
  }
};

export default BillDetailsReducer;
