import {
  FETCH_HOMEO_MEDICINE,
  FETCH_ALL_POTENCY
} from "../actions/Type";

const initialState = {
  homeoMedicines: [],
  homeoPotency: [],
};

const HomeopathyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOMEO_MEDICINE:
      return {
        ...state,
        homeoMedicines: action.payload,
      };
    case FETCH_ALL_POTENCY:
      return {
        ...state,
        homeoPotency: action.payload,
      };
    default:
      return state;
  }
};

export default HomeopathyReducer;
