import {
  FETCH_CONSULTATION_PATIENT_LIST,
  FETCH_ALL_PRESCRIPTION_DETAILS,
  FETCH_CONSULTATION_MEDICINE_DETAILS,
  FETCH_CONSULTATION_PATIENT_ID,
  FETCH_PHARMACY_PATIENT_DETAILS,
  FETCH_ACTIVE_UOM,
} from "../actions/Type";

const initialState = {
  patientAllfPrescription:[],
  patientList: [],
  consultationId: "",
  consultationMedicineDetails: [],
  pharmacyPatient: [],
  uoms: [],
};

const PrescriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONSULTATION_PATIENT_LIST:
      return {
        ...state,
        patientList: action.payload,
      };
    case FETCH_ALL_PRESCRIPTION_DETAILS:
        return {
          ...state,
          patientAllfPrescription: action.payload,
    };
    case FETCH_CONSULTATION_PATIENT_ID:
      return {
        ...state,
        consultationId: action.payload,
      };
    case FETCH_CONSULTATION_MEDICINE_DETAILS:
      return {
        ...state,
        consultationMedicineDetails: action.payload,
      };
    case FETCH_PHARMACY_PATIENT_DETAILS:
      return {
        ...state,
        pharmacyPatient: action.payload,
      };
    case FETCH_ACTIVE_UOM:
      return {
        ...state,
        uoms: action.payload,
      };
    default:
      return state;
  }
};

export default PrescriptionReducer;
