import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_SNACKBAR } from "../../actions/Type";

const useStyles = makeStyles(theme => ({
  // root: {
  //   width: "100%",
  //   "& > * + *": {
  //     marginTop: theme.spacing(1),
  //   },
  // },
  alert:{
    padding:'30px',
    fontSize:'18px'
  },
  snackbar:{
      marginTop:"30vh"
  }
}));

function Alert(props) {
  return <MuiAlert  elevation={6} variant="filled" {...props} />;
}

export default function SimpleSnackbar() {
  const { msg, openSnackbar, type } = useSelector(state => state.showSnackbar);

  const dispatch = useDispatch();
  const [state] = React.useState({ vertical: "center", horizontal: "center" });
  const { vertical, horizontal } = state;
  const classes = useStyles();

  const handleClose = () => {
    dispatch({ type: SHOW_SNACKBAR, payload: { type: "", msg: "", openSnackbar: false } });
  };

  return (
    <div className={classes.root}>
      <Snackbar className={classes.snackbar} anchorOrigin={{ vertical, horizontal }} open={openSnackbar} autoHideDuration={2000} onClose={handleClose}>
        <Alert  className={classes.alert} onClose={handleClose} severity={type}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
