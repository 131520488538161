import {
  FETCH_PULSE_LIST,
  FETCH_VITALS_DETAILS,
  FETCH_SUB_PULSE_LIST,
  FETCH_VITAL_COUNT,
  FETCH_NABZ_MURAKKAB_LIST,
  FETCH_TODAY_VITAL_SIGNS
} from "../actions/Type";

const initialState = {
  pulseDetails: [],
  vitalsDetails: [],
  nabzMurakkabs: [],
  vitalsCount:[],
  todayVitalSigns:[],
  subPulseDetails: [],
};

const PulseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PULSE_LIST:
      return {
        ...state,
        pulseDetails: action.payload,
      };
    case FETCH_SUB_PULSE_LIST:
      return {
        ...state,
        subPulseDetails: action.payload,
      };
    case FETCH_NABZ_MURAKKAB_LIST:
      return {
        ...state,
        nabzMurakkabs: action.payload,
      };
    case FETCH_VITALS_DETAILS:
      return {
        ...state,
        vitalsDetails: action.payload,
      };
      case FETCH_VITAL_COUNT:
        return {
          ...state,
          vitalsCount: action.payload,
        };
        case FETCH_TODAY_VITAL_SIGNS:
        return {
          ...state,
          todayVitalSigns: action.payload,
        };

    default:
      return state;
  }
};

export default PulseReducer;
