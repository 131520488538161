//material-ui
import { FormControl, FormControlLabel, RadioGroup as MuiRadioGroup, Radio } from '@material-ui/core';
//RadioGroup function defined here
export default function RadioGroup(props) {

    const { name, label, value, onChange, items, hidden } = props;

    return (
        <div id="activeStatusDiv">
           {
               hidden ? '' : <FormControl>
                {/* <FormLabel>{label}</FormLabel> */}
                <MuiRadioGroup row
                    name={name}
                    value={value}
                    onChange={onChange}>
                    {
                        items.map(
                            item => (
                                <FormControlLabel key={item.id} value={item.id} control={<Radio/>} label={item.activeStatus} />
                            )
                        )
                        
                    }
                </MuiRadioGroup>
            </FormControl>
           }
        
        </div>
    )
}
