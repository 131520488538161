import {
    FETCH_EXTERNAL_TESTS,FETCH_ALL_EXTERNAL_GROUP_REPORT, FETCH_EXTERNAL_RADIOLOGY_REPORT
  } from "../actions/Type";
  
  const initialState = {
    externalTests: [],
    externalGroupReport: [],
    externalRadioReport: null
  };
  
  const ExternalGroupReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_EXTERNAL_TESTS:
        return {
          ...state,
          externalTests: action.payload,
        };
        case FETCH_ALL_EXTERNAL_GROUP_REPORT:
        return {
          ...state,
          externalGroupReport: action.payload,
        };
        case FETCH_EXTERNAL_RADIOLOGY_REPORT:
        return {
          ...state,
          externalRadioReport: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default ExternalGroupReportReducer;
  