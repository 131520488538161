import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles(theme => ({
  fabs: {
    backgroundColor: "#f50057",
    color: "white",
    "&:hover": {
      backgroundColor: "#c51162",
      color: "white",
    },
  },
}));
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 200,
  });

  return (
    <Zoom in={trigger}>
      <Box role="presentation" sx={{ position: "fixed", bottom: 130, right: 10, zIndex: 10 }}>
        {children}
      </Box>
    </Zoom>
  );
}

export default function BackToTop(props) {
  const classes = useStyles();
  return (
    <ScrollTop>
      <Fab
        className={classes.fabs}
        size="small"
        onClick={e => {
          e.stopPropagation();
          window.scroll(0, 0);
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTop>
  );
}
