import {
  SNACKBAR_SUCCESS,
  SNACKBAR_CLEAR,
  SPINNER_HIDE,
  SPINNER_SHOW
} from "../actions/Type";


const NotificationReducer = (state = {}, action) => {

  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.msg,
        messageInfoType: action.msgType
      };
    case SPINNER_SHOW:
      return {
        ...state,
       SpinnerOpen: true,
      
      };
      case SPINNER_HIDE:
      return {
        ...state,
       SpinnerOpen: false,
      
      };

    case SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        // errorSnackbarOpen: false,
        //infoSnackbarOpen: false,

      };

    default:
      return state;
  }
};

export default NotificationReducer;