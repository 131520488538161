import {
  FETCH_ROLE,
  SAVE_BUILDING, FETCH_DURATION_TYPE, FETCH_ALL_DUTYROSTER, FETCH_STAFF,
  SAVE_DUTYROSTER,
  DELETE_DUTYROSTER,
  FETCH_NURSE_DUTY_LIST
} from "../actions/Type";

const initialState = {
  roles: [],
  dutyRoster: [],
  duration: [],
  staff: [],
  saveDutyRoster:[],
  delDutyRoster: [],
  nurseDutyList: [],
  // apptId: String
};

const DutyRoasterReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_ROLE:
      return {
        ...state,
        roles: action.payload,

      };


    case FETCH_ALL_DUTYROSTER:
      return {
        ...state,
        dutyRoster: action.payload
      };
    case FETCH_DURATION_TYPE:
      return {
        ...state,
        duration: action.payload
      };
    case FETCH_STAFF:
      return {
        ...state,
        staff: action.payload
      };

    case DELETE_DUTYROSTER:
      return {
        ...state,
        delDutyRoster: action.payload,

      };
      case SAVE_DUTYROSTER:
        return {
            ...state,
            saveDutyRoster: action.payload,
        };    
        case FETCH_NURSE_DUTY_LIST:
          return {
              ...state,
              nurseDutyList: action.payload,
          };

    default:
      return state;
  }
};

export default DutyRoasterReducer;