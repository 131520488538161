import { Button, DialogContentText, DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsultationPatient } from "../../../actions/ConsultationAction";
import { fetchPrevComplaints } from "../../../actions/DiagnosisAction";
import {
  SET_PATIENT_DATA,
  SET_REGISTRATION_ID,
  SET_REGISTRATION_PATIENT
} from "../../../actions/Type";
import axiosInstance from "../../../api/Instance";
import ipdOpdInstance from "../../../api/IpdOpdInstance";
import { CloseButton } from "../../../component/utils/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 235,
  },
  tableCell: {
    "$hover:hover &": {
      color: "white",
    },
    color: "black",
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9F9F9",
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#F2FEFF",
    },
    "&:hover": {
      backgroundColor: "#07A0B0 !important",
    },
  },
}))(TableRow);

export default function DenseTable(props) {
  const { close, department } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(22);
  const [searched, setSearched] = React.useState("");
  const [consulted, setConsulted] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [index, setIndex] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [deptId, setDeptId] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();

  const patientDetails = useSelector(
    (state) => state.consultationReducer.consultationPatients
  );
  const selectedPatient = useSelector(
    (state) => state.consultationReducer.patient
  );

  const consultationID = useSelector(
    (state) => state.consultationReducer.patient.consultationId
  );

  const registrationID = useSelector(
    (state) => state.consultationReducer.registrationId
  );

  const registrationPatient = useSelector(
    (state) => state.consultationReducer.registrationPatient
  );

  const staffLogin = useSelector((state) => state.loginReducer.staffLogin);

  const handleDrawerClose = () => {
    close();
  };
  useEffect(() => {
    console.log("department: ", department);
    setDeptId(department?.id);
  }, [department]);

  useEffect(() => {
    console.log("staffLogin", staffLogin);
  }, [staffLogin]);

  useEffect(() => {
    console.log("selectedPatient", selectedPatient);
  }, [selectedPatient]);

  useEffect(() => {
    console.log("registrationID", registrationID);
  }, [registrationID]);

  useEffect(() => {
    console.log("registrationPatient", registrationPatient);
  }, [registrationPatient]);

  const requestSearch = (searchedVal) => {
    const filteredRows = patientDetails.filter((row) => {
      return (
        row.tokenNumber.toString().includes(searchedVal) ||
        row.patientName.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    (async () => {
      if (deptId !== undefined && deptId !== 0) {
        try {
          const response = await ipdOpdInstance
            .get(`bill/getAllBilledPatientsByDeptId/${deptId}`)
            .then((res) => res.data);

          if (response !== undefined || response !== "failure") {
            const patientList = response
              ?.filter((p) => !["C", "L"].includes(p.consultationStatus))
              ?.map((patient) => ({ ...patient, consultationId: "" }));
            dispatch({ type: SET_PATIENT_DATA, payload: patientList });
            setRows(patientList);
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [deptId]);

  useEffect(() => {
    console.log("rowss", rows);
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleIdClick = (patient) => {
    if (patientDetails?.length > 1) {
      setOpenAlert(true);
    }
    handleDrawerClose();
    console.log("registrationId:", patient);
    dispatch({ type: SET_REGISTRATION_ID, payload: patient.opId });
    dispatch({ type: SET_REGISTRATION_PATIENT, payload: patient });
    const consultationPatient = axiosInstance.get(`patient/${patient.patientId}`).then((res) =>
      dispatch(fetchConsultationPatient(staffLogin.staffId, patient.patientId))
    );
    dispatch(fetchPrevComplaints(patient.patientId));
    handleClose();
  };

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <div>
      <div>
        <SearchBar
          style={{
            marginTop: "1%",
            marginBottom: "2%",
            width: "93%",
            fontSize: "12px",
            marginLeft: "2%",
            height: "3%",
            fontFamily: "helvetica",
          }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder="Search"
        />
      </div>
      <div style={{ width: 350 }}>
        <TableContainer style={{ width: "100%" }} component={Paper}>
          <Table
            className={classes.table}
            style={{ width: "100%" }}
            size="small"
            aria-label="a dense table">
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => {
                  return (
                    <StyledTableRow
                      value={row.registrationId}
                      hover
                      checkboxSelection
                      classes={{ hover: classes.hover }}
                      onClick={() => setIndex(row.registrationId)}
                      style={{
                        backgroundColor:
                          index === row.registrationId
                            ? "#CAF7FB"
                            : "" && consulted
                            ? "red"
                            : "lightgreen",
                      }}
                      disabled={row.doctorId !== staffLogin?.staffId}>
                      <TableCell
                        className={classes.tableCell}
                        align="left"
                        style={{
                          width: "10px",
                          fontFamily: "helvetica",
                        }}
                        onClick={() => {
                          setSelectedRow(row);
                          if (patientDetails?.length > 1) {
                            setOpenAlert(true);
                            handleDrawerClose();
                          } else if (patientDetails?.length === 1) {
                            handleIdClick(row);
                          }
                        }}>
                        {row.tokenNumber}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="left"
                        style={{
                          width: "10px",
                          fontFamily: "helvetica",
                        }}
                        onClick={() => {
                          setSelectedRow(row);
                          if (patientDetails?.length > 1) {
                            setOpenAlert(true);
                          } else if (patientDetails?.length === 1) {
                            handleIdClick(row);
                          }
                        }}>
                        {row.patientName}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are in consultation page, do you want to switch Patient?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleIdClick(selectedRow)} color="primary" variant="contained">
            Yes
          </Button>
          <CloseButton onClick={handleClose}>
            No
          </CloseButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
