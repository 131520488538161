import {
  ADR_TABLE_RECORDS,
  ADR_SELECTED_MEDICINE_TABLE,
  GET_MEDICINE_FORM_STATE,
} from "../actions/Type";

const All_Records = [
  {
    isChecked: false,
    id: 1,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: "fsdfsd sdf",
  },
  {
    isChecked: false,
    id: 2,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: " sdfdsf rterstert",
  },
  {
    isChecked: false,
    id: 3,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: "cbcvbcbcxhrehd sdgrse",
  },
  {
    isChecked: false,
    id: 4,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: "ret retd vdxsg",
  },
  {
    isChecked: false,
    id: 5,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: "qaedwqrfr jfd",
  },
  {
    isChecked: false,
    id: 6,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: ";ioluikj fhdf",
  },
  {
    isChecked: false,
    id: 7,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: " dgdrg",
  },
  {
    isChecked: false,
    id: 8,
    medicineName: "hssdfj ss fsd",
    qty: "1",
    unit: 2,
    f: 1,
    a: 2,
    n: 4,
    afbf: 5,
    days: 8,
    vehicle: 8,
    remarks: " dfgdfg drg",
  },
];

const initialState = {
  selectedMedicine: [],
  tableData: [],
  getMedicineFormState: {},
};

export const adr = (state = initialState, action) => {
  switch (action.type) {
    case ADR_SELECTED_MEDICINE_TABLE:
      return {
        ...state,
        selectedMedicine: action.payload,
      };
    case ADR_TABLE_RECORDS:
      return {
        ...state,
        tableData: action.payload,
      };
    case GET_MEDICINE_FORM_STATE:
      return {
        ...state,
        getMedicineFormState: action.payload,
      };

    default:
      return state;
  }
};
