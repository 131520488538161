import { showSnackbar, spinnerHide } from "./Notification";
import axiosInstance from "../api/Instance";
import ipdOpdInstance from "../api/IpdOpdInstance";
import InventoryInstance from "../api/InventoryInstance";
import {
  FETCH_MEDICINE_NAME,
  FETCH_ALL_VEHICLE_NAME,
  FETCH_VEHICLE_NAME,
  FETCH_DRUG_INTERVALS,
  FETCH_SOM,
  FETCH_UOM,
  FETCH_MEDICINE_NAME_BY_SOM,
  FETCH_ALL_MEDICINE_NAME_BY_SOM,
  FETCH_SINGLE_MEDICINE_TYPE,
  FETCH_AVAL_QTY,
} from "./Type";

export const fetchUom = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`uom/getAllUom`);
    dispatch({
      type: FETCH_UOM,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchAllMedicineVehicle = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`medicineVehicle/getAllMedVehicleName`);
    dispatch({
      type: FETCH_ALL_VEHICLE_NAME,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchSom = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`medicineStream/getAllSom`);
    dispatch({
      type: FETCH_SOM,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log("failed to get som");
    return [];
  }
};
export const fetchMedicineName = (med_type_id,orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`medicineName/getByMedicineTypeForOrg/${med_type_id}/${orgId}`);
    dispatch({
      type: FETCH_MEDICINE_NAME,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const fetchAvailabelQty = (med_name_id, orgId) => async (dispatch) => {
  try {
    const res = await InventoryInstance.get(`subStockManagement/fetchOnlyDrugQty/${orgId}/${med_name_id}`);
    dispatch({
      type: FETCH_AVAL_QTY,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchMedicineNameBySom = (som_id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`medicineName/getByMedicineType/${som_id}`);
    dispatch({
      type: FETCH_MEDICINE_NAME_BY_SOM,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchAllMedicineNameBySom = (som_id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`medicineName/getMedicineNameByMedicineStreamId/${som_id}`);
    dispatch({
      type: FETCH_ALL_MEDICINE_NAME_BY_SOM,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchVehicleName = (somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`medicineVehicle/getAllMedVehicle/${somId}`);
    dispatch({
      type: FETCH_VEHICLE_NAME,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchDrugInterval = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`drugInterval/getAllActiveDrugIntervals`);
    dispatch({
      type: FETCH_DRUG_INTERVALS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveMedicines = (medicines) => async (dispatch) => {
  try {
    const url = "prescription/saveprescription";
    const response = await ipdOpdInstance.post(url, medicines);
    const res = response.data;
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveMedicineDetails = (medicineDetails) => async (dispatch) => {
  try {
    const url = "prescription/saveprescription";

    const response = await ipdOpdInstance.post(url, medicineDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Save failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message =
        medicineDetails.id === undefined || medicineDetails.id === "" ? "Saved Successfully" : "Updated Successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const getSingleMedicineTypes = (somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`medicineType/getSingleMedicineTypes/${somId}`);
    dispatch({
      type: FETCH_SINGLE_MEDICINE_TYPE,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const editPaidPrescription = (medicines) => async (dispatch) => {
  try {
    const url = "prescription/editPaidPrescription";

    const response = await ipdOpdInstance.post(url, medicines);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Save failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message =
        medicines?.id === undefined || medicines?.id === "" ? "Saved Successfully" : "Updated Successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const editComplexPrescription = (medicines) => async (dispatch) => {
  try {
    const url = "prescription/saveComplexMedicinePrescription";

    const response = await ipdOpdInstance.post(url, medicines);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Save failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message =
        medicines?.id === undefined || medicines?.id === "" ? "Saved Successfully" : "Updated Successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const editExternalPrescription = (medicines) => async (dispatch) => {
  try {
    const url = "prescription/editExternalPrescription";

    const response = await ipdOpdInstance.post(url, medicines);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Save failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message =
        medicines?.id === undefined || medicines?.id === "" ? "Saved Successfully" : "Updated Successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};
