import React from "react";
import "./style.css";

const Loader = () => {
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:'100vh'}}>
    <div class="lds-spinner" >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
  );
};

export default Loader;
