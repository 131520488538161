import {
  SNACKBAR_SUCCESS,
  SNACKBAR_CLEAR,
  SNACKBAR_ERROR,
  SPINNER_SHOW,
  SPINNER_HIDE
} from "./Type";


export const showSnackbar = ( message ) => {
  console.log("message", message.message)
  var msg = message.message;
  var msgType = message.messageInfoType;

  return dispatch => {
    dispatch({ type: SNACKBAR_SUCCESS,msg,msgType});
  };
};
export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: SNACKBAR_CLEAR });
  };
};
export const spinnerShow=()=>{
  return dispatch => {
    dispatch({ type:SPINNER_SHOW });
  };
}
export const spinnerHide=()=>{
  return dispatch => {
    dispatch({ type:SPINNER_HIDE });
  };
}// export const showErrorSnackbar = message => {
//   return dispatch => {
//     dispatch({ type: SNACKBAR_ERROR, message });
//   };
// };

