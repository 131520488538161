import DateFnsUtils from "@date-io/date-fns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Paper,
  Popper,
  Select,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import PropTypes from "prop-types";
import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
// import Pluse from "../Pulse";
// import Complaints from "../Complaints";
// import Lab from "../Lab";
// import ConcomitantMedicine from "../ConcomitantMedicine";
// import Diagnosis from "../Diagnosis";
// import Prescription from "../Prescription";
// import DoDont from "../DoDont";
// import Historytabs from "../HistoryTabs";
// import Compliance from "../Compliance";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../actions/Notification";
// import PulseUnani from "../PulseUnani";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  fetchHealthFacilityBySomId,
  saveTranferIPAdmissionDetails,
  saveTransferDetails,
} from "../../../actions/AdminMasterAction";
import {
  fetchIpdDept,
  fetchRegDeptListByOrgId,
} from "../../../actions/RegistrationAction";
import {
  // FETCH_ALL_SURGICAL_PROCEDURES,
  FETCH_ALL_ACTIVE_PROCEDURES,
  SHOW_SNACKBAR,
} from "../../../actions/Type";
import axiosInstance from "../../../api/Instance";
import ipdOpdInstance from "../../../api/IpdOpdInstance";
import Loader from "../../Loader";
import { CloseButton } from "../../utils/Button";
import GeneralPhysicalExaminations from "../GeneralPhysicalExaminations";
import PrescriptionPrint from "../PrescriptionPrint";
import Preview from "../Preview";
import SurgicalProcedure from "../SurgicalProcedure";
import SystemicExamination from "../SystemicExamination";
import Treatment from "../Treatment";
import "./styles.css";

const Pluse = lazy(() => import("../Pulse"));
const Complaints = lazy(() => import("../Complaints"));
const Lab = lazy(() => import("../Lab"));
const ConcomitantMedicine = lazy(() => import("../ConcomitantMedicine"));
const Diagnosis = lazy(() => import("../Diagnosis"));
const Prescription = lazy(() => import("../Prescription"));
const DoDont = lazy(() => import("../DoDont"));
const Historytabs = lazy(() => import("../HistoryTabs"));
const Compliance = lazy(() => import("../Compliance"));
const PulseUnani = lazy(() => import("../PulseUnani"));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      // style={{width: "100% !important" }}
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    opacity: 1,
  },
  panel: {
    backgroundColor: "#CAF7FB",
    color: "black ",
    marginRight: "8px",
    textDecoration: "none",
    fontWeight: 500,
    textTransform: "capitalize",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      minWidth: "198px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
  },
  tab: {
    // boxShadow: 0,
    marginTop: "2%",
    // width:"100vw"
    // opacity: 1,
  },
  buttonRoot: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "right",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      textAlign: "left",
      padding: "0px",
    },
  },
  root1: {
    width: "100%",
    marginTop: "2%",
  },
  title: {
    fontSize: 14,
    // fontWeight: "bold",
  },
  accordion1: {
    width: "100%",
    backgroundColor: "#07A0B0",
    color: "white",
    // padding:8
  },
  content: {
    backgroundColor: "white",
    color: "black",
  },
  icon: {
    color: "white",
  },
  paper: {
    minWidth: "70vw",
    [theme.breakpoints.up("md")]: {
      minWidth: "750px",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "600px",
    },
  },
  input: {
    // width: 245,
    [theme.breakpoints.up("xs")]: {
      minWidth: 100,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 245,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 240,
    },
    // [theme.breakpoints.up("lg")]: {
    //   width: 45,
    // },
    // [theme.breakpoints.up("xl")]: {
    //   width: 45,
    // },
  },
  input2: {
    // width: 245,
    [theme.breakpoints.up("xs")]: {
      minWidth: 50,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 245,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 240,
    },
    // [theme.breakpoints.up("lg")]: {
    //   width: 45,
    // },
    // [theme.breakpoints.up("xl")]: {
    //   width: 45,
    // },
  },

  highlightCharacter: {
    borderBottom: "1px solid",
    padding: "0px",
  },
}));

export default function NavTabs(props) {
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const menuThemeColor = useSelector(
    (state) => state.MainReducer.selectedMenuTheme
  );

  const consultationID = useSelector(
    (state) => state.consultationReducer.patient.consultationId
  );
  const ayushID = useSelector(
    (state) => state.consultationReducer.patient.ayushId
  );
  const departments = useSelector(
    (state) => state.registrationReducer.deptList
  );
  const [som, setSom] = React.useState("");
  // const [depts, setDepts] = React.useState(null);
  const [selectedDept, setSelectedDept] = React.useState(null);
  const healthFacilities = useSelector(
    (state) => state.adminMasterReducer.healthFacilities
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const ipdDept = useSelector((state) => state.registrationReducer.ipdDept);
  const [hospitalValue, setHospitalValue] = React.useState("");
  const selectedPatient = useSelector(
    (state) => state.consultationReducer.patient
  );

  let StaffLogin = useSelector((state) => state.loginReducer.staffLogin);
  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);

  const dispatch = useDispatch();
  const handleMouseEnter = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleMouseLeave = (event) => {
    setAnchorEl(null);
  };
  const [isPatientSelected, setIsPatientSelected] = React.useState(false);
  const [pulseData, setPulseData] = React.useState(null);
  const [complaintsData, setComplaintsData] = React.useState(null);
  const [doDontValue, setDoDontValue] = React.useState(null);
  const [treatmentData, setTreatmentData] = React.useState(null);
  const [physicalData, setPhysicalData] = React.useState(null);
  const [systemicData, setSystemicData] = React.useState(null);
  const [procedureData, setProcedureData] = React.useState(null);
  const [errormsg, setErrormsg] = React.useState(false);
  const [errormsgTong, setErrormsgTong] = React.useState(false);
  const [dirtyVal, setDirtyVal] = React.useState(false);
  const [diagnosisData, setDiagnosisData] = React.useState(null);
  const [concomitantMedicine, setConcomitantMedicine] = React.useState(null);
  const [preview, setPreview] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [previewData, setPreviewData] = React.useState(null);
  const [printData, setPrintData] = React.useState(null);
  const [
    compliancePrognosisValue,
    setCompliancePrognosisValue,
  ] = React.useState(null);
  const [pulseUnani, setPulseUnani] = React.useState(null);
  const [prescription, setPrescription] = React.useState(null);
  const [openPrescriptionPrint, setOpenPrescriptionPrint] = React.useState(
    false
  );
  const [labData, setLabData] = React.useState(null);
  const [historyData, setHistoryData] = React.useState({
    past: null,
    personal: null,
    family: null,
    menstrual: null,
  });
  const [transferData, setTransferData] = useState({
    staffId: StaffLogin.staffId,
    ayushId: ayushID,
    consultationId: consultationID,
    organizationId: "",
    departmentId: "",
    ownOrganizationId: 0,
  });

  const [ipAdmissiontransferData, setIpAdmissiontransferData] = useState({
    organizationId: StaffLogin.orgId,
    consultationId: consultationID,
    staffId: StaffLogin.staffId,
    departmentId: "",
    remarks: "",
    admissionDate: new Date(),
  });

  const [signSymptom, setSignSymptom] = useState([]);
  const complaintsRef = React.useRef(null);
  const concomitantMedicineRef = React.useRef(null);
  const prescriptionRef = React.useRef(null);

  const editPreview = (tab) => {
    setOpenPreview(false);
    setValue(tab);
  };

  // const fetchSurgicalProcedures = () => {
  //   axiosInstance
  //     .get(
  //       `surgicalProcedure/fetchAllSurgicalProcedureByOrgId/${StaffLogin?.orgId}`
  //     )
  //     .then((res) =>
  //       dispatch({ type: FETCH_ALL_SURGICAL_PROCEDURES, payload: res.data })
  //     )
  //     .catch((err) =>
  //       console.log("fetchAllSurgicalProcedureByOrgId error", err)
  //     );
  // };

  const fetchActiveProcedures = () => {
    axiosInstance
      .get(
        `surgicalProcedure/fetchActiveProcedureByOrgId/${StaffLogin?.orgId}`
      )
      .then((res) =>
        dispatch({ type: FETCH_ALL_ACTIVE_PROCEDURES, payload: res.data })
      )
      .catch((err) =>
        console.log("fetchAllActiveProcedureByOrgId error", err)
      );
  };
  useEffect(() => {
    axiosInstance
      .get("signsSymptoms/getSignSymptomForCons/" + StaffLogin.somId)
      .then((symptomResponse) => {
        setSignSymptom(symptomResponse.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // fetchSurgicalProcedures();
    fetchActiveProcedures();
  }, []);

  React.useEffect(() => {
    console.log("selected patient transfer");
    setIsPatientSelected(Object.keys(selectedPatient).length > 0);
    setPulseData(null);
    setComplaintsData(null);
    setDoDontValue(null);
    setDiagnosisData(null);
    setConcomitantMedicine(null);
    setPrescription(null);
    setCompliancePrognosisValue(null);
    setPulseUnani(null);
    setLabData(null);
    setHistoryData({
      past: null,
      personal: null,
      family: null,
      menstrual: null,
    });
  }, [selectedPatient]);
  const handleChange = async (event, newValue) => {
    let valid = true;
    if (value === 1 && complaintsRef) {
      const isValid = await complaintsRef?.current?.complaintTabValue?.map(
        (e) => e.signSymptomId
      )?.[0];
      if (isValid) {
        const list = complaintsRef?.current?.complaintTabValue?.map(
          (e) => !e.duration || !e.durationTypeId
        )?.[0];
        if (list) {
          valid = false;
        } else {
          valid = true;
        }
      } else {
        valid = true;
      }
    }
    if (somId === 3) {
      if (value === 9 && concomitantMedicineRef) {
        const isValid = await concomitantMedicineRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }

      if (value === 11 && StaffLogin.somId !== 5) {
        const isValid = await prescriptionRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }
    } else {
      if (value === 6 && concomitantMedicineRef) {
        const isValid = await concomitantMedicineRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }
      if (value === 8 && StaffLogin.somId !== 5) {
        const isValid = await prescriptionRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }
    }

    if (!valid) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          msg: "Please fill the required fields or delete the row",
          openSnackbar: true,
        },
      });
      return;
    } else {
      setValue(newValue);
    }
    // console.log("dirtyVal", dirtyVal);
    // if (dirtyVal) {
    if (somId === 3 && dirtyVal) {
      if (
        systemicData.hasOwnProperty("respiratorySystem") &&
        systemicData.respiratorySystem !== ""
      ) {
        setValue(newValue);
        setErrormsg(false);
      } else {
        setValue(4);
        setErrormsg(true);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Please fill the required fields ",
            openSnackbar: true,
          },
        });
        return;
      }
      if (
        systemicData.hasOwnProperty("tongueDiagnosis") &&
        systemicData.tongueDiagnosis !== ""
      ) {
        setValue(newValue);
        setErrormsgTong(false);
      } else {
        setValue(4);
        setErrormsgTong(true);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Please fill the required fields ",
            openSnackbar: true,
          },
        });
        return;
      }
    }
    // }
  };

  // useEffect(() => {
  //   (async () => {
  //     if (consultationID) {
  //       await dispatch(fetchInvestigationSummary(consultationID));
  //     }
  //   })();
  // }, [consultationID]);

  // const fetchAllDepts = () => {
  //   if (StaffLogin.orgId) {
  //     axiosInstance
  //       .get("department/getOPDepts/" + StaffLogin.orgId)
  //       .then((res) => setDepts(res?.data))
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }
  // };

  useEffect(() => {
    if (ayushID != undefined) {
      setTransferData({ ...transferData, ayushId: ayushID });
    }
  }, [ayushID]);

  useEffect(() => {
    if (consultationID) {
      setTransferData({
        ...transferData,
        consultationId: consultationID,
      });
      setIpAdmissiontransferData({
        ...ipAdmissiontransferData,
        consultationId: consultationID,
      });
    }
  }, [consultationID]);

  useEffect(() => {
    if (hospital != undefined) {
      setTransferData({
        ...transferData,
        organizationId: hospital,
      });
    }
  }, [hospital]);

  useEffect(() => {
    // fetchAllDepts();

    if (StaffLogin !== undefined) {
      setTransferData({
        ...transferData,
        staffId: StaffLogin?.staffId,
        organizationId: StaffLogin.orgId,
        ownOrganizationId: StaffLogin.orgId,
      });
      const somId = StaffLogin?.somId;
      setSom(somId);
      if (somId === 3 || somId === 5) {
        setValue(1);
      }
      dispatch(fetchHealthFacilityBySomId(somId)).then((res) => {
        setHospital(StaffLogin?.orgId);
      });
    }
  }, [StaffLogin]);

  useEffect(() => {
    if (StaffLogin?.orgId) {
      dispatch(fetchIpdDept(StaffLogin?.orgId, 3));
    }
  }, [StaffLogin]);

  const handleSubmit = (e) => {
    // if (somId !== 5) {
    //   if (external?.length > 0) {
    //     externalData = external?.map((d) => ({
    //       ...d,
    //       consultationId: consultationID,
    //       prescriptionType: "E",
    //     }));
    //     // dispatch(saveMedicines(externalData));
    //   }
    //   if (paid?.length > 0) {
    //     paidData = paid?.map((d) => ({
    //       ...d,
    //       consultationId: consultationID,
    //       prescriptionType: "P",
    //     }));
    //     // dispatch(saveMedicines(paidData));
    //   }
    //   if (complex?.length > 0) {
    //     complexDetails = data?.prescription?.value?.complex?.map((d) => ({
    //       ...d,
    //       consultationId: consultationID,
    //       prescriptionType: "C",
    //     }));
    //     // dispatch(saveComplexMedicines(complexDetails)).then((res) => {
    //     //   console.log(res);
    //     // });
    //   }
    //   preview.prescriptionList = {
    //     singleMedicine: simpleData,
    //     externalMedicine: externalData,
    //     complexMedicine: complexDetails,
    //     paidMedicine: paidData,
    //   };
    // } else {
    //   if (
    //     (data?.prescription &&
    //       data?.prescription?.value?.homeo?.length !== 0) ||
    //     data?.prescription?.value?.homeoMed?.length !== 0
    //   ) {
    //     const singleMed = simple?.map((d) => ({
    //       ...d,
    //       consultationId: consultationID,
    //       prescriptionType: "S",
    //     }));
    //     const singleData = singleMed?.[0];
    //     const multiMed = homeo?.map((d) => ({
    //       ...d,
    //       consultationId: consultationID,
    //       prescriptionType: "H", //for HOMEO - Single
    //       potencyTypeId:
    //         d.decimal !== "" && d.decimal !== 0
    //           ? d.decimal
    //           : d.centesimal !== "" && d.centesimal !== 0
    //           ? d.centesimal
    //           : d.millesimal !== "" && d.millesimal !== 0
    //           ? d.millesimal
    //           : null,
    //     }));
    //     const externalMed = data?.prescription?.value?.external?.map((d) => ({
    //       ...d,
    //       consultationId: consultationID,
    //       prescriptionType: "E",
    //     }));
    //     const paidMed = data?.prescription?.value?.paid?.map((d) => ({
    //       ...d,
    //       consultationId: consultationID,
    //       prescriptionType: "P",
    //     }));
    //     preview.homoeoPrescriptionList = {
    //       singleMedicine: singleData,
    //       multipleMedicine: multiMed,
    //       externalMedicine: externalMed,
    //       paidMedicine: paidMed,
    //     };
    //     preview.homoeo = true;
    //     // dispatch(
    //     //   saveHomeopathy({
    //     //     singleMedicine: singleData,
    //     //     multipleMedicine: multiMed,
    //     //     externalMedicine: externalMed,
    //     //     paidMedicine: paidMed,
    //     //   })
    //     // );
    //   }
    // }

    let simpleData,
      externalData,
      paidData,
      complexDetails,
      singleData,
      multiMed;

    let simple =
      somId !== 5 ? prescription?.value?.simple : prescription?.value?.homeo;
    let complex = prescription?.value?.complex;
    let paid = prescription?.value?.paid;
    let external = prescription?.value?.external;
    let homeoMed = prescription?.value?.homeoMed;

    const transferPrescription = [];
    if (somId !== 5) {
      if (Array.isArray(simple) && simple?.length > 0) {
        simpleData = simple?.map((d) => ({
          ...d,
          consultationId: consultationID,
          prescriptionType: "S",
        }));
        // transferPrescription.push(...simpleData);
      }

      if (Array.isArray(complex) && complex?.length > 0) {
        complexDetails = complex?.map((d) => ({
          ...d,
          consultationId: consultationID,
          prescriptionType: "C",
        }));
        // transferPrescription.push(...complexDetails);
      }
    } else {
      if (
        (prescription && prescription?.value?.homeo?.length !== 0) ||
        prescription?.value?.homeoMed?.length !== 0
      ) {
        singleData = simple?.map((d) => ({
          ...d,
          consultationId: consultationID,
          prescriptionType: "S",
        }));
        // singleData = singleMed?.[0];
        multiMed = homeoMed?.map((d) => ({
          ...d,
          consultationId: consultationID,
          prescriptionType: "H", //for HOMEO - Single
          potencyTypeId:
            d.decimal !== "" && d.decimal !== 0
              ? d.decimal
              : d.centesimal !== "" && d.centesimal !== 0
              ? d.centesimal
              : d.millesimal !== "" && d.millesimal !== 0
              ? d.millesimal
              : null,
        }));
        // transferPrescription.push(...multiMed);
      }
    }
    if (Array.isArray(external) && external?.length > 0) {
      externalData = external?.map((d) => ({
        ...d,
        consultationId: consultationID,
        prescriptionType: "E",
      }));
      // transferPrescription.push(...externalData);
    }
    if (Array.isArray(paid) && paid?.length > 0) {
      paidData = paid?.map((d) => ({
        ...d,
        consultationId: consultationID,
        prescriptionType: "P",
      }));
      // transferPrescription.push(...paidData);
    }
    // preview.prescriptionList = {
    //   singleMedicine: simpleData,
    //   externalMedicine: externalData,
    //   complexMedicine: complexDetails,
    //   paidMedicine: paidData,
    // };
    // let transferSaveData = null
    const transferSaveData =
      somId === 5
        ? {
            ...transferData,
            isHomoeo: true,
            homoeoPrescriptionList: {
              consultationId: consultationID,
              prescriptionList: {
                singleMedicine: singleData,
                multipleMedicine: multiMed,
                externalMedicine: externalData,
                paidMedicine: paidData,
              },
              prescriptionRemarks: prescription?.prescriptionRemarks,
              staffId: StaffLogin?.id,
            },
          }
        : {
            ...transferData,
            isHomoeo: false,
            prescriptionDto: {
              singleMedicine: simpleData,
              externalMedicine: externalData,
              complexMedicine: complexDetails,
              paidMedicine: paidData,
            },
          };

    dispatch(saveTransferDetails(transferSaveData))
      .then((res) => {
        let responseObj = {
          message: "",
          messageInfoType: "",
        };
        if (res === "failure") {
          responseObj.message = "Request failed. Plz try again.";
          responseObj.messageInfoType = "error";
          dispatch(showSnackbar(responseObj));
        } else {
          responseObj.message = "Patient transferred successfully.";
          responseObj.messageInfoType = "success";
          dispatch(showSnackbar(responseObj));
          history.push("/Dashboard");
          setOpen(false);
        }
        setSom(StaffLogin.somId);
      })
      .catch((error) => console.log("saveTransferDetails error", error));
  };

  const handleSubmitIPAdmission = (e) => {
    if (StaffLogin) {
      setIpAdmissiontransferData({
        ...ipAdmissiontransferData,
        staffId: StaffLogin?.staffId,
        organizationId: StaffLogin.orgId,
        consultationId: consultationID,
      });
    }
    console.log("ipAdmissiontransferData", ipAdmissiontransferData);

    dispatch(saveTranferIPAdmissionDetails(ipAdmissiontransferData)).then(
      (res) => {
        let responseObj = {
          message: "",
          messageInfoType: "",
        };
        if (res === "failure") {
          responseObj.message = "Request failed. Plz try again.";
          responseObj.messageInfoType = "error";
          dispatch(showSnackbar(responseObj));
        } else {
          responseObj.message = "Patient transferred successfully.";
          responseObj.messageInfoType = "success";
          dispatch(showSnackbar(responseObj));
          history.push("/Dashboard");
          setOpen(false);
        }
        setSom(StaffLogin.somId);
      }
    );
  };

  // const handleChangeBranch = (event) => {
  //   setHospital(event.target.value);
  //   setTransferData({
  //     ...transferData,
  //     organizationId: event.target.value,
  //   });
  //   axiosInstance
  //     .get("department/getOPDepts/" + event.target.value)
  //     .then((res) => {
  //       setDepts(res.data);
  //     });
  // };

  const handleChangeDept = (event) => {
    setSelectedDept(event.target.value);
    setTransferData({
      ...transferData,
      departmentId: event.target.value,
    });
  };

  const handleKey = (event) => {
    const code = event.code;
    switch (code) {
      case "ArrowLeft":
        setValue((value + 8) % 9);
        break;
      case "ArrowRight":
        setValue((value + 1) % 9);
        break;
      default:
        break;
    }
  };
  console.log("open transfer", open);
  const handleClickOpen = () => {
    if (isPatientSelected) {
      console.log("ispatientselected transfer");
      setOpen(true);
      setPreview(true);
      handlePreview();
    } else {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "info",
          msg: "Please select a patient",
          openSnackbar: true,
        },
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenCancel = () => {
    if (_.isEmpty(selectedPatient)) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "info",
          msg: "Please select a patient",
          openSnackbar: true,
        },
      });
      return;
    }

    setOpenCancel(true);
  };
  const handleCloseCancel = () => {
    setOpenCancel(false);
  };

  const handlePatientCancel = async () => {
    // console.log("consultationID", consultationID);
    try {
      const res = await ipdOpdInstance.get(
        `consultation/clearConsultation/${consultationID}`
      );
      if (res?.data === "success") {
        history.push("/Dashboard");
      } else {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Cancel failed. Plz try again.",
            openSnackbar: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          msg: "Cancel failed. Plz try again.",
          openSnackbar: true,
        },
      });
    }
    setOpenCancel(false);
  };

  const handleChangeSom = (event) => {
    setSom(event.target.value);
    dispatch(fetchHealthFacilityBySomId(event.target.value));
  };

  const [hospital, setHospital] = React.useState(StaffLogin?.orgId);

  const [unit4, setUnit4] = React.useState("");

  const handleChange4 = (event) => {
    setUnit4(event.target.value);
  };

  const [print, setPrint] = React.useState(false);

  const handlePrint = () => {
    setPrint(true);
    if (value !== undefined) {
      const data = {};

      if (prescription !== undefined) {
        data.prescription = prescription;
      }
      if (diagnosisData !== undefined) {
        data.diagnosis = diagnosisData;
      }
      if (doDontValue !== undefined) {
        data.doDont = doDontValue;
      }
      setPrintData(data);
      setOpenPrescriptionPrint(true);
    }
  };

  const handleKeyPress = useCallback((event) => {
    if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 80 || event.keyCode === 112)
    ) {
      //ALT+P (PULSE)
      setValue(0);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 68 || event.keyCode === 100)
    ) {
      // ALT+D  (COMPLAINTS AND DURATION)
      setValue(1);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 84 || event.keyCode === 116) &&
      somId === 3
    ) {
      // ALT+T (TREATMENT)
      setValue(2);
    }
    else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 89) &&
      somId === 3
    ) {
      // ALT+Y (GEN PHY)
      setValue(3);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 69) &&
      somId === 3
    ) {
      // ALT+E (SYS EXA)
      setValue(4);
    }  
    else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 72 || event.keyCode === 104)
    ) {
      // ALT+H (CLINICAL HISTORY)
      setValue(somId === 3 ? 5: 2);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 71 || event.keyCode === 103)
    ) {
      //ALT+G (COMPLIANCE AND PROGNOSIS)

      setValue(somId === 3 ? 6 : 3);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 76 || event.keyCode === 108)
    ) {
      //ALT+L (LAB & INVESTIGATIONS)
      setValue(somId === 3 ? 7 : 4);
    } 
    else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 85)
    ) {
      // ALT+U  (SURGICAL PROCEDURE)
      setValue(somId === 3 ? 8 : 5);
    }
    else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 77 || event.keyCode === 109)
    ) {
      // ALT+M (CONCOMITANT MEDICATION)
      setValue(somId === 3 ? 9 : 6);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 79 || event.keyCode === 111)
    ) {
      // ALT+O  (DD WITH DIAGNOSIS)
      setValue(somId === 3 ? 10 : 7);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 83 || event.keyCode === 115)
    ) {
      // ALT+S (PRESCRIPTION)
      setValue(somId === 3 ? 11 : 8);
    } else if (
      (event.metaKey || event.altKey) &&
      (event.keyCode === 78 || event.keyCode === 110)
    ) {
      // ALT+N (DOS AND DONT)
      setValue(somId === 3 ? 12 : 9);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyPress);
    return () => {
      document.body.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handlePreview = async () => {
    if (_.isEmpty(selectedPatient)) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "info",
          msg: "Please select a patient",
          openSnackbar: true,
        },
      });
      return;
    }

    let valid = true;
    if (value === 1 && complaintsRef) {
      const isValid = await complaintsRef?.current?.complaintTabValue?.map(
        (e) => e.signSymptomId
      )?.[0];
      if (isValid) {
        const list = complaintsRef?.current?.complaintTabValue?.map(
          (e) => !e.duration || !e.durationTypeId
        )?.[0];
        if (list) {
          valid = false;
        } else {
          valid = true;
        }
      } else {
        valid = true;
      }
    }
    if (somId === 3) {
      if (value === 9 && concomitantMedicineRef) {
        const isValid = await concomitantMedicineRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }

      if (value === 11 && StaffLogin.somId !== 5) {
        const isValid = await prescriptionRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }
    } else {
      if (value === 6 && concomitantMedicineRef) {
        const isValid = await concomitantMedicineRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }
      if (value === 8 && StaffLogin.somId !== 5) {
        const isValid = await prescriptionRef?.current?.isValid();
        if (!isValid) {
          valid = false;
        }
      }
    }
    if (!valid) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          msg: "Please fill the required fields or delete the row",
          openSnackbar: true,
        },
      });
      return;
    }

    setPreview(true);
    if (value !== undefined) {
      const data = {};
      if (pulseData !== undefined) {
        data.pulse = pulseData;
      }
      if (complaintsData !== undefined) {
        data.candD = complaintsData;
      }
      if (diagnosisData !== undefined) {
        data.diagnosis = diagnosisData;
      }
      if (doDontValue !== undefined) {
        data.doDont = doDontValue;
      }
      if (prescription !== undefined) {
        data.prescription = prescription;
      }
      if (historyData !== undefined) {
        data.history = historyData;
      }
      if (concomitantMedicine !== undefined) {
        data.concomitantMedicines = concomitantMedicine;
      }
      if (labData !== undefined) {
        data.labData = labData;
      }
      if (compliancePrognosisValue !== undefined) {
        data.compliancePrognosisValue = compliancePrognosisValue;
      }
      if (pulseUnani !== undefined) {
        data.pulseUnani = pulseUnani;
      }

      if (treatmentData !== undefined) {
        data.treatment = treatmentData;
      }
      if (physicalData !== undefined) {
        data.physical = physicalData;
      }
      if (systemicData !== undefined) {
        data.systemic = systemicData;
      }
      if (procedureData !== undefined) {
        data.surgicalProceduresDtos = procedureData;
      }
      setPreviewData(data);

      setOpenPreview(true);
    }
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popper" : undefined;
  return (
    <>
      <div className={classes.root}>
        <Tabs
          className={classes.tab}
          orientation={sm ? "vertical" : "horizontal"}
          style={{
            backgroundColor: "white",
            elevation: 0,
            opacity: 1,
            textAlign: "center",
            // maxHeight:"20vh",
          }}
          variant={sm ? "fullWidth" : "scrollable"}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          onKeyDown={handleKey}
          aria-label="nav tabs example"
        >
          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                <span className={classes.highlightCharacter}>P</span>ulse
              </Typography>
            }
            href="/drafts"
            style={{
              backgroundColor: value === 0 ? myOwnColor : menuThemeColor,
              opacity: 1,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
              display: som === 5 || som === 3 ? "none" : null,
            }}
            {...a11yProps(0)}
          ></LinkTab>
          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                Complaints &{" "}
                <span className={classes.highlightCharacter}>D</span>uration
              </Typography>
            }
            href="/drafts"
            style={{
              opacity: 1,
              backgroundColor: value === 1 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(1)}
          />
          {somId === 3 ? (
            <LinkTab
              className={classes.panel}
              label={
                <Typography>
                  <span className={classes.highlightCharacter}>T</span>reatment
                </Typography>
              }
              style={{
                opacity: 1,
                backgroundColor: value === 2 ? myOwnColor : menuThemeColor,
                color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
              }}
            />
          ) : null}

          {somId === 3 ? (
            <LinkTab
              className={classes.panel}
              label={
                <Typography>
                  <span className={classes.highlightCharacter}>G</span>eneral
                  physical examinations
                </Typography>
              }
              style={{
                opacity: 1,
                backgroundColor: value === 3 ? myOwnColor : menuThemeColor,
                color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
              }}
            />
          ) : null}

          {somId === 3 ? (
            <LinkTab
              className={classes.panel}
              label={
                <Typography>
                  <span className={classes.highlightCharacter}>S</span>ystemic
                  Examination
                </Typography>
              }
              style={{
                opacity: 1,
                backgroundColor: value === 4 ? myOwnColor : menuThemeColor,
                color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
              }}
            />
          ) : null}

          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                Clinical <span className={classes.highlightCharacter}>H</span>
                istory
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 5
                  ? myOwnColor
                  : somId !== 3 && value === 2
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(3)}
          />
          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                Compliance & Pro
                <span className={classes.highlightCharacter}>g</span>nosis
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 6
                  ? myOwnColor
                  : somId !== 3 && value === 3
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(4)}
          />
          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                <span className={classes.highlightCharacter}>L</span>ab &
                Investigations
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 7
                  ? myOwnColor
                  : somId !== 3 && value === 4
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(5)}
          />

          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                <span className={classes.highlightCharacter}>S</span>urgical
                Procedures
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 8
                  ? myOwnColor
                  : somId !== 3 && value === 5
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(6)}
          />

          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                Concomitant{" "}
                <span className={classes.highlightCharacter}>M</span>edication
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 9
                  ? myOwnColor
                  : somId !== 3 && value === 6
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(6)}
          />
          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                DD with Diagn
                <span className={classes.highlightCharacter}>o</span>sis
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 10
                  ? myOwnColor
                  : somId !== 3 && value === 7
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(7)}
          />
          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                Pre<span className={classes.highlightCharacter}>s</span>cription
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 11
                  ? myOwnColor
                  : somId !== 3 && value === 8
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(8)}
          />
          <LinkTab
            className={classes.panel}
            label={
              <Typography>
                Do’s & Do<span className={classes.highlightCharacter}>n</span>
                t’s
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor:
                somId === 3 && value === 12
                  ? myOwnColor
                  : somId !== 3 && value === 9
                  ? myOwnColor
                  : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(9)}
          />

          {/* <LinkTab
            className={classes.panel}
            label={
              <Typography>
                Medical<span className={classes.highlightCharacter}>C</span>
                ertificate
              </Typography>
            }
            href="/spam"
            style={{
              opacity: 1,
              backgroundColor: value === 9 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            {...a11yProps(9)}
          /> */}
        </Tabs>

        <TabPanel value={value} index={0}>
          {som === 2 ? (
            <Suspense fallback={<Loader />}>
              <PulseUnani value={pulseUnani} onChange={setPulseUnani} />
            </Suspense>
          ) : som !== 3 && som !== 5 ? (
            <Suspense fallback={<Loader />}>
              <Pluse value={pulseData} onChange={setPulseData} />
            </Suspense>
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Suspense fallback={<Loader />}>
            <Complaints
              ref={complaintsRef}
              value={complaintsData}
              signSymptom={signSymptom}
              onChange={setComplaintsData}
            />
          </Suspense>
        </TabPanel>
        {somId === 3 ? (
          <TabPanel value={value} index={2}>
            <Suspense fallback={<Loader />}>
              <Treatment value={treatmentData} onChange={setTreatmentData} />
            </Suspense>
          </TabPanel>
        ) : null}
        {somId === 3 ? (
          <TabPanel value={value} index={3}>
            <Suspense fallback={<Loader />}>
              <GeneralPhysicalExaminations
                value={physicalData}
                onChange={setPhysicalData}
              />
            </Suspense>
          </TabPanel>
        ) : null}
        {somId === 3 ? (
          <TabPanel value={value} index={4}>
            <Suspense fallback={<Loader />}>
              <SystemicExamination
                value={systemicData}
                onChange={setSystemicData}
                errormsg={errormsg}
                setErrormsg={setErrormsg}
                setErrormsgTong={setErrormsgTong}
                errormsgTong={errormsgTong}
                setDirtyVal={setDirtyVal}
              />
            </Suspense>
          </TabPanel>
        ) : null}

        <TabPanel
          value={value}
          index={somId === 3 ? 5 : 2}
          className="historytab"
        >
          <Suspense fallback={<Loader />}>
            <Historytabs value={historyData} onChange={setHistoryData} />
          </Suspense>
        </TabPanel>
        <TabPanel value={value} index={somId === 3 ? 6 : 3}>
          <Suspense fallback={<Loader />}>
            <Compliance
              value={compliancePrognosisValue}
              onChange={setCompliancePrognosisValue}
            />
          </Suspense>
        </TabPanel>
        <TabPanel value={value} index={somId === 3 ? 7 : 4}>
          <Suspense fallback={<Loader />}>
            <Lab value={labData} onChange={setLabData} />
          </Suspense>
        </TabPanel>
        <TabPanel value={value} index={somId === 3 ? 8 : 5}>
          <Suspense fallback={<Loader />}>
            <SurgicalProcedure
              value={procedureData}
              onChange={setProcedureData}
            />
          </Suspense>
        </TabPanel>
        <TabPanel value={value} index={somId === 3 ? 9 : 6}>
          <Suspense fallback={<Loader />}>
            <ConcomitantMedicine
              ref={concomitantMedicineRef}
              value={concomitantMedicine}
              onChange={setConcomitantMedicine}
            />
          </Suspense>
        </TabPanel>
        <TabPanel value={value} index={somId === 3 ? 10 : 7}>
          <Suspense fallback={<Loader />}>
            <Diagnosis
              value={diagnosisData}
              onChange={setDiagnosisData}
              diagnosisId={complaintsData?.diagnosisId}
            />
          </Suspense>
        </TabPanel>

        <TabPanel value={value} index={somId === 3 ? 11 : 8}>
          <Suspense fallback={<Loader />}>
            <Prescription
              ref={prescriptionRef}
              value={prescription}
              onChange={setPrescription}
            />
          </Suspense>
        </TabPanel>

        <TabPanel value={value} index={somId === 3 ? 12 : 9}>
          <Suspense fallback={<Loader />}>
            <DoDont value={doDontValue} onChange={setDoDontValue} />
          </Suspense>
        </TabPanel>
      </div>
      <div className={classes.buttonRoot}>
        {value === 7 ? (
          <Button
            variant="contained"
            style={{
              backgroundColor: " #CAF7FB ",
              color: "black",
              border: "1px solid #07A0B0",
            }}
            onClick={handlePrint}
          >
            Print Prescription
          </Button>
        ) : null}
      </div>
      <div className={classes.buttonRoot}>
        <Button
          variant="contained"
          style={{ backgroundColor: myOwnColor, color: "white" }}
          onClick={handlePreview}
        >
          Preview
        </Button>
        <CloseButton onClick={handleOpenCancel}>Cancel</CloseButton>
        <Dialog open={openCancel} onClose={handleCloseCancel}>
          <DialogContent style={{ padding: 20 }}>
            <Typography color="error" variant="h6">
              Are you sure you want to cancel?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handlePatientCancel}
              color="primary"
              variant="outlined"
              size="small"
            >
              Yes
            </Button>
            {/* </Link> */}
            <Button
              onClick={handleCloseCancel}
              style={{ color: "red" }}
              variant="outlined"
              size="small"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ backgroundColor: "#0C51B8", color: "white" }}
        >
          Transfer
        </Button>
        <Dialog
        // open={open}
        // onClose={handleClose}
        // aria-labelledby="form-dialog-title"
        // classes={{ paper: classes.paper }}
        >
          <DialogContent>
            <Grid container spacing={0}>
              <Grid item xs={11} align="left">
                <Typography variant="h6" style={{ marginTop: 10 }}>
                  TRANSFER
                </Typography>
              </Grid>
              <Grid item xs={1} align="right">
                <IconButton onClick={handleClose} edge="start" color="primary">
                  <CloseIcon style={{ color: "black" }} />
                </IconButton>
              </Grid>
            </Grid>

            <Accordion className={classes.accordion1} defaultExpanded="true">
              <AccordionSummary
                style={{
                  backgroundColor: myOwnColor,
                  minHeight: 40,
                  maxHeight: 40,
                  // padding:16
                }}
                expandIcon={<ExpandMoreIcon className={classes.icon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Referral</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.content}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} sm={4}>
                    <Typography>Stream</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <FormControl size="small" fullWidth>
                      <Select
                        native
                        variant="outlined"
                        value={som}
                        onChange={handleChangeSom}
                      >
                        <option value="" disabled selected>
                          Stream
                        </option>

                        <option value={1}>Ayurveda</option>
                        <option value={2}>Unani</option>
                        <option value={3}>Yoga & Naturopathy</option>
                        <option value={4}>Siddha</option>
                        <option value={5}>Homoeopathy</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Typography>Hospital</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    {hospitalValue !== "" && hospitalValue !== undefined ? (
                      <Popper
                        placement="top"
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        style={{ zIndex: 9999 }}
                      >
                        <Paper>
                          <Typography style={{ padding: 12 }}>
                            {inputValue}
                          </Typography>
                        </Paper>
                      </Popper>
                    ) : null}
                    <Autocomplete
                      aria-describedby={id}
                      options={healthFacilities}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      // className={classes.input2}
                      getOptionLabel={(option) => option.organisationName}
                      // filterSelectedOptions
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      onChange={(e, v) => {
                        setHospitalValue(v?.id);
                        v && dispatch(fetchRegDeptListByOrgId(v?.id));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          placeholder="Search Hospital"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Typography>Department</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <FormControl size="small" fullWidth>
                      <Select
                        variant="outlined"
                        native
                        // id="department"
                        onChange={handleChangeDept}
                        value={selectedDept}
                      >
                        <option value="" disabled selected>
                          Department
                        </option>

                        {departments &&
                          departments.length > 0 &&
                          departments.map((dept) => {
                            return (
                              <option value={dept.id}>{dept.deptName}</option>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      style={{
                        backgroundColor: myOwnColor,
                        color: "white",
                      }}
                    >
                      Transfer
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ marginTop: "2%" }}
              className={classes.accordion1}
            >
              <AccordionSummary
                style={{
                  backgroundColor: myOwnColor,
                  minHeight: 40,
                  maxHeight: 40,
                }}
                expandIcon={<ExpandMoreIcon className={classes.icon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  IP Admission
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.content}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} sm={4}>
                    <Typography>Date</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        format="dd/MM/yyy"
                        id="date-picker-inline"
                        value={ipAdmissiontransferData.admissionDate}
                        onChange={(date) => {
                          setIpAdmissiontransferData({
                            ...ipAdmissiontransferData,
                            admissionDate: date,
                          });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Typography>Department</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <FormControl fullWidth size="small">
                      <Select
                        native
                        variant="outlined"
                        onChange={(e) => {
                          setIpAdmissiontransferData({
                            ...ipAdmissiontransferData,
                            departmentId: e.target.value,
                          });
                        }}
                        value={ipAdmissiontransferData.departmentId}
                      >
                        <option value="" disabled selected>
                          Department
                        </option>

                        {ipdDept &&
                          ipdDept.length > 0 &&
                          ipdDept.map((dept) => {
                            return (
                              <option value={dept.id}>{dept.deptName}</option>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={6} sm={4}>
                    <Typography>Ward</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <FormControl fullWidth size="small">
                      <Select
                        native
                        variant="outlined"
                        // onChange={(e) => {
                        //   setIpAdmissiontransferData({
                        //     ...ipAdmissiontransferData,
                        //     departmentId: e.target.value,
                        //   });
                        // }}
                        onChange={handleChange4}
                        InputProps={{
                          className: classes.input,
                        }}
                        // value={ipAdmissiontransferData.departmentId}
                        value={unit4}
                      >
                        <option value="" disabled selected>
                          Ward
                        </option>
                        <option value={10}>W01</option>
                        <option value={20}>W02 </option>
                        <option value={30}>W03 </option>
                        <option value={40}>W04</option>

                        {/* {ipdDept &&
                          ipdDept.length > 0 &&
                          ipdDept.map((dept) => {
                            return (
                              <option value={dept.id}>{dept.deptName}</option>
                            );
                          })} 
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={6} sm={4}>
                    <Typography>Reason for Transfer</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <TextField
                      onChange={(e) => {
                        setIpAdmissiontransferData({
                          ...ipAdmissiontransferData,
                          remarks: e.target.value,
                        });
                      }}
                      placeholder="Remarks"
                      size="small"
                      variant="outlined"
                      name="pulserate"
                      fullWidth
                      rows={3}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmitIPAdmission();
                      }}
                      style={{
                        backgroundColor: myOwnColor,
                        color: "white",
                        fontSize: "12px",
                        float: "right",
                      }}
                    >
                      Transfer
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
      {print && (
        <PrescriptionPrint
          data={printData}
          open={openPrescriptionPrint}
          onClose={setOpenPrescriptionPrint}
        />
      )}
      {preview && (
        <Preview
          data={previewData}
          open={openPreview}
          onClose={setOpenPreview}
          onEdit={editPreview}
          openTransfer={open}
          closeTransferFn={handleClose}
        />
      )}
    </>
  );
}
