import { showSnackbar } from "../actions/Notification";
import axiosInstance from "../api/Instance";
import ipdOpdInstance from "../api/IpdOpdInstance";

import {
  FETCH_DIAGNOSIS_LIST,
  FETCH_DIAGNOSIS,
  FETCH_FINAL_DIAGNOSIS,
  FETCH_DIAGNOSIS_LIST_FOR_PRES,
  FETCH_PREV_COMPLAINTS,
  FETCH_FINAL_DIAGNOSIS_BY_CONSULTATION_ID,
} from "./Type";

export const fetchDiagnosisDetails = (somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get("disease/getAllDiseases/" + somId);

    dispatch({
      type: FETCH_DIAGNOSIS_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveDiagnosisDetails = (diagnosisDetails) => async (dispatch) => {
  try {
    const url = "disease/saveDisease";
  
    const response = await axiosInstance.post(url, diagnosisDetails);
    const res = response.data;
  
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      if (diagnosisDetails.id == 0) {
        responseObj.message = "Diagnosis details added successfully";
      } else {
        responseObj.message = "Diagnosis details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
    
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const deleteDiagnosis = (diagnosisId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`disease/deleteDisease/${diagnosisId}`);
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res.data == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Diagnosis details deleted successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    
  } catch (error) {
    console.log(error)
  }
};

export const saveDiagnosis = (diagnosisDetails) => async (dispatch) => {
  try {
    const url = "diagnosis/saveDiagnosis";
  
    const response = await ipdOpdInstance.post(url, diagnosisDetails);
  
    const res = response.data;
  
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      if (diagnosisDetails.id == 0) {
        responseObj.message = "Diagnosis details added successfully";
      } else {
        responseObj.message = "Diagnosis details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
    
  } catch (error) {
    console.log(error)
    return "failure";
  }
};

export const fetchSavedDiagnosis = (somId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get("diagnosis/fetchSavedDiagnosis/" + somId);

    dispatch({
      type: FETCH_DIAGNOSIS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveFinalDiagnosis = (diagnosisDetails) => async (dispatch) => {
  try {
    const url = "diagnosis/saveFinalDiagnosis";
  
    const response = await ipdOpdInstance.post(url, diagnosisDetails);
  
    const res = response.data;
  
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      if (diagnosisDetails.id == 0) {
        responseObj.message = "Diagnosis details added successfully";
      } else {
        responseObj.message = "Diagnosis details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
    
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchSavedFinalDiagnosis = (somId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get("diagnosis/fetchSavedFinalDiagnosis/" + somId);

    dispatch({
      type: FETCH_FINAL_DIAGNOSIS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchDiagnosisDetailsForPres = (somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`disease/getAllDiseasesForPres/` + somId);
    dispatch({
      type: FETCH_DIAGNOSIS_LIST_FOR_PRES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchPrevComplaints = (patientId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get("complaints//getPreviousComplaints/" + patientId);

    dispatch({
      type: FETCH_PREV_COMPLAINTS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchSavedDiagnosisByConsultationId = (consultId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get("diagnosis/fetchSavedFinalDiagnosis/" + consultId);

    dispatch({
      type: FETCH_FINAL_DIAGNOSIS_BY_CONSULTATION_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
