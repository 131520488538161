import Axios from "axios";
import {
  FETCH_ROOM_LIST,
  FETCH_ALL_CONSULTATION_ROOMS_BY_STAFFID,
  FETCH_CONSULTATION_DEPT,
  FETCH_ALL_CONSULTATION_ROOMS,
  FETCH_ALL_CONSULTATION_ROOMS_BY_DEPID,
} from "./Type";
import { showSnackbar } from "./Notification";
import configData from "../config.json";
import axiosInstance from "../api/Instance";

export const fetchConsultationRoomsByDeptId = (deptId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`consultationRoomDepartmentMap/getRoomsByDeptId/${deptId}`);

    dispatch({
      type: FETCH_ALL_CONSULTATION_ROOMS_BY_DEPID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchConsultationRoomsByStaffId = (staffId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`consultationRoomDepartmentMap/getAvailableRoomByStaffId/${staffId}`);

    dispatch({
      type: FETCH_ALL_CONSULTATION_ROOMS_BY_STAFFID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchConsultationRooms = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`consultationroom/getAllConsultationRoomByOrg?orgId=${orgId}`);

    dispatch({
      type: FETCH_ROOM_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchDeptByOrgId = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`department/${orgId}`);

    dispatch({
      type: FETCH_ROOM_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchAllRooms = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`consultationRoomDepartmentMap//getAllDataByOrgId/${orgId}`);

    dispatch({
      type: FETCH_ALL_CONSULTATION_ROOMS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchConsultationDept = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`department/getDepartmentsForAppt/${orgId}`);
    dispatch({
      type: FETCH_CONSULTATION_DEPT,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveConsultationRoom = (consultationRoom) => async (dispatch) => {
  try {

    const url = "consultationroom/saveConsultationRoom";
  
    const response = await axiosInstance.post(url, consultationRoom);
  
    const res = response.data;
  
    console.log("success", res);
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res?.message == "success") {
      if (consultationRoom.id == 0) {
        responseObj.message = "Consultation Room details added successfully";
      } else {
        responseObj.message = "Consultation Room details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      // responseObj.message = res?.message;
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error)
    return "failure";
  }
};

export const saveConsultationRoomForDoctor = (deptConsultationRoomMapping) => async (dispatch) => {
  try {
    const url = "consultationRoomDepartmentMap/updateConsultationRoomForDoctor";

    const response = await axiosInstance.post(url,deptConsultationRoomMapping);
    const res = response.data;
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveConsultationDeptRoom = (data) => async (dispatch) => {
  try {
    const url = "consultationRoomDepartmentMap/saveconsultationRoomDepartmentMap";
  
    const response = await axiosInstance.post(url, data);
  
    const res = response.data;
  
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "success") {
      if (data.id == 0) {
        responseObj.message = "Consultation Room details added successfully";
      } else {
        responseObj.message = "Consultation Room details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
    
  } catch (error) {
    console.log(error);
    return "failure";
  }
};
