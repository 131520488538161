import { TableCell, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
    fontSize: "1rem",
    padding:4,
    // fontWeight:500,
  },
  body: {
    fontSize: 14,
    // padding: "6px !important",
  },
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },

  },
}))(TableRow);

export const SUBMIT_COLOR = "tomato"
