import {
  FETCH_TOTAL_AYUSH_PRACTIONERS,
  FETCH_TOTAL_PATIENT_REGISTERED,
  TOTAL_AYUSH_HEALTH_FACILITIES,
  TODAY_PATIENT_REGISTERED,
  TODAY_PRACTIONERS,
  DRILL_DOWN_FETCH_STATE,
  DRILL_DOWN_FETCH_COUNTRY,
  FETCH_HEALTH_FACILITIES_LIST,
  DRILL_DOWN_TODAY_PATIENT_REGISTERED,
  DRILL_DOWN_TODAY_PRACTIONERS,
  DRILL_DOWN_AYUSH_HEALTH_FACILITIES,
  DRILL_DOWN_TOTAL_AYUSH_PRACTIONERS,
  DRILL_DOWN_TOTAL_PATIENT_REGISTERED,
  TOTAL_BED_OCCUPIED,
  DRILL_DOWN_TOTAL_BED_OCCUPIED,
} from "../actions/Type";

const initialState = {
  totalPractioners: [],
  totalPatientRegistered: [],
  todayPatientRegistered: [],
  totalAyushHealthFacilities: [],
  todayPractioners: [],
  states: [],
  cities: [],
  drillTodayPatientRegistered: [],
  healthList: [],
  drillTotalPractioners: [],
  drillTotalPatientRegistered: [],
  drillTotalAyushHealthFacilities: [],
  drillTodayPractioners: [],
  drillTotalBedOccupied: [],
};

const GraphReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOTAL_AYUSH_PRACTIONERS:
      return {
        ...state,
        totalPractioners: action.payload,
      };
    case FETCH_TOTAL_PATIENT_REGISTERED:
      return {
        ...state,
        totalPatientRegistered: action.payload,
      };
    case TOTAL_AYUSH_HEALTH_FACILITIES:
      return {
        ...state,
        totalAyushHealthFacilities: action.payload,
      };
    case TODAY_PATIENT_REGISTERED:
      return {
        ...state,
        todayPatientRegistered: action.payload,
      };
    case TODAY_PRACTIONERS:
      return {
        ...state,
        todayPractioners: action.payload,
      };
    case DRILL_DOWN_FETCH_STATE:
      return {
        ...state,
        states: action.payload,
      };
    case DRILL_DOWN_FETCH_COUNTRY:
      return {
        ...state,
        cities: action.payload,
      };
    case DRILL_DOWN_TODAY_PATIENT_REGISTERED:
      return {
        ...state,
        drillTodayPatientRegistered: action.payload,
      };
    case FETCH_HEALTH_FACILITIES_LIST:
      return {
        ...state,
        healthList: action.payload,
      };
    case DRILL_DOWN_TODAY_PRACTIONERS:
      return {
        ...state,
        healthList: action.payload,
      };
    case DRILL_DOWN_TOTAL_AYUSH_PRACTIONERS:
      return {
        ...state,
        healthList: action.payload,
      };

    case DRILL_DOWN_AYUSH_HEALTH_FACILITIES:
      return {
        ...state,
        healthList: action.payload,
      };

    case DRILL_DOWN_TOTAL_PATIENT_REGISTERED:
      return {
        ...state,
        healthList: action.payload,
      };
    case DRILL_DOWN_TOTAL_BED_OCCUPIED:
      return {
        ...state,
        drillTotalBedOccupied: action.payload,
      };
    case TOTAL_BED_OCCUPIED:
      return {
        ...state,
        totalBedOccupied: action.payload,
      };

    default:
      return state;
  }
};

export default GraphReducer;
