import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  InputAdornment,
  IconButton,
  TableBody,
  TableHead,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  FormControl,
  LinearProgress,
  DialogTitle,
} from "@material-ui/core";
import _ from "lodash";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InventoryInstance from "../../../api/InventoryInstance";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ahmis from "../../../assets/ahmis.png";
import leaf from "../../../assets/leaf.png";
import IndentIssueForm from "./IndentIssueForm";
import { SHOW_SNACKBAR } from "../../../actions/Type";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NotificationSuccess from "../../../component/Notification/NotificationSuccess";
import moment from "moment";
import Controls from "../../../component/controls/Controls";
import useTable from "../../../component/useTable";
import { faEdit, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import DialogActions from "@material-ui/core/DialogActions";

const headCells = [
  { id: "srNo", label: "Sl No." },
  { id: "indentReqNO", label: "Indent Request No." },
  { id: "indentissueNo", label: "Indent Issue NO." },
  { id: "expectedDate", label: "Expected Date" },
  { id: "totalBillAmount", label: "Total Bill Amount(₹)" },
  { id: "Store", label: "From Store" },
  { id: "reorder", label: "Reorder" },

  { id: "approvalStatus", label: "Status" },
  { id: "action", label: "Action", disableSorting: true },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const AccordionSummary = withStyles((theme) => ({
  root: {
    color: "white",
    minHeight: "40px",
    "&$expanded": {
      minHeight: "40px",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },

  expanded: {},
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0.5%",
    marginLeft: "-0.5%",
  },

  icon: {
    color: "white",
  },
  content: {
    backgroundColor: "white",
    color: "black",
    display: "block",
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnPending: {
    backgroundColor: "rgb(231 108 25)",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
}));

export const defaultComplexRow = () => ({
  batchNo: "",
  qty: "",
  mfgDate: null,
  expDate: null,
  issuedQty: "",
});

export const defaultComplex = () => ({
  dtoId: "",
  drugTypeName: "",
  drugName: "",
  uomName: "",
  singleUnit: "",
  singleUnitContains: "",
  approvedQty: "",
  singleQtyPrice: "",
  price: "",

  rows: [defaultComplexRow()],
});
const style = {
  cellPadding: 1,
  fontSize: 8,
  font: "helvetica",
  lineWidth: 0.01,
  lineColor: 127,
  fontStyle: "normal",
  overflow: "linebreak",
  fillColor: false,
  textColor: 0,
  halign: "left",
  valign: "middle",
  cellWidth: "auto",
};
function Inner({ value: medicine, onChange: setMedicine }, ref) {
  const classes = useStyles();
  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const dispatch = useDispatch();
  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);

  const batchSchema = yup.object().shape({
    serialNo: yup.string(),
  });

  const complexRowSchema = yup.object().shape({
    batchNo: batchSchema.nullable().required("This field is required"),
    qty: yup.string(),
    // mfgDate: yup.string().required("This field is required"),
    mfgDate: yup.string(),

    // issuedQty: yup.string().required("This field is required").matches(/^[1-9][0-9]*$/, "Can not start with 0"),
    issuedQty: yup
      .string()
      .nullable()
      .required("Required")
      .matches(/^[1-9][0-9]*$/, "Can not start with 0")
      .when("qty", (qty) => {
        if (qty) {
          return yup
            .number()
            .typeError("Required")
            .max(qty, "Less in Stock");
        }
      }),

    // expDate: yup.string().required("This field is required"),
    expDate: yup.string(),
  });

  const complexSchema = yup.object().shape({
    drugTypeName: yup.string(),
    drugName: yup.string(),
    uomName: yup.string(),
    singleUnit: yup.string(),
    singleUnitContains: yup.string(),
    approvedQty: yup.string(),
    singleQtyPrice: yup
      .string()
      .required("This field is required")
      .matches(/^\d{0,5}(\.\d{1,2})?$/, "Invalid value"),
    price: yup.string(),
    dtoId: yup.string(),
    rows: yup
      .array()
      .of(complexRowSchema)
      .required("Must have fields"),
  });

  const schema = yup.object().shape({
    totalBillAmount: "",
    complex: yup
      .array()
      .of(complexSchema)
      .required("Must have fields"),
  });

  const defaultValues = {
    totalBillAmount: "",
    complex: [],
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    setValue,
    setError,
    getValues,
    trigger,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  React.useImperativeHandle(ref, () => {
    return {
      isValid: trigger,
    };
  });

  const historyRef = React.useRef(null);

  const complexRef = React.useRef(null);

  const uncheckHistory = React.useCallback(
    (mainIndex, index) => historyRef.current?.uncheck(mainIndex, index),
    [historyRef]
  );
  const staff = useSelector((state) => state.loginReducer.staffLogin);
  // console.log(staff,"Staff name")
  const orgId = staff?.orgId;
  const staffId = staff?.staffId;
  const storeId = staff?.storeId;

  const [editData, setDataEdit] = React.useState(null);
  const [viewData, setdata] = React.useState(null);
  const [overAllData, setOverAllData] = React.useState(null);
  const [complexMedicines, setComplexMedicines] = React.useState({});

  const [isDeleted, setDeletedData] = useState(true);
  const [loading, setLoading] = React.useState(false);

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [viewMood, setViewMood] = useState(false);
  const [pendingApproval, setPendingApproval] = useState(false);
  const [errosMsg, errorMsg] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [expectedDate, setExpectedDate] = React.useState("");
  const [dataChild, setChildData] = useState(null);

  const [records, setRecords] = React.useState([]);
  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let value = e.target.value;

    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        }
        return items.filter(
          (x) =>
            x.indentNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.reqFroStore?.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
  };

  const fetchIndentIssue = () => {
    setLoading(true);
    InventoryInstance.get(`indentIssue/fetchReqByStore/${orgId}/${storeId}`)
      .then((res) => {
        var mydatais = res.data.sort((a, b) => a.id - b.id);

        setRecords(mydatais);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        console.log("error");
      });
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    setPendingApproval(false);

    window.scroll(0, 0);
    InventoryInstance.get(`indentIssue/test/${orgId}/${storeId}/${id}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setDataEdit(data);
        setExpectedDate(data?.expectedDate);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const handleEditPending = (e, id) => {
    window.scroll(0, 0);
    e.preventDefault();
    setPendingApproval(true);
    InventoryInstance.get(`indentIssue/test/${orgId}/${storeId}/${id}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setDataEdit(data);
        setExpectedDate(data?.expectedDate);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const handleViewRecord = (e, orderId) => {
    e.preventDefault();
    setPendingApproval(false);
    window.scroll(0, 0);
    InventoryInstance.get(`indentIssue/fetchByIndentId/${orgId}/${orderId}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setOpen(true);
        setOverAllData(data);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const fetchBillData = (indentId, issueID) => {
    setViewMood(true);
    window.scroll(0, 0);
    InventoryInstance.get(`indentIssue/fetchByIssueId/${indentId}/${issueID}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        console.log(data);
        setdata(data);
        setExpectedDate(data?.expectedDate);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const onFormSubmit = (data) => {
    console.log(data, "form Date");

    if (data.complex.length !== 0) {
      let amt = 0;
      data.complex?.forEach((row, index) => {
        row.rows.forEach((innerRow, index) => {
          const rowAmount = +innerRow.issuedQty;
          amt += rowAmount;
        });
      });

      const req = {
        organizationId: orgId,
        createdBy: staffId,
        subStockStoreId: storeId,
        indentRequestId: editData?.id,
        completed: isDeleted,
        netAmount: data?.totalBillAmount,
        indentIssueDetailsDtos: data.complex.map((el, index) => ({
          indentReqDetId: el.dtoId,
          amount: el.price,
          costPerUnit: el.singleQtyPrice,
          issuedQty: data.complex[index].rows.reduce((accumulator, object) => {
            return accumulator + +object.issuedQty;
          }, 0),

          pendingQty: +el.approvedQty,
          issueDetDrugDto: data.complex[index].rows.map((ml, index) => ({
            maDate: ml.mfgDate,
            exDate: ml.expDate,
            issuedQty: ml.issuedQty,
            serialNo: ml.batchNo.serialNo,
          })),
        })),
      };

      console.log(req, "req data");
      InventoryInstance.post(`indentIssue/save`, req)
        .then((response) => {
          if (response.data === "success") {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "success",
                msg: "Record Updated successfully",
                openSnackbar: true,
              },
            });
            reset(defaultValues);
            setIsEditMode(false);
            setViewMood(false);
            setPendingApproval(false);
            fetchIndentIssue();
          } else {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "error",
                msg: "Something went wrong",
                openSnackbar: true,
              },
            });
          }
        })
        .catch(() => {
          console.log("error");
        });
    } else {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          msg: "No rows selected",
          openSnackbar: true,
        },
      });
      // setIsEditMode(false);
      // setViewMood(false);
      // reset(defaultValues);
    }
  };

  const [W, H] = [260, 297];
  const margin = 10;

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    fetchIndentIssue();
  }, []);

  const generatePdf = (report, data, staffData) => {
    const org = staffData.orgName;
    const now = moment(new Date()).format("DD-MM-YYYY");
    const time = new Date().toLocaleTimeString();

    const body = [
      ...data.indentRequestDetailsDtos
        .map((d, index) => [
          [
            {
              content: d.drugTypeName,
              rowSpan: d.detDrugDto.length,
              styles: { halign: "left" },
            },
            {
              content: d.drugName,
              rowSpan: d.detDrugDto.length,
              styles: { halign: "left" },
            },
            {
              content: d.uomName,
              rowSpan: d.detDrugDto.length,
              styles: { halign: "left" },
            },

            {
              content: d.issueNo,
              rowSpan: d.detDrugDto.length,
              styles: { halign: "left" },
            },

            {
              content: d.approvedQty,
              rowSpan: d.detDrugDto.length,
              styles: { halign: "right" },
            },
            {
              content: `Rs. ${d.costPerUnit}`,
              rowSpan: d.detDrugDto.length,
              styles: { halign: "right" },
            },
            {
              content: d.issueDate,
              rowSpan: d.detDrugDto.length,
              styles: { halign: "left" },
            },
            moment(d.detDrugDto[0].manufactureDate).format("DD-MM-YYYY"),
            moment(d.detDrugDto[0].expiryDate).format("DD-MM-YYYY"),
            d.detDrugDto[0].serialNo,
            d.detDrugDto[0].issuedQty,
          ],
          // ...d.entryDetDtoSet.flatMap((dl) => dl.entryDetailsDrugDtos.flatMap((ml)=>[ml.expiryDate])),

          ...d.detDrugDto
            .slice(1)
            .map((ml) => [
              moment(ml.manufactureDate).format("DD-MM-YYYY"),
              moment(ml.expiryDate).format("DD-MM-YYYY"),
              ml.serialNo,
              ml.issuedQty,
            ]),
        ])
        .flat(),
    ];
    const pdf = new jsPDF("p", "mm", [W, H], true);
    let Y = margin;
    pdf.addImage(ahmis, "PNG", margin, Y + 2, 15, 15);
    pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
    pdf.setFontSize(14);
    let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
    pdf.text(splitTitle, W / 2, Y, { align: "center" });
    pdf.setFontSize(13);
    pdf.text(report, W / 2, Y + 15, "center");
    Y += 30;
    pdf.setFontSize(10);
    pdf.text(`Indent Request No.: ${data.indentReqNo}`, margin, Y);

    Y += 10;

    pdf.autoTable({
      head: [
        [
          "Drug Type",
          "Drug Name",
          "UOM",
          "Issue No.",
          "Approved Qty",
          "Cost Per Unit (Rs.)",
          "Issue Date & Time",
          "Mfg. Date",
          "Exp. Date",
          "Serial/Batch No.",
          "Issued Qty",
        ],
      ],

      body: body,

      startY: Y,
      margin,
      headStyles: {
        ...style,
        fillColor: [11, 156, 171],
        textColor: 255,
        lineColor: 255,
        fontSize: 8,
        fontWeight: 500,
      },
      bodyStyles: { ...style },
      footStyles: { ...style },
    });

    pdf.save(`Indent Issue Report-${now}, ${time}.pdf`);
  };

  const printDocument = (e, orderId) => {
    e.preventDefault();
    console.log(orderId);

    const reportName = "Indent Issue Report";

    InventoryInstance.get(`indentIssue/fetchByIndentId/${orgId}/${orderId}`)

      .then((res) => {
        generatePdf(reportName, res.data, staff);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const initialValue = 0;
  const getSum = (arr) => {
    if (Array.isArray(arr)) {
      return arr.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );
    }
  };

  const CheckError = (e, childData) => {
    e.preventDefault();
    errorMsg(childData);
    console.log(childData, "childData");
  };

  const deleted = (deletedData) => {
    setDeletedData(deletedData);
  };

  const getlength =
    overAllData &&
    overAllData.purchaseOrderDetailsDtos?.map((d, index) =>
      getSum(d.entryDetDtoSet?.map((a) => a.entryDetailsDrugDtos.length))
    );

  return (
    <>
      <div className={classes.root}>
        <NotificationSuccess />
        {isEditMode ? (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={0} style={{ marginTop: "1%" }}>
              <Grid items xs={12} align="center">
                <IndentIssueForm
                  viewMood={viewMood}
                  pendingApproval={pendingApproval}
                  ref={complexRef}
                  control={control}
                  editData={editData}
                  viewData={viewData}
                  watch={watch}
                  setError={setError}
                  reset={reset}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors.complex}
                  uncheckHistory={uncheckHistory}
                  complexMedicines={complexMedicines}
                  setComplexMedicines={setComplexMedicines}
                  CheckError={CheckError}
                  deleted={deleted}
                />
              </Grid>

              <Grid items xs={12}>
                <span>Total Amount: &#8377; &nbsp;&nbsp;</span>
                <TextField
                  {...register(`totalBillAmount`)}
                  placeholder="Total Amount"
                  maxRows={4}
                  size="small"
                  disabled
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  // value={totalBillAmount}
                  variant="outlined"
                />
                <span style={{ float: "right" }}>
                  {viewMood ? (
                    <Button
                      onClick={() => {
                        reset(defaultValues);
                        setIsEditMode(false);
                        setViewMood(false);
                      }}
                      variant="contained"
                      style={{ margin: "5px" }}
                    >
                      Close
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={handleSubmit((data) => onFormSubmit(data))}
                        variant="contained"
                        disabled={errosMsg || isSubmitting || !isValid}
                        style={{ margin: "5px" }}
                        color="primary"
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEditMode(false);
                          setViewMood(false);
                          reset(defaultValues);
                        }}
                        variant="contained"
                        style={{ margin: "5px" }}
                      >
                        Close
                      </Button>
                    </>
                  )}
                </span>
              </Grid>
            </Grid>
          </form>
        ) : null}
      </div>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ marginTop: "1%" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10} align="right">
          <Controls.Input
            style={{ maxWidth: 300 }}
            label="Search"
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
          <TableContainer style={{ maxHeight: "570px" }}>
            {loading && <LinearProgress />}
            <Table size="small" stickyHeader>
              <TblHead />
              {recordsAfterPagingAndSorting()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{item.srNo}</StyledTableCell>
                      <StyledTableCell>{item.indentNo}</StyledTableCell>
                      <StyledTableCell>
                        <div
                          style={{
                            maxWidth: 250,
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.issueDet?.map((subitem) => (
                            <span
                              href="#"
                              style={{
                                marginRight: "10px",
                                textDecoration: "underline",
                                color: "#136657",
                                fontWeight: 400,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                reset(defaultValues);
                                setIsEditMode(true);
                                setViewMood(true);
                                setPendingApproval(false);
                                fetchBillData(item.indentId, subitem.issueId);
                              }}
                            >
                              {subitem.issueNo},
                            </span>
                          ))}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{item.expectedDate}</StyledTableCell>
                      <StyledTableCell>{item.billAmount}</StyledTableCell>
                      {/* <StyledTableCell align="center">
                      {item.departmentId}
                    </StyledTableCell> */}
                      <StyledTableCell>{item.reqFroStore}</StyledTableCell>
                      <StyledTableCell>
                        {item.reIndent === true ? "Yes" : "No"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Controls.Button
                          className={
                            item.issueStatus === "Pending"
                              ? classes.btnPending
                              : classes.btnActive
                          }
                          text={item.issueStatus}
                        ></Controls.Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.issueStatus == "Pending" ? (
                          item?.issueDet.length === 0 ? (
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                reset(defaultValues);
                                setIsEditMode(true);
                                setViewMood(false);
                                handleEdit(e, item.indentId);
                              }}
                            >
                              <FontAwesomeIcon
                                title="Edit"
                                style={{ color: "#ff6600", fontSize: "13px" }}
                                icon={faEdit}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                reset(defaultValues);
                                setIsEditMode(true);
                                setViewMood(false);
                                handleEditPending(e, item.indentId);
                              }}
                              style={{ zIndex: 0 }}
                            >
                              <FontAwesomeIcon
                                title="Edit"
                                style={{ color: "#ff6600", fontSize: "13px" }}
                                icon={faEdit}
                              />
                            </IconButton>
                          )
                        ) : (
                          <>
                            <IconButton
                              color="secondary"
                              onClick={(e) => {
                                reset(defaultValues);
                                handleViewRecord(e, item.indentId);
                              }}
                            >
                              <FontAwesomeIcon
                                title="view "
                                style={{ color: "#087683", fontSize: "13px" }}
                                icon={faEye}
                              />
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                reset(defaultValues);

                                printDocument(e, item.indentId);
                              }}
                            >
                              <FontAwesomeIcon
                                title="views"
                                style={{
                                  color: "#1957b3",
                                  fontSize: "13px",
                                  zIndex: 0,
                                }}
                                icon={faFilePdf}
                              />
                            </IconButton>
                          </>
                        )}
                      </StyledTableCell>
                      {/* <StyledTableCell>

                      {item.issueStatusString === "Issued" ?(
                      
                      <IconButton
                        color="secondary"
                        onClick={(e) => {reset(defaultValues); handleViewRecord(e, item.indentId)}}
                      >
                        <FontAwesomeIcon
                          title="view "
                          style={{ color: "#087683", fontSize: "13px" }}
                          icon={faEye}
                        />
                      </IconButton>) : (
                      
                      <IconButton
                        color="primary"
                        onClick={(e) => {reset(defaultValues);   setIsEditMode(true);
                          setViewMood(false); handleEdit(e, item.indentId)}}
                      >
                        <FontAwesomeIcon
                          title="Edit"
                          style={{ color: "#ff6600", fontSize: "13px" }}
                          icon={faEdit}
                        />
                      </IconButton>)} 
                     
                    </StyledTableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <StyledTableCell colSpan={9} align="center">
                      No Records Found
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TblPagination />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        justifyContent="center"
        maxWidth
      >
        <DialogTitle>Indent Issue</DialogTitle>
        <DialogContent>
          <div id="toPrint">
            <Grid container spacing={0} id="print">
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <TableContainer>
                  <Table
                    size="small"
                    className={classes.table}
                    aria-label="simple table"
                    id="data-table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Drug Type</StyledTableCell>
                        <StyledTableCell>Drug Name</StyledTableCell>
                        <StyledTableCell>UOM</StyledTableCell>
                        <StyledTableCell>Single Unit</StyledTableCell>
                        <StyledTableCell>Single Unit Contains</StyledTableCell>
                        {/* <StyledTableCell>Required Qty</StyledTableCell> */}
                        <StyledTableCell>Approved Qty</StyledTableCell>
                        <StyledTableCell>Cost Per Unit (₹)</StyledTableCell>                   
                        <StyledTableCell>Issue Date & Time</StyledTableCell>
                        <StyledTableCell>Mfg. Date</StyledTableCell>
                        <StyledTableCell>Exp. Date</StyledTableCell>
                        <StyledTableCell>Batch No</StyledTableCell>
                        <StyledTableCell>Issued Qty</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {overAllData?.length !== 0 ? (
                      <TableBody>
                        {overAllData?.indentRequestDetailsDtos?.map(
                          (row, index) => (
                            <>
                              <TableRow key={row.id} align="center">
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.drugTypeName}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.drugName}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.uomName}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.singleUnit}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.singleUnitContains}
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  
                                  // rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.requiredQty}
                                </StyledTableCell> */}
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.approvedQty}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  ₹ {row.costPerUnit}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={row.detDrugDto.length + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.issueDate}
                                </StyledTableCell>
                              </TableRow>

                              {row.detDrugDto?.map((f, index) => (
                                <TableRow>
                                  <StyledTableCell
                                    style={{
                                      border: "1px solid grey",
                                    }}
                                  >
                                    {moment(f.manufactureDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    style={{
                                      border: "1px solid grey",
                                    }}
                                  >
                                    {moment(f.expiryDate).format("DD-MM-YYYY")}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    style={{
                                      border: "1px solid grey",
                                    }}
                                  >
                                    {f.serialNo}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    style={{
                                      border: "1px solid grey",
                                    }}
                                  >
                                    {f.issuedQty}
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            </>
                          )
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <StyledTableCell colSpan={3} align="center">
                            No Data found
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "end" }}>
          <Button
            onClick={(e) => {
              handleClose();
            }}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const IndentIssue = React.forwardRef(Inner);
export default IndentIssue;
