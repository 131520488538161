import { FETCH_ALL_DISEASES } from "../actions/Type";

const initialState = {
  allDiseases: [],
};

export const apiData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_DISEASES:
      return {
        ...state,
        allDiseases: action.payload,
      };

    default:
      return state;
  }
};
