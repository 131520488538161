import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  Button,
  TableCell,
  TableRow,
  Typography,
  Grid,
  FormControl,
  Paper,
  LinearProgress,
  Select,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Controls from "../../../component/controls/Controls";
import { useForm } from "../../../component/useForm";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_SNACKBAR } from "../../../actions/Type";
import useTable from "../../../component/useTable";
import IconButton from "@material-ui/core/IconButton";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { restrict } from "../../../component/restrict";
import "../style.css";
import axiosInstance from "../../../api/Instance";
import axiosInvInstance from "../../../api/InventoryInstance";

// import NotificationSuccess from "../../component/Notification/NotificationSuccess";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: 16,
    marginBottom: 16,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
  body: {
    fontSize: 14,
    // padding: 6,
  },
}))(TableCell);

const initialFValues = {
  productType: "",
  medicineTypeId: "",
  requiredQty: "",
  mfgDate: "",
  expDate: "",
  batchNo: "",

  // umo: ''
};
let headCells = [
  // { id: '#,',    label: 'Serial No.',},
  { id: "drugType", label: "Drug Type" },
  { id: "drugName", label: "Drug Name" },
  { id: "quantity", label: "Quantity" },
  { id: "unit", label: "UOM" },
  { id: "manufacturingDate", label: "Mfg. Date" },
  { id: "expiryDate", label: "Exp. Date" },
  { id: "batchNo", label: "Batch No." },
  { id: "actions", label: "Actions", disableSorting: true },
];

function DrugSelection(props) {
  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);
  const orgId = useSelector((state) => state.loginReducer.staffLogin.orgId);
  const dispatch = useDispatch();
  const staffId = useSelector((state) => state.loginReducer.staffLogin.staffId);
  const storeId = useSelector((state) => state.loginReducer.staffLogin.storeId);

  const [valueUoM, SetvalueUoM] = useState("");
  const [valueUoMId, SetvalueUoMId] = useState("");
  const { handleSubmit, pristine, reset, submitting } = props;
  const classes = useStyles();
  const [pointerEvent, setPointerNone] = React.useState(false);
  const [dataToUpdate, SetdataToUpdate] = React.useState(false);
  const [dataToUpdateDataID, SetdataToUpdateDataID] = React.useState(false);
  const [DrugId, setdrugid] = React.useState([]);
  const myOwnColor = "#0B9CAB";
  const [DrugName, setdrugName] = useState([]);
  const [subStore, setStore] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);
  const handleSearch = (e) => {
    let value = e.target.value;
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        }
        return items.filter(
          (x) =>
            x.drugTypeName?.toLowerCase().includes(value.toLowerCase()) ||
            x.drugName?.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("mfgDate" in fieldValues) {
      temp.mfgDate =
        fieldValues.mfgDate.length != 0 ? "" : "This field is required.";
    }
    if ("expDate" in fieldValues) {
      temp.expDate =
        fieldValues.expDate.length != 0 ? "" : "This field is required.";
    }
    if ("batchNo" in fieldValues) {
      temp.batchNo =
        fieldValues.batchNo.length != 0
          ? /^[^\s]+(\s.*)?$/.test(fieldValues.batchNo)
            ? ""
            : "This field can't contain blank only space"
          : "This field is required.";
    }
    if ("requiredQty" in fieldValues) {
      temp.requiredQty =
        fieldValues.requiredQty.length != 0 ? "" : "This field is required.";
    }
    if ("medicineTypeId" in fieldValues) {
      temp.medicineTypeId =
        fieldValues.medicineTypeId != 0 ? "" : "This field is required.";
    }
    if ("productType" in fieldValues) {
      temp.productType =
        fieldValues.productType.length != 0 ? "" : "This field is required.";
    }
    if ("store" in fieldValues) {
      temp.store =
        fieldValues.store.length != 0 ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const resetFormData = () => {
    values.productType = "";
    values.medicineTypeId = "";
    values.requiredQty = "";
    values.mfgDate = "";
    values.expDate = "";
    values.batchNo = "";

    SetvalueUoMId("");
    SetvalueUoM("");
    setErrors({});
    SetdataToUpdate(false);
    setPointerNone(false);
  };

  const fetchDataDrugid = () => {
    axiosInstance
      .get("medicineType/getAllMedicineTypeBySom/" + somId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setdrugid(data);
      });
  };

  useEffect(() => {
    fetchDataDrugid();
    setFilteredProductName();
    fetchDataLocation();
  }, []);

  const setFilteredProductName = (e, id) => {
    values.medicineTypeId = "";
    setdrugName(null);
    SetvalueUoM("");

    if (id) {
      axiosInstance
        .get("medicineName/getByMedTypeNOrgId/" + id + "/" + orgId)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.length != 0) {
            setdrugName(data);
          } else {
            values.medicineTypeId = "";
          }
        });
    } else {
      setdrugName(null);
      values.medicineTypeId = "";
    }
  };

  // for uom

  const setFilteredUOM = (e, id) => {
    e.preventDefault();
    var datafilterumo = DrugName.filter((datais) => {
      if (datais.id == id) {
        return datais;
      }
    });
    SetvalueUoM(datafilterumo[0].uomName);
    SetvalueUoMId(datafilterumo[0].uomId);
  };

  const fetchDataLocation = () => {
    setLoading(true);
    axiosInvInstance
      .get("stockEntry/fetch/" + orgId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        var mydatais = data.sort((a, b) => a.id - b.id);
        setRecords(mydatais);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmit1 = (e, id) => {
    setErrors({});
    SetdataToUpdateDataID(id);
    window.scroll(0, 0);
    var drugDataToUpdate = records.filter((datais) => {
      if (datais.id == id) {
        return datais;
      }
    });

    SetdataToUpdate(true);

    setFilteredProductName(e, drugDataToUpdate[0].drugTypeId);
    values.productType = drugDataToUpdate[0].drugTypeId;
    values.medicineTypeId = drugDataToUpdate[0].drugNameId;
    values.requiredQty = drugDataToUpdate[0].quantity;

    values.batchNo = drugDataToUpdate[0].batchNo;
    setRecordForEdit(values);

    if (drugDataToUpdate[0].manufactureDate) {
      var date = new Date(drugDataToUpdate[0].manufactureDate);
      var dateString = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      values.mfgDate = dateString;

      var expiry_date = new Date(drugDataToUpdate[0].expiryDate);
      var dateString_approve = new Date(
        expiry_date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      values.expDate = dateString_approve;
    } else {
      values.mfgDate = "";
      values.expDate = "";
    }
    SetvalueUoM(drugDataToUpdate[0].uomName);

    SetvalueUoMId(drugDataToUpdate[0].uomId);
  };

  useEffect(() => {}, [recordForEdit]);

  const updateSubmitData = (e) => {
    e.preventDefault();
    if (validate()) {
      const drugData = {
        id: dataToUpdateDataID,
        drugTypeId: values.productType,
        drugNameId: values.medicineTypeId,
        organizationId: orgId,
        quantity: values.requiredQty,
        manufactureDate: values.mfgDate,
        expiryDate: values.expDate,
        batchNo: values.batchNo,
        createdBy: staffId,
        uomName: valueUoMId,
        storeId: storeId,
      };

      axiosInvInstance
        .post(`stockEntry/save`, drugData)
        .then(async (response) => {
          const dbresponse = await response.data;
          if (dbresponse === "success") {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "success",
                msg: "Stock entry updated successfully",
                openSnackbar: true,
              },
            });
            fetchDataLocation();
            resetFormData();
          } else {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "error",
                msg: "Something went wrong",
                openSnackbar: true,
              },
            });
          }
        });
    }
  };

  const submitData = (e) => {
    e.preventDefault();
    if (validate()) {
      const drugData = {
        drugTypeId: values.productType,
        drugNameId: values.medicineTypeId,
        organizationId: orgId,
        quantity: values.requiredQty,
        manufactureDate: values.mfgDate,
        expiryDate: values.expDate,
        batchNo: values.batchNo,
        createdBy: staffId,
        uomName: valueUoMId,
        storeId: storeId,
      };

      axiosInvInstance
        .post(`stockEntry/save`, drugData)
        .then(async (response) => {
          const dbresponse = await response.data;
          if (dbresponse === "success") {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "success",
                msg: "Stock entry added successfully",
                openSnackbar: true,
              },
            });
            fetchDataLocation();
            resetFormData();
          } else {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "error",
                msg: "Something went wrong",
                openSnackbar: true,
              },
            });
          }
        });
    }
  };
  const disablesPast = () => {
    var today = new Date();
    var mm = today.getMonth() + 1;
    var dd = today.getDate();
    var yy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm.toString();
    }
    if (dd < 10) {
      dd = "0" + dd.toString();
    }
    return yy + "-" + mm + "-" + dd;
  };

  const setMaxYear = () => {
    var today = new Date();
    var mm = today.getMonth() + 1;
    var dd = today.getDate();
    var yy = today.getFullYear() + 10;
    if (mm < 10) {
      mm = "0" + mm.toString();
    }
    if (dd < 10) {
      dd = "0" + dd.toString();
    }
    return yy + "-" + mm + "-" + dd;
  };

  const checkUpdate = (e, val) => {
    e.preventDefault();
    if (dataToUpdate) {
      const checkDuplicateUpdate = records.findIndex(
        (item) => item.drugNameId === +val
      );
      if (checkDuplicateUpdate !== -1) {
        setPointerNone(true);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "record already exits",
            openSnackbar: true,
          },
        });
      } else {
        setPointerNone(false);
      }
    }
  };

  const fetchStockStore = () => {
    axiosInvInstance
      .get(`indentRequest/fetchStore/${orgId}/${staffId}`)
      .then((res) => {
        setStore(res?.data);
      })
      .catch(() => {
        console.log("error");
        // dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", msg: "Something Went Wrong", openSnackbar: true } });
      });
  };

  React.useEffect(() => {
    fetchStockStore();
  }, []);

  return (
    <>
      <Paper className={classes.pageContent}>
        <form
          noValidate
          onSubmit={dataToUpdate ? updateSubmitData : submitData}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={8} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography align="center">
                    Drug Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Select
                      native
                      name="productType"
                      value={values.productType}
                      onChange={(e) => {
                        handleInputChange(e);
                        setFilteredProductName(e, e.target.value);
                      }}
                      error={!!errors.productType}
                    >
                      <option value="" disabled selected>
                        Choose Product Type
                      </option>
                      {DrugId?.map((p) => (
                        <option value={p?.id}>{p?.typeName}</option>
                      ))}
                    </Select>
                    <FormHelperText error={!!errors.productType}>
                      {errors.productType}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography align="center">
                    Drug Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Select
                      native
                      name="medicineTypeId"
                      value={values.medicineTypeId}
                      onChange={(e) => {
                        handleInputChange(e);
                        checkUpdate(e, e.target.value);
                        setFilteredUOM(e, e.target.value);
                      }}
                      error={!!errors.medicineTypeId}
                    >
                      <option value="" selected>
                        Choose Product Name
                      </option>
                      {DrugName?.map((p) => (
                        <option
                          style={{
                            backgroundColor: p.isEssential ? "#dafed6" : "#fff",
                          }}
                          value={p?.id}
                        >
                          {p?.MedicineName}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText error={!!errors.medicineTypeId}>
                      {errors.medicineTypeId}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography align="center" id={classes.label}>
                    UoM
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Controls.Input
                    name="umo"
                    type="text"
                    value={valueUoM}
                    disabled
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography align="center">
                    Mfg. Date <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Controls.Input
                    name="mfgDate"
                    type="date"
                    value={values.mfgDate}
                    onChange={handleInputChange}
                    onKeyDown={(e) => e.preventDefault()}
                    error={errors.mfgDate}
                    // inputProps={{ min: 10, max: 50 }}
                    inputProps={{
                      max: disablesPast(),
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography align="center">
                    Exp. Date <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Controls.Input
                    name="expDate"
                    type="date"
                    // minDate={disablesPast}
                    value={values.expDate}
                    onChange={handleInputChange}
                    error={errors.expDate}
                    onKeyDown={(e) => e.preventDefault()}
                    inputProps={{
                      min: disablesPast(),
                      max: setMaxYear(),
                    }}
                    // onInput={e => {
                    //   restrict.digits(e);
                    // }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography align="center">
                    Quantity <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Controls.Input
                    name="requiredQty"
                    value={values.requiredQty}
                    onChange={handleInputChange}
                    error={errors.requiredQty}
                    onInput={restrict.number}
                    inputProps={{
                      maxLength: 7,
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography align="center" id={classes.label}>
                    Batch No.<span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Controls.Input
                    name="batchNo"
                    type="text"
                    value={values.batchNo}
                    onChange={handleInputChange}
                    placeholder="Serial/batchNo"
                    onInput={restrict.batchNo}
                    inputProps={{ maxLength: 20 }}
                    error={errors.batchNo}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                type="submit"
                disabled={
                  JSON.stringify(values) === JSON.stringify(recordForEdit)
                }
                style={{
                  backgroundColor: dataToUpdate ? "#ff6600" : myOwnColor,
                  color:
                    JSON.stringify(values) === JSON.stringify(recordForEdit)
                      ? ""
                      : "#fff",
                  pointerEvents: pointerEvent ? "none" : "unset",
                }}
              >
                {dataToUpdate ? "Update" : "Submit"}
              </Button>
            </Grid>
            <Grid item xs={6} align="left">
              <Button
                variant="contained"
                disabled={pristine || submitting}
                onClick={resetFormData}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={8} align="right">
          <Controls.Input
            label="Search"
            style={{ maxWidth: 300 }}
            placeholder="Product Type and Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <TableContainer style={{ maxHeight: "570px" }}>
            {loading && <LinearProgress />}
            <Table size="small" stickyHeader>
              <TblHead />
              {recordsAfterPagingAndSorting()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item, index) => (
                    <TableRow key={item.id}>
                      {/* <StyledTableCell>{item.quantity}</StyledTableCell> */}
                      <StyledTableCell>{item.drugTypeName}</StyledTableCell>
                      <StyledTableCell>{item.drugName}</StyledTableCell>
                      <StyledTableCell>{item.quantity}</StyledTableCell>
                      <StyledTableCell>{item.uomName}</StyledTableCell>
                      <StyledTableCell>
                        {item.manufactureDateString}
                      </StyledTableCell>
                      <StyledTableCell>{item.expiryDateString}</StyledTableCell>
                      <StyledTableCell>{item.batchNo}</StyledTableCell>
                      <StyledTableCell>
                        <IconButton onClick={(e) => handleSubmit1(e, item.id)}>
                          <FontAwesomeIcon
                            title="Edit"
                            icon={faEdit}
                            style={{ color: "#ff6600", fontSize: "13px" }}
                          />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <StyledTableCell colSpan={7} align="center">
                      No Records Found
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TblPagination />
        </Grid>
      </Grid>
    </>
  );
}

export default DrugSelection;

// TR101/Err1324/30Sep23: Fixed==> Decimal value also should be allowed.
