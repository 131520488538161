import {
  FETCH_MEDICINE_TYPE,
  FETCH_MEDICINE_NAME,
  FETCH_VEHICLE_NAME,
  FETCH_DRUG_INTERVALS,
  FETCH_UOM,
  FETCH_SOM,
  FETCH_ALL_VEHICLE_NAME,
  FETCH_MEDICINE_NAME_BY_SOM,
  FETCH_ALL_MEDICINE_NAME_BY_SOM,
  FETCH_SINGLE_MEDICINE_TYPE,
} from "../actions/Type";

const initialState = {
  simpleMedicineTypes: [],
  simpleMedicineNames: [],
  vehicleName: [],
  allVehicleName: [],
  drugIntervals: [],
  uom: [],
  som: [],
  medicineNameBySom: [],
  allMedicineNameBySom: [],
  singleMedicineTypes: [],
};

const SimpleMedicineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEDICINE_TYPE:
      return {
        ...state,
        simpleMedicineTypes: action.payload,
      };

    case FETCH_MEDICINE_NAME:
      return {
        ...state,
        simpleMedicineNames: action.payload,
      };
    case FETCH_ALL_VEHICLE_NAME:
      return {
        ...state,
        allVehicleName: action.payload,
      };
    case FETCH_VEHICLE_NAME:
      return {
        ...state,
        vehicleName: action.payload,
      };
    case FETCH_UOM:
      return {
        ...state,
        uom: action.payload,
      };

    case FETCH_MEDICINE_NAME_BY_SOM:
      return {
        ...state,
        medicineNameBySom: action.payload,
      };
      case FETCH_ALL_MEDICINE_NAME_BY_SOM:
        return {
          ...state,
          allMedicineNameBySom: action.payload,
        };

    case FETCH_SOM:
      return {
        ...state,
        som: action.payload,
      };
    case FETCH_DRUG_INTERVALS:
      return {
        ...state,
        drugIntervals: action.payload,
      };
    case FETCH_SINGLE_MEDICINE_TYPE:
      return {
        ...state,
        singleMedicineTypes: action.payload,
      };
    default:
      return state;
  }
};

export default SimpleMedicineReducer;
