import { SHOW_SNACKBAR } from "../actions/Type";
// the show snack bar action Reducer
// pass the action in payload properties this ===> ( msg:"you Message",type:"error/success/warming",openSnackbar:false/true})
const initialState = { msg: "", type: "error", openSnackbar: false };

export function showSnackbar(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return { ...action.payload };
    default:
      return state;
  }
}
