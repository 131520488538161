import { Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import ahmis from "../../src/assets/ahmis.png";
import leaf from "../../src/assets/leaf.png";
import configData from "../../src/config.json";
import { CloseButton } from "./utils/Button";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
    maxWidth: "100%",
    margin: "auto",

    [theme.breakpoints.down("md")]: {
      border: "1.5px solid black",
    },
    [theme.breakpoints.down("sm")]: {
      border: "1.5px solid black",
    },
  },
}));

export default function RadioInvestigation(props) {
  const {open, onClose, show, setShow, data } = props
  let componentRef = useRef();
  const classes = useStyles();
  const [age, setAge] = useState("");

  const staffLogin = useSelector((state) => state.loginReducer.staffLogin);
  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const selectedPatient = useSelector(
    (state) => state.consultationReducer.patient
  );
  const registrationID = useSelector(
    (state) => state.consultationReducer.registrationId
  );

  const radiologyInvestigation = useSelector(
    (state) => state.labMasterReducer.radiologyInvestigation
  );

  React.useEffect(() => {
    if (radiologyInvestigation?.length != 0 && radiologyInvestigation !== "EMPTY") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [radiologyInvestigation]);


  React.useEffect(() => {
    console.log("selectedPatient", data);
    (async () => {
      const date = moment(data?.dob).format("YYYY-MM-DD");
      const res = await Axios.get(
        configData.MASTER_SERVICE_URL + `age/calculate/${date}`
      );
      if (res.data[0] === 0 && +res.data[1] === 0 && +res.data[2] == 0) {
        const finalAge = "";
        setAge(finalAge);
      } else if (res.data[0] === 0) {
        const finalAge = `${res.data[0]} Years ${res.data[1]} Months ${res.data[2]} Days`;
        console.log("finalAge", finalAge);
        setAge(finalAge);
      } else {
        const finalAge = `${res.data[0]} Years`;
        console.log("finalAge", finalAge);
        setAge(finalAge);
      }
    })();
  }, [data]);

  const handleClosePrint = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClosePrint}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <div
          id="print"
          ref={(el) => (componentRef = el)}
          style={{ backgroundColor: "white" }}
        >
          <DialogContent>
            <Grid container spacing={0}>
              <Grid item xs={3} sm={3} md={1}>
                <img
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                  src={ahmis}
                  alt="ahmis"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={9}>
                <Grid container spacing={0}>
                  <Grid item xs={12} align="center">
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {staffLogin.orgName}
                    </span>
                  </Grid>
                  <Grid item xs={12} align="center"></Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} sm={3} md={2} align="right">
                <img
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                  src={leaf}
                  alt="leaf"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography style={{ fontSize: "14px" }}>RADIOLOGY REPORT</Typography>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6} align="left">
                Name : {data.patientName}
              </Grid>
              <Grid item xs={6} align="right">
                OP ID : {registrationID}
              </Grid>
              <Grid item xs={6} align="left">
                Date Of Birth :
                {data.dob !== undefined &&
                  moment(data.dob).format("DD-MM-YYYY")}
              </Grid>
              <Grid item xs={6} align="right">
                Gender : {data?.genderName || data?.gender}
              </Grid>
              <Grid item xs={6} align="left">
                Age : {age  || data?.age}
              </Grid>
              <Grid item xs={6} align="right">
                Phone: {data.phone || data?.contact}
              </Grid>
              {show ? (<>
                <Grid item xs={6} align="left">
                Consultation Date : { radiologyInvestigation?.consultedDate !== "NA" ? moment(radiologyInvestigation?.consultedDate).format("DD-MM-YYYY") : radiologyInvestigation?.consultedDate}
              </Grid>
              <Grid item xs={6} align="right">
              Conducted By: {radiologyInvestigation?.approvedBy}
              </Grid>
              </>):null} 
            </Grid>

            <br />
            {show ? (
              <>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow
                            style={{
                              backgroundColor: myOwnColor,
                              paddingLeft: "10px",
                            }}
                          >
                            <TableCell align="left" style={{ color: "#fff" }}>
                              <b>Body Part</b>
                            </TableCell>
                            <TableCell align="left" style={{ color: "#fff" }}>
                              <b>Remarks</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {radiologyInvestigation &&
                            radiologyInvestigation?.Radiology?.map((row, id) => (
                              <>
                                <Typography
                                  style={{
                                    padding: "10px 0 0 15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Body Part Scanned {row.bodyPartName}
                                </Typography>
                                {row.views?.map((row1, id1) => (
                                  <>
                                    <TableRow key={row1?.uniqueTestId}>
                                    
                                      <TableCell align="left">
                                        {row1?.labTestName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row1.comments}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}

                              </>

                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <p align="center">
                  RADIOLOGY REPORT IS NOT AVAILABLE
                </p>
              </>
            )}
          </DialogContent>
        </div>

        <DialogActions>
          <CloseButton onClick={handleClosePrint}>Close</CloseButton>
          {show ? (
            <>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0C51B8", color: "white" }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef}
              />
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}
