import { showSnackbar, spinnerHide } from "./Notification";
import ipdOpdInstance from "../api/IpdOpdInstance"

import {
  FETCH_COMPLIANCE_HISTORY,
  FETCH_PROGNOSIS_HISTORY,
  FETCH_PROGNOSIS,
} from "./Type";

export const fetchComplianceHistory = (regId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`compliance/getComplianceHistory/${regId}`);
    dispatch({
      type:  FETCH_COMPLIANCE_HISTORY,                  
      payload: res.data
    });
  } catch (error) {
    console.log(error)
  }
};

export const fetchPrognosisHistory = (regId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`prognosis/getPrognosisHistory/${regId}`);
    dispatch({
      type:  FETCH_PROGNOSIS_HISTORY,                  
      payload: res.data
    });
  } catch (error) {
    console.log(error)
  }
};

export const fetchLatestComplaints = (regId) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`consultation/getLatestComplaints/${regId}`);    
    dispatch({
      type: FETCH_PROGNOSIS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
