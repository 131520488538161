import { SELETED_DIAGNOSIS_TAB } from "../actions/Type";


const selectedValue = "icd 10";


const selectedIcdTab = (state = selectedValue, action) => {
    switch (action.type) {
        case SELETED_DIAGNOSIS_TAB:
            return action.payload

        default:
            return state;
    }
};

export default selectedIcdTab;