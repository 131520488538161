import { Grid, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../actions/LoginAction";
import { encryptFn } from "../Login";
import Controls from "../controls/Controls";
import { Form, useForm } from "../useForm";

const useStyles = makeStyles((theme) => ({
  input: {
    height: 35,
    width: "100%",
  },
}));
function ChangePwd(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const userName = useSelector((state) => state.loginReducer.staffLogin.username);
  const initialFValues = {
    id: 0,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    username: userName,
    currentPassword: "",
    password: "",
  };

  const validateField = (oldPassword) => {
    if (!oldPassword) {
      return "This field is required";
    } else if (oldPassword && oldPassword.length < 5) {
      return "Please Enter more than 5 character";
    }
    return "";
  };
  const validateFieldPassword = (value, oldPassword) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!value) {
      return "this field is required";
    } else if (value === oldPassword) {
      return "Password is same Old Password. Re-Enter";
    } else if (value && value.length < 8) {
      return "Minimum 8 characters required";
    } else if (value && value.length > 30) {
      return "Maximum 30 characters allowed";
    } else if (value && !re.test(value)) {
      return "Password must contain Upper,Lower,Number,Special character";
    }
    return "";
  };
  const validatePassword = (newPassword, confirmPassword) => {
    if (!confirmPassword) {
      return "This field is required";
    } else if (newPassword !== confirmPassword) {
      return "Password Mismatch. Re-Enter";
    }
    return "";
  };
  const validatePassword1 = (oldPassword) => {
    if (!oldPassword) {
      return "This field is required";
    }
    // else if (newPassword !== confirmPassword) {
    //   return "Password Mismatch. Re-Enter";
    // }
    return "";
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("oldPassword" in fieldValues) {
      temp.oldPassword = validatePassword1(fieldValues.oldPassword);
    }
    if ("newPassword" in fieldValues) {
      temp.newPassword = validateFieldPassword(fieldValues.newPassword, fieldValues.oldPassword);
    }
    if ("confirmPassword" in fieldValues && "newPassword" in fieldValues) {
      temp.confirmPassword = validatePassword(fieldValues.newPassword, fieldValues.confirmPassword);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setValues({
      ...values,
      username: userName,
    });
  }, [userName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setValues({
        ...values,
        passwordChange: true,
      });
      const encryptedUsername = encryptFn(values.username);
      const encryptedOldPassword = encryptFn(values.oldPassword);
      const encryptedNewPassword = encryptFn(values.newPassword);
      const data = {
        username: encryptedUsername,
        currentPassword: encryptedOldPassword,
        password: encryptedNewPassword,
      };
      console.log("data:", data);
      dispatch(updatePassword(data)).then((res) => {
        setValues(initialFValues);
        if (res) {
          props.onClose();
        }
      });
    }
  };

  return (
    <Form>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
          <Grid container>
            <Grid item xs={false} sm={2} md={2} lg={2} xl={2} align="center" />
            <Grid item xs={12} sm={8} md={8} lg={8} xl={12} align="center">
              <Controls.Input
                label="Old Password *"
                name="oldPassword"
                type={showPassword1 ? "text" : "password"}
                value={values.oldPassword}
                onChange={handleInputChange}
                error={errors.oldPassword}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword1} onMouseDown={handleMouseDownPassword1}>
                        {showPassword1 ? (
                          <Visibility style={{ fontSize: "20px" }} />
                        ) : (
                          <VisibilityOff style={{ fontSize: "20px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Controls.Input
                label="New Password *"
                name="newPassword"
                type={showPassword ? "text" : "password"}
                value={values.newPassword}
                onChange={handleInputChange}
                error={errors.newPassword}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? (
                          <Visibility style={{ fontSize: "20px" }} />
                        ) : (
                          <VisibilityOff style={{ fontSize: "20px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Controls.Input
                label="Confirm Password *"
                name="confirmPassword"
                type={showPassword2 ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleInputChange}
                error={errors.confirmPassword}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword2}>
                        {showPassword2 ? (
                          <Visibility style={{ fontSize: "20px" }} />
                        ) : (
                          <VisibilityOff style={{ fontSize: "20px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={false} sm={2} md={2} lg={2} xl={2} align="center" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" marginTop="30px">
          <div style={{ alignContent: "center" }}>
            <Controls.Button type="submit" text="Update Password" onClick={handleSubmit} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}

export default ChangePwd;
