import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import NotificationSuccess from "../Notification/NotificationSuccess";
import { restrict } from "../restrict";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%%",
    marginTop: "20px",
  },
  button: {
    border: "1px solid #C4C4C4",
    borderRadius: 20,
    margin: "0px 10px 10px 0px",
    padding: "5px 10px 5px 10px",
  },
  input: {
    height: 35,
    width: 380,
  },
  input1: {
    height: 35,
    width: 50,
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  customInput: {
    [theme.breakpoints.down("lg")]: {
      width: "100% !important",
    },
  },
  icon: {
    color: "#ff6600",
    fontSize: "13px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "13px",
  },
  smallFont: {
    fontSize: "14px",
    color: "#000000",
    opacity: 1,
  },
  errormsg: {
    fontSize: 11,
    color: "red",
  },
}));

function Treatment({
  value: systemicValue,
  onChange: setSystemicValue,
  errormsg,
  setErrormsg,
  errormsgTong,
  setErrormsgTong,
  setDirtyVal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const StaffLogin = useSelector((state) => state.loginReducer.staffLogin);

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  const consultationID = useSelector((state) => state.consultationReducer.patient.consultationId);

  const defaultValues = {
    cardioVascularSystem: "",
    respiratorySystem: "",
    gastroIntestinalSytem: "",
    centralNervousSystem: "",
    motorSystem: "",
    facialDiagEncumbrance: "",
    tongueDiagnosis: "",
    irisDiagLeft: "",
    irisDiagRight: "",
  };

  const schema = yup.object().shape({
    cardioVascularSystem: yup.string().trim(),
    // .typeError("required")
    // .required("required"),
    respiratorySystem: yup
      .string()
      .trim()
      .typeError("required")
      .required("required"),
    gastroIntestinalSytem: yup.string().trim(),
    centralNervousSystem: yup.string().trim(),
    motorSystem: yup.string().trim(),
    facialDiagEncumbrance: yup.string().trim(),
    tongueDiagnosis: yup
      .string()
      .trim()
      .typeError("required")
      .required("required"),

    irisDiagLeft: yup.string().trim(),
    irisDiagRight: yup.string().trim(),
  });

  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    setDirtyVal(isDirty);
  }, [isDirty]);

  return (
    <div className={classes.root}>
      <NotificationSuccess />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={2}>
          <Typography>Cardio Vascular System</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="cardioVascularSystem"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, cardioVascularSystem: e.target.value });
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.cardioVascularSystem : value}
                placeholder="Cardio Vascular System"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography>
            Respiratory System <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="respiratorySystem"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, respiratorySystem: e.target.value });
                  setErrormsg(false);
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.respiratorySystem : value}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder="Respiratory System"
                size="small"
                variant="outlined"
                fullWidth
                // error={!!errors.respiratorySystem}
                // helperText={errors.respiratorySystem?.message}
              />
            )}
          />

          {errormsg ? <Typography className={classes.errormsg}>This field is required</Typography> : null}
        </Grid>
      </Grid>
      <br /> <br />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={2}>
          <Typography> Gastro-intestinal System</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="gastroIntestinalSytem"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, gastroIntestinalSytem: e.target.value });
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.gastroIntestinalSytem : value}
                inputProps={{ maxLength: 50 }}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder="Gastro-intestinal System"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography>Central Nervous System</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="centralNervousSystem"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, centralNervousSystem: e.target.value });
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.centralNervousSystem : value}
                inputProps={{ maxLength: 50 }}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder="Central Nervous System"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>{" "}
      </Grid>
      <br /> <br />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={2}>
          <Typography> Motor System</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="motorSystem"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, motorSystem: e.target.value });
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.motorSystem : value}
                inputProps={{ maxLength: 50 }}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder="Motor System"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography>Facial Diagnosis / Encumbrance</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="facialDiagEncumbrance"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, facialDiagEncumbrance: e.target.value });
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.facialDiagEncumbrance : value}
                inputProps={{ maxLength: 50 }}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder="Facial Diagnosis / Encumbrance"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>{" "}
      </Grid>
      <br /> <br />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={2}>
          <Typography>
            {" "}
            Tongue Diagnosis <span style={{ color: "red" }}>*</span>{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="tongueDiagnosis"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, tongueDiagnosis: e.target.value });
                  setErrormsgTong(false);
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.tongueDiagnosis : value}
                inputProps={{ maxLength: 50 }}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder="Tongue Diagnosis "
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />{" "}
          {errormsgTong ? <Typography className={classes.errormsg}>This field is required</Typography> : null}
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography>Left Iris Diagnosis </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="irisDiagLeft"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, irisDiagLeft: e.target.value });
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.irisDiagLeft : value}
                inputProps={{ maxLength: 50 }}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder="Left Iris Diagnosis"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>{" "}
      </Grid>
      <br /> <br />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={2}>
          <Typography> Right Iris Diagnosis</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="irisDiagRight"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setSystemicValue({ ...systemicValue, irisDiagRight: e.target.value });
                }}
                value={systemicValue?.length !== 0 ? systemicValue?.irisDiagRight : value}
                inputProps={{ maxLength: 50 }}
                onInput={restrict.alphanumerichypenbracketscommaspacedotunderscore}
                placeholder=" Right Iris Diagnosis"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Treatment;
