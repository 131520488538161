

import { Box, Button } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core/styles';
import React from 'react';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    button: {
    backgroundColor:theme.palette.myColor.main,
    color: "#103A4D" ,   
    border:`1px solid ${theme.palette.primary.dark} !important`,
    [theme.breakpoints.down("sm")]: {
      minWidth: 150,
      fontSize:12,      
    },   
    minWidth:220,
     fontFamily:'unset',
     wordWrap:"break-word",
     fontSize:14,
     fontWeight:"bold",
      "&:hover, &:focus": {
      // backgroundColor: theme.palette.primary.light,
      color:"#000"
    }
  },
}));

const MainContent = () => {
  const classes = useStyles();

  return (
<Box style={{ marginTop: 40,display:"flex",justifyContent:"center",alignItems:"center" }}>
    <div style={{marginRight:5}} >
        <Link to="/RegistrationForm" style={{ textDecoration: 'none' }} >
          <Button variant="contained" className={classes.button}>REGISTER TO GET AYUSH ID</Button>
        </Link>
        </div>
      <div  style={{marginLeft:5}}>
        <Link to="/BookAppointment" style={{ textDecoration: 'none' }} >
          <Button variant="contained" className={classes.button} >BOOK APPOINTMENT</Button>
        </Link>
        </div>
    </Box>
  );
}

export default MainContent;

