import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Typography,
    makeStyles
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
    dialog: {
        borderRadius: 0
    },
    button: {
        borderRadius: 0,
        textTransform: "none",
        padding: 5
    },
    logout: {
        borderRadius: 0,
        textTransform: "none",
        padding: 5,
        color: "#fff",
        backgroundColor: 'red',
        "&:hover": {
            backgroundColor: 'red'
        }
    },
    countdown: {
        color: 'red'
    },
    title: {       
            color: '#000 !important'
    },
    dialogButtons: {
        margin: 10
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialog }}
            TransitionComponent={Transition}
        >
            <DialogTitle><Typography className={classes.title}>Session Timeout</Typography> </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    The current session is about to expire in{" "}
                    <span className={classes.countdown}>{countdown}</span> seconds.
                </Typography>
                <Typography variant="body2">{`Would you like to continue the session?`}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogButtons}>
                <Button
                    onClick={onLogout}
                    variant="contained"
                    className={classes.logout}
                >
                    Signout
                </Button>
                <Button
                    onClick={onContinue}
                    color="primary"
                    variant="contained"
                    className={classes.button}
                >
                    Continue Session
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default SessionTimeoutDialog;
