import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root1: {
    backgroundColor: "#FCFAFA",
    width: "96%",
  },
  input: {
    height: 35,
    width: "100%",
    maxWidth: 400,
  },
  ageInput: {
    height: 35,
    width: "100%",
  },
  input1: {
    height: 90,
    width: "100%",
  },
  select: {
    width: "100%",
    maxWidth: 400,
  },
  content: {
    fontSize: 18,
    color: "#033C93",
    fontWeight: "bold",
    marginLeft: "2%",
  },
  nextbtn: {
    fontSize: "15px",
    backgroundColor: "#0B9CAB",
    color: "white",
    Textalign: "right",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 105, 116)",
    },
  },
  btn: {
    margin: "5px",
    backgroundColor: "#E5E8E8",
    color: "black",
    Textalign: "right",
    "&:hover": {
      color: "black",
      backgroundColor: "lightGrey",
    },
  },

  btnSelected: {
    margin: "5px",
    backgroundColor: "rgb(0, 105, 116)",
    color: "white",
    Textalign: "right",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 105, 116)",
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  verifyInput: {
    height: 35,
    width: 180,
  },
  verifyCustomTextField: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },

  label: {
    textAlign: "left",
    [theme.breakpoints.up("lg")]: {
      textAlign: "center",
      paddingTop: "10px",
    },
  },
  emailSelect: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "90%",
      marginLeft: "46px",
    },
  },
  emailLabel: {
    textAlign: "left",
    [theme.breakpoints.up("lg")]: {
      textAlign: "center",
      paddingTop: "10px",
      paddingLeft: "35px",
    },
  },
  actionArrowbutton: {
    width: "20px !important",
    backgroundColor: "#4bb948",
    color: "#fff",
  },
  arrowButton: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  contactInfoPanel: {
    width: "90%",
    margin: "20px auto",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  patientDialogContent: {
    padding: "24px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  orgNameText: {
    fontWeight: "bold",
    fontSize: "14px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  orgAddressText: {
    fontWeight: "bold",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  patientNameLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  barcode: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  detailsPanel: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {},
  },
  ayshLogo: {
    width: "70%",
    height: "max-content",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "auto",
    },
  },
  ayshGridLogo: {
    width: "70%",
    height: "max-content",
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "50px",
    },
  },
  tokenContainer: {
    padding: "24px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  previewActionButtons: {
    position: "sticky",
    bottom: -16,
    right: 0,
    backgroundColor: "#efefef",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const data = [
  {
    resourceType: "Bundle",
    id: "OPConsultation-2202221404861902",
    meta: {
      versionId: "1",
      lastUpdated: "2022-03-23T18:01:51.0979044+05:30",
      profile: [
        "https://nrces.in/ndhm/fhir/r4/StructureDefinition/DocumentBundle",
      ],
      security: [
        {
          system: "http://terminology.hl7.org/CodeSystem/v3-Confidentiality",
          code: "V",
          display: "very restricted",
        },
      ],
    },
    identifier: {
      system: "http://hip.in",
      value: "d3a05510-f965-4e5b-a61a-8ff1a014d50d",
    },
    type: "document",
    timestamp: "2022-03-23T18:01:51.0979044+05:30",
    entry: [
      {
        fullUrl: "Composition/1",
        resource: {
          resourceType: "Composition",
          id: "1",
          meta: {
            versionId: "1",
            lastUpdated: "2022-02-21T18:30:00Z",
            profile: [
              "https://nrces.in/ndhm/fhir/r4/StructureDefinition/OPConsultRecord",
            ],
          },
          language: "en-IN",
          text: {
            status: "generated",
            div:
              '\u003cdiv xmlns\u003d"http://www.w3.org/1999/xhtml" xml:lang\u003d"en-IN" lang\u003d"en-IN"\u003e\u003ch4\u003eNarrative with Details\u003c/h4\u003e\\r\\n      \u003cp\u003e\u003cb\u003eid:\u003c/b\u003e 1\u003c/p\u003e\\r\\n\\t  \u003cp\u003e\u003cb\u003estatus:\u003c/b\u003e active\u003c/p\u003e\\r\\n\\t  \u003cp\u003e\u003cb\u003etype:\u003c/b\u003e Prescription record (Details : {http://snomed.info/sct} code \u0027440545006\u0027 \u003d \u0027Prescription record\u0027)\u003c/p\u003e\\r\\n      \u003cp\u003e\u003cb\u003esubject:\u003c/b\u003e SHUBHAM . Generated Summary: id: 1; Medical Record Number \u003d 1234 (System : {https://ndhm.in/SwasthID}); active; SHUBHAM  ; ph: +917387106250(HOME); gender: male; birthDate: Thu Jan 01 00:00:00 IST 1998\u003c/p\u003e\\r\\n      \u003cp\u003e\u003cb\u003edate:\u003c/b\u003e 2017-05-27T11:46:09+05:30\u003c/p\u003e\\r\\n\\t  \u003cp\u003e\u003cb\u003eauthor:\u003c/b\u003e Dr. SAYLEE DESHMUKH. Generated Summary: id: 1; Medical License number \u003d I-66620-A  (System : {https://ndhm.in/DigiDoc})\u003c/p\u003e\\r\\n      \u003ch3\u003eMedication prescribed for SHUBHAM  issued 9-July 2020\u003c/h3\u003e\\r\\n      \u003cpre\u003eMedicine                                                                   Directions           Instruction\\r\\nAzithromycin (as azithromycin dihydrate) 250 mg oral capsule           1 capsule per day       With or after food      \u003c/pre\u003e\\r\\n\u003cp\u003eInc signed: Dr. SAYLEE DESHMUKH, MD (Medicine)\u003c/p\u003e\u003c/div\u003e',
          },
          identifier: {
            system: "https://ndhm.in/phr",
            value: "d122a9e4-e04e-49e7-a93a-845a146b9041",
          },
          status: "final",
          type: {
            coding: [
              {
                system: "http://snomed.info/sct",
                code: "371530004",
                display: "Clinical consultation report",
              },
            ],
            text: "Clinical Consultation report",
          },
          subject: { reference: "Patient/shubhamanand", display: "SHUBHAM " },
          date: "2022-02-21T18:30:00Z",
          author: [{ reference: "Practitioner/I-66620-A" }],
          title: "OP Consultation record for SHUBHAM ",
          section: [
            {
              title: "OP Consultation record for SHUBHAM ",
              code: {
                coding: [
                  {
                    system: "http://snomed.info/sct",
                    code: "371530004",
                    display: "Clinical consultation report",
                  },
                ],
              },
              entry: [
                {
                  reference: "MedicationRequest/9922358",
                  type: "MedicationRequest",
                },
              ],
            },
          ],
          encounter: { reference: "Encounter/1" },
        },
      },
      {
        fullUrl: "Patient/shubhamanand",
        resource: {
          resourceType: "Patient",
          id: "shubhamanand",
          meta: {
            versionId: "1",
            lastUpdated: "2022-02-15T00:00:00+05:30",
            profile: [
              "https://nrces.in/ndhm/fhir/r4/StructureDefinition/Patient",
            ],
          },
          text: {
            status: "generated",
            div:
              '\u003cdiv xmlns\u003d"http://www.w3.org/1999/xhtml" xml:lang\u003d"en-IN" lang\u003d"en-IN"\u003eSHUBHAM , 24 years, male\u003c/div\u003e',
          },
          identifier: [
            {
              system: "https://healthid.ndhm.gov.in",
              value: "shubhamanand",
              type: {
                coding: [
                  {
                    system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                    code: "MR",
                    display: "Medical record number",
                  },
                ],
              },
            },
          ],
          name: [{ text: "SHUBHAM " }],
          telecom: [{ system: "phone", value: "7387106250", use: "home" }],
          gender: "male",
          birthDate: "1998-01-01",
        },
      },
      {
        fullUrl: "Practitioner/I-66620-A",
        resource: {
          resourceType: "Practitioner",
          id: "I-66620-A",
          meta: {
            versionId: "1",
            lastUpdated: "2018-11-13T00:00:00+05:30",
            profile: [
              "https://nrces.in/ndhm/fhir/r4/StructureDefinition/Practitioner",
            ],
          },
          text: {
            status: "generated",
            div:
              '\u003cdiv xmlns\u003d"http://www.w3.org/1999/xhtml" xml:lang\u003d"en-IN" lang\u003d"en-IN"\u003eDr. SAYLEE DESHMUKH, \u003c/div\u003e',
          },
          identifier: [
            {
              system: "https://doctor.ndhm.gov.in",
              value: "I-66620-A",
              type: {
                coding: [
                  {
                    system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                    code: "MD",
                    display: "Medical record number",
                  },
                ],
              },
            },
          ],
          name: [{ text: "SAYLEE DESHMUKH" }],
        },
      },
      {
        fullUrl: "Encounter/1",
        resource: {
          resourceType: "Encounter",
          id: "1",
          meta: {
            lastUpdated: "2022-03-23T18:01:51.0979044+05:30",
            profile: [
              "https://nrces.in/ndhm/fhir/r4/StructureDefinition/Encounter",
            ],
          },
          text: {
            status: "generated",
            div:
              '\u003cdiv xmlns\u003d"http://www.w3.org/1999/xhtml" xml:lang\u003d"en-IN" lang\u003d"en-IN"\u003eOut Patient Consultation Encounter\u003c/div\u003e',
          },
          identifier: [{ system: "https://ndhm.in", value: "S100" }],
          status: "finished",
          subject: { reference: "Patient/shubhamanand" },
          diagnosis: [{ condition: { reference: "Condition/4409984" } }],
          class: {
            system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
            code: "AMB",
            display: "ambulatory",
          },
        },
      },
      {
        fullUrl: "Condition/63",
        resource: {
          resourceType: "Condition",
          id: "63",
          meta: {
            profile: [
              "https://nrces.in/ndhm/fhir/r4/StructureDefinition/Condition",
            ],
          },
          text: {
            status: "generated",
            div:
              '\u003cdiv xmlns\u003d"http://www.w3.org/1999/xhtml" xml:lang\u003d"en-IN" lang\u003d"en-IN"\u003eABADDHABASHANAM\u003c/div\u003e',
          },
          subject: { reference: "Patient/shubhamanand" },
          code: { text: "ABADDHABASHANAM" },
          clinicalStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-clinical",
                code: "active",
                display: "Active",
              },
            ],
          },
        },
      },
      {
        fullUrl: "Condition/4409984",
        resource: {
          resourceType: "Condition",
          id: "4409984",
          meta: {
            profile: [
              "https://nrces.in/ndhm/fhir/r4/StructureDefinition/Condition",
            ],
          },
          text: {
            status: "generated",
            div:
              '\u003cdiv xmlns\u003d"http://www.w3.org/1999/xhtml" xml:lang\u003d"en-IN" lang\u003d"en-IN"\u003eICD Diagnosis:  Ayush Diagnosis: \u003c/div\u003e',
          },
          subject: { reference: "Patient/shubhamanand" },
          code: { text: "ICD Diagnosis:  Ayush Diagnosis: " },
          clinicalStatus: {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-clinical",
                code: "active",
                display: "Active",
              },
            ],
          },
        },
      },
      {
        fullUrl: "MedicationRequest/9922358",
        resource: {
          resourceType: "MedicationRequest",
          id: "9922358",
          meta: {
            profile: [
              "https://nrces.in/ndhm/fhir/r4/StructureDefinition/MedicationRequest",
            ],
          },
          text: {
            status: "generated",
            div:
              '\u003cdiv xmlns\u003d"http://www.w3.org/1999/xhtml"\u003e\u003cp\u003e\u003cb\u003eGenerated Narrative\u003c/b\u003e\u003c/p\u003e\u003cp\u003e\u003cb\u003estatus\u003c/b\u003e: active\u003c/p\u003e\u003cp\u003e\u003cb\u003eintent\u003c/b\u003e: order\u003c/p\u003e\u003cp\u003e\u003cb\u003emedication\u003c/b\u003e: \u003cspan title\u003d"Codes: "\u003e1960\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cb\u003esubject\u003c/b\u003e: \u003ca href\u003d"#Patient_shubhamanand"\u003eSHUBHAM . Generated Summary: Medical record number: 22-7225-4829-5255;  SHUBHAM ; Phone: +917387106250; gender: male; birthDate: 1998-01-01\u003c/a\u003e\u003c/p\u003e\u003cp\u003e\u003cb\u003eauthoredOn\u003c/b\u003e: 2022-02-22\u003c/p\u003e\u003cp\u003e\u003cb\u003erequester\u003c/b\u003e: \u003ca href\u003d"#Practitioner_I-66620-A"\u003eDr. SAYLEE DESHMUKH. Generated Summary: Medical License number: I-66620-A; Dr. SAYLEE DESHMUKH\u003c/a\u003e\u003c/p\u003e\u003c/div\u003e',
          },
          status: "active",
          subject: { reference: "Patient/shubhamanand", display: "SHUBHAM " },
          intent: "order",
          requester: { reference: "Practitioner/I-66620-A" },
          medicationCodeableConcept: { text: "ARJUN TWAK CHURNA" },
          dosageInstruction: [
            {
              text: "2",
              timing: {
                repeat: { frequency: 2, period: 7, periodUnit: "d" },
              },
              route: { text: "HONEY" },
            },
          ],
          authoredOn: "2022-02-22",
        },
      },
    ],
  },
];

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    margin: "20px auto",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#FF9900",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const FhirDisplay = ({ closeDialog }) => {
  const classes = useStyles();

  const backgroundColor = useSelector(
    (state) => state.MainReducer.selectedColor
  );

  const isIpd =
    useSelector((state) => state.IpdTopBarReducer) === "ipdConsultation"
      ? true
      : false;

  const fhirDataFromApp = useSelector(
    (state) => state.registrationReducer.fhirData
  );

  useEffect(() => {
    const groupedData = _.groupBy(fhirDataFromApp, (ele) => ele?.patientName);
    setFhirDataGrouped(groupedData);
  }, [fhirDataFromApp]);

  const [fhirData, setFhirData] = useState([]);
  const [fhirDataGrouped, setFhirDataGrouped] = useState({});
  const [nameArray, setNameArray] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    console.log("fhirDataGrouped", fhirDataGrouped);
    const names = Object.keys(fhirDataGrouped);
    setNameArray(names);
  }, [fhirDataGrouped]);

  useEffect(() => {
    console.log("fhirnameArray", nameArray);
  }, [nameArray]);

  const nameButtons = () => {
    return nameArray.map((name) => (
      <Button
        className={name === selectedName ? classes.btnSelected : classes.btn}
        onClick={(e) => handleNameChange(name)}
      >
        {name}
      </Button>
    ));
  };

  const handleNameChange = (name) => {
    setSelectedName(name);
    setFhirData(fhirDataGrouped[name].map((ele) => ele?.dataObj));
  };

  const [daysArray, setDaysArray] = useState([
    { date: moment(new Date()).format("DD-MM-YYYY") },
  ]);

  const datePaging = () => {
    return daysArray.map((day) => (
      <Button
        className={
          day?.date === selectedDate ? classes.btnSelected : classes.btn
        }
        onClick={(event) => handlePageChange(day.date)}
      >
        {day.date}
      </Button>
    ));
  };

  const [date, setDate] = useState(null);
  const [opConsultation, setOpConsultation] = useState(fhirData);
  const [prescription, setPrescription] = useState(fhirData);
  const [diagnostic, setDiagnostic] = useState(fhirData);
  const [discharge, setDischarge] = useState(fhirData);
  const [wellness, setWellness] = useState(fhirData);
  const [immunization, setImmunization] = useState(fhirData);
  const [healthDoc, setHealthDoc] = useState(fhirData);

  const [opConsultationOpen, setOpConsultationOpen] = useState(true);
  const [prescriptionOpen, setPrescriptionOpen] = useState(true);
  const [diagnosticOpen, setDiagnosticOpen] = useState(true);
  const [dischargeOpen, setDischargeOpen] = useState(true);
  const [wellnessOpen, setWellnessOpen] = useState(true);
  const [immunizationOpen, setImmunizationOpen] = useState(true);
  const [healthDocOpen, setHealthDocOpen] = useState(true);

  const handlePageChange = (date) => {
    setSelectedDate(date);
    const [day, month, year] = date.split("-");
    const newDate = `${year}-${month}-${day}`;
    setDate(newDate);
    if (Array.isArray(fhirData)) {
      setOpConsultation(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) => e.includes("OPConsult"))
        )[0]
      );
      setPrescription(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) => e.includes("Prescription"))
        )[0]
      );
      setDiagnostic(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("DiagnosticReport")
              )
        )[0]
      );
      setDischarge(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("DischargeSummaryRecord")
              )
        )[0]
      );
      setWellness(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("WellnessRecord")
              )
        )[0]
      );
      setImmunization(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("ImmunizationRecord")
              )
        )[0]
      );
      setHealthDoc(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("HealthDocumentRecord")
              )
        )[0]
      );
    } else {
      setOpConsultation(
        fhirData?.entry &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.lastUpdated.includes(newDate) &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.profile?.find((e) => e.includes("OPConsult"))
      );
      setPrescription(
        fhirData?.entry &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.lastUpdated.includes(newDate) &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.profile?.find((e) => e.includes("Prescription"))
      );
      setDiagnostic(
        fhirData?.entry &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.lastUpdated.includes(newDate) &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.profile?.find((e) =>
              e.includes("DiagnosticReport")
            )
      );
      setDischarge(
        fhirData?.entry &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.lastUpdated.includes(newDate) &&
          fhirData?.entry
            ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
            ?.resource?.meta?.profile?.find((e) =>
              e.includes("DischargeSummaryRecord")
            )
      );
      setWellness(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("WellnessRecord")
              )
        )[0]
      );
      setImmunization(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("ImmunizationRecord")
              )
        )[0]
      );
      setHealthDoc(
        fhirData?.filter(
          (ele) =>
            ele?.entry &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.lastUpdated.includes(newDate) &&
            ele?.entry
              ?.filter(
                (ele) => ele?.resource?.resourceType === "Composition"
              )[0]
              ?.resource?.meta?.profile?.find((e) =>
                e.includes("HealthDocumentRecord")
              )
        )[0]
      );
    }
  };

  useEffect(() => {
    console.log(
      "opConsultation",
      opConsultation,
      "prescription",
      prescription,
      "diagnostic",
      diagnostic,
      "discharge",
      discharge
    );
  }, [diagnostic, opConsultation, prescription, discharge]);

  useEffect(() => {
    let da = [...daysArray];
    if (Array.isArray(fhirData))
      fhirData.forEach((element) => {
        const date = element?.entry
          ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
          ?.resource?.meta?.lastUpdated?.split("T")[0];

        const formattedDate = moment(date).format("DD-MM-YYYY");

        if (da.filter((e) => e.date === formattedDate).length === 0) {
          da.push({
            date: formattedDate,
          });
        }

        setDaysArray(da);
      });
    else {
      const date = fhirData?.entry
        ?.filter((ele) => ele?.resource?.resourceType === "Composition")[0]
        ?.resource?.meta?.lastUpdated?.split("T")[0];
      const formattedDate = moment(date).format("DD-MM-YYYY");
      if (!da?.includes(formattedDate))
        da.push({
          date: formattedDate,
        });
      setDaysArray(da);
    }
  }, [fhirData]);

  useEffect(() => {
    datePaging();
  }, [daysArray]);

  const displayMedicationCard = (medicationRequests) => {
    return (
      <>
        <Typography>Medication:</Typography>

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            dense
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                >
                  Date
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                  align="left"
                >
                  Medication
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Dosing Instruction
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Additional Information
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medicationRequests?.map((row, index) => (
                <StyledTableRow key={"row?.id"}>
                  <StyledTableCell align="left">
                    {row?.resource?.authoredOn}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.resource?.medicationCodeableConcept?.code
                      ? row?.resource?.medicationCodeableConcept?.code
                          ?.coding[0]?.display
                      : row?.resource?.medicationCodeableConcept?.text}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${row?.resource?.dosageInstruction[0]?.timing?.repeat?.frequency} times for ${row?.resource?.dosageInstruction[0]?.timing?.repeat?.period} ${row?.resource?.dosageInstruction[0]?.timing?.repeat?.periodUnit}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.resource?.dosageInstruction[0]?.additionalInstruction
                      ?.coding
                      ? row?.resource?.dosageInstruction[0]
                          ?.additionalInstruction?.coding?.display
                      : row?.resource?.dosageInstruction[0]
                          ?.additionalInstruction?.text}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const displayConditionCard = (conditions) => {
    return (
      <>
        <Typography>Condition:</Typography>

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            dense
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                >
                  Recorded Date
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                  align="left"
                >
                  Condition
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Additional Notes
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conditions?.map((row, index) => (
                <StyledTableRow key={"row?.id"}>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.resource?.code?.coding
                      ? row?.resource?.code?.coding[0]?.display
                      : row?.resource?.code?.text}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`Severity: ${
                      row?.resource?.severity
                        ? row?.severity?.coding
                          ? row?.severity?.coding[0]?.display
                          : row?.severity?.text
                        : "Unspecified"
                    }`}
                    <br />
                    {`Clinical Status: ${row?.resource?.clinicalStatus?.coding[0]?.display}`}
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const displayDiagnosticsCard = (diagnostics) => {
    return (
      <>
        <Typography>Lab Tests:</Typography>

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            dense
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                >
                  Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Test Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                  align="left"
                >
                  Value
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Unit
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diagnostics?.map((row, index) => (
                <StyledTableRow key={"row?.id"}>
                  <StyledTableCell align="left">{date}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.resource?.code && row?.resource?.code?.coding
                      ? row?.resource?.code?.coding[0]?.display
                      : row?.resource?.code?.text}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${row?.resource?.valueQuantity?.value}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.resource?.valueQuantity?.unit}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const displayImmunizationCard = (immunizationReccomendations) => {
    const dueVaccines = immunizationReccomendations?.map((ele) =>
      ele?.resource?.recommendation.filter((ele) =>
        ele?.forecastStatus?.coding
          ? ele?.forecastStatus?.coding[0]?.code === "due"
          : ele?.text === "due"
      )
    );
    return (
      <>
        <Typography>Immnunization Records:</Typography>

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            dense
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                >
                  Vaccine (Status)
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Due Date
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                  align="left"
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Dose Number
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dueVaccines?.map((ele, index) =>
                ele?.map((row) => (
                  <StyledTableRow key={"row?.id"}>
                    <StyledTableCell align="left">
                      {`${
                        row?.vaccineCode !== undefined
                          ? row?.vaccineCode[0]?.coding
                            ? row?.vaccineCode[0]?.coding[0]?.display
                            : row?.vaccineCode[0]?.text
                          : null
                      } (${
                        row?.forecastStatus && row?.forecastStatus?.coding
                          ? row?.forecastStatus?.coding[0]?.display
                          : row?.forecastStatus?.text
                      })`}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {`${row?.dateCriterion && row?.dateCriterion[0]?.value}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.description}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.doseNumberPositiveInt}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const displayWellnessCard = (diagnostics) => {
    return (
      <>
        <Typography>Wellness Records:</Typography>

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            dense
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                >
                  Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Test Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    border: "1px solid white",
                  }}
                  align="left"
                >
                  Value
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white" }}
                  align="center"
                >
                  Unit
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diagnostics?.map((row, index) => (
                <StyledTableRow key={"row?.id"}>
                  <StyledTableCell align="left">{date}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.resource?.code && row?.resource?.code?.coding
                      ? row?.resource?.code?.coding[0]?.display
                      : row?.resource?.code?.text}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${
                      row?.resource?.valueQuantity
                        ? row?.resource?.valueQuantity?.value
                        : row?.resource?.valueCodeableConcept?.coding
                        ? row?.resource?.valueCodeableConcept?.coding[0]
                            ?.display
                        : // : row?.resource?.valueCodeableConcept?.text
                        row?.resource?.component
                        ? row?.resource?.component[0].valueQuantity?.value +
                          "/" +
                          row?.resource?.component[1].valueQuantity?.value
                        : null
                    }`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.resource?.valueQuantity
                      ? row?.resource?.valueQuantity?.unit
                      : row?.resource?.component
                      ? row?.resource?.component[0].valueQuantity?.unit
                      : null}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const openPdf = (base64String) => {
    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(base64String) +
        "'></iframe>"
    );
  };

  return (
    <Container maxWidth="xl">
      <div style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* <Link
          to={isIpd ? "/ipd/consultation" : "/ConsultationLayout"}
          style={{ textDecoration: "none" }}
        > */}
        {/* <Button
            className={classes.button}
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => closeDialog()}
          >
            Close
          </Button> */}
        <Grid container spacing={0}>
          <Grid item xs={11} sm={11} md={11}>
            <Typography style={{ color: backgroundColor }}>
              <b>Patient Medical History</b>
            </Typography>
          </Grid>

          <Grid item xs={1} sm={1} md={1} align="right">
            <IconButton
              onClick={() => closeDialog()}
              edge="start"
              color="primary"
              aria-label="menu"
              size="small"
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
        <hr
          style={{
            color: "#D3D3D3",
            backgroundColor: "lightgreen",
            height: 0.1,
            width: "100%",
          }}
        />
        {/* </Link> */}
      </div>

      <Card className={classes.card}>
        <Grid container spacing={0} style={{ marginTop: "0.5%" }}>
          <Grid item xs={12} sm={2}>
            <Typography style={{ fontWeight: "bold" }}>
              Patient Name:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>
              {
                opConsultation?.entry?.filter(
                  (ele) => ele?.resource?.resourceType === "Patient"
                )[0]?.resource?.name[0]?.text
              }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography style={{ fontWeight: "bold" }}>Gender:</Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>
              {opConsultation?.entry?.filter(
                (ele) => ele?.resource?.resourceType === "Patient"
              )[0]?.resource?.gender
                ? opConsultation?.entry?.filter(
                    (ele) => ele?.resource?.resourceType === "Patient"
                  )[0]?.resource?.gender
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                fontSize: "15px",
              }}
            >
              DOB :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} align="left">
            <Typography style={{ fontSize: "15px" }}>
              {opConsultation?.entry?.filter(
                (ele) => ele?.resource?.resourceType === "Patient"
              )[0]?.resource?.birthDate
                ? moment(
                    opConsultation?.entry?.filter(
                      (ele) => ele?.resource?.resourceType === "Patient"
                    )[0]?.resource?.birthDate
                  ).format("DD-MM-YYYY")
                : "NA"}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <Grid
        container
        spacing={0}
        style={{ marginTop: "2%" }}
        direction="row"
        alignItems="flex-end"
        justify="center"
      >
        <Grid container item>
          {nameButtons()}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        style={{ marginTop: "2%" }}
        direction="row"
        alignItems="flex-end"
        justify="center"
      >
        <Grid container item>
          {datePaging()}
        </Grid>
      </Grid>
      <Accordion style={{ border: "2px solid #0B9CAB" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor,
            color: "white",
            height: "2px",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            style={{
              margin: "10px 0px 0px 20px",
              color: "white",
              fontSize: "16px",
              fontFamily: "helvetica",
            }}
          >
            <b>OP Consultation</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                DOCUMENT:{" "}
                {opConsultation?.entry &&
                  opConsultation?.entry[0]?.resource?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                OP Consultation Record:{" "}
                {opConsultation?.entry &&
                  opConsultation?.entry[0]?.resource?.section[0]?.title}
              </Typography>
            </Grid>
            <Grid container item>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {displayMedicationCard(
                  opConsultation?.entry?.filter(
                    (ele) => ele?.resource?.resourceType === "MedicationRequest"
                  )
                )}
                <br />

                {displayConditionCard(
                  opConsultation?.entry?.filter(
                    (ele) => ele?.resource?.resourceType === "Condition"
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ border: "2px solid #0B9CAB" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor,
            color: "white",
            height: "2px",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            style={{
              margin: "10px 0px 0px 20px",
              color: "white",
              fontSize: "16px",
              fontFamily: "helvetica",
            }}
          >
            <b>Prescription</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                DOCUMENT:{" "}
                {prescription?.entry && prescription?.entry[0]?.resource?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Prescription Record:{" "}
                {prescription?.entry &&
                  prescription?.entry[0]?.resource?.section[0]?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {displayMedicationCard(
                prescription?.entry?.filter(
                  (ele) => ele?.resource?.resourceType === "MedicationRequest"
                )
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "2px solid #0B9CAB" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor,
            color: "white",
            height: "2px",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            style={{
              margin: "10px 0px 0px 20px",
              color: "white",
              fontSize: "16px",
              fontFamily: "helvetica",
            }}
          >
            <b>Daignostic Reports</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                DOCUMENT:{" "}
                {diagnostic?.entry && diagnostic?.entry[0]?.resource?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Diagnostic Report Lab Record:{" "}
                {diagnostic?.entry &&
                  diagnostic?.entry[0]?.resource?.section[0]?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {displayDiagnosticsCard(
                diagnostic?.entry?.filter(
                  (ele) => ele?.resource?.resourceType === "Observation"
                )
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "2px solid #0B9CAB" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor,
            color: "white",
            height: "2px",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            style={{
              margin: "10px 0px 0px 20px",
              color: "white",
              fontSize: "16px",
              fontFamily: "helvetica",
            }}
          >
            <b>Discharge Summary</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                DOCUMENT:{" "}
                {discharge?.entry && discharge?.entry[0]?.resource?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Discharge Summary Record:{" "}
                {discharge?.entry &&
                  discharge?.entry[0]?.resource?.section[0]?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {displayMedicationCard(
                discharge?.entry?.filter(
                  (ele) => ele?.resource?.resourceType === "MedicationRequest"
                )
              )}
              <br />
              {displayConditionCard(
                discharge?.entry?.filter(
                  (ele) => ele?.resource?.resourceType === "Condition"
                )
              )}
              <br />
              {displayDiagnosticsCard(
                discharge?.entry?.filter(
                  (ele) => ele?.resource?.resourceType === "Observation"
                )
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "2px solid #0B9CAB" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor,
            color: "white",
            height: "2px",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            style={{
              margin: "10px 0px 0px 20px",
              color: "white",
              fontSize: "16px",
              fontFamily: "helvetica",
            }}
          >
            <b>Wellness Record</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                DOCUMENT:{" "}
                {wellness?.entry && wellness?.entry[0]?.resource?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Wellness Record:{" "}
                {wellness?.entry &&
                  wellness?.entry[0]?.resource?.section[0]?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {displayWellnessCard(
                wellness?.entry?.filter(
                  (ele) => ele?.resource?.resourceType === "Observation"
                )
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "2px solid #0B9CAB" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor,
            color: "white",
            height: "2px",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            style={{
              margin: "10px 0px 0px 20px",
              color: "white",
              fontSize: "16px",
              fontFamily: "helvetica",
            }}
          >
            <b>Immunization Record</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                DOCUMENT:{" "}
                {immunization?.entry && immunization?.entry[0]?.resource?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Immunization Record:{" "}
                {immunization?.entry &&
                  immunization?.entry[0]?.resource?.section[0]?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {displayImmunizationCard(
                immunization?.entry?.filter(
                  (ele) =>
                    ele?.resource?.resourceType === "ImmunizationRecommendation"
                )
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "2px solid #0B9CAB", marginBottom: "20px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor,
            color: "white",
            height: "2px",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            style={{
              margin: "10px 0px 0px 20px",
              color: "white",
              fontSize: "16px",
              fontFamily: "helvetica",
            }}
          >
            <b>Health Document</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                DOCUMENT:{" "}
                {healthDoc?.entry && healthDoc?.entry[0]?.resource?.title}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ alignItems: "center" }}
            >
              <Typography>
                Health Document:{" "}
                {healthDoc?.entry &&
                  healthDoc?.entry[0]?.resource?.section[0]?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {healthDoc?.entry
                ?.filter(
                  (ele) => ele?.resource?.resourceType === "DocumentReference"
                )
                .map((ele) => (
                  <Button
                    onClick={(e) =>
                      openPdf(ele?.resource?.content[0]?.attachment?.data)
                    }
                  >
                    {ele?.fullUrl}
                  </Button>
                ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FhirDisplay;
