import { FETCH_THERAPY_DETAILS } from "../actions/Type";

const initialState = {
  therapyDetail: [],  
 
};

const WoundReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THERAPY_DETAILS:
      return {
        ...state,
        therapyDetail: action.payload,
      };       
    default:
      return state;
  }
};

export default WoundReducer;