import {
  DELETE_PRIVILEGE_CONTROL,
  FETCH_PRIVILEGE_CONTROL,
  FETCH_PRIVILEGE_NAME,
  SET_STAFF_DEPT_LIST,
} from "../actions/Type";

const initialState = {
  privilegeName: [],
  privilegeControlDetails: [],
  deletePrivilegeControlDetails: [],
  staffDeptList: [],
};

const DutyRoasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRIVILEGE_CONTROL:
      return {
        ...state,
        privileges: action.payload,
      };
    case DELETE_PRIVILEGE_CONTROL:
      return {
        ...state,
        delPrivilegeControl: action.payload,
      };
    case FETCH_PRIVILEGE_NAME:
      return {
        ...state,
        privilegeName: action.payload,
      };

    case SET_STAFF_DEPT_LIST:
      return {
        ...state,
        staffDeptList: action.payload,
      };
    default:
      return state;
  }
};

export default DutyRoasterReducer;
