import { FETCH_SSR_LIST, FETCH_DISEASE_SYMPTOM_MAP } from "../actions/Type";

const initialState = {
  ssrDetails: [],
  diseaseSymptomMap: [],
};

const SignSymptomReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SSR_LIST:
      return {
        ...state,
        ssrDetails: action.payload,
      };

    case FETCH_DISEASE_SYMPTOM_MAP:
      return {
        ...state,
        diseaseSymptomMap: action.payload,
      };
    default:
      return state;
  }
};

export default SignSymptomReducer;
