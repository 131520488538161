import {
  FETCH_PREVIOUS_CONSULTATION_DETAILS,
  FETCH_ALL_CONSULTATION_DETAILS,
  FETCH_PREVIOUS_CONSULTATION_BY_PATIENTID,
} from "../actions/Type";

const initialState = {
  previousConsultation: {},
  allConsultationDetails: [],
  allConsultationDetailsByPatientId: {},
};

const PreviousConsultationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PREVIOUS_CONSULTATION_DETAILS:
      return {
        ...state,
        previousConsultation: action.payload,
      };
    case FETCH_ALL_CONSULTATION_DETAILS:
      return {
        ...state,
        allConsultationDetails: action.payload,
      };
    case FETCH_PREVIOUS_CONSULTATION_BY_PATIENTID:
      return {
        ...state,
        allConsultationDetailsByPatientId: action.payload,
      };

    default:
      return state;
  }
};

export default PreviousConsultationReducer;
