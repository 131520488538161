import {
  FETCH_PAST_HISTORY,
  FETCH_PERSONAL_HISTORY,
  FETCH_FAMILY_HISTORY,
  FETCH_MENSTRUAL_HISTORY,
  FETCH_HISTORY,
  FETCH_ACTIVE_HISTORY,
} from "../actions/Type";

const initialState = {
  pastHistory: {},
  personalHistory: {},
  familyHistory: {},
  menstrualHistory: [],
  getHistory: [],
  getActiveHistory: [],
};

const HistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAST_HISTORY:
      return {
        ...state,
        pastHistory: action.payload,
      };

    case FETCH_PERSONAL_HISTORY:
      return {
        ...state,
        personalHistory: action.payload,
      };
    case FETCH_FAMILY_HISTORY:
      return {
        ...state,
        familyHistory: action.payload,
      };
    case FETCH_MENSTRUAL_HISTORY:
      return {
        ...state,
        menstrualHistory: action.payload,
      };
    case FETCH_HISTORY:
      return {
        ...state,
        getHistory: action.payload,
      };
    case FETCH_ACTIVE_HISTORY:
      return {
        ...state,
        getActiveHistory: action.payload,
      };
    default:
      return state;
  }
};

export default HistoryReducer;
