import { showSnackbar } from "../actions/Notification";
import configData from "../config.json";
import { UPDATE_CONSULTATION } from "./Type";
import ipdOpdInstance from "../api/IpdOpdInstance";

export const saveConsultationStatus = (data) => async (dispatch) => {
  try {
    const url = "consultation/updateConsultationStatus";

    const response = await ipdOpdInstance.post(url, data);
    const res = response.data;

    console.log("res", res);
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Status saved successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const savePreviewDetail = (data) => async (dispatch) => {
  try {
    // const url = "preview/savePreviewDetail";
    const url = "preview/savePreviewDetail";

    const response = await ipdOpdInstance.post(url, data);

    const res = response.data;
    var responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      responseObj.message = "Consultation details saved successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    responseObj.message = "Failed!";
    responseObj.messageInfoType = "error";
    dispatch(showSnackbar(responseObj));
    return "failure";
  }
};

export const updateConsultation = (consultationId, staffId) => async (
  dispatch
) => {
  try {
    const res = await ipdOpdInstance.get(
      `consultation/updateStaffId/${consultationId}/${staffId}`
    );

    dispatch({
      type: UPDATE_CONSULTATION,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const careContextNotify = (
  careContextNotifyDto,
  phone,
  userId
) => async (dispatch) => {
  const url = `ndhm/m2/links/context/notify/${userId}`;
  try {
    const response = await ipdOpdInstance.post(url, careContextNotifyDto);

    const res = response.data;
    console.log("res", res);

    const smsNotifyDto = {
      phoneNo: `+91-${phone}`,
      hip: {
        id: configData.hiuId,
      },
    };
    const smsurl = `ndhm/m2/sms_notify`;
    const smsresponse = await ipdOpdInstance.post(smsurl, smsNotifyDto);
    const smsres = smsresponse.data;
    console.log("smsres", smsres);
    return res;
  } catch (e) {
    console.log("failure:", e);
    return "failure";
  }
};

export const sendMsgToPharmacist = (orgId) => async (dispatch) => {
  try {
    await ipdOpdInstance.get("/preview/sendMsgToPharmacist/" + orgId);
  } catch (error) {
    console.log(error);
  }
};
