import {
  FETCH_CONSULTATION_PATIENT_DETAILS,
  FETCH_VITAL_PATIENT_DETAILS,
  FETCH_LAB_CONSULTATION_PATIENT_DETAILS,
} from "./Type";
import { showSnackbar, spinnerHide } from "../actions/Notification";
import axiosInstance from "../api/Instance";
import axiosIpdOpdInstance from "../api/IpdOpdInstance";

export const fetchLabConsultationPatient = (id) => async (dispatch) => {
  try {
    const res = axiosInstance
      .get(`patient/labConsultationPatient/${id}`)
      .then((res) =>
        dispatch({
          type: FETCH_LAB_CONSULTATION_PATIENT_DETAILS,
          payload: res.data,
        })
      );
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchConsultationPatient = (staffId, id) => (dispatch) => {
  axiosInstance
    .get(`/patient/consultationPatient/${staffId}/${id}`)
    .then((res) => {
      dispatch({
        type: FETCH_CONSULTATION_PATIENT_DETAILS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((e) => {
      console.log(e);
      return {};
    });
};

export const saveComplaintsDetails = (complaintsDetails) => async (
  dispatch
) => {
  try {
    const url = "complaintsDetails/saveComp";
    const response = await axiosIpdOpdInstance.post(url, complaintsDetails);
    const res = response.data;
    console.log("res", res);
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveComplaintsDetailsIpd = (complaintsDetails) => async (
  dispatch
) => {
  try {
    const url = "complaintsDetails/saveComlaintsDetailsIpd";
    const response = await axiosIpdOpdInstance.post(url, complaintsDetails);
    const res = response.data;
    console.log("res", res);
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveComplaints = (complaints) => async (dispatch) => {
  try {
    const url = "complaints/saveComplaints";
    const response = await axiosIpdOpdInstance.post(url, complaints);
    const res = response.data;
    console.log("res", res);
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveRenewal = (renewalDetails) => async (dispatch) => {
  try {
    const url = "consultation/renewal";
    const response = await axiosIpdOpdInstance.post(url, renewalDetails);
    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Save failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message = "Renewed Successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const getPatientData = (id) => async (dispatch) => {
  try {
    const res = axiosInstance
      .get(`/patient/getPatientData/${id}`)
      .then((res) =>
        dispatch({ type: FETCH_VITAL_PATIENT_DETAILS, payload: res.data })
      );
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};
