import { FETCH_BED_TYPES,FETCH_BED ,FETCH_BED_STATUS} from "../actions/Type";

const initialState = {
  bedType: [],  
  beds:[],
  bedStatus:[]
};

const BedReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BED_TYPES:
      return {
        ...state,
        bedType: action.payload,
      };  
      case FETCH_BED:
        return {
          ...state,
          beds: action.payload,
        }; 
        case FETCH_BED_STATUS:
          return {
            ...state,
            bedStatus: action.payload,
          }; 
    default:
      return state;
  }
};

export default BedReducer;