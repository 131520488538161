import { showSnackbar, spinnerHide } from "./Notification";

import {
  FETCH_COUNTRY_LIST,
  FETCH_STATES_LIST,
  FETCH_PERM_STATES_LIST,
  FETCH_CORR_STATES_LIST,
  FETCH_CITIES_LIST,
  FETCH_PERM_CITIES_LIST,
  FETCH_CORR_CITIES_LIST,
  FETCH_PATIENT_NAME,
  FETCH_NDHM_MSG,
  SET_NDHM_MODE,
  SET_NDHM_PURPOSE,
  SET_NDHM_TXN_ID,
  SET_PATIENT_DTO,
  FETCH_REG_DEPT_LIST,
  FETCH_IPD_DEPT,
  FETCH_PATIENT_DETAILS_BY_OPID,
  SET_CARE_CONTEXT_LINKING_TOKEN,
  SET_HIU_ON_FETCH_DTO,
  SHOW_SNACKBAR,
} from "./Type";
import axiosInstance from "../api/Instance";
import axiosIpdOpdInstance from "../api/IpdOpdInstance";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import _ from "lodash";

export const fetchCountries = () => async (dispatch) => {
  try {
    console.log("fetchCountries");
    const res = await axiosInstance.get("/country/getAll");

    dispatch({
      type: FETCH_COUNTRY_LIST,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchCountries error", error);
  }
  // console.log("cntry", res.data);
};

export const fetchStatesById = (countryId, perm) => async (dispatch) => {
  try {
    console.log("fetchStatesById");
    const res = await axiosInstance.get(`country/${countryId}`);

    dispatch({
      type:
        perm === undefined
          ? FETCH_STATES_LIST
          : perm
          ? FETCH_PERM_STATES_LIST
          : FETCH_CORR_STATES_LIST,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchStatesById error", error);
  }
  // console.log("states", res.data);
};

export const fetchCitiesByStateId = (stateId, perm) => async (dispatch) => {
  try {
    console.log("fetchCitiesByStateId");
    const res = await axiosInstance.get(`state/${stateId}`);

    dispatch({
      type:
        perm === undefined
          ? FETCH_CITIES_LIST
          : perm
          ? FETCH_PERM_CITIES_LIST
          : FETCH_CORR_CITIES_LIST,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchCitiesByStateId error", error);
  }
};

export const registerPatient = (patientDetails) => async (dispatch) => {
  try {
    const url = "patient/register";

    const response = await axiosInstance.post(url, patientDetails);

    let res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res.message == "failure") {
      responseObj.message = "Registration failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    return res;
  } catch (error) {
    console.log("registerPatient error", error);
    return "failure";
  }
};

export const updatePatient = (patientDetails) => async (dispatch) => {
  try {
    const url = "patient/updatePatientDetails";

    const response = await axiosInstance.post(url, patientDetails);

    let res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Updation failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else if (res == "success") {
      responseObj.message = "Patient details updated successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message = "Updation Failed: " + res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    return res;
  } catch (error) {
    console.log("updatePatient error", error);
    return "failure";
  }
};

export const fetchPatientDetailsByAyushId = (ayushId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/patient/ayushId/${ayushId}`);
    if (_.isEmpty(res.data)) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: { type: "error", msg: "No data found!", openSnackbar: true },
      });
      return;
    }

    dispatch({
      type: FETCH_PATIENT_NAME,
      payload: res.data,
    });
    console.log("PATIENT NAME", res.data);
  } catch (error) {
    console.log("PATIENT NAME", error);
  }
};

export const fetchNdhmMsg = (msg) => async (dispatch) => {
  console.log("in ndhm action");
  let responseObj = {
    message: "",
    messageInfoType: "",
  };

  if (msg.message === "ACCEPTED") {
    responseObj.message = "Valid Patient ABHA ID";
    responseObj.messageInfoType = "success";
    dispatch({
      type: SET_NDHM_MODE,
      payload: msg.modes,
    });
    dispatch({
      type: SET_NDHM_PURPOSE,
      payload: msg.purpose,
    });
    dispatch(showSnackbar(responseObj));
    dispatch(spinnerHide());
  } else {
    responseObj.message = "Invalid Patient ABHA ID, please try again!";
    responseObj.messageInfoType = "error";
    dispatch(showSnackbar(responseObj));
    dispatch(spinnerHide());
  }
  dispatch({
    type: FETCH_NDHM_MSG,
    payload: { message: msg.message, name: msg.name },
  });
};

export const fetchInitCall = (msg, verificationMethod) => (dispatch) => {
  console.log("in ndhm action", msg, verificationMethod);
  let responseObj = {
    message: "",
    messageInfoType: "",
  };
  dispatch({
    type: SET_NDHM_TXN_ID,
    payload: msg.dataObj,
  });
  if (
    verificationMethod === "MOBILE_OTP" ||
    verificationMethod === "AADHAAR_OTP"
  ) {
    if (msg.message === "ACCEPTED") {
      responseObj.message = "OTP Sent";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message = "Could not send OTP, please try again!";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
  }
};

export const fetchOTPCall = (msg) => (dispatch) => {
  console.log("user details: ", msg.confirmPatientDto);
  let responseObj = {
    message: "",
    messageInfoType: "",
  };
  dispatch({
    type: FETCH_NDHM_MSG,
    payload: { message: msg.message, name: msg.name },
  });
  if (msg?.dataObj?.status && msg?.dataObj?.status === "DENIED") {
    responseObj.message = "Consent Denied";
    responseObj.messageInfoType = "error";
    dispatch(showSnackbar(responseObj));
    dispatch(spinnerHide());
  } else if (msg.message === "ACCEPTED") {
    responseObj.message = "Verification Success!";
    responseObj.messageInfoType = "success";
    console.log("msg?.dataObj?.patient", msg?.dataObj?.patient);
    console.log("msg?.dataObj?.accessToken", msg?.dataObj?.accessToken);
    dispatch({
      type: SET_PATIENT_DTO,
      payload: msg?.dataObj?.patient,
    });
    dispatch({
      type: SET_CARE_CONTEXT_LINKING_TOKEN,
      payload: msg?.dataObj?.accessToken,
    });
    dispatch(showSnackbar(responseObj));
    dispatch(spinnerHide());
  } else {
    responseObj.message = "Verification failed!";
    responseObj.messageInfoType = "error";
    dispatch(showSnackbar(responseObj));
    dispatch(spinnerHide());
  }
};

export const clearPatientData = () => (dispatch) => {
  dispatch({
    type: SET_PATIENT_DTO,
    payload: {},
  });
};

export const submitDemographicData = (demographicData) => async (dispatch) => {
  try {
    const url = "ndhm/confirm/demographics";

    const response = await axiosIpdOpdInstance.post(url, demographicData);
    return response.data;
  } catch (error) {
    console.log("fetchPatientDetails error", error);
    return "failure";
  }
};

export const registerPatientFromReception = (patientDetails) => async (
  dispatch
) => {
  try {
    const url = "patient/registerPatientFromReception";

    const response = await axiosInstance.post(url, patientDetails);
    console.log("action res", response);
    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Registration failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else {
      responseObj.message =
        patientDetails.id === undefined || patientDetails.id === ""
          ? "Registered Successfully with Ayush Health ID: " + res?.ayushId
          : "Patient details updated";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }
    if (res.message == "failure") {
      responseObj.message = "Registration failed";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else if (res.message == "existing") {
      responseObj.message =
        "Patient already exists with the provided details having Ayush ID:" +
        res.ayushId;
      responseObj.messageInfoType = "info";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    } else if (
      res.message !== "new" &&
      res.message !== "failure" &&
      res.message !== "existing"
    ) {
      responseObj.message = "Registration failed: " + res.message;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
      dispatch(spinnerHide());
    }

    return res;
  } catch (error) {
    console.log("registerPatientFromReception error", error);
    return "failure";
  }
};

export const fetchRegDeptListByOrgId = (orgId) => async (dispatch) => {
  try {
    console.log("fetchDeptListByOrgId", orgId);
    const res = await axiosInstance.get(`department/getOPDepts/${orgId}`);
    dispatch({
      type: FETCH_REG_DEPT_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log("fetchRegDeptListByOrgId error", error);
    return [];
  }
};

export const fetchIpdDept = (orgId, patientTypeId) => async (dispatch) => {
  try {
    console.log("fetchDeptListByOrgId", orgId);
    const res = await axiosInstance.get(
      `department/getAllDepartmentByOrgIdAndTypeId/${orgId}/${patientTypeId}`
    );
    dispatch({
      type: FETCH_IPD_DEPT,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log("fetchRegDeptListByOrgId error", error);
    return [];
  }
};

export const fetchPatientDetails = (opId, orgId) => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get(
      `registration/getPatientDetails/${opId}/${orgId}`
    );
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    dispatch({
      type: FETCH_PATIENT_DETAILS_BY_OPID,
      payload: res.data,
    });
    console.log("patient details is ", res.data);
    if (res.data == "failure") {
      responseObj.message = "User doesn't exit";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res.data;
  } catch (error) {
    console.log("fetchPatientDetails error", error);
    return "failure";
  }
};

export const getPatientDetailsByAyushIdOrOpId = (opId, orgId, type) => async (
  dispatch
) => {
  try {
    const res = await axiosIpdOpdInstance.get(
      `registration/getPatientDetailsByAyushIdOrOpId/${opId}/${orgId}/${type}`
    );
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    dispatch({
      type: FETCH_PATIENT_DETAILS_BY_OPID,
      payload: res.data,
    });
    console.log("patient details is ", res.data);
    if (res.data == "failure") {
      responseObj.message = "User doesn't exit";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res.data;
  } catch (error) {
    console.log("getPatientDetailsByAyushIdOrOpId error: ", error);
    return "failure";
  }
};

export const getDirectAccessToken = (patientId) => async (dispatch) => {
  try {
    const directAccessToken = await axiosIpdOpdInstance
      .get(`ndhm/m2/getDirectAccessToken/${patientId}`)
      .then((res) => res.data);
    console.log("directAccessToken", directAccessToken);
    if (directAccessToken === "failure") {
      //return failure
      //set care-context linking token to empty string in case of failure
      dispatch({
        type: SET_CARE_CONTEXT_LINKING_TOKEN,
        payload: "",
      });
    } else {
      dispatch({
        type: SET_CARE_CONTEXT_LINKING_TOKEN,
        payload: directAccessToken,
      });
      console.log("directAccessToken redux", directAccessToken);
    }
    return directAccessToken;
  } catch (e) {
    console.log("Direct Access Token fetch Exception:", e);
    return "failure";
  }
};

const getHITypesList = (hiTypes) => {
  // const hiTypes = props.hiTypes;
  let str = "";
  let i = 0;
  for (i = 0; i < hiTypes.length; i++) {
    str += `${i + 1}. ${hiTypes[i]} \n`;
  }
  return str;
};

export const handleHiuOnFetchRequest = (dataObj, clientId) => async (
  dispatch
) => {
  dispatch({ type: SET_HIU_ON_FETCH_DTO, payload: dataObj });
  dispatch(
    showSnackbar({
      message:
        `Consent ${dataObj?.status} for HI Types:\n` +
        getHITypesList(dataObj?.consentDetail?.hiTypes),
      messageInfoType: "info",
    })
  );

  const requestBody = {
    requestId: uuidv4(),
    timestamp: moment(new Date()).toISOString(),
    hiRequest: {
      consent: {
        id: dataObj?.consentDetail?.consentId,
      },
      dateRange: {
        from: dataObj?.consentDetail?.permission?.dateRange?.from + ".000",
        to: dataObj?.consentDetail?.permission?.dateRange?.to + ".000",
      },
      dataPushUrl:
        "https://uat1.bhis-dev.in/ahmis-ndhm-services/services/v0.5/health-information/hiu/dataPushUrl",
      keyMaterial: {
        cryptoAlg: "ECDH",
        curve: "Curve25519",
        dhPublicKey: {
          expiry: dataObj?.consentDetail?.permission?.dataEraseAt,
          parameters: "Curve25519/32byte random key",
          keyValue:
            "BFTahuFVsPMzbUpo3iM0CSGWZ/B5pJRgEBwjahG11p+/TJBWI8E+9AW1eKDYKn/LF5G6SzqDIXfV+8Ajm78nWP0=",
        },
        nonce: "srzb8xdGPyx39fEwMcKl8690HKyeM4OvudRaUHWOZNE=",
      },
    },
  };
  console.log("hiRequest", requestBody);
  try {
    const response = await axiosIpdOpdInstance.post(
      `ndhm/m3/health-information/cm/request/${clientId}`,
      requestBody
    );
    console.log("cm/request response", response);
  } catch (error) {
    console.log("cm/request error", error);
  }
};
