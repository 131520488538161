import { enableRipple } from "@syncfusion/ej2-base";
import * as React from "react";
enableRipple(true);

//SampleBase class defined here
export class SampleBase extends React.PureComponent {
  rendereComplete() {
    /**custom render complete function */
  }
  componentDidMount() {
    setTimeout(() => {
      this.rendereComplete();
    });
  }
}
