import { PHARMACOLOGIST_TOP_BAR_SELECT } from "../actions/Type";


export const  PharmacologistTopBarReducer = (state = "dispensary", action) => {
  switch (action.type) {
    case PHARMACOLOGIST_TOP_BAR_SELECT:
      return action.payload

    default:
      return state;
  }
};


