import * as Types from "./Type";

export const changeThemes = (themeName) => ({
  type: Types.CHANGE_THEME,
  themeName,
});
export const changeThemeColor = (selectedColor) => ({
  type: Types.CHANGE_COLOR,
  selectedColor,
});

export const changeMenuThemeColor = (selectedMenuTheme) => ({
  type: Types.CHANGE_MENU_COLOR,
  selectedMenuTheme,
});
