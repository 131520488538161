import {
  FETCH_COMPLEX_MEDICINE_TYPE,
  FETCH_COMPLEX_MEDICINE_NAME,
  FETCH_PRESCRIPTION_HISTORY,
  // FETCH_VEHICLE_NAME,
} from "../actions/Type";

const initialState = {
  complexMedicineTypes: [],
  complexMedicineNames: [],
  prescriptionHistory: [],
  // vehicleName: [],

  // uniqueCities:[],
  // departments:[],
  // apptId: String
};

const ComplexMedicineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPLEX_MEDICINE_TYPE:
      return {
        ...state,
        complexMedicineTypes: action.payload,
      };

    case FETCH_COMPLEX_MEDICINE_NAME:
      return {
        ...state,
        complexMedicineNames: action.payload,
      };

    // case FETCH_VEHICLE_NAME:
    //   return {
    //     ...state,
    //     vehicleName: action.payload,
    //   };

    case FETCH_PRESCRIPTION_HISTORY:
      return {
        ...state,
        prescriptionHistory: action.payload,
      };

    default:
      return state;
  }
};

export default ComplexMedicineReducer;
