import React, { useEffect, useState } from "react";
import robot from "../../../assets/robot-icon.webp";
//BotMessage function specified here
export default function BotMessage({ fetchMessage }) {
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  //useEffect defined
  useEffect(() => {
    async function loadMessage() {
      const msg = await fetchMessage();
      setLoading(false);
      setMessage(msg);
    }
    loadMessage();
  }, [fetchMessage]);

  return (
    <div className="message-container">
      <img
        style={{ width: "15%", marginRight: "80%", marginBottom: "-34%" }}
        src={robot}
        alt="AHMIS"
      />

      <div className="bot-message">{isLoading ? "..." : message}</div>
    </div>
  );
}
