import { Button, Card, CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import CryptoJS from "crypto-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import { SHOW_SNACKBAR, STAFF_LOGIN } from "../actions/Type";
import axiosInstance, { ipdOpdAxios } from "../api/Instance";
import axiosInventory from "../api/InventoryInstance";
import IpdOpdInstance from "../api/IpdOpdInstance";
import axiosUser from "../api/UserInstance";
import axiosRptMaster from "../api/ReportMasterInstance";
import { restrict } from "../component/restrict";
import configData from "../config.json";
import Appbar from "./Appbar";
import Header from "./Header2";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(90vh - 60px)",
    backgroundColor: "#eff7fa",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "calc(90vh - 10px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(90vh - 10px)",
    },
  },
  container: {
    paddingTop: 100,
    height: "100%",
  },

  btn: {
    fontSize: "16px",
    backgroundColor: "#0B9CAB",
    width: "170px",
    color: "white",
    "&:hover, &:focus": {
      color: "white",
      background: "#0B9CAB",
    },
  },
  btnLoading: {
    fontSize: "16px",
    backgroundColor: "#0B9CAB",
    width: "170px",
    color: "white",
    "&:hover, &:focus": {
      color: "white",
      background: "lightgrey",
    },
  },

  inputSize: {
    fontSize: 16,
  },
  shrink: {
    transform: "translate(6px, 2.5px) scale(1.00)",
    color: theme.palette.primary.main,
  },
}));

export const encryptFn = (data) => {
  const encryptionSecret = configData.encryptionSecret;
  var key = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var iv = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  return encrypted.toString();
};

const Login = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  const [captchaValue, setCaptchaValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [error, setError] = React.useState({
    usernameError: false,
    passwordError: false,
  });

  const [credentials, setCredentials] = React.useState({
    username: "",
    password: "",
    showPassword: false,
  });

  React.useEffect(() => {
    loadCaptchaEnginge(6);
    setCaptchaValue("");
  }, []);

  const handleUsername = (e) => {
    setCredentials({
      ...credentials,
      username: e.target.value,
    });
  };

  const handlePassword = async (e) => {
    setCredentials({
      ...credentials,
      password: e.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setCredentials({ ...credentials, showPassword: !credentials.showPassword });
  };

  const handleCaptchaSubmit = () => {
    const val = validateCaptcha(captchaValue);
    if (captchaValue === "") {
      const msg = {
        message: "Captcha mandatory",
        type: "error",
      };
      dispatch({
        type: SHOW_SNACKBAR,
        payload: { type: msg.type, msg: msg.message, openSnackbar: true },
      });
    } else {
      if (!val) {
        const msg = {
          message: "Invalid Captcha",
          type: "error",
        };
        setCaptchaValue("");
        dispatch({
          type: SHOW_SNACKBAR,
          payload: { type: msg.type, msg: msg.message, openSnackbar: true },
        });
      }
    }
    return val;
  };

  const validateCredentials = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (event.key === "Enter" || event.type === "click") {
      const val = await handleCaptchaSubmit();
      if (val) {
        const username = credentials.username;
        const password = credentials.password;

        if (password && username) {
          const url = configData.USER_LOGIN_SERVICE_URL + "user/userLogin";
          const encryptedUserName = await encryptFn(username);
          const encryptedPassword = await encryptFn(password);
          try {
            const res = await axios.post(
              url,

              { userName: encryptedUserName, password: encryptedPassword }
            );

            const value = res.data;
            console.log(value, "user profile");

            if (value.message !== "success") {
              const msg = {
                message: value.message,
                type: "error",
              };
              dispatch({
                type: SHOW_SNACKBAR,
                payload: {
                  type: msg.type,
                  msg: msg.message,
                  openSnackbar: true,
                },
              });

              loadCaptchaEnginge(6, "white", "black", "");
              setError({
                ...error,
                usernameError: true,
                passwordError: true,
              });
            } else {
              localStorage.setItem("token", "Bearer " + (value.token || "_Token_not_came_from_the_server"));
              axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + (value.token || "_Token_not_came_from_the_server");
              ipdOpdAxios.defaults.headers.common["Authorization"] = "Bearer " + (value.token || "_Token_not_came_from_the_server");
              axiosInventory.defaults.headers.common["Authorization"] = "Bearer " + (value.token || "_Token_not_came_from_the_server");
              IpdOpdInstance.defaults.headers.common["Authorization"] = "Bearer " + (value.token || "_Token_not_came_from_the_server");
              axiosUser.defaults.headers.common["Authorization"] = "Bearer " + (value.token || "_Token_not_came_from_the_server");
              axiosRptMaster.defaults.headers.common["Authorization"] = "Bearer " + (value.token || "_Token_not_came_from_the_server");
              if (value.roleName !== null && value.roleName !== undefined) {
                dispatch({ type: STAFF_LOGIN, payload: value });
                if (value.roleName.toLowerCase().includes("jr doctor") || value.roleName.toLowerCase().includes("chief doctor")) {
                  history.push("ipd/consultation");
                } else if (
                  value.roleName.toLowerCase().includes("DOCTOR".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("CONSULTATION".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("PHYSICIAN".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("CONSULTANT".toLowerCase())
                )
                  history.push("/ipdOpdSelection");
                else if (
                  value.roleName.toLowerCase().includes("Reception/doctor".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("REGISTRATION".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("RECEPTIONIST".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("FRONT".toLowerCase())
                )
                  history.push("/receptionist");
                else if (value.roleName.toLowerCase().includes("Admin".toLowerCase())) history.push("/master");
                else if (
                  value.roleName.toLowerCase().includes("LAB TECHNICIAN".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("Pathologist".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("Biochemist".toLowerCase()) ||
                  value.roleName.toLowerCase().includes("LAB ATTENDANT".toLowerCase())
                )
                  history.push("/LabSampleCollect");
                else if (value.roleName.toLowerCase().includes("Radiologist".toLowerCase())) history.push("/RadiologyEntry");
                else if (value.isCds == true) history.push("/dispensaryCDS");
                else if (value.roleName.toLowerCase().includes("Pharmacist".toLowerCase())) history.push("/dispensary");
                else if (value.roleName === "Head Nurse") history.push("/HeadNurse");
                else if (value.roleName.toLowerCase().includes("NURSE".toLowerCase())) history.push("/VitalSigns");
                else if (value.roleName.toLowerCase().includes("Demo".toLowerCase())) history.push("/receptionist");
                else if (value.roleName.toLowerCase().includes("Therapist".toLowerCase())) history.push("/Therapy");
                else if (value.roleName.toLowerCase().includes("HoD/HS".toLowerCase())) history.push("/inventory");
              }

              setError({
                ...error,
                usernameError: false,
                passwordError: false,
              });
            }
          } catch (error) {
            const msg = {
              message: "Failed to login please try after sometime",
              type: "error",
            };
            console.log(error, "error123");
            dispatch({
              type: SHOW_SNACKBAR,
              payload: { type: msg.type, msg: msg.message, openSnackbar: true },
            });
            loadCaptchaEnginge(6, "white", "black", "");
          }
        } else {
          const msg = {
            message: "Username and Password mandatory",
            type: "error",
          };
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: msg.type, msg: msg.message, openSnackbar: true },
          });
        }
      }
    }
    setLoading(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <Header />
      <Appbar />

      <div className={classes.root}>
        <form onSubmit={validateCredentials}>
          <Container maxWidth="sm" className={classes.container}>
            <Grid component={Card} style={{ padding: 16 }} container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <Typography align="center" style={{ color: myOwnColor, fontSize: 22, fontWeight: 500 }}>
                  Staff Login
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Username"
                  name="username"
                  inputProps={{ maxLength: 100 }}
                  autoFocus="true"
                  onChange={handleUsername}
                  fullWidth
                  autofocus
                  onInput={restrict.alphanumericunderscoredot}
                  InputLabelProps={{
                    classes: {
                      shrink: classes.shrink,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputSize,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon style={{ color: "#0B9CAB" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type={credentials.showPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  inputProps={{ maxLength: 100 }}
                  onChange={handlePassword}
                  InputLabelProps={{
                    classes: {
                      shrink: classes.shrink,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.inputSize,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "#0B9CAB" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton tabIndex={-1} onClick={handleClickShowPassword}>
                          {credentials.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={5} sm={4} align="center">
                <LoadCanvasTemplate />
              </Grid>
              <Grid item xs={7} sm={8}>
                <TextField
                  value={captchaValue}
                  onChange={(event) => setCaptchaValue(event.target.value)}
                  placeholder="Enter Captcha"
                  inputProps={{ maxLength: 6 }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  disabled={loading}
                  variant="contained"
                  type="submit"
                  className={loading ? classes.btnLoading : classes.btn}
                  align="center"
                  onClick={validateCredentials}
                >
                  {loading && <CircularProgress size="24px" />} Login
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Login);
