import React from "react";
import { withStyles, makeStyles, alpha } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Grid, IconButton, Tooltip, FormHelperText, Typography } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";

import { useSelector } from "react-redux";
import { Controller, useWatch, useFieldArray } from "react-hook-form";

import { useDispatch } from "react-redux";
import { restrict } from "../../../component/restrict";
import { defaultComplexRow } from "./IndentIssue";

import moment from "moment";
import ReactSelect from "react-select";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  cell: {
    padding: "5px",
  },
  input: {
    height: 35,
    width: 130,
  },
  input2: {
    height: 35,
    width: 140,
    [theme.breakpoints.down("lg")]: {
      width: 75,
    },
  },
  input3: {
    height: 35,
    width: 105,
  },
  input5: {
    height: 35,
    width: 100,
    [theme.breakpoints.down("lg")]: {
      width: 100,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    color: "inherit",
    border: "1px solid #AAAAAA",
    borderRadius: 4,
    height: 35,
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  icon: {
    color: "#ff6600",
    fontSize: "16px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "16px",
  },
  addIcon: {
    padding: 5,
    marginTop: "-10px",
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
  red: {
    fontSize: "15px",
    fontWeight: 400,
    background: "#e6bcbb",
    color: "#474444 !important",
    "&.Mui-disabled": {
      background: "#e6bcbb",
      color: "black",
    },
  },
  green: {
    fontSize: "15px",
    fontWeight: 400,
    background: "#d5f4d5",
    color: "#474444 !important",
    "&.Mui-disabled": {
      background: "#d5f4d5",
      color: "black",
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    // color: "white",
    // padding: "12px",
    padding: "1px",
    fontSize: "14px",
    fontWeight: "none",
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

function GroupedMedicineTable({
  remove,
  control,
  register,
  index: mainIndex,
  errors,
  getValues,
  setValue,
  setError,
  watch,
  complexMedicineTypes,
  complexMedicines,
  viewMood,
  pendingApproval,
  CheckError,
  filterBatchNo,

  complexValue,
  deleted,
}) {
  const classes = useStyles();
  const [meds, setMeds] = React.useState([]);
  const [errosMsg, errorMsg] = React.useState("");
  const dispatch = useDispatch();

  const rows = useFieldArray({
    control,
    name: `complex[${mainIndex}].rows`,
  });

  const calculateNetAmount = (e, index, mainIndex) => {
    // setReturnValue(e.target.value)
    const rows = getValues(`complex.${mainIndex}.rows`);

    const complex = getValues(`complex`);
    console.log(rows, "rows");
    console.log(complex, "complex");
    const getSingleUnit = +getValues(`complex.${mainIndex}.singleQtyPrice`);

    let amt = 0;
    rows?.forEach((row, index) => {
      const rowAmount = +row.issuedQty;
      amt += rowAmount;
    });

    setValue(`complex.${mainIndex}.price`, amt * getSingleUnit);
  };

  const ValidateQty = (e, index, mainIndex) => {
    e.preventDefault();

    const getApprovedQty = getValues(`complex.${mainIndex}.approvedQty`);
    const getAvlQty = getValues(`complex.${mainIndex}.rows.${index}.qty`);
    const getIssuedQty = getValues(`complex.${mainIndex}.rows.${index}.issuedQty`);

    const rows = getValues(`complex.${mainIndex}.rows`);

    let amt = 0;
    rows?.forEach((row, index) => {
      const rowAmount = +row.issuedQty;
      amt += rowAmount;
    });

    setValue(`complex.${mainIndex}.remainingQty`, getApprovedQty - amt);
    if (amt > getApprovedQty) {
      if (pendingApproval) {
        errorMsg("Must be <= pending qty");

        CheckError(e, "Must be <= pending qty");
      } else {
        errorMsg("Must be <= approved qty");

        CheckError(e, "Must be <= approved qty");
      }
      //  setError(`complex.${mainIndex}.rows.${index}.qty`, { type: 'text', message: 'Quantity should  be less than approved qty' });
    } else {
      errorMsg("");
      CheckError(e, null);
    }
  };

  const totalBill = () => {
    let totalPrice = 0;
    const complex = getValues(`complex`);
    console.log(complex, "complex");
    complex.forEach((item, index) => {
      if (item.price !== undefined || item.price !== "") {
        const price = item.price;
        totalPrice += price;
      }
    });
    setValue(`totalBillAmount`, totalPrice);
  };

  console.log(watch(`complex[${mainIndex}].rows`), "getValues");
  return (
    <>
      <TableBody>
        {rows.fields.map((row, index) => (
          <TableRow key={row.id}>
            {index + 1 === 1 ? (
              <TableCell
                align="center"
                className={classes.cell}
                style={{ borderRight: "1px solid lightgrey" }}
                rowSpan={index + 1 === 1 ? rows?.fields?.length : null}
              >
                {mainIndex + 1}.
              </TableCell>
            ) : null}

            <TableCell className={classes.cell}>
              <FormControl style={{ minWidth: "150px" }}>
                {index === 0 && (
                  <>
                    <Tooltip
                      title={<Typography style={{ fontSize: "14px" }}>{getValues()?.complex[mainIndex]?.drugTypeName}</Typography>}
                      arrow
                    >
                      <TextField
                        style={{ width: "150px" }}
                        disabled
                        InputProps={{
                          className: classes.inputFont,
                        }}
                        {...register(`complex.${mainIndex}.drugTypeName`)}
                        placeholder="Drug Type"
                        size="small"
                        fullWidth
                        variant="outlined"
                      />
                    </Tooltip>
                  </>
                )}
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl style={{ minWidth: "150px" }}>
                {index === 0 && (
                  <Tooltip title={<Typography style={{ fontSize: "14px" }}>{getValues()?.complex[mainIndex]?.drugName}</Typography>} arrow>
                    <TextField
                      disabled
                      style={{ width: "150px", fontSize: "14px" }}
                      {...register(`complex.${mainIndex}.drugName`)}
                      placeholder="Drug Type"
                      InputProps={{
                        className: classes.inputFont,
                      }}
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  </Tooltip>
                )}
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  style={{ width: "80px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.uomName`)}
                  placeholder="UOM"
                  size="small"
                  fullWidth
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                  disabled
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  disabled
                  style={{ width: "120px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.singleUnit`)}
                  onInput={restrict.number}
                  placeholder="Single Unit"
                  size="small"
                  fullWidth
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                />
              )}
            </TableCell>
            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  disabled
                  style={{ width: "120px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.singleUnitContains`)}
                  onInput={restrict.number}
                  placeholder="Single Unit Contains"
                  size="small"
                  fullWidth
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                />
              )}
            </TableCell>
            <TableCell className={classes.cell}>
              {viewMood ? (
                <TextField
                  disabled
                  style={{ width: "120px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.rows.${index}.batchNo`)}
                  placeholder="Batch No."
                  size="small"
                  fullWidth
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                />
              ) : (
                <>
                  <Controller
                    control={control}
                    name={`complex.${mainIndex}.rows.${index}.batchNo`}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        options={filterBatchNo[watch(`complex.${mainIndex}.drugName`)]}
                        openMenuOnClick={false}
                        maxMenuHeight={200}
                        isOptionDisabled={(option) =>
                          watch(`complex[${mainIndex}].rows`)?.some((el) => el?.batchNo?.serialNo === option?.serialNo)
                        }
                        isClearable={true}
                        onChange={(val) => {
                          onChange(val);
                          setValue(`complex.${mainIndex}.rows.${index}.qty`, getValues(`complex.${mainIndex}.rows.${index}.batchNo.qty`));
                          setValue(
                            `complex.${mainIndex}.rows.${index}.mfgDate`,
                            getValues(`complex.${mainIndex}.rows.${index}.batchNo.manufacture`)
                          );
                          setValue(
                            `complex.${mainIndex}.rows.${index}.expDate`,
                            getValues(`complex.${mainIndex}.rows.${index}.batchNo.expiry`)
                          );
                        }}
                        value={value}
                        menuPosition="fixed"
                        getOptionLabel={(option) => option?.serialNo}
                        getOptionValue={(option) => option?.serialNo}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors?.rows?.[index]?.batchNo}>{errors?.rows?.[index]?.batchNo?.message}</FormHelperText>
                </>
              )}
            </TableCell>
            <TableCell className={classes.cell}>
              <FormControl>
                <TextField
                  style={{ fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.rows.${index}.mfgDate`)}
                  placeholder="Mfg. Date"
                  size="small"
                  fullWidth
                  disabled
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                />
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl>
                <TextField
                  style={{ fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.rows.${index}.expDate`)}
                  placeholder="Exp. Date"
                  size="small"
                  fullWidth
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  disabled
                  variant="outlined"
                />
              </FormControl>
            </TableCell>
            {viewMood ? null : (
              <TableCell className={classes.cell}>
                <FormControl>
                  <TextField
                    {...register(`complex.${mainIndex}.rows.${index}.qty`)}
                    InputProps={{
                      className:
                        getValues(`complex.${mainIndex}.rows.${index}.qty`) < getValues(`complex.${mainIndex}.rows.${index}.issuedQty`)
                          ? classes.red
                          : classes.green,
                    }}
                    classes={{
                      root: classes.customTextField,
                    }}
                    inputProps={{
                      className: `${classes.alignRight} ${classes.inputFont}`,
                      className:
                        getValues(`complex.${mainIndex}.rows.${index}.qty`) < getValues(`complex.${mainIndex}.rows.${index}.issuedQty`)
                          ? classes.red
                          : classes.green,
                    }}
                    placeholder="Quantity"
                    disabled
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                </FormControl>
              </TableCell>
            )}

            <TableCell className={classes.cell}>
              <FormControl>
                {(() => {
                  const issuedQty = register(`complex.${mainIndex}.rows.${index}.issuedQty`);
                  return (
                    <TextField
                      //  {...register(`complex.${mainIndex}.rows.${index}.issuedQty`)}
                      {...issuedQty}
                      onChange={(e) => {
                        issuedQty.onChange(e);
                        calculateNetAmount(e, index, mainIndex);
                        ValidateQty(e, index, mainIndex);
                        totalBill();
                      }}
                      InputProps={{
                        className: classes.input2,
                      }}
                      classes={{
                        root: classes.customTextField,
                      }}
                      inputProps={{
                        className: `${classes.alignRight} ${classes.inputFont}`,

                        maxLength: 7,
                      }}
                      onInput={restrict.digits}
                      placeholder="Quantity"
                      disabled={viewMood}
                      required
                      error={!!errors?.rows?.[index]?.issuedQty || !!errosMsg.length}
                      helperText={errors?.rows?.[index]?.issuedQty?.message || errosMsg}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  );
                })()}
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  {...register(`complex.${mainIndex}.approvedQty`)}
                  InputProps={{
                    className: classes.input2,
                  }}
                  classes={{
                    root: classes.customTextField,
                  }}
                  placeholder="Approved Qty"
                  size="small"
                  variant="outlined"
                  disabled
                  fullWidth
                  required
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 &&
                (() => {
                  const singleQtyPrice = register(`complex.${mainIndex}.singleQtyPrice`);
                  return (
                    <TextField
                      // {...register(`complex.${mainIndex}.singleQtyPrice`)}
                      InputProps={{
                        className: classes.input5,
                      }}
                      {...singleQtyPrice}
                      onChange={(e) => {
                        singleQtyPrice.onChange(e);
                        calculateNetAmount(e, index, mainIndex);
                        totalBill();
                      }}
                      classes={{
                        root: classes.customTextField,
                      }}
                      disabled={viewMood}
                      //  onInput={restrict.number}
                      placeholder="Cost Per Unit (₹)"
                      //  onInput={restrict.number}

                      size="small"
                      variant="outlined"
                      inputProps={{
                        className: `${classes.alignRight} ${classes.inputFont}`,
                        maxLength: 10,
                      }}
                      fullWidth
                      error={!!errors?.singleQtyPrice}
                      helperText={errors?.singleQtyPrice?.message}
                    />
                  );
                })()}
            </TableCell>
            <TableCell>
              {index === 0 && (
                <TextField
                  {...register(`complex.${mainIndex}.price`)}
                  InputProps={{
                    className: classes.input5,
                  }}
                  disabled
                  classes={{
                    root: classes.customTextField,
                  }}
                  placeholder="Price(₹)"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  fullWidth
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 ? (
                <div style={{ display: "flex" }}>
                  <Tooltip title="Add Row" arrow>
                    <IconButton
                      disabled={viewMood}
                      onClick={() => {
                        rows.append(defaultComplexRow());
                        const data = [...meds];
                        data.push(0);
                        setMeds(data);
                      }}
                      style={{ height: "20px", width: "20px" }}
                    >
                      <Add style={{ color: "#0B9CAB" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Complex Medicine" arrow disabled={viewMood}>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        remove(mainIndex);
                        totalBill();
                        deleted(false);
                      }}
                      size="small"
                    >
                      <Delete title="Delete" color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <Tooltip title="Delete Row" arrow>
                  <IconButton
                    disabled={viewMood}
                    onClick={() => {
                      rows.remove(index);
                      const medArr = [...meds];
                      // console.log("popped meds", meds[index]);
                      const filtered = medArr.filter((e) => e != meds[index]);
                      setMeds(filtered);
                    }}
                    size="small"
                  >
                    <Delete title="Delete" color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

function ComplexMedicineTable(
  {
    control,
    register,
    watch,
    reset,
    setValue,
    setError,
    getValues,
    errors,
    uncheckHistory,
    complexMedicines,
    setComplexMedicines,
    editData,
    viewData,
    viewMood,
    pendingApproval,
    CheckError,
    deleted,
    // complexValue,
  },
  ref
) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const complexValue = useFieldArray({
    control,
    name: "complex",
  });

  React.useImperativeHandle(ref, () => ({
    add: (data, mainIndex, index) => {
      const newData = { ...data, history: `${mainIndex}|${index}` };
      complexValue.append(newData);
    },
    remove: (mainIndex, index) => {
      const history = `${mainIndex}|${index}`;
      // console.log(history,"complex")
      const values = getValues("complex");
      const newValues = values.filter((x) => x.history !== history);
      setValue("complex", newValues);
    },
  }));
  const [filterBatchNo, setFilterBatchNo] = React.useState([]);

  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);

  let complexMedicineTypes = useSelector((state) => state.complexMedicineReducer.complexMedicineTypes);

  let vehicleNames = useSelector((state) => state.simpleMedicineReducer.vehicleName);

  let drugIntervals = useSelector((state) => state.simpleMedicineReducer.drugIntervals);

  const medicineChange = useWatch({
    control,
    name: "complex",
  });

  React.useEffect(() => {
    if (pendingApproval) {
      if (editData) {
        const filterData = {};

        editData?.indentRequestDetailsDtos?.forEach((item) => {
          filterData[item.drugName] = item?.drugNameBatchDto;
        });

        setFilterBatchNo(filterData);

        reset({
          complex: editData?.indentRequestDetailsDtos?.map((el) => ({
            dtoId: el.id,
            drugTypeName: el.drugTypeName,
            drugName: el.drugName,
            uomName: el.uomName,
            singleUnit: el.singleUnit,
            singleUnitContains: el.singleUnitContains,
            approvedQty: el.remQty,
            singleQtyPrice: "",
            price: "",
            rows: [{ batchNo: "", qty: "", mfgDate: "", expDate: "" }],
          })),
        });
      }
    } else {
      if (editData) {
        const filterData = {};

        editData?.indentRequestDetailsDtos?.forEach((item) => {
          filterData[item.drugName] = item?.drugNameBatchDto;
        });

        setFilterBatchNo(filterData);

        reset({
          complex: editData?.indentRequestDetailsDtos?.map((el) => ({
            dtoId: el.id,
            drugTypeName: el.drugTypeName,
            drugName: el.drugName,
            uomName: el.uomName,
            singleUnit: el.singleUnit,
            singleUnitContains: el.singleUnitContains,
            approvedQty: el.approvedQty,
            singleQtyPrice: "",
            price: "",
            rows: [{ batchNo: "", qty: "", mfgDate: "", expDate: "" }],
          })),
        });
      }
    }
  }, [editData]);

  React.useEffect(() => {
    if (viewData) {
      reset({
        complex: viewData?.indentRequestDetailsDtos?.map((el, index) => ({
          drugTypeName: el.drugTypeName,
          drugName: el.drugName,
          uomName: el.uomName,
          singleUnit: el.singleUnit,
          singleUnitContains: el.singleUnitContains,
          approvedQty: el.approvedQty,
          singleQtyPrice: el.costPerUnit,
          price: el.amount,
          dtoId: el?.id,

          rows: viewData?.indentRequestDetailsDtos[index]?.detDrugDto?.map((ml, index) => ({
            batchNo: ml.serialNo,
            issuedQty: ml?.issuedQty,
            qty: "",
            mfgDate: moment(ml.manufactureDate).format("YYYY-MM-DD"),
            expDate: moment(ml.expiryDate).format("YYYY-MM-DD"),
          })),
        })),
      });
      setValue("totalBillAmount", viewData?.netAmount);
    }
  }, [viewData]);

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={11}></Grid>

        <Grid item xs={12}>
          {/* <input {...register(`complex.${index}.history`)} type="hidden" /> */}
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow
                  align="center"
                  style={{
                    backgroundColor: myOwnColor,
                  }}
                >
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Sr. No
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Drug Type
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Drug Name
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    UoM
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Single Unit
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Single Unit Contains
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Serial/Batch No.
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Mfg. Date
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Exp.Date
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} colSpan={viewMood ? 2 : 3}>
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Cost Per Unit (₹)
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Amount(₹)
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead} rowSpan={viewMood ? 2 : 3}>
                    Action
                  </StyledTableCell>
                </TableRow>
                <TableRow
                  style={{
                    backgroundColor: myOwnColor,
                    color: "white",
                  }}
                >
                  {viewMood ? null : (
                    <StyledTableCell align="center" className={classes.inputFontThead}>
                      Avl Qty
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center" className={classes.inputFontThead}>
                    Issued
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.inputFontThead}>
                    {pendingApproval ? "Pending Qty" : "Approved Qty"}
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              {complexValue.fields.map((medicine, index) => (
                <GroupedMedicineTable
                  errors={errors?.[index]}
                  remove={() => {
                    const history = getValues(`complex[${index}].history`);
                    if (typeof history === "string") {
                      const split = history.split("|");
                      const mainIndex = split[0];
                      const index = split[1];
                      if (mainIndex !== undefined && index !== undefined) {
                        uncheckHistory(mainIndex, index);
                      }
                    }
                    complexValue.remove(index);
                  }}
                  {...{
                    index,
                    complexValue,
                    control,
                    register,
                    watch,
                    reset,
                    medicine,
                    complexMedicineTypes,
                    complexMedicines,

                    getValues,
                    setValue,
                    setError,
                    viewMood,
                    pendingApproval,
                    CheckError,
                    deleted,
                    filterBatchNo,
                  }}
                />
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <br />
    </>
  );
}

const IndentIssueForm = React.forwardRef(ComplexMedicineTable);
export default React.memo(IndentIssueForm);
