import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import TopBar from "../PrescriptionDetails/TopBar";

import PurchaseOrderRequest from "../Inventory/ExternalInv/PurchaseOrderRequest";
// import PurchaseEntry from "../Inventory/ExternalInv/PurchaseEntry";
import PurchaseReceipt from "../Inventory/ExternalInv/PurchaseReceipt";
import PurchaseorderReturn from "../Inventory/ExternalInv/PurchaseorderReturn";
import IndentIssue from "../Inventory/InternalInv/IndentIssue";
import StockEntry from "../Inventory/InternalInv/StockEntry";
import SubstockManagement from "../Inventory/InternalInv/SubstockManagement";
import NewEntry from "../Inventory/ExternalInv/NewEntry";
import StockAdjustmentRequest from "../Inventory/InternalInv/StockAdjustmentRequest";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function LinkTab(props) {
  return (
    <Tab
      style={{ color: "black", border: "1px" }}
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 80,
  },
  panel: {
    backgroundColor: "#CAF7FB",
    // color: "black ",
    opacity: 1,
    marginBottom: "7px",
    textDecoration: "none",
    // fontWeight: 500,
    textTransform: "capitalize",
    elevation: 0,
    margin: 5,
    // height: '1px'
  },
  tab: {
    boxShadow: 0,
  },
  highlightCharacter: {
    // color:"red",
    // border:"1px solid #666",
    borderBottom: "1px solid",
    padding: "0px",
    // textTransform:"uppercase",
  },
}));

export default function InventoryCDS() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const myOwnColor = "#0B9CAB";
  const menuThemeColor = "#CAF7FB";
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const prescriptionRef = React.useRef(null);
  const [prescription, setPrescription] = React.useState(null);
  const purchaseref = React.useRef(null);
  const [purchase, setPurchase] = React.useState(null);
  return (
    <div className={classes.root}>
      <TopBar />

      <div className={classes.root}>
        <Tabs
          style={{
            backgroundColor: "white",
            zIndex: 100,
            //   position: "fixed",
            marginTop: "1.2%",
            // height:'24px'
          }}
          scrollButtons="on"
          variant="scrollable"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          {/* <LinkTab
            className={classes.panel}
            label={<Typography><span className={classes.highlightCharacter}>P</span>urchase Request<br/>Approval</Typography>}
          
            style={{
              backgroundColor: value === 0 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={0}
          /> */}

          <LinkTab
            className={classes.panel}
            label="Stock Entry"
            style={{
              backgroundColor: value === 0 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={0}
          />
          <LinkTab
            className={classes.panel}
            label="Main Stock Management"
            style={{
              backgroundColor: value === 7 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={7}
          />
          <LinkTab
            className={classes.panel}
            label="Indent Issue"
            style={{
              backgroundColor: value === 5 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={5}
          />
          <LinkTab
            className={classes.panel}
            label="Stock Adjustment Request"
            style={{
              backgroundColor: value === 51 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={51}
          />

          <LinkTab
            className={classes.panel}
            label="Purchase Request"
            style={{
              backgroundColor: value === 6 ? myOwnColor : menuThemeColor,
              color: value === 6 ? "#fff" : "#000",
            }}
            value={6}
          />

          <LinkTab
            className={classes.panel}
            label="Purchase Entry"
            style={{
              backgroundColor: value === 2 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={2}
          />

          <LinkTab
            className={classes.panel}
            label="Purchase Receipt"
            style={{
              backgroundColor: value === 3 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={3}
          />

          <LinkTab
            className={classes.panel}
            label="Purchase Return"
            style={{
              backgroundColor: value === 4 ? myOwnColor : menuThemeColor,
              color: menuThemeColor === "#CAF7FB" ? "#000" : "#fff",
            }}
            value={4}
          />
        </Tabs>

        {/* <TabPanel value={value} index={0}>
          <div style={{ marginLeft: "6%" }} className={classes.form}>
          <PurchaseOrderApproval/>
          </div>
        </TabPanel> */}

        <TabPanel value={value} index={0}>
          <div className={classes.form}>
            <StockEntry />
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div className={classes.form}>
            <NewEntry
              ref={prescriptionRef}
              value={prescription}
              onChange={setPrescription}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <div className={classes.form}>
            <PurchaseReceipt
              ref={purchaseref}
              value={purchase}
              onChange={setPurchase}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <div className={classes.form}>
            <PurchaseorderReturn />
          </div>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <div className={classes.form}>
            <IndentIssue />
          </div>
        </TabPanel>
        <TabPanel value={value} index={51}>
          <div className={classes.form}>
            <StockAdjustmentRequest />
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div className={classes.form}>
            <PurchaseOrderRequest />
          </div>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <div className={classes.form}>
            <SubstockManagement />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
