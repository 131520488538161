import {
  FETCH_FEES,
  FETCH_FEES_TYPE,
  DELETE_FEES,
  FETCH_FEES_BY_ORG,
  FETCH_FEES_BY_OPID,
  FETCH_ACTIVE_FEES_BY_ORG,
} from "../actions/Type";

const initialState = {
  fees: [],
  feesType: [],
  deletedId: [],
  feesByOrg: [],
  feesByOpId: [],
  activeFeesByOrg: [],
  // uniqueCities:[],
  // departments:[],
  // apptId: String
};

const FeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEES:
      return {
        ...state,
        fees: action.payload,
      };
    case FETCH_FEES_BY_OPID:
      return {
        ...state,
        feesByOpId: action.payload,
      };

    case FETCH_FEES_TYPE:
      return {
        ...state,
        feesType: action.payload,
      };

    case DELETE_FEES:
      return {
        ...state,
        deletedId: action.payload,
      };

    case FETCH_FEES_BY_ORG:
      return {
        ...state,
        feesByOrg: action.payload,
      };

    case FETCH_ACTIVE_FEES_BY_ORG:
      return {
        ...state,
        activeFeesByOrg: action.payload,
      };

    //   case SAVE_SHIFT_NAME:
    //   return {
    //       ...state,
    //       shift: action.payload,
    //   };

    //   case DELETE_SHIFT:
    //   return {
    //       ...state,
    //       delShift: action.payload,

    //   }

    default:
      return state;
  }
};

export default FeeReducer;
