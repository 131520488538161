import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { changeThemeColor, changeMenuThemeColor } from "../../actions/Index";
import { AppBar, Grid, Avatar, DialogActions, DialogContent, DialogTitle, Popover } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";
import MoreIcon from "@material-ui/icons/MoreVert";
import ThemeSettings from "../../component/ThemeSettings";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import { useDispatch } from "react-redux";
import { staffLogout } from "../../actions/LoginAction";
import { PHARMACOLOGIST_TOP_BAR_SELECT } from "../../actions/Type";
import { saveConsultationRoomForDoctor } from "../../actions/ConsultationRoomAction";
import UpdatePassword from "../../component/Layout/UpdatePassword";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialog from "@material-ui/core/Dialog";
import { STAFF_LOGOUT } from "../../actions/Type";
import history from "../../history";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      //  marginLeft:'5%'
    },
  },
  input: {
    borderRadius: 4,
    height: 24,
    width: 220,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "5px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const Dialog1 = withStyles((theme) => ({
  root: {
    maxWidth: "2200px !important",
    maxHeight: "1000px !important",
    [theme.breakpoints.only("xs")]: {
      transform: "translate(0vw, 0vh)",
    },
    [theme.breakpoints.only("sm")]: {
      transform: "translate(0vw, 0vh)",
    },
    [theme.breakpoints.only("md")]: {
      transform: "translate(0vw, 0vh)",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      transform: "translate(0vw, 0vh)",
    },
  },
}))(MuiDialog);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  icon: {
    fontSize: "25px",
    marginTop: "3%",
    marginRight: "%",
  },
  icon2: {
    fontSize: "25px",
    marginTop: "%",
    marginRight: "130%",
    position: "static",
    backgroundColor: "#CAF7FB",
  },
  button: {
    borderRadius: "0.3em",
    backgroundColor: "#CAF7FB",
    marginTop: "5%",
    position: "fixed",
    zIndex: 1,
  },
  grow: {
    flexGrow: 1,
    display: "flex",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [base64Photo, setBase64Photo] = React.useState(null);
  //   const [unit1, setUnit1] = React.useState("");

  const [open5, setOpen5] = React.useState(false);

  const handleClickOpen5 = () => {
    setOpen5(true);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const handleProfile = (event) => {
    setAnchorProfile(event.currentTarget);
    setAnchorEl(null);
    handleMobileMenuClose();
    handleClose();
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const openProfile = Boolean(anchorProfile);
  const id = openProfile ? "simple-popover" : undefined;
  const StaffLogin = useSelector((state) => state.loginReducer.staffLogin);

  const topBarValue = useSelector((state) => state.PharmacologistTopBarReducer);
  const logout = { userName: StaffLogin.username };
  console.log(topBarValue, "1234");
  const staffDetails = [
    {
      action: "logout",
      staffId: StaffLogin?.staffId,
    },
  ];
  const handleMenuCloseSignOut = () => {
    dispatch(staffLogout(logout)).then((res) => {});
    dispatch(saveConsultationRoomForDoctor(staffDetails)).then((res) => {
      setAnchorEl(null);
      handleMobileMenuClose();
    });
    dispatch({ type: PHARMACOLOGIST_TOP_BAR_SELECT, payload: "dispensary" });
  };

  useEffect(() => {
    const photo1 = StaffLogin.orgPhoto;
    if (photo1 !== undefined) {
      const base64String = btoa(String.fromCharCode(...new Uint8Array(StaffLogin.orgPhoto)));
      setBase64Photo("data:image/png;base64," + base64String);
    }
    // return () => {
    //   dispatch({ type: PHARMACOLOGIST_TOP_BAR_SELECT, payload: "master" });
    // };
  }, [StaffLogin, dispatch]);

  const handleChange1 = (event) => {
    const val = event.target.value;
    dispatch({ type: PHARMACOLOGIST_TOP_BAR_SELECT, payload: val });
    if (val === "dispensary") {
      history.push("/dispensary");
    } else if (val === "dispensaryReports") {
      history.push("/dispensaryReports");
    } else if (val === "inventory") {
      history.push("/inventory");
    } else if (val === "master") {
      history.push("/master");
    } else if (val === "reports") {
      history.push("/LandingMasterReports");
    } else if (val === "ipd") {
      history.push("/LandingIPD");
    } else if (val === "dispensaryCDS") {
      history.push("/dispensaryCDS");
    } else if (event.target.value === "videotutorials") {
      history.push("/videotutorials");
    }
  };

  let staffLogin = useSelector((state) => state.loginReducer.staffLogin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  let staffPhoto = "";
  let photoStr = btoa(String.fromCharCode(...new Uint8Array(staffLogin.staffPhoto)));
  staffPhoto = "data:image/png;base64," + photoStr;

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem style={{ color: "green", fontWeight: 500, wordBreak: "break-word" }}>{staffLogin.staffName}</MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings label="Theme Settings" />
      </MenuItem>

      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings label="Menu Theme Settings" type="CHANGE_MENU_COLOR" />
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleMenuClose();
          dispatch(changeThemeColor("#07A0B0"));
          dispatch(changeMenuThemeColor("#CAF7FB"));
        }}
      >
        Default Settings
      </MenuItem>
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <Popover
        id={id}
        open={openProfile}
        anchorEl={anchorProfile}
        onClose={handleProfileClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 68, left: 1500 }}
        PaperProps={{
          style: { overflowY: "hidden", width: "22%" },
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={11} align="left"></Grid>
          <Grid item xs={1} align="left">
            <IconButton
              onClick={handleProfileClose}
              edge="start"
              color="primary"
              arialabel="menu"
              style={{ marginLeft: "-84%" }}
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>

        <Avatar
          style={{
            width: 74,
            height: 74,
            margin: "auto",
            backgroundColor: "#07a0b0",
            fontSize: "26px",
            textTransform: "uppercase",
          }}
          alt={staffLogin.staffName}
          src={staffPhoto}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} align="center">
            {StaffLogin.staffName} - {StaffLogin.username}
          </Grid>
          <Grid item xs={12} align="center">
            <Typography style={{ fontSize: "14px" }}>{StaffLogin.orgName}</Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              style={{
                color: "rgb(7, 160, 176)",
                width: "80%",
              }}
              onClick={handleClickOpen5}
            >
              Change Password
            </Button>
            <Dialog1 open={open5} onClose={handleClose5} aria-labelledby="form-dialog-title" maxWidth="xs">
              <Grid container spacing={0}>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <DialogTitle>
                    <Typography style={{ color: "black" }}>Change password</Typography>
                  </DialogTitle>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <IconButton onClick={handleClose5} edge="start" color="primary" arialabel="menu">
                    <CloseIcon style={{ color: "black" }} />
                  </IconButton>
                </Grid>
              </Grid>

              <DialogContent>
                <UpdatePassword />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog1>
          </Grid>
        </Grid>
      </Popover>

      <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
        <MenuItem onClick={handleMenuCloseSignOut}>Sign Out</MenuItem>
      </Link>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings />
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <ThemeSettings label="Menu Theme Settings" type="CHANGE_MENU_COLOR" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          dispatch(changeThemeColor("#0B9CAB"));
        }}
      >
        Default Settings
      </MenuItem>
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
        <MenuItem onClick={handleMenuCloseSignOut}>Sign Out</MenuItem>
      </Link>
    </Menu>
  );
  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      event.returnValue = `Are you sure you want to leave?`;
      dispatch(staffLogout(logout));
    });
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ backgroundColor: myOwnColor, height: "60px" }}
      >
        <Toolbar>
          {base64Photo != null ? <img src={base64Photo} width={40} height={40} edge="start" alt="logo" /> : <div></div>}
          &nbsp;{" "}
          {StaffLogin?.opdDepartment?.length !== 0 &&
          StaffLogin?.opdDepartment !== undefined &&
          StaffLogin?.hospitalShortName !== undefined ? (
            <>
              <Typography
                variant="h6"
                noWrap
                style={{
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                {`${StaffLogin?.hospitalShortName}
            (${StaffLogin?.opdDepartment?.[0]?.deptName})`}
              </Typography>
              <Typography style={{ textTransform: "uppercase" }}>&nbsp;- {StaffLogin.roleName}</Typography>
            </>
          ) : StaffLogin?.hospitalShortName !== undefined ? (
            <>
              <Typography
                variant="h6"
                noWrap
                style={{
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                {`${StaffLogin?.hospitalShortName}`}
              </Typography>
              <Typography style={{ textTransform: "uppercase" }}>&nbsp;- {StaffLogin.roleName}</Typography>
            </>
          ) : null}
          <div className={classes.grow} />
          <div className={`${classes.sectionDesktop} dropdown-menu-options`}>
            <Grid container spacing={0}>
              <Grid items xs={12} sm={12} lg={12} md={12} xl={12}>
                <FormControl className={classes.margin}>
                  <NativeSelect
                    value={topBarValue}
                    onChange={handleChange1}
                    input={
                      <BootstrapInput
                        style={{
                          width: 200,
                          marginRight: "180%",
                        }}
                      />
                    }
                    InputProps={{
                      className: classes.input,
                    }}
                  >
                    <>
                      <option disabled value={""}>
                        Select Department
                      </option>
                      {StaffLogin.roleName === "PI/PU Admin" ? (
                        <>
                          <option value={"master"}>Master</option>
                          <option value={"reports"}>Reports</option>
                          <option value={"ipd"}>IPD</option>
                          <option value={"inventory"}>Inventory</option>
                        </>
                      ) : StaffLogin.roleName === "NI Admin" ? (
                        <>
                          <option value={"master"}>Master</option>
                          <option value={"reports"}>Reports</option>
                          <option value={"ipd"}>IPD</option>
                          <option value={"inventory"}>Inventory</option>
                          <option value={"videotutorials"}>Video Tutorials</option>
                        </>
                      ) : StaffLogin.roleName === "HoD/HS" ? (
                        <>
                          <option value={"inventory"}>Inventory</option>
                        </>
                      ) : StaffLogin.isCds === true ? (
                        <option value={"dispensaryCDS"}>Pharmacy</option>
                      ) : (
                        <>
                          <option value={"dispensary"}>Dispensary</option>
                          <option value={"dispensaryReports"}>Reports</option>
                          <option value={"inventory"}>Inventory</option>
                        </>
                      )}
                    </>
                  </NativeSelect>
                </FormControl>
              </Grid>

              {/* <Grid items xs={12} sm={12} lg={12} md={12} xl={12}>
           
                        </Grid> */}
            </Grid>
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#fafafa",
                  color: "#07a0b0",
                  textTransform: "uppercase",
                }}
                alt={staffLogin.staffName}
                src={staffPhoto}
              />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
};

export default TopBar;
