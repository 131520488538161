import {
  FETCH_DISHCHARGE_NOTES,
  DOS_AND_DONTS,
  DOS_AND_DONT_STATE,
  DISCHARGE_NOTE_STATE,
  SIMPLE_PRESCRIPTION_STATE,
  COMPLEX_PRESCRIPTION_STATE,
  PRESCRIPTION_STATE,
} from "../actions/Type";

const initialState = {
  dischargeNotes: [],
  dosAndDonts: { dos: [], donts: [] },
  dosAndDontTabState: { selectedDos: [], selectedDonts: [] },
  dischargeNotesTabState: {},
  simple: {},
  complex: {},
  prescriptionData:{}
};

const DischargeNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISHCHARGE_NOTES:
      return {
        ...state,
        dischargeNotes: action.payload,
      };
    case DOS_AND_DONTS:
      return {
        ...state,
        dosAndDonts: action.payload,
      };
    case DOS_AND_DONT_STATE:
      return {
        ...state,
        dosAndDontTabState: action.payload,
      };
    case DISCHARGE_NOTE_STATE:
      return {
        ...state,
        dischargeNotesTabState: action.payload,
      };
    case SIMPLE_PRESCRIPTION_STATE:
      return {
        ...state,
        simple: action.payload,
      };
    case COMPLEX_PRESCRIPTION_STATE:
      return {
        ...state,
        complex: action.payload,
      };
      case PRESCRIPTION_STATE:
        return {
          ...state,
          prescriptionData: action.payload,
        };
  

    default:
      return state;
  }
};

export default DischargeNotesReducer;
