import { showSnackbar } from "../actions/Notification";
import axiosInstance from "../api/Instance";
import ipdOpdInstance from "../api/IpdOpdInstance";

import {
  FETCH_ORG_LIST,
  FETCH_DEPT_TYPE_LIST,
  FETCH_DEPT_LIST,
  FETCH_STAFF_TYPE_LIST,
  FETCH_USER_LIST,
  FETCH_INSTITUTE_LIST,
  FETCH_HEALTH_FACILITY_LIST,
  ORG_STAFF_LIST,
  FETCH_ALL_PRIVATE_STATE_INSTITUTE,
  FETCH_OWN_INSTITUTE_LIST,
  FETCH_STAFF_BY_ID,
  FETCH_ALL_PRIVATE_STATE_INSTITUTE_COUNCIL,
} from "./Type";
import { encryptFn } from "../component/Login";

export const fetchStaffById = (staffId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get("staff/getStaffByStaffId/" + staffId);

    dispatch({
      type: FETCH_STAFF_BY_ID,
      payload: res.data,
    });
    console.log("staff Details", res.data);
  } catch (error) {
    console.log(error);
  }
};

export const updateProfile = (updateProfile) => async (dispatch) => {
  try {
    const url = "/staff/updateStaff";

    const response = await axiosInstance.post(url, updateProfile);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "success") {
      responseObj.message = "Profile details updated successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveOrganization = (orgDetails, logo) => async (dispatch) => {
  try {
    const url = "organization/saveOrganization";

    const response = await axiosInstance.post(url, orgDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      if (orgDetails.id == 0) {
        responseObj.message = "Organization added successfully";
      } else {
        responseObj.message = "Organization details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchAllOrganizations = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("organization/getAllOrgs");

    dispatch({
      type: FETCH_ORG_LIST,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchDeptTypes = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("departmentType/getAllDeptTypes");
    dispatch({
      type: FETCH_DEPT_TYPE_LIST,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchDepts = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("department/getAllDepts");
    dispatch({
      type: FETCH_DEPT_LIST,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveDepartment = (deptDetails) => async (dispatch) => {
  try {
    const url = "department/saveDepartment";

    const response = await axiosInstance.post(url, deptDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      if (deptDetails.departmentTypeId === 2) {
        if (deptDetails.id == 0) {
          responseObj.message = "OP added successfully";
        } else {
          responseObj.message = "OP details updated successfully";
        }
      }
      if (deptDetails.departmentTypeId === 3) {
        if (deptDetails.id == 0) {
          responseObj.message = "IP added successfully";
        } else {
          responseObj.message = "IP details updated successfully";
        }
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchStaff = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "staff/getAllStaffWithoutUsers/" + orgId
    );
    dispatch({
      type: FETCH_STAFF_TYPE_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchUsers = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get("user/getAllUsers/" + orgId);
    dispatch({
      type: FETCH_USER_LIST,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveUser = (userDetails) => async (dispatch) => {
  try {
    const encryptedUsername = encryptFn(userDetails.username);
    const encryptedPassword = encryptFn(userDetails.password);
    const encryptedConfirmPassword = encryptFn(userDetails.confirmPassword);
    const encryptedUser = {
      ...userDetails,
      username: encryptedUsername,
      password: encryptedPassword,
      confirmPassword: encryptedConfirmPassword,
    };
    const url = "user/saveUser";

    const response = await axiosInstance.post(url, encryptedUser);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      if (userDetails.id == 0) {
        responseObj.message =
          "User added successfully for login ID - " + userDetails.username;
      } else {
        responseObj.message =
          "User details updated successfully for login ID - " +
          userDetails.username;
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchInstitute = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("organization/getAllInstitutes");
    dispatch({
      type: FETCH_INSTITUTE_LIST,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const saveInstitute = (instituteDetails) => async (dispatch) => {
  try {
    const url = "organization/saveInstitute";

    const response = await axiosInstance.post(url, instituteDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      if (instituteDetails.id == 0) {
        responseObj.message = "Institute added successfully";
      } else {
        responseObj.message = "Institute details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const updateInstitute = (instituteDetails) => async (dispatch) => {
  try {
    const url = "organization/updateInstitute";

    const response = await axiosInstance.post(url, instituteDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Institue details updated successfully.";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/user/deleteUser/${userId}`);

    console.log("deleteUser", res.data);
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res.data == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "User deleted successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchHealthFacilityBySomId = (somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "/organization/fetchHealthFacilityBySomId/" + somId
    );

    dispatch({
      type: FETCH_HEALTH_FACILITY_LIST,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const saveTransferDetails = (transferData) => async (dispatch) => {
  try {
    const url = "consultation/saveTransferDetails";

    const response = await ipdOpdInstance.post(url, transferData);

    const res = response.data;
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveOPDTransferDetails = (transferData) => async (dispatch) => {
  try {
    const url = "registration/saveOpdTransferDetails";

    const response = await ipdOpdInstance.post(url, transferData);

    const res = response.data;
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveTranferIPAdmissionDetails = (transferIPData) => async (
  dispatch
) => {
  try {
    const url = "consultation/saveTranferIPAdmissionDetails";

    const response = await ipdOpdInstance.post(url, transferIPData);

    const res = response.data;
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const saveTranferIPAdmissionDetailsNew = (transferIPData) => async (
  dispatch
) => {
  try {
    const url = "registration/saveTranferIPAdmissionDetailsNew";

    const response = await ipdOpdInstance.post(url, transferIPData);

    const res = response.data;
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const getChildInstitutes = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "organization/getChildInstitutes/" + orgId
    );

    dispatch({
      type: FETCH_INSTITUTE_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getOwnInstitutes = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "organization/getOwnOrganization/" + orgId
    );

    dispatch({
      type: FETCH_OWN_INSTITUTE_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getStaffsByOrgId = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get("staff/getStaffsByOrgId/" + orgId);

    dispatch({
      type: ORG_STAFF_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllDepartmentsByOrgId = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "department/getAllDepartmentsByOrgId/" + orgId
    );
    dispatch({
      type: FETCH_DEPT_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getAllDepartmentsByOrgIdDeptId = (orgId, typeId) => async (
  dispatch
) => {
  try {
    const res = await axiosInstance.get(
      `department/getAllDeptByOrgIdTypeId/${orgId}/${typeId}`
    );
    dispatch({
      type: FETCH_DEPT_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getChildOrganizations = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "/organization/getChildOrganizations/" + orgId
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllChildInstituteByOrgId = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "/organization/getAllChildInstitutesByOrgId/" + orgId
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllPrivateOrStateInstitutes = (orgId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "/organization//getAllPrivateOrStateInstitutes/" + orgId
    );

    dispatch({
      type: FETCH_ALL_PRIVATE_STATE_INSTITUTE,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllPrivateOrStateInstitutesCouncil = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      "/organization//getAllPrivateOrStateInstitutes/1"
    );

    dispatch({
      type: FETCH_ALL_PRIVATE_STATE_INSTITUTE_COUNCIL,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const savePrivateOrStateInstitute = (orgDetails) => async (dispatch) => {
  try {
    const url = "/organization/savePrivateOrStateInstitute";

    const response = await axiosInstance.post(url, orgDetails);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res?.toLowerCase() === "success") {
      if (orgDetails.id == 0) {
        responseObj.message = "Details added successfully";
      } else {
        responseObj.message = "Details updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else if (res === "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};
