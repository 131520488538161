import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import "font-awesome/css/font-awesome.min.css";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./table.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },

  table: {
    "& thead th": {
      fontWeight: "400", //600
      fontSize: 14, //remove
      color: "#FFFFFF",
      // backgroundColor: "#0B9CAB",
    },
    "& tbody td": {
      fontWeight: "200",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& .MuiTableCell-root": {
      padding: "3px 5px 5px 15px",
    },
    "& .MuiTableCell-head": {
      padding: theme.spacing(0), //1
    },
  },
  actionText: {
    fontSize: "13px",
    textTransform: "capitalize",
  },
  actionButton: {
    border: "1px solid #ddd0d0",
    marginRight: "10px",
  },
  paginationSection: {
    display: "flex",
    justifyContent: "end",
  },
  Content: {
    height: "10px",
    color: "white",
    paddingLeft: "15px",
    fontSize: "1rem",
    zIndex: 10,
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className={classes.actionButton}
        startIcon={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
      >
        {theme.direction === "rtl" ? (
          <Typography className={classes.actionText}>first</Typography>
        ) : (
          <Typography className={classes.actionText}>first</Typography>
        )}
      </Button>
      <Button
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className={classes.actionButton}
        startIcon={<FontAwesomeIcon icon={faAngleLeft} />}
      >
        {theme.direction === "rtl" ? (
          <Typography className={classes.actionText}>previous</Typography>
        ) : (
          <Typography className={classes.actionText}>previous</Typography>
        )}
      </Button>
      <Button
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className={classes.actionButton}
        endIcon={<FontAwesomeIcon icon={faAngleRight} />}
      >
        {theme.direction === "rtl" ? (
          <Typography className={classes.actionText}> next</Typography>
        ) : (
          <Typography className={classes.actionText}> next</Typography>
        )}
      </Button>
      <Button
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className={classes.actionButton}
        endIcon={<FontAwesomeIcon icon={faAngleDoubleRight} />}
      >
        {theme.direction === "rtl" ? (
          <Typography className={classes.actionText}> Last</Typography>
        ) : (
          <Typography className={classes.actionText}> Last</Typography>
        )}
      </Button>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function useTable(records, headCells, filterFn) {
  const classes = useStyles();

  const pages = [15, 20, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const TblContainer = (props) => (
    <Table size="small" className={classes.table}>
      {props.children}
    </Table>
  );
  useEffect(() => {
    setPage(0);
    setRowsPerPage(pages[0]);
  }, [filterFn]);
  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };
    const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
    return (
      <TableHead style={{ backgroundColor: myOwnColor }}>
        <TableRow style={{ backgroundColor: myOwnColor }}>
          {headCells.map((headCell) => (
            <TableCell
              align="left"
              key={headCell.id}
              className={classes.Content}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const TblPagination = () => {
    const filteredLength = React.useMemo(
      () => filterFn.fn(records)?.length,
      []
    );
    return (
      <>
        <TablePagination
          rowsPerPageOptions={[
            15,
            20,
            25,
            { label: "All", value: filteredLength },
          ]}
          colSpan={3}
          count={filteredLength}
          rowsPerPage={rowsPerPage}
          page={page}
          pagination
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          className={classes.paginationSection}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </>
    );
  };

  const stableSort = (array, comparator) => {
    if (Array.isArray(array)) {
      const stabilizedThis = array?.map((el, index) => [el, index]);
      stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis?.map((el) => el[0]);
    }
  };

  const getComparator = React.useCallback(
    (order, orderBy) =>
      order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy),
    []
  );

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const recordsAfterPagingAndSorting = React.useCallback(() => {
    return stableSort(
      filterFn?.fn(records),
      getComparator(order, orderBy)
    )?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }, [filterFn, getComparator, order, orderBy, page, records, rowsPerPage]);

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
