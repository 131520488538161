import axios from "axios";
import configData from "../config.json";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default axios.create({
  baseURL: configData.IPD_OPD_SERVICE_URL,
  // timeout: 30000,
  headers,
});
