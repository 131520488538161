import {
    FETCH_SAVED_CONCOMITANT_MEDICINE_DETAILS,FETCH_SAVED_ALLOPATHY_MEDICINE_DETAILS
  } from "../actions/Type";
  
  const initialState = {
    savedConcomitantDetails: [],
    savedAllopathyDetails: [],
   
  };
  
  const ConcomitantAllopathyMedReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SAVED_CONCOMITANT_MEDICINE_DETAILS:
        return {
          ...state,
          savedConcomitantDetails: action.payload,
        };
  
      case FETCH_SAVED_ALLOPATHY_MEDICINE_DETAILS:
        return {
          ...state,
          savedAllopathyDetails: action.payload,
        };
      
      default:
        return state;
    }
  };
  
  export default ConcomitantAllopathyMedReducer;
  