import { showSnackbar } from "./Notification";
import {
  FETCH_RADIOLOGY_WAITING_FOR_SCAN,
  FETCH_RADIOLOGY_SCAN_COMPLETED, FETCH_RADIOLOGY_STATISTICS,
  FETCH_RADIOLOGY_BODY_PARTS, FETCH_RADIOLOGY_BODY_PARTS_EDIT,FETCH_RADIOLOGY_INVESTIGATION
} from "./Type";
import ipdOpdInstance from "../api/IpdOpdInstance";

//service used in Radiology entry side bar
export const fetchRadiologywaitingForScanPatientList = (id) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`patientRadiology/getRadiologyWaitingForScanPatientList/${id}`);  
    dispatch({
        type: FETCH_RADIOLOGY_WAITING_FOR_SCAN,
        payload: res.data,
    });
    return res.data;
        
  } catch (error) { 
      console.log(error)
      return []
  }
};

//service used in Radiology entry table
export const fetchRadiologyScanCompletedPatientList = (id) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`patientRadiology/getRadiologyScanCompletedPatientList/${id}`);
  
    dispatch({
        type: FETCH_RADIOLOGY_SCAN_COMPLETED,
        payload: res.data,
    });
    return res.data;
        
  } catch (error) { 
      console.log(error)
      return []
  }
};

export const fetchRadiologyStatistics = (id) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`patientRadiology/getRadiologyStatsticsByOrgId/${id}`);
  
    dispatch({
        type: FETCH_RADIOLOGY_STATISTICS,
        payload: res.data,
    });
    return res.data;
        
} catch (error) { 
    console.log(error)
    return []
}
};

export const fetchBodyPartRadiologyViews = (id,dateString) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`patientRadiology/getConsultationRadiologyViews/${id}/${dateString}`);
  
    dispatch({
        type: FETCH_RADIOLOGY_BODY_PARTS,
        payload: res.data,
    });
    return res.data;
        
  } catch (error) { 
      console.log(error)
      return []
  }
};

export const fetchBodyPartRadiologyViewsEdit = (id,dateString) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`patientRadiology/getConsultationCompletedRadiologyViews/${id}/${dateString}`);  
    dispatch({
        type: FETCH_RADIOLOGY_BODY_PARTS_EDIT,
        payload: res.data,
    });
    return res.data;
        
  } catch (error) { 
      console.log(error)
      return []
  }
};

export const saveRadiologyRemarks = (data) => async (dispatch) => {
  try {
    const url = "patientRadiology/saveRemarksOfRadiologyView";
  
    const response = await ipdOpdInstance.post(url, data)
  
    const res = response.data
  
    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "success") {
      responseObj.message = "Remarks added successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
  }
  return res;
    
  } catch (error) {
    console.log(error)
    return "failure";
  }
};

export const fetchRadiologyInvestigationSummary = (con_id,dateString) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(`patientRadiology/getRadiologyInvestigationSummary/${con_id}/${dateString}`);
    dispatch({
      type: FETCH_RADIOLOGY_INVESTIGATION,
      payload: res.data,
    });
    return res.data;
        
  } catch (error) { 
      console.log(error)
      return "EMPTY"
  }
};