import {
  FETCH_ROOM_LIST, SAVE_ROOM,
  FETCH_ALL_CONSULTATION_ROOMS_BY_STAFFID, FETCH_CONSULTATION_DEPT, FETCH_ALL_CONSULTATION_ROOMS, FETCH_ALL_CONSULTATION_ROOMS_BY_DEPID
} from "../actions/Type";

const initialState = {
  rooms: [],
  roomsSaved: [],
  consultationDept: [],
  allRooms: [],
  allRoomsByDeptId:[],
  allRoomsByStaffId:[],
  // departments:[],
  // apptId: String
};

const ConsultationRoomReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_ROOM_LIST:
      return {
        ...state,
        rooms: action.payload,
      };
      case FETCH_ALL_CONSULTATION_ROOMS_BY_STAFFID:
        return {
          ...state,
          allRoomsByStaffId: action.payload,
        };
    case SAVE_ROOM:
      return {
        ...state,
        roomsSaved: action.payload,
      };
    case FETCH_CONSULTATION_DEPT:
      return {
        ...state,
        consultationDept: action.payload,
      };

    case FETCH_ALL_CONSULTATION_ROOMS:
      return {
        ...state,
        allRooms: action.payload,
      };
      case FETCH_ALL_CONSULTATION_ROOMS_BY_DEPID:
        return {
          ...state,
          allRoomsByDeptId: action.payload,
        };
    default:
      return state;
  }
};

export default ConsultationRoomReducer;