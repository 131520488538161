import { SELECTED_PATIENT } from "../actions/Type";

const initialState = null;

export function selectedPatient(state = initialState, action) {
  switch (action.type) {
    case SELECTED_PATIENT:
      return action.payload ;
    default:
      return state;
  }
}