import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar } from "../../actions/Notification";

function Alert(props) {
  return <MuiAlert style={{ height: "40%" }} elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars() {  
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(clearSnackbar());
  }
  const [state, setState] = React.useState({
    // open: false,
    vertical: "center",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const { successSnackbarMessage, successSnackbarOpen, messageInfoType } = useSelector((state) => state.snackBar);
  console.log("messageInfoType = ", messageInfoType);

  return (
    <div>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert
          style={{ padding: "40px 40px 40px 40px", fontSize: "18px" }}
          onClose={handleClose}
          severity={messageInfoType}
        >
          {successSnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
