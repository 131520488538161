import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//material-ui
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  InputAdornment,
  IconButton,
  TableBody,
  TableHead,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  FormControl,
  LinearProgress,
  DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InventoryInstance from "../../../api/InventoryInstance";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ahmis from "../../../assets/ahmis.png";
import leaf from "../../../assets/leaf.png";
import EntryForm from "./EntryForm";
import { SHOW_SNACKBAR } from "../../../actions/Type";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NotificationSuccess from "../../../component/Notification/NotificationSuccess";
import moment from "moment";
import Controls from "../../../component/controls/Controls";
import useTable from "../../../component/useTable";
import { faEdit, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import DialogActions from "@material-ui/core/DialogActions";
import { restrict } from "../../../component/restrict";

const headCells = [
  { id: "srNo", label: "Sl No." },
  { id: "billNo", label: "Bill No." },
  { id: "purchaseOrderId", label: "Purchase Order ID" },
  { id: "date", label: "Date" },
  { id: "vendorname", label: "Vendor (M/S)" },
  { id: "totalBillAmount", label: "Total Bill Amt (₹)" },
  { id: "reorder", label: "Reorder" },
  { id: "approvalStatus", label: "Status" },
  { id: "action", label: "Action", disableSorting: true },
];

//css defined
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0.5%",
    marginLeft: "-0.5%",
  },
  icon: {
    color: "white",
  },
  content: {
    backgroundColor: "white",
    color: "black",
    display: "block",
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnPending: {
    backgroundColor: "rgb(231 108 25)",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
  buttonProgress: {
    color: theme.palette.primary.dark,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const defaultComplexRow = () => ({
  batchNo: "",
  qty: "",
  mfgDate: null,
  expDate: null,
});

export const defaultComplex = () => ({
  drugTypeName: "",
  drugName: "",
  uomName: "",
  singleUnit: "",
  approvedQty: "",
  singleQtyPrice: "",
  price: "",
  dtoId: "",
  rows: [defaultComplexRow()],
});
const style = {
  cellPadding: 1,
  fontSize: 8,
  font: "helvetica",
  lineWidth: 0.01,
  lineColor: 127,
  fontStyle: "normal",
  overflow: "linebreak",
  fillColor: false,
  textColor: 0,
  halign: "left",
  valign: "middle",
  cellWidth: "auto",
};
function Inner({ value: medicine, onChange: setMedicine }, ref) {
  const classes = useStyles();
  const dispatch = useDispatch();

  //validation
  const complexRowSchema = yup.object().shape({
    batchNo: yup
      .string()
      .required("This field is required")
      .min(1, "Batch No should have min 1 digits")
      .max(25, "Batch No should not exceed 25 digits")
      .matches(/^(?![\s-])[\w\s-]+$/g, "* Beginning spaces are not allowed"),
    qty: yup.string().required("This field is required"),
    // mfgDate: yup.string().required("This field is required"),
    mfgDate: yup
      .string()
      .typeError("Invalid Date")
      .max(new Date(), "Future Date not allow")

      .nullable()
      .required("This field is required"),
    // expDate: yup.string().required("This field is required"),
    expDate: yup
      .string()
      .typeError("Invalid Date")
      .when("mfgDate", (mfgDate) => {
        if (mfgDate) {
          return yup
            .date()
            .typeError("Invalid Date")
            .min(mfgDate, "Should be Greater than Mfg. date");
        }
      })
      .nullable()
      .required("This field is required"),
  });

  const complexSchema = yup.object().shape({
    drugTypeName: yup.string(),
    drugName: yup.string(),
    uomName: yup.string(),
    singleUnit: yup.string(),
    approvedQty: yup.string(),
    singleQtyPrice: yup.string(),
    price: yup.string(),
    dtoId: yup.string(),
    rows: yup
      .array()
      .of(complexRowSchema)
      .required("Must have fields"),
  });

  const schema = yup.object().shape({
    billNo: yup
      .string()
      .nullable()
      .required("This field is required")
      .matches(/^(?![\s-])[\w\s-]+$/g, "* Beginning spaces are not allowed"),
    complex: yup
      .array()
      .of(complexSchema)
      .required("Must have fields"),
  });

  const defaultValues = {
    complex: [],
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    setValue,
    setError,
    getValues,
    trigger,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  React.useImperativeHandle(ref, () => {
    return {
      isValid: trigger,
    };
  });

  const historyRef = React.useRef(null);

  const complexRef = React.useRef(null);

  const uncheckHistory = React.useCallback(
    (mainIndex, index) => historyRef.current?.uncheck(mainIndex, index),
    [historyRef]
  );
  const staff = useSelector((state) => state.loginReducer.staffLogin);
  // console.log(staff,"Staff name")
  const orgId = staff?.orgId;
  const staffId = staff?.staffId;
  //useState defined
  const [editData, setDataEdit] = React.useState(null);
  const [viewData, setdata] = React.useState(null);
  const [overAllData, setOverAllData] = React.useState(null);
  const [complexMedicines, setComplexMedicines] = React.useState({});
  const [followUpDateRadio, setFollowUpDateRadio] = React.useState("");
  const [followUpDateText, setFollowUpDateText] = React.useState("");
  const [prescriptionRemarks, setPrescriptionRemarks] = React.useState("");
  const [followUpDate, setFollowUpDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [followUpVisitDay, setFollowUpVisitDay] = React.useState("");
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [viewMood, setViewMood] = useState(false);
  const [isDeleted, setDeletedData] = useState(true);
  const [pendingApproval, setPendingApproval] = useState(false);
  const [errosMsg, errorMsg] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [expectedDate, setExpectedDate] = React.useState("");
  const [btnLoading, setBtnLoading] = React.useState(false);

  //useEffect defined
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setMedicine((med) => ({ ...med, value }));
      // trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleChangeFollowUpDate = (days) => {
    const date = moment()
      .add(days, "days")
      .format("YYYY-MM-DD");
    setFollowUpDate(date);
  };

  React.useEffect(() => {
    const days = followUpDateText;
    setFollowUpVisitDay(days);
    handleChangeFollowUpDate(days);
  }, [followUpDateText]);

  React.useEffect(() => {
    setMedicine((med) => ({
      ...med,

      complexMedicines,
      prescriptionRemarks,
      followUpVisitDay,
      followUpDate,
      followUpDateRadio,
      followUpDateText,
    }));
  }, [
    complexMedicines,
    prescriptionRemarks,
    followUpVisitDay,
    followUpDate,
    followUpDateRadio,
    followUpDateText,
    setMedicine,
  ]);
  const [records, setRecords] = React.useState([]);
  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let value = e.target.value;

    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        }
        return items.filter(
          (x) =>
            x.entryDet.billNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.purchaseOrderNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.vendorName?.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
  };

  const fetchDataLocation = () => {
    setLoading(true);
    InventoryInstance.get("purchaseEntry/fetchOrdByOrg/" + orgId)
      .then((res) => {
        var mydatais = res.data.sort((a, b) => a.id - b.id);

        setRecords(mydatais);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        console.log("error");
      });
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    window.scroll(0, 0);
    setPendingApproval(false);
    InventoryInstance.get(`purchaseEntry/fetch/${orgId}/${id}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setDataEdit(data);
        setdata(null);
        setExpectedDate(data?.expectedDate);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const handleEditPending = (e, id) => {
    window.scroll(0, 0);
    console.log("pending Approval");
    e.preventDefault();
    setPendingApproval(true);
    InventoryInstance.get(`purchaseEntry/fetchOrderForEntry/${id}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setDataEdit(data);
        setdata(null);
        setExpectedDate(data?.expectedDate);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const handleViewRecord = (e, orderId) => {
    e.preventDefault();
    setPendingApproval(false);
    InventoryInstance.get(`purchaseEntry/fetch/${orgId}/${orderId}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setOpen(true);
        setOverAllData(data);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };
  const fetchBillData = (orderId, entryId) => {
    setViewMood(true);
    window.scroll(0, 0);
    InventoryInstance.get(`purchaseEntry/fetchByEntryId/${orderId}/${entryId}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setdata(data);
        setDataEdit(null);
        setExpectedDate(data?.expectedDate);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const onFormSubmit = (data) => {
    setBtnLoading(true);
    const req = {
      organizationId: orgId,
      createdBy: staffId,
      approvalStatus: 2,
      purchaseOrderId: editData?.id,
      billNo: data.billNo,
      completed: isDeleted,
      purchaseEntryDetDtos: data.complex.map((el, index) => ({
        purchaseOrdDetId: el?.dtoId,
        remainingQty: el.approvedQty,
        entryDetailsDrugDtos: data.complex[index].rows.map((ml, index) => ({
          manufactureDate: moment(ml.mfgDate).format("YYYY-MM-DD"),
          expiryDate: moment(ml.expDate).format("YYYY-MM-DD"),
          serialNo: ml.batchNo,
          receivedQty: ml.qty,
        })),
      })),
    };

    InventoryInstance.post(`purchaseEntry/save`, req)
      .then((response) => {
        if (response.data === "success") {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "success",
              msg: "Record Updated successfully",
              openSnackbar: true,
            },
          });
          // reset(defaultValues);
          setBtnLoading(false);
          setIsEditMode(false);
          setViewMood(false);
          setPendingApproval(false);
          setDeletedData(true);
          fetchDataLocation();
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              msg: "Something went wrong",
              openSnackbar: true,
            },
          });
          setBtnLoading(false);
        }
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
        setBtnLoading(false);
      });
  };

  const [W, H] = [260, 297];
  const margin = 10;

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    fetchDataLocation();
  }, []);

  const generatePdf = (report, data, staffData, filterData) => {
    const org = staffData.orgName;

    const now = moment(new Date()).format("DD-MM-YYYY");
    const time = new Date().toLocaleTimeString();

    const getlength =
      data &&
      data.purchaseOrderDetailsDtos?.map((d, index) =>
        getSum(d.entryDetDtoSet?.map((a) => a.entryDetailsDrugDtos.length))
      );

    const body = [
      ...data.purchaseOrderDetailsDtos
        .map((d, index) => [
          [
            {
              content: d.drugTypeName,
              rowSpan: d.entryDetDtoSet.length,
              styles: { halign: "left" },
            },
            {
              content: d.drugName,
              rowSpan: d.entryDetDtoSet.length,
              styles: { halign: "left" },
            },
            {
              content: d.uomName,
              rowSpan: d.entryDetDtoSet.length,
              styles: { halign: "left" },
            },
            {
              content: d.singleUnit !== undefined ? d.singleUnit : "",
              rowSpan: d.entryDetDtoSet.length,
              styles: { halign: "center" },
            },
            {
              content: d.requiredQty,
              rowSpan: d.entryDetDtoSet.length,
              styles: { halign: "center" },
            },
            {
              content: d.approvedQty,
              rowSpan: d.entryDetDtoSet.length,
              styles: { halign: "center" },
            },
            moment(
              d.entryDetDtoSet[0].entryDetailsDrugDtos[0].manufactureDate
            ).format("DD-MM-YYYY"),
            moment(
              d.entryDetDtoSet[0].entryDetailsDrugDtos[0].expiryDate
            ).format("DD-MM-YYYY"),
            d.entryDetDtoSet[0].entryDetailsDrugDtos[0].serialNo,
            d.entryDetDtoSet[0].entryDetailsDrugDtos[0].receivedQty,
          ],
          // ...d.entryDetDtoSet.flatMap((dl) => dl.entryDetailsDrugDtos.flatMap((ml)=>[ml.expiryDate])),
          ...d.entryDetDtoSet
            .slice(1)
            .map((dl) =>
              dl.entryDetailsDrugDtos.map((ml) => [
                moment(ml.manufactureDate).format("DD-MM-YYYY"),
                moment(ml.expiryDate).format("DD-MM-YYYY"),
                ml.serialNo,
                ml.receivedQty,
              ])
            ),
        ])
        .flat(),
    ];
    const pdf = new jsPDF("p", "mm", [W, H], true);
    let Y = margin;
    pdf.addImage(ahmis, "PNG", margin, Y + 2, 15, 15);
    pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
    pdf.setFontSize(14);
    let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
    pdf.text(splitTitle, W / 2, Y, { align: "center" });
    pdf.setFontSize(13);
    pdf.text(report, W / 2, Y + 15, "center");
    Y += 30;
    pdf.setFontSize(10);
    pdf.text(`Purchase Order No.: ${data.purchaseOrderNo}`, margin, Y);
    pdf.text(`Status: ${filterData[0]?.entryStatus},`, margin, Y + 5);
    pdf.text(`Vendor (M/S): ${data?.vendorName},`, margin, Y + 11);
    pdf.text(`GST No.: ${data?.vendorGstNo}`, margin, Y + 17);
    // pdf.text(`CGST: ${data?.vendorCgst}`, W - margin, Y + 11, "right");
    // pdf.text(`SGST: ${data?.vendorSgst}`, W - margin, Y + 17, "right");

    Y += 26;

    pdf.autoTable({
      head: [
        [
          "Drug Type",
          "Drug Name",
          "UOM",
          "Single Unit",
          "Required Qty",
          "Approved Qty",
          "Mfg. Date",
          "Exp. Date",
          "Serial/Batch No.",
          "Quantity",
        ],
      ],
      body: body,

      foot: [
        [
          {
            content: `Total Amount(Rs.): ${data.totalBillAmount}`,
            colSpan: 10,
            styles: {
              halign: "right",
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
          },
        ],
      ],
      startY: Y,
      margin,
      headStyles: {
        ...style,
        fillColor: [11, 156, 171],
        textColor: 255,
        lineColor: 255,
        fontSize: 8,
        fontWeight: 500,
      },
      bodyStyles: { ...style },
      footStyles: { ...style },
    });

    pdf.save(`Purchase Entry Report-${now}, ${time}.pdf`);
  };

  const printDocument = (e, orderId) => {
    e.preventDefault();
    console.log(orderId);
    const filterData = records.filter((item) => item.orderId === orderId);
    const reportName = "Purchase Entry";
    console.log(filterData, "filterData");
    InventoryInstance.get(`purchaseEntry/fetch/${orgId}/${orderId}`)

      .then((res) => {
        generatePdf(reportName, res.data, staff, filterData);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const initialValue = 0;
  const getSum = (arr) => {
    if (Array.isArray(arr)) {
      return arr.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );
    }
  };

  const CheckError = (e, childData) => {
    e.preventDefault();
    errorMsg(childData);
  };

  const deleted = (deletedData) => {
    // e.preventDefault();
    setDeletedData(deletedData);
  };

  const getlength =
    overAllData &&
    overAllData.purchaseOrderDetailsDtos?.map((d, index) =>
      getSum(d.entryDetDtoSet?.map((a) => a.entryDetailsDrugDtos.length))
    );

  // console.log(changeFormate,"changeFormat")
  return (
    <>
      <div className={classes.root}>
        <NotificationSuccess />
        {isEditMode ? (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={0} style={{ marginTop: "1%" }}>
              <Grid items xs={12} align="center">
                <EntryForm
                  viewMood={viewMood}
                  pendingApproval={pendingApproval}
                  ref={complexRef}
                  control={control}
                  editData={editData}
                  viewData={viewData}
                  watch={watch}
                  setError={setError}
                  reset={reset}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors.complex}
                  uncheckHistory={uncheckHistory}
                  complexMedicines={complexMedicines}
                  setComplexMedicines={setComplexMedicines}
                  CheckError={CheckError}
                  deleted={deleted}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                      <span>Total Amount(₹)</span>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                      <TextField
                        {...register(`totalBillAmount`)}
                        placeholder="Comment"
                        maxRows={4}
                        size="small"
                        disabled
                        InputProps={{
                          className: classes.inputFont,
                        }}
                        inputProps={{
                          className: `${classes.alignRight} ${classes.inputFont}`,
                        }}
                        //  value={totalBillAmount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                      <span>
                        <Typography component={"span"}>Bill No.</Typography>
                      </span>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={3} align="left">
                      <FormControl>
                        <TextField
                          placeholder="Bill No."
                          {...register(`billNo`)}
                          //  value={billNoEntry}
                          size="small"
                          disabled={viewMood}
                          onInput={restrict.alphanumericspace}
                          // disabled={viewMode}
                          inputProps={{
                            className: classes.inputFont,
                            maxLength: 20,
                          }}
                          variant="outlined"
                          error={!!errors?.billNo}
                          helperText={errors?.billNo?.message}
                          //  name="amount"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                      <Typography component={"span"}>Vendor (M/S)</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={3} align="left">
                      <TextField
                        {...register(`vendorName`)}
                        placeholder="Vendor (M/S)"
                        maxRows={4}
                        size="small"
                        disabled
                        InputProps={{
                          className: classes.inputFont,
                        }}
                        variant="outlined"
                        //  name="amount"
                        //  value={VendorName}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}></Grid>

                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                      <span>Expected Date</span>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={2} align="left">
                      <span>
                        <TextField
                          {...register(`expectedDate`)}
                          placeholder="Expected Date."
                          size="small"
                          disabled
                          InputProps={{
                            className: classes.inputFont,
                          }}
                          variant="outlined"
                          value={moment(expectedDate).format("DD-MM-YYYY")}
                          //  name="amount"
                          //  value={selectedDate}
                        />
                      </span>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={1} align="left">
                      {" "}
                      <span>
                        <Typography component={"span"}>GST No.</Typography>{" "}
                      </span>
                    </Grid>

                    <Grid item xs={6} sm={3} md={3} lg={3} align="left">
                      <span>
                        <TextField
                          {...register(`gst`)}
                          placeholder="GST No."
                          maxRows={4}
                          size="small"
                          disabled
                          InputProps={{
                            className: classes.inputFont,
                          }}
                          variant="outlined"
                          //  name="amount"
                        />
                      </span>
                    </Grid>
                    {/* <Grid item xs={6} sm={3} md={3} lg={1} align="left">
       <span>
         <Typography component={"span"}>CGST No.</Typography>
       </span>
     </Grid>
     <Grid item xs={6} sm={3} md={3} lg={3} align="left">
       <span>
         <TextField
           {...register(`cgstNo`)}
           placeholder="CGST No."
           maxRows={4}
           size="small"
           disabled
           InputProps={{
             className: classes.inputFont,
           }}
           variant="outlined"
           //  name="amount"
         />
       </span>
     </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  {/* <span>
     <Typography component={"span"}>SGST No.</Typography>
   </span>
   <span className={classes.margin}>
     <TextField
       style={{ width: "170px", fontSize: "14px" }}
       {...register(`sgstNo`)}
       placeholder="SGST No."
       maxRows={4}
       size="small"
       disabled
       InputProps={{
         className: classes.inputFont,
       }}
       variant="outlined"
       //  name="amount"
     />
   </span> */}
                  {/* <span style={{ float: "right", marginTop: 10 }}>
    
       <>
         <span className={classes.margin}>
           <Button
             onClick={handleSubmit((data) => onFormSubmit(data))}
             variant="contained"
             style={{
               backgroundColor: myOwnColor,
               color: "#fff",
             }}
           >
             Submit
           </Button>
         </span>
       </>
  
     <span className={classes.margin}>
       <Button onClick={handleReset} variant="contained">
        {viewMode?"close":"Reset"} 
       </Button>
     </span>
   </span> */}
                </Grid>
              </Grid>
              <Grid items xs={12} align="right">
                {viewMood ? (
                  <Button
                    onClick={() => {
                      setIsEditMode(false);
                      setViewMood(false);
                    }}
                    variant="contained"
                    style={{ margin: "5px" }}
                  >
                    Close
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={handleSubmit((data) => onFormSubmit(data))}
                      variant="contained"
                      disabled={
                        !isValid || errosMsg || isSubmitting || btnLoading
                      }
                      style={{ margin: "5px" }}
                      color="primary"
                    >
                      Submit
                      {btnLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                    <Button
                      onClick={() => {
                        setIsEditMode(false);
                        setViewMood(false);
                        setDeletedData(true);
                        reset(defaultValues);
                      }}
                      variant="contained"
                      style={{ margin: "5px" }}
                    >
                      Close
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        ) : null}
      </div>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={10} align="right">
          <Controls.Input
            style={{ maxWidth: 300 }}
            label="Search"
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <TableContainer style={{ maxHeight: "570px" }}>
            {loading && <LinearProgress />}
            <Table size="small" stickyHeader>
              <TblHead />
              {recordsAfterPagingAndSorting()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item) => (
                    <TableRow key={item.id}>
                      <StyledTableCell>{item.srNo}</StyledTableCell>
                      <StyledTableCell>
                        <div
                          style={{
                            maxWidth: 300,
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {item?.entryDet?.map((subitem) => (
                            <span
                              href="#"
                              style={{
                                marginRight: "10px",
                                textDecoration: "underline",
                                color: "#136657",
                                fontWeight: 400,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                reset(defaultValues);

                                setIsEditMode(true);
                                setViewMood(true);
                                setPendingApproval(false);
                                fetchBillData(item.orderId, subitem.entryId);
                              }}
                            >
                              {subitem.billNo},
                            </span>
                          ))}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{item.purchaseOrderNo}</StyledTableCell>
                      <StyledTableCell>
                        {item.createdDatetimeString}
                      </StyledTableCell>
                      <StyledTableCell>{item.vendorName}</StyledTableCell>
                      <StyledTableCell>{item.totalBillAmount}</StyledTableCell>
                      <StyledTableCell>
                        {item.reorder === false ? "No" : "Yes"}
                      </StyledTableCell>

                      <StyledTableCell>
                        <Controls.Button
                          size="small"
                          className={
                            item.entryStatus === "Pending"
                              ? classes.btnPending
                              : item.entryStatus === "Completed"
                              ? classes.btnActive
                              : classes.btnInActive
                          }
                          text={
                            item.entryStatus === "Pending"
                              ? "Pending"
                              : "Completed"
                          }
                        ></Controls.Button>
                      </StyledTableCell>

                      <StyledTableCell>
                        {item.entryStatus === "Pending" ? (
                          item?.entryDet.length === 0 ? (
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                reset(defaultValues);
                                setIsEditMode(true);
                                setDeletedData(true);
                                setViewMood(false);
                                handleEdit(e, item.orderId);
                              }}
                              style={{ zIndex: 0 }}
                            >
                              <FontAwesomeIcon
                                title="Edit"
                                style={{ color: "#ff6600", fontSize: "13px" }}
                                icon={faEdit}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                reset(defaultValues);
                                setIsEditMode(true);
                                setViewMood(false);
                                setDeletedData(true);
                                handleEditPending(e, item.orderId);
                              }}
                              style={{ zIndex: 0 }}
                            >
                              <FontAwesomeIcon
                                title="Edit"
                                style={{ color: "#ff6600", fontSize: "13px" }}
                                icon={faEdit}
                              />
                            </IconButton>
                          )
                        ) : (
                          <>
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                reset(defaultValues);
                                setIsEditMode(false);
                                handleViewRecord(e, item.orderId);
                              }}
                            >
                              <FontAwesomeIcon
                                title="View"
                                style={{
                                  color: "#1957b3",
                                  fontSize: "13px",
                                  zIndex: 0,
                                }}
                                icon={faEye}
                              />
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                reset(defaultValues);

                                printDocument(e, item.orderId);
                              }}
                            >
                              <FontAwesomeIcon
                                title="Print Pdf"
                                style={{
                                  color: "#f55140",
                                  fontSize: "15px",
                                  zIndex: 0,
                                }}
                                icon={faFilePdf}
                              />
                            </IconButton>
                          </>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <StyledTableCell colSpan={9} align="center">
                      No Records Found
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TblPagination />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        justifyContent="center"
        maxWidth
      >
        <DialogTitle>Purchase Entry</DialogTitle>
        <DialogContent>
          <div id="toPrint">
            <Grid container spacing={0} id="print">
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <TableContainer>
                  <Table
                    size="small"
                    className={classes.table}
                    aria-label="simple table"
                    id="data-table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Drug Type</StyledTableCell>
                        <StyledTableCell>Drug Name</StyledTableCell>
                        <StyledTableCell>UOM</StyledTableCell>
                        <StyledTableCell>Single Unit</StyledTableCell>
                        <StyledTableCell>Required Qty</StyledTableCell>
                        <StyledTableCell>Approved Qty</StyledTableCell>
                        <StyledTableCell>Single unit Price(₹)</StyledTableCell>
                        <StyledTableCell>Mfg. Date</StyledTableCell>
                        <StyledTableCell>Exp. Date </StyledTableCell>
                        <StyledTableCell>Batch No.</StyledTableCell>
                        <StyledTableCell>Quantity</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {overAllData?.length !== 0 ? (
                      <TableBody>
                        {overAllData?.purchaseOrderDetailsDtos?.map(
                          (row, index) => (
                            <>
                              <TableRow key={row.id} align="center">
                                <StyledTableCell
                                  rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.drugTypeName}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.drugName}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.uomName}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.singleUnit}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.requiredQty}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.approvedQty}
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={getlength[index] + 1}
                                  style={{
                                    border: "1px solid grey",
                                  }}
                                  align="center"
                                >
                                  {row.singleQtyPrice}
                                </StyledTableCell>
                              </TableRow>

                              {row.entryDetDtoSet?.map((d, index) =>
                                d.entryDetailsDrugDtos?.map((f, index) => (
                                  <TableRow>
                                    <StyledTableCell
                                      style={{
                                        border: "1px solid grey",
                                      }}
                                    >
                                      {moment(f.manufactureDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="right"
                                      style={{
                                        border: "1px solid grey",
                                      }}
                                    >
                                      {moment(f.expiryDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="right"
                                      style={{
                                        border: "1px solid grey",
                                      }}
                                    >
                                      {f.serialNo}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="right"
                                      style={{
                                        border: "1px solid grey",
                                      }}
                                    >
                                      {f.receivedQty}
                                    </StyledTableCell>
                                  </TableRow>
                                ))
                              )}
                            </>
                          )
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <StyledTableCell colSpan={3} align="center">
                            No Data found
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "end" }}>
          <Button
            onClick={(e) => {
              handleClose();
            }}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const NewEntry = React.forwardRef(Inner);
export default NewEntry;
