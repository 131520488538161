import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { useSelector } from "react-redux";
import config from '../../config.json';
import SessionTimeoutDialog from "./SessionTimeoutDialog";


let countdownInterval;
let timeout;

const SessionTimeout = ({ isAuthenticated, logOut }) => {
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const staffLogin = useSelector((state) => state.loginReducer.staffLogin)
    const idleTimer = useRef(null);
    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };
    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };
    const handleLogout = async (isTimedOut = false) => {
        try {
            setTimeoutModalOpen(false);
            clearSessionInterval();
            clearSessionTimeout();
            logOut();
        } catch (err) {
            console.error(err);
        }
    };
    const handleContinue = () => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
    };
    const onActive = () => {
        if (!timeoutModalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };
    const onIdle = () => {
        const delay = 1000 * 1;
        if (isAuthenticated && !timeoutModalOpen) {
            timeout = setTimeout(() => {
                let countDown = 20;
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout(true);
                    }
                }, 1000);
            }, delay);
        }
    };
    return (
        <>
            <IdleTimer
                ref={idleTimer}
                onActive={onActive}
                onIdle={onIdle}
                debounce={250}
                timeout={staffLogin.roleName === "Doctor" ? config.sessionTimeoutTimerDoctor : config.sessionTimeoutTimer}
            />
            <SessionTimeoutDialog
                countdown={timeoutCountdown}
                onContinue={handleContinue}
                onLogout={() => handleLogout(false)}
                open={timeoutModalOpen}
            />
        </>
    );
};
export default SessionTimeout;
