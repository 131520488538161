import {
  FETCH_APPT_CITY_LIST,
  SAVE_APPT_BOOKING,
  FETCH_DEPT_LIST,
  FETCH_PATIENT_DETAILS_WITH_OPID,
  SELECTED_AYUSH_ID
} from "../actions/Type";

const initialState = {
  branchNames: [],
  uniqueCities: [],
  patient: [],
  departments: [],
  apptId: String,
  selectedAyushId: ""
};

const AppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPT_CITY_LIST:
      return {
        ...state,
        branchNames: [action.payload],
        uniqueCities: [[...action.payload][0].uniqueCities],
      };

    case SAVE_APPT_BOOKING:
      return {
        ...state,
        apptId: action.payload,
      };

    case FETCH_DEPT_LIST:
      return {
        ...state,
        departments: [action.payload],
      };
    case FETCH_PATIENT_DETAILS_WITH_OPID:
      return {
        ...state,
        patient: action.payload,
      };
    case SELECTED_AYUSH_ID:
      return {
        ...state,
        selectedAyushId: action.payload,
      };

    default:
      return state;
  }
};

export default AppointmentReducer;
