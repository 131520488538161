import { Button, DialogActions, Grid, List, ListItem, ListItemText } from "@material-ui/core";
import MuiDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { fetchAdviceDetails } from "../../actions/AdviceAction";
import { fetchDrugInterval } from "../../actions/SimpleMedicineAction";
import ahmis from "../../assets/ahmis.png";
import leaf from "../../assets/leaf.png";
import { CloseButton } from "../utils/Button";

const Dialog2 = withStyles((theme) => ({
  root: {
    maxWidth: "2200px !important",
    //maxHeight: "1000px !important",
    [theme.breakpoints.only("xs")]: {
      transform: "translate(0vw, 0vh)",
    },
    [theme.breakpoints.only("sm")]: {
      transform: "translate(0vw, 0vh)",
    },
    [theme.breakpoints.only("md")]: {
      transform: "translate(0vw, 0vh)",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      transform: "translate(0vw, 0vh)",
    },
  },
}))(MuiDialog);

const useStyles = makeStyles((theme) => ({
  color: {
    fontSize: "12px",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
  },
  heading: {
    fontSize: "12px",
    borderBottom: "1px solid black",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "6px",
      padding: "0",
    },
  },

  tableCell: {
    fontSize: "10px",
    border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
      // border: "1.5px solid black",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      padding: "0",
      // border: "1.5px solid black",
    },
  },

  header: {
    fontSize: "16px",
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  diag: {
    fontSize: "14px",
    textAlign: "center",
    border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      textAlign: "center",
    },
  },
  org: {
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  finalDiag: {
    [theme.breakpoints.down("md")]: {
      border: "1px solid black",
      maxWidth: "100%",
    },
  },
  doDontGrid: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },

  text2: {
    fontSize: "12px",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  list: {
    [theme.breakpoints.down("md")]: {
      border: "1px solid black",
      padding: "0 !important",
    },
  },
  follow: {
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
  },
  tableGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  table: {
    maxWidth: "100%",
    margin: "auto",
    marginBottom: "20px",
  },
  dosLabel: {
    "& span": {
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
        margin: 0,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px",
        margin: 0,
      },
    },
  },
  dialogContainer: {
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "6px",
    },
    padding: "10px",
  },
  ahmisLogo: {
    width: "65%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      height: "auto",
    },
  },
  ayushLogo: {
    width: "65%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      height: "auto",
    },
  },

  intervalLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      padding: "4px",
    },
  },
  checkboxIcon: {
    fontSize: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
  },

  dialogDividers: {
    margin: "auto",
    "&:first-child": {
      paddingTop: "6px",
    },
  },
  doList: {
    padding: 0,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: "12px",
    padding: "0px",
    border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      border: "1px solid black",
      //lineHeight: "12px",
    },
  },
  body: {
    fontSize: 15,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "16%",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "10%",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Preview(props) {
  let componentRef = useRef();
  const { data, open, onClose } = props;
  const classes = useStyles();
  const [diagnosisData, setDiagnosisData] = useState(null);
  const [simple, setSimple] = useState([]);
  const [external, setExternal] = useState([]);
  const [dos, setDos] = useState(null);
  const [donts, setDonts] = useState(null);
  const [paid, setPaid] = useState([]);
  const [complex, setComplex] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [complexData, setComplexData] = useState([]);
  const [homeo, setHomeo] = useState([]);
  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);
  const dispatch = useDispatch();
  const advice = useSelector((state) => state.adviceReducer.advices);
  const now = new Date().toLocaleString();
  const capitalizeFirstLetter = (string) => {
    const newString = string.charAt(0) + string.slice(1, string.maxLength).toLowerCase();
    return newString;
  };
  React.useEffect(() => {
    console.log("props data", data);
    if (props && props.data) {
      setPreviewData(props.data);
    }
    if (data && data.doDont) {
      const doDont = data.doDont;
      const doData = doDont?.selectedDos;
      const obj1 = doData.map((d) => ({ d }));
      const result1 = advice.filter((o1) => obj1.some((o2) => o2.d === o1.id));
      console.log("rrrr", obj1);
      let dosRows = [];
      let dosValue = "";
      doData?.forEach((element) => {
        dosValue += capitalizeFirstLetter(element.advice) + ", ";
      });
      const str = dosValue?.slice(0, -2);
      let newRow = {
        advice: str,
      };
      dosRows.push(newRow);

      setDos(dosRows);
      const dontData = doDont?.selectedDonts;
      const obj2 = dontData.map((d) => ({ d }));
      const result2 = advice.filter((o1) => obj2.some((o2) => o2.d === o1.id));
      let dontsRows = [];
      let dontsValue = "";
      dontData?.forEach((element) => {
        dontsValue += capitalizeFirstLetter(element.advice) + ", ";
      });
      const str1 = dontsValue?.slice(0, -2);
      let newRow1 = {
        advice: str1,
      };
      dontsRows.push(newRow1);

      setDonts(dontsRows);
    }
    if (data && data.prescription && data.prescription.value) {
      const prescriptionData = { ...props.data.prescription.value };
      const simpleData = prescriptionData?.simple;
      const externalData = prescriptionData?.external;
      const paidData = prescriptionData?.paid;
      const complexData = prescriptionData?.complex;
      const homeo = prescriptionData?.homeoMed;
      const homeosingle = prescriptionData?.homeo;
      const list = [].concat.apply([], prescriptionData?.complex);

      setComplexData(list);
      console.log("complexData", complexData);
      const complexRows = [];
      const rows = list?.forEach((s) => {
        complexRows.push(s.rows);
      });
      const complexDetails = [].concat.apply([], complexRows);
      console.log("complex rows", complexDetails);
      setComplex(complexDetails);
      if (somId === 5) {
        if (homeosingle?.length !== 0) {
          setSimple(homeosingle);
        }
      } else {
        if (simpleData?.length !== 0) {
          setSimple(simpleData);
        }
      }
      setHomeo(homeo);
      setExternal(externalData);
      setPaid(paidData);
    }

    if (data && data.diagnosis) {
      const diagnosis = { ...props.data.diagnosis };
      setDiagnosisData(diagnosis);
    }
  }, [props]);

  const handleClose = () => {
    onClose();
  };
  React.useEffect(() => {
    dispatch(fetchAdviceDetails(somId));
  }, [somId]);

  const selectedPatient = useSelector((state) => state.consultationReducer.patient);

  const registrationID = useSelector((state) => state.consultationReducer.registrationId);
  let drugIntervals = useSelector((state) => state.simpleMedicineReducer.drugIntervals);
  let vehicleNames = useSelector((state) => state.simpleMedicineReducer.vehicleName);

  const [allDrugInterval, setAllDrugInterval] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      await dispatch(fetchDrugInterval()).then((res) => setAllDrugInterval(res.data));
    })();
  }, []);

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const StaffLogin = useSelector((state) => state.loginReducer.staffLogin);
  const homeoPotency = useSelector((state) => state.homeoPathyReducer.homeoPotency);

  return (
    <div>
      <Dialog2 open={open} onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="md" fullWidth>
        <div>
          <DialogContent dividers className={classes.dialogDividers}>
            <div id="previewPrint" style={{ padding: "15px", margin: "auto" }} ref={(el) => (componentRef = el)}>
              <Grid container spacing={0} className={classes.dialogContainer}>
                <Grid item xs={3} sm={1}>
                  <img className={classes.ahmisLogo} src={ahmis} alt="ahmis" />
                </Grid>
                <Grid item xs={6} sm={10}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} align="center">
                      <span className={classes.org}>{StaffLogin.orgName}</span>
                    </Grid>
                    <Grid item xs={12} align="center">
                      <span style={{ fontSize: "8px" }}>{StaffLogin.orgAddress}</span>
                    </Grid>
                    <Grid item xs={12} align="center">
                      <Typography className={classes.header}>Prescription</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={1} style={{ textAlign: "right" }}>
                  <img className={classes.ayushLogo} src={leaf} alt="leaf" />
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                <Grid item xs={12} sm={7} align="left" style={{ paddingLeft: "10px" }}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} align="left">
                      <b className={classes.color}> OP ID/ Name: </b>
                      <span className={classes.color}>
                        {registrationID} / {selectedPatient.patientName}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={0}>
                    {diagnosisData &&
                    diagnosisData.finalNsmc &&
                    diagnosisData.finalNsmc.length != 0 &&
                    diagnosisData.finalIcd &&
                    diagnosisData.finalIcd.length != 0 ? (
                      <Grid item xs={12} sm={12} md={12} className={classes.finalDiag}>
                        <Typography className={classes.diag}>
                          <b>Final Diagnosis</b>
                        </Typography>
                      </Grid>
                    ) : null}
                    {diagnosisData && diagnosisData.finalNsmc && diagnosisData.finalNsmc.length != 0 ? (
                      <Grid item xs={12} sm={12} md={12} className={classes.finalDiag}>
                        <Typography className={classes.color}>
                          <b>Diagnosis NSMC</b> :
                          {diagnosisData && diagnosisData.finalNsmc && diagnosisData.finalNsmc.diseaseNameWithCode}
                        </Typography>
                      </Grid>
                    ) : null}
                    {diagnosisData && diagnosisData.finalIcd && diagnosisData.finalIcd.length != 0 ? (
                      <Grid item xs={12} sm={12} md={12} className={classes.finalDiag}>
                        <Typography className={classes.color}>
                          <b>Diagnosis ICD</b> :
                          {diagnosisData && diagnosisData.finalIcd && diagnosisData.finalIcd.diagTermWithCode}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={0}>
                    {homeo && homeo?.length !== 0 ? (
                      <Grid item xs={12} sm={12} md={12} className={classes.tableGrid}>
                        <TableContainer>
                          <Table aria-label="simple table" size="small" className={classes.table}>
                            <TableHead>
                              <StyledTableRow align="center" style={{ backgroundColor: myOwnColor }}>
                                {[
                                  "S.No",
                                  "Medicine Name",
                                  "Dose Qty",
                                  "Potency",
                                  "Units",
                                  "No Of Days",
                                  "Interval",
                                  "AF/ BF",
                                  "Vehicle",
                                  "Remarks",
                                ].map((header) => (
                                  <StyledTableCell align="center">
                                    <b className={classes.color}>{header}</b>
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {homeo?.map((row, id) => (
                                <TableRow key={row.id}>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {id + 1}.
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {
                                      data?.prescription?.simpleMedicines[row.medicineType.toString()]?.find(
                                        (d) => d.id === row.medicineName
                                      )?.MedicineName
                                    }
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {row.dosage}
                                  </TableCell>
                                  {row.decimal !== "" && row.decimal !== 0 ? (
                                    <TableCell align="center" className={classes.tableCell}>
                                      {`${homeoPotency?.Decimal?.find((s) => s.id === row.decimal)?.typeName} Decimal`}
                                    </TableCell>
                                  ) : null}
                                  {row.centesimal !== "" && row.centesimal !== 0 ? (
                                    <TableCell align="center" className={classes.tableCell}>
                                      {`${
                                        homeoPotency?.Centesimal?.find((s) => s.id === row.centesimal)?.typeName
                                      } Centesimal`}
                                    </TableCell>
                                  ) : null}
                                  {row.millesimal !== "" && row.millesimal !== 0 ? (
                                    <TableCell align="center" className={classes.tableCell}>
                                      {`${
                                        homeoPotency?.Millesimal?.find((s) => s.id === row.millesimal)?.typeName
                                      } Millesimal`}
                                    </TableCell>
                                  ) : null}
                                  <TableCell align="center" className={classes.tableCell}>
                                    {row.uomName}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {row.noDays}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.shortName}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {row.isBf === "true" ? "AF" : row.isBf === "false" ? "BF" : null}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {vehicleNames?.find((s) => s.id === row.medicineVehicle)?.vehicleName}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    {row.remarks}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : null}
                    {simple?.length !== 0 ||
                    complexData?.length !== 0 ||
                    external?.length !== 0 ||
                    paid?.length !== 0 ? (
                      <Grid item xs={12} sm={12} md={12} className={classes.tableGrid}>
                        <TableContainer>
                          <Table className={classes.table} size="small" aria-label="simple table">
                            <TableHead>
                              <StyledTableRow align="center" style={{ backgroundColor: myOwnColor }}>
                                <StyledTableCell align="center">
                                  <b className={classes.color}>S.No</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.color}>Medicine Name</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.color}>Dose Qty</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.color}>Unit</b>
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  <b className={classes.intervalLabel}>FN</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.intervalLabel}>AN</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.intervalLabel}>E</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.intervalLabel}>N</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.intervalLabel}>AF BF</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.color}> Days</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b className={classes.color}>Vehicle</b>
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  <b className={classes.color}>Remarks</b>
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>

                            <TableBody>
                              <>
                                {complexData?.length !== 0 ? (
                                  <TableRow>
                                    <TableCell
                                      className={classes.heading}
                                      align="center"
                                      style={{ border: "1px solid black" }}
                                      colSpan={12}
                                    >
                                      <b className={classes.color}>Mixture Medicine</b>
                                    </TableCell>
                                  </TableRow>
                                ) : null}
                                {complexData?.length !== 0 ? (
                                  <>
                                    {complexData?.map((row, rowIndex) => (
                                      <>
                                        {row?.rows?.map((x, i) => (
                                          <TableRow key={row.id}>
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                              >
                                                {rowIndex + 1}
                                              </TableCell>
                                            ) : null}
                                            <TableCell className={classes.tableCell} align="center">
                                              {
                                                data?.prescription?.complexMedicines[x?.medicineType?.toString()]?.find(
                                                  (d) => d.id === x.medicineName
                                                )?.MedicineName
                                              }
                                            </TableCell>

                                            <TableCell className={classes.tableCell} align="center">
                                              {x.dosage}
                                            </TableCell>

                                            <TableCell className={classes.tableCell} align="center">
                                              {x.uomName}
                                            </TableCell>
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                                align="center"
                                              >
                                                {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.morning ===
                                                1 ? (
                                                  <CheckIcon className={classes.checkboxIcon} />
                                                ) : (
                                                  "-"
                                                )}
                                              </TableCell>
                                            ) : null}
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                                align="center"
                                              >
                                                {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.noon === 1 ? (
                                                  <CheckIcon className={classes.checkboxIcon} />
                                                ) : (
                                                  "-"
                                                )}
                                              </TableCell>
                                            ) : null}
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                                align="center"
                                              >
                                                {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.evening ===
                                                1 ? (
                                                  <CheckIcon className={classes.checkboxIcon} />
                                                ) : (
                                                  "-"
                                                )}
                                              </TableCell>
                                            ) : null}
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                                align="center"
                                              >
                                                {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.night ===
                                                1 ? (
                                                  <CheckIcon className={classes.checkboxIcon} />
                                                ) : (
                                                  "-"
                                                )}
                                              </TableCell>
                                            ) : null}
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                              >
                                                {row?.isBf === "true" ? "AF" : row.isBf === "false" ? "BF" : null}
                                              </TableCell>
                                            ) : null}
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                              >
                                                {row.noDays}
                                              </TableCell>
                                            ) : null}
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                              >
                                                {vehicleNames?.find((s) => s.id === row.medicineVehicle)?.vehicleName}
                                              </TableCell>
                                            ) : null}
                                            {i + 1 === 1 ? (
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                                rowSpan={i + 1 === 1 ? row?.rows?.length : null}
                                              >
                                                {row.remarks}
                                              </TableCell>
                                            ) : null}
                                          </TableRow>
                                        ))}
                                      </>
                                    ))}
                                  </>
                                ) : null}
                              </>

                              <>
                                {simple?.length !== 0 ? (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        className={classes.heading}
                                        align="center"
                                        style={{ border: "1px solid black" }}
                                        colSpan={12}
                                      >
                                        <b className={classes.color}>Single Medicine</b>
                                      </TableCell>
                                    </TableRow>

                                    {simple?.map((row, i) => (
                                      <TableRow key={row.i}>
                                        <TableCell className={classes.tableCell} align="center">
                                          {i + 1}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {
                                            data?.prescription?.simpleMedicines[row.medicineType.toString()]?.find(
                                              (d) => d.id === row.medicineName
                                            )?.MedicineName
                                          }
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.dosage}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.uomName}
                                        </TableCell>

                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.morning === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.noon === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.evening === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.night === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.isBf && row.isBf === "true" ? "AF" : row.isBf === "false" ? "BF" : null}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.noDays}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {vehicleNames?.find((s) => s.id === row.medicineVehicle)?.vehicleName}
                                        </TableCell>

                                        <TableCell align="center" className={classes.tableCell}>
                                          {row.remarks}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                ) : null}
                              </>

                              {paid?.length !== 0 ? (
                                <>
                                  <TableRow>
                                    <TableCell
                                      className={classes.heading}
                                      align="center"
                                      style={{ border: "1px solid black" }}
                                      colSpan={12}
                                    >
                                      <b className={classes.color}>Paid Medicine</b>
                                    </TableCell>
                                  </TableRow>
                                  {paid &&
                                    paid.map((row, i) => (
                                      <TableRow key={row.i} className={classes.tableCell}>
                                        <TableCell className={classes.tableCell} align="center">
                                          {i + 1}
                                        </TableCell>
                                        <TableCell align="center" className={classes.tableCell}>
                                          {
                                            data?.prescription?.paidMedicines[row.medicineType.toString()]?.find(
                                              (d) => d.id === row.medicineName
                                            )?.MedicineName
                                          }
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.dosage}
                                        </TableCell>
                                        <TableCell align="center" className={classes.tableCell}>
                                          {row.uomName}
                                        </TableCell>

                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.morning === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.noon === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.evening === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.night === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.isBf && row.isBf === "true" ? "AF" : row.isBf === "false" ? "BF" : null}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.noDays}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {vehicleNames?.find((s) => s.id === row.medicineVehicle)?.vehicleName}
                                        </TableCell>

                                        <TableCell align="center" className={classes.tableCell}>
                                          {row.remarks}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </>
                              ) : null}
                              {external?.length !== 0 ? (
                                <>
                                  <TableRow>
                                    <TableCell
                                      className={classes.heading}
                                      align="center"
                                      style={{ border: "1px solid black" }}
                                      colSpan={12}
                                    >
                                      <b className={classes.color}>External Medicine</b>
                                    </TableCell>
                                  </TableRow>
                                  {external &&
                                    external.map((row, id) => (
                                      <TableRow key={row.id} className={classes.tableCell}>
                                        <TableCell className={classes.tableCell} align="center">
                                          {id + 1}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {
                                            data?.prescription?.externalMedicines[row.medicineType.toString()]?.find(
                                              (d) => d.id === row.medicineName
                                            )?.MedicineName
                                          }
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.dosage}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.uomName}
                                        </TableCell>

                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.morning === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.noon === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.night === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {drugIntervals?.find((d) => d.id === row.drugIntervalId)?.night === 1 ? (
                                            <CheckIcon className={classes.checkboxIcon} />
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          -
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.noDays}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                          {row.mixwith}
                                        </TableCell>

                                        <TableCell align="center" className={classes.tableCell}>
                                          {row.remarks}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </>
                              ) : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid items xs={12} sm={12}>
                {!isNaN(data?.prescription?.followUpVisitDay) ? (
                  <Typography className={classes.follow}>
                    <b>Follow - Up in : </b>
                    {data?.prescription?.followUpVisitDay}
                    Days
                  </Typography>
                ) : null}
              </Grid>
              <Grid items xs={12} sm={12}>
                {data?.prescription?.prescriptionRemarks ? (
                  <Typography className={classes.follow}>
                    <b>Prescription Remarks :</b>
                    {data?.prescription?.prescriptionRemarks}
                  </Typography>
                ) : null}
              </Grid>
              <Grid container spacing={0}>
                <Grid items xs={12} sm={12}>
                  {(data?.doDont?.selectedDos && data?.doDont?.selectedDos?.length !== 0) ||
                  data?.doDont?.dosRemarks?.length !== 0 ? (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.doDontGrid} align="left">
                        <Typography className={classes.diag}>
                          <b>Do's</b>
                        </Typography>

                        <List dense className={classes.doList}>
                          {dos?.map((v) => (
                            <ListItem className={classes.list}>
                              <ListItemText primary={v.advice} className={classes.dosLabel} />
                            </ListItem>
                          ))}
                        </List>
                        {data?.doDont?.dosRemarks ? (
                          <Grid item xs={6} className={classes.text2}>
                            <Typography className={classes.text2}>
                              <b>Do's Remarks : </b>
                              <span>{data?.doDont?.dosRemarks}</span>
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                <Grid items xs={12} sm={12}>
                  {(data?.doDont?.selectedDonts && data?.doDont?.selectedDonts?.length !== 0) ||
                  data?.doDont?.dontsRemarks?.length !== 0 ? (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.doDontGrid} align="left">
                        <Typography className={classes.diag}>
                          <b>Dont's</b>
                        </Typography>
                        <List dense className={classes.doList}>
                          {donts?.map((v) => (
                            <ListItem className={classes.list}>
                              <ListItemText primary={v.advice} className={classes.dosLabel} />
                            </ListItem>
                          ))}
                        </List>
                        {(data?.doDont?.selectedDonts && data?.doDont?.selectedDonts?.length !== 0) ||
                        data?.doDont?.dontsRemarks !== 0 ? (
                          <Grid item xs={6} className={classes.text2}>
                            <Typography className={classes.text2}>
                              <b>Dont's Remarks : </b>
                              <span>{data?.doDont?.dontsRemarks}</span>
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} align="right">
                  <Typography className={classes.color}>
                    <b>Prescribed by :</b>
                    <span style={{ textTransform: "uppercase" }}>{StaffLogin.staffName}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} align="right">
                  <Typography className={classes.color}>
                    <b>Date :</b> {moment(new Date())?.format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} align="center">
                  <Typography className={classes.color}>
                    <b> Notes</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                  <Typography className={classes.color}>
                    FN - Fore Noon , AN - After Noon , E - Evening, N - Night, AF - After Food , BF - Before Food
                  </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                  <Typography className={classes.color}>
                    *** Digital Prescription. Signature not required. ***
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </div>
        <DialogActions>
          <CloseButton onClick={handleClose}>Close</CloseButton>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" style={{ backgroundColor: "#0C51B8", color: "white" }}>
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </DialogActions>
      </Dialog2>
    </div>
  );
}
