import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  InputAdornment,
  IconButton,
  TableBody,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InventoryInstance from "../../../api/InventoryInstance";
import { Search } from "@material-ui/icons";
import EntryForm from "./EntryForm";
import { SHOW_SNACKBAR } from "../../../actions/Type";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NotificationSuccess from "../../../component/Notification/NotificationSuccess";
import moment from "moment";
import Controls from "../../../component/controls/Controls";
import useTable from "../../../component/useTable";
import { faEdit, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import DialogActions from "@material-ui/core/DialogActions";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ahmis from "../../../assets/ahmis.png";
import leaf from "../../../assets/leaf.png";

const headCells = [
  { id: "srNo", label: "Sl No." },
  { id: "purchaseOrderId", label: "Purchase Order ID" },
  { id: "vendorName", label: "Vendor Name" },
  { id: "totalBillAmount", label: "Total Bill Amount (₹)" },
  { id: "reorder", label: "Reorder" },

  { id: "status", label: "Status" },
  { id: "billNo", label: "Bill No." },
  { id: "entryDate", label: "Entry Date" },

  { id: "receiptNo", label: "Receipt No." },
  { id: "action", label: "Action", disableSorting: true },
];

//css defined
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "0.5%",
    marginLeft: "-0.5%",
  },
  icon: {
    color: "white",
  },
  content: {
    backgroundColor: "white",
    color: "black",
    display: "block",
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
  btnPending: {
    backgroundColor: "rgb(231 108 25)",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
    zIndex: -1,
  },
}));

export const defaultComplexRow = () => ({
  batchNo: "",
  qty: "",
  mfgDate: null,
  expDate: null,
});

export const defaultComplex = () => ({
  drugTypeName: "",
  drugName: "",
  uomName: "",
  singleUnit: "",
  approvedQty: "",
  singleQtyPrice: "",
  price: "",
  id: "",
  rows: [defaultComplexRow(), defaultComplexRow(), defaultComplexRow(), defaultComplexRow()],
});

function Inner({ value: medicine, onChange: setMedicine }, ref) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const style = {
    cellPadding: 1, // a number, array or object (see margin below)
    fontSize: 8,
    font: "helvetica", // helvetica, times, courier
    lineWidth: 0.01,
    lineColor: 127,
    fontStyle: "normal", // normal, bold, italic, bolditalic
    overflow: "linebreak", // visible, hidden, ellipsize or linebreak
    fillColor: false, // false for transparent or a color as described below
    textColor: 0,
    halign: "left", // left, center, right
    valign: "middle", // top, middle, bottom
    cellWidth: "auto", // 'auto', 'wrap' or a number
  };
  const [W, H] = [240, 297];
  const margin = 10;
  const complexRowSchema = yup.object().shape({
    batchNo: yup.string(),

    mfgDate: yup.string(),

    expDate: yup.string(),
  });
  //validation
  const complexSchema = yup.object().shape({
    drugTypeName: yup.string(),
    drugName: yup.string(),
    uomName: yup.string(),
    singleUnit: yup.string(),
    approvedQty: yup.string(),
    singleQtyPrice: yup.string(),
    price: yup.string(),
    id: yup.string(),
    rows: yup.array().of(complexRowSchema).required("Must have fields"),
  });

  const schema = yup.object().shape({
    billNo: yup.string().nullable().required("This field is required"),
    complex: yup.array().of(complexSchema).required("Must have fields"),
  });

  const defaultValues = {
    complex: [],
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    setValue,
    setError,
    getValues,
    trigger,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  React.useImperativeHandle(ref, () => {
    return {
      isValid: trigger,
    };
  });

  const historyRef = React.useRef(null);

  const complexRef = React.useRef(null);

  const uncheckHistory = React.useCallback((mainIndex, index) => historyRef.current?.uncheck(mainIndex, index), [historyRef]);
  const staff = useSelector(state => state.loginReducer.staffLogin);

  const orgId = staff?.orgId;
  const staffId = staff?.staffId;

  const [editData, setDataEdit] = React.useState(null);
  const [viewData, setdata] = React.useState(null);
  const [overAllData, setOverAllData] = React.useState(null);
  const [complexMedicines, setComplexMedicines] = React.useState({});
  const [followUpDateRadio, setFollowUpDateRadio] = React.useState("");
  const [followUpDateText, setFollowUpDateText] = React.useState("");
  const [prescriptionRemarks, setPrescriptionRemarks] = React.useState("");
  const [followUpDate, setFollowUpDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [followUpVisitDay, setFollowUpVisitDay] = React.useState("");
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [viewMood, setViewMood] = useState(false);
  const [viewVBillData, setViewData] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [entryId, setEntryId] = React.useState("");
  const [orderID, setOrderId] = React.useState("");

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setMedicine(med => ({ ...med, value }));
      // trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    setMedicine(med => ({
      ...med,

      complexMedicines,
      prescriptionRemarks,
      followUpVisitDay,
      followUpDate,
      followUpDateRadio,
      followUpDateText,
    }));
  }, [complexMedicines, prescriptionRemarks, followUpVisitDay, followUpDate, followUpDateRadio, followUpDateText, setMedicine]);
  const [records, setRecords] = React.useState([]);
  const [filterFn, setFilterFn] = React.useState({
    fn: items => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn);

  const handleSearch = e => {
    let value = e.target.value;

    setFilterFn({
      fn: items => {
        if (value === "") {
          return items;
        }
        return items.filter(
          x =>
            x.billNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.purchaseOrderNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.vendorName?.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
  };

  const fetchpurchaseReceipt = () => {
    setLoading(true);
    InventoryInstance.get("purchaseReceipt/fetchOrdByOrg/" + orgId)
      .then(res => {
        var mydatais = res.data.sort((a, b) => a.id - b.id);

        setRecords(mydatais);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchBillData = (orderId, entryId) => {
    setViewMood(true);
    setIsEditMode(true);
    setEntryId(entryId);

    setOrderId(orderId);
    InventoryInstance.get(`purchaseEntry/fetchByEntryId/${orderId}/${entryId}`)
      .then(response => {
        return response.data;
      })
      .then(data => {
        setdata(data);
        window.scroll(0, 0);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const generatePdf = (report, data, staffData) => {
    const org = staffData.orgName;
    console.log(data);
    const now = moment(new Date()).format("DD-MM-YYYY");
    const time = new Date().toLocaleTimeString();

    const getlength = data && data.purchaseOrderDetailsDtos?.map((d, index) => getSum(d.entryDetDtoSet?.map(a => a.entryDetailsDrugDtos.length)));

    const body = [
      ...data.purchaseOrderDetailsDtos
        .map(d => [
          [
            {
              content: d.drugTypeName,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d.drugName,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d.uomName,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d.singleUnit !== undefined ? d.singleUnit : "",
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d.requiredQty,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            {
              content: d.approvedQty,
              rowSpan: d.entryDetailsDrugDtl.length,
              styles: { halign: "left" },
            },
            moment(d.entryDetailsDrugDtl[0].manufactureDate).format("DD-MM-YYYY"),
            moment(d.entryDetailsDrugDtl[0].expiryDate).format("DD-MM-YYYY"),
            d.entryDetailsDrugDtl[0].serialNo,
            d.entryDetailsDrugDtl[0].receivedQty,
          ],
          ...d.entryDetailsDrugDtl.slice(1).map(dl => [moment(dl.manufactureDate).format("DD-MM-YYYY"), moment(dl.expiryDate).format("DD-MM-YYYY"), dl.serialNo, dl.receivedQty]),
        ])
        .flat(),
    ];
    const pdf = new jsPDF("p", "mm", [W, H], true);
    let Y = margin;
    pdf.addImage(ahmis, "PNG", margin, Y + 2, 15, 15);
    pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
    pdf.setFontSize(14);
    let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
    pdf.text(splitTitle, W / 2, Y, { align: "center" });
    pdf.setFontSize(13);
    pdf.text(report, W / 2, Y + 15, "center");
    Y += 30;
    pdf.setFontSize(10);
    pdf.text(`Purchase Order No.: ${data.purchaseOrderNo}`, margin, Y);
    // pdf.text(`Status: ${filterData[0]?.entryStatus},`, margin, Y + 5);
    pdf.text(`Vendor Name: ${data?.vendorName},`, margin, Y + 7);
    pdf.text(`GST No.: ${data?.vendorGstNo},`, margin, Y + 15);
    pdf.text(`CGST: ${data?.vendorCgst}`, W - margin, Y + 7, "right");
    pdf.text(`SGST: ${data?.vendorSgst}`, W - margin, Y + 15, "right");

    Y += 20;

    pdf.autoTable({
      head: [["Drug Type", "Drug Name", "UoM", "Single Unit", "Required Qty", "Approved Qty", "Mfg. Date", "Exp. Date", "Serial/Batch No.", "Qty"]],
      body: body,

      foot: [
        [
          {
            content: `Total Amount(₹).: ${data.totalBillAmount}`,
            colSpan: 10,
            styles: {
              halign: "right",
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
          },
        ],
      ],
      startY: Y,
      margin,
      headStyles: {
        ...style,
        fillColor: [11, 156, 171],
        textColor: 255,
        lineColor: 255,
        fontSize: 8,
        fontWeight: 500,
      },
      bodyStyles: { ...style },
      footStyles: { ...style },
    });

    pdf.save(`Purchase Receipt Report-${now}, ${time}.pdf`);
  };

  const printDocument = (orderId, entryId) => {
    const reportName = "Purchase Receipt Report";
    //  const filterData = recordsSubmitted.filter(item => item.purOrdId === id);
    InventoryInstance.get(`purchaseEntry/fetchByEntryId/${orderId}/${entryId}`)
      .then(res => {
        generatePdf(
          reportName,

          res.data,
          staff
        );
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something went wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const onFormSubmit = data => {
    setOpen(true);
  };
  const handleReceipt = () => {
    const req = {
      purchaseOrderId: orderID,
      organizationId: orgId,
      createdBy: staffId,
      approvalStatus: 1,
      billNo: viewData.billNo,
      purchaseEntryId: entryId,

      purchaseOrderNo: viewData.purchaseOrderNo,
      vendorNameId: viewData.vendorNameId,
      totalBillAmount: viewData.totalBillAmount,
      expectedDate: moment(viewData.expectedDate).format("YYYY-MM-DD"),
    };

    InventoryInstance.post(`purchaseReceipt/save`, req)
      .then(response => {
        if (response.data === "success") {
          setOpen(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "success",
              msg: "Record updated successfully",
              openSnackbar: true,
            },
          });
          reset(defaultValues);
          setIsEditMode(false);
          setViewMood(false);

          fetchpurchaseReceipt();
        } else {
          setOpen(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              msg: "Something went wrong",
              openSnackbar: true,
            },
          });
        }
      })
      .catch(() => {
        setOpen(false);
        console.log("error");
      });
  };

  const handleClose = (e, type) => {
    setOpen(false);
  };
  React.useEffect(() => {
    fetchpurchaseReceipt();
  }, []);

  const initialValue = 0;

  const getSum = arr => {
    if (Array.isArray(arr)) {
      return arr.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
    }
  };

  const getlength = overAllData && overAllData.purchaseOrderDetailsDtos.map((d, index) => getSum(d.entryDetDtoSet.map(a => a.entryDetailsDrugDtos.length)));

  console.log(getlength);

  return (
    <>
      <div className={classes.root}>
        <NotificationSuccess />
        {isEditMode ? (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={0} style={{ marginTop: "1%" }}>
              <Grid items xs={12} align="center">
                <EntryForm
                  viewMood={viewMood}
                  ref={complexRef}
                  control={control}
                  editData={editData}
                  viewData={viewData}
                  watch={watch}
                  setError={setError}
                  reset={reset}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors.complex}
                  uncheckHistory={uncheckHistory}
                  complexMedicines={complexMedicines}
                  setComplexMedicines={setComplexMedicines}
                  isReceipt
                />
              </Grid>
              <Grid items xs={12} align="right">
                {viewVBillData ? (
                  <Button
                    onClick={() => {
                      setIsEditMode(false);
                      setViewMood(false);
                      setViewData(false);
                      reset(defaultValues);
                    }}
                    variant="contained"
                    style={{ margin: "5px" }}
                  >
                    Close
                  </Button>
                ) : (
                  <>
                    <Button onClick={handleSubmit(data => onFormSubmit(data))} variant="contained" style={{ margin: "5px" }} color="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={() => {
                        setIsEditMode(false);
                        setViewMood(false);
                        setViewData(false);
                        reset(defaultValues);
                      }}
                      variant="contained"
                      style={{ margin: "5px" }}
                    >
                      Close
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        ) : null}
      </div>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={10} align="right">
          <Controls.Input
            style={{ maxWidth: 300 }}
            label="Search"
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <TableContainer style={{ maxHeight: "570px" }}>
            {loading && <LinearProgress />}
            <Table size="small" stickyHeader>
              <TblHead />
              {recordsAfterPagingAndSorting()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map(item => (
                    <>
                      <TableRow key={item.id}>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.srNo}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.purchaseOrderNo}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.vendorName}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.totalBillAmount}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>{item.reorder === false ? "No" : "Yes"}</TableCell>
                        <TableCell rowSpan={item.entryDet.length + 1}>
                          <Controls.Button
                            size="small"
                            className={item.receiptStatus === "Pending" ? classes.btnPending : item.receiptStatus === "Completed" ? classes.btnActive : classes.btnInActive}
                            text={item.receiptStatus === "Pending" ? "Pending" : "Completed"}
                          ></Controls.Button>
                        </TableCell>
                      </TableRow>
                      {item.entryDet.map(subItem => (
                        <TableRow key={item.id}>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {subItem.billNo}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {moment(subItem.entryDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {subItem.receiptNo}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "0px 7px 0px 10px",
                            }}
                          >
                            {subItem.receiptNo === "NA" ? (
                              <IconButton
                                color="primary"
                                onClick={e => {
                                  reset(defaultValues);
                                  setViewMood(true);
                                  setViewData(false);
                                  fetchBillData(item.orderId, subItem.entryId);
                                }}
                              >
                                <FontAwesomeIcon title="Edit" style={{ color: "#ff6600", fontSize: "13px" }} icon={faEdit} />
                              </IconButton>
                            ) : (
                              <>
                                <IconButton
                                  color="primary"
                                  onClick={e => {
                                    reset(defaultValues);
                                    setViewMood(true);
                                    setViewData(true);
                                    fetchBillData(item.orderId, subItem.entryId);
                                  }}
                                >
                                  <FontAwesomeIcon title="View" style={{ color: "#1957b3", fontSize: "13px" }} icon={faEye} />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  onClick={e => {
                                    reset(defaultValues);
                                    setViewMood(true);
                                    printDocument(item.orderId, subItem.entryId);
                                  }}
                                >
                                  <FontAwesomeIcon title="Print Pdf" style={{ color: "#f55140", fontSize: "15px" }} icon={faFilePdf} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <StyledTableCell colSpan={10} align="center">
                      No Records Found
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TblPagination />
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" justifyContent="center">
        <DialogTitle id="alert-dialog-title" align="center" style={{ width: 300 }}>
          <p style={{ fontWeight: 400, lineHeight: "26px", margin: "0px", fontSize: 24 }}>Are you sure?</p>
        </DialogTitle>
        {/* <DialogContent></DialogContent> */}
        <DialogActions style={{ justifyContent: "center", paddingBottom: "20px" }}>
          <Button
            onClick={e => {
              handleReceipt(e);
            }}
            color="primary"
            variant="contained"
            size="small"
          >
            Yes
          </Button>
          <Button
            onClick={e => {
              handleClose();
            }}
            variant="contained"
            size="small"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const PurchaseReceipt = React.forwardRef(Inner);
export default PurchaseReceipt;
