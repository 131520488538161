import { Box, Button, Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../App.css";
import { fetchGoodFeedbacks } from "../actions/FeedbackAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 120,
    padding: theme.spacing(2),
  },
  date:{
    fontSize:10,
    opacity:0.7
  },
  name:{   
    fontSize:14,
    color:"#090808",    
    fontWeight: 500,
    wordBreak: "break-word",
  },
  organizationName:{   
    fontSize:12,
    color: "#103A4D",
    fontWeight: 500,
    wordBreak: "break-all",
  },
  feedBack:{
    marginTop:16,
    opacity:1,
    color:"#000"
  },
  title:{
    backgroundColor:"#f1f1f1",
    display:"inline-block",
    padding:"8px 16px",
    borderRadius:4 ,
    color: "#052657",
    fontWeight: "bold",
    fontFamily: "unset",
    fontSize: "16px",
  },
  viewMore:{
    marginTop: 10,
    backgroundColor: theme.palette.primary.dark, 
    color:"#fff",
    fontFamily:'unset',
    wordWrap:"break-word",
    fontWeight: "bold",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
      color:"#fff"
    }
  }
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const goodFeedBack = useSelector(
    (state) => state.feedBackReducer.goodFeedBack
  );
  useEffect(() => {
    dispatch(fetchGoodFeedbacks());
  }, []);

  return (
    <Box style={{ border: "2px solid #aaaaaa",position:"relative"}}>
    <Grid
      container
      spacing={2}
      // style={{ border: "2px solid #aaaaaa",position:"relative"}}
      justifyContent="space-evenly"
    >
      <Grid items xs={12}>
        <Typography
        className={classes.title}
          style={{
            // margin: "-25px 0px 0px 20px",
            position:"absolute",
            top:-20,
            left:40,           
          }}
        >
          USER TESTIMONY
        </Typography>
      </Grid>

      <Grid item xs={12} style={{marginTop:4,marginRight:18}}>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} align="right">
            <Link to="/ViewMore"  style={{ textDecoration: "none" }}>
              <Button className={classes.viewMore} variant="contained">VIEW MORE</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-evenly" style={{padding:16}}>
          {goodFeedBack &&
            goodFeedBack.map((feedBack, id) =>
              id <= 3 ? (
                // {goodFeedBack && goodFeedBack.slice(-4).map((feedBack,id) => (
                <Grid item lg={3} xs={12} md={3} key={id} >
                  <Card  className={classes.paper} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5} md={12} lg={7} xl={8}>
                        <Typography className={classes.name}>{feedBack.name}</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="right">
                        <Rating
                          name="read-only"
                          defaultValue={feedBack.stars}
                          precision={0.5}
                          readOnly
                        ></Rating>
                      </Grid>
                    </Grid>
                    <Typography className={classes.organizationName} >{feedBack.organizationName}</Typography>
                    <Typography className={classes.date}>
                      {moment(feedBack.createdDatetime).format("DD MMM YYYY")}
                    </Typography>

                    <Typography className={classes.feedBack} inputProps={{ maxLength: 500 }}>{feedBack.comments}</Typography>
                  </Card>
                </Grid>
              ) : null
            )}
        </Grid>
      </Grid>

    </Grid>
    </Box>
  );
}
