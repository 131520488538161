import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import NotificationSuccess from "../Notification/NotificationSuccess";
import { restrict } from "../restrict";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%%",
    marginTop: "20px",
  },
  button: {
    border: "1px solid #C4C4C4",
    borderRadius: 20,
    margin: "0px 10px 10px 0px",
    padding: "5px 10px 5px 10px",
  },
  input: {
    height: 35,
    width: 380,
  },
  input1: {
    height: 35,
    width: 50,
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  customInput: {
    [theme.breakpoints.down("lg")]: {
      width: "100% !important",
    },
  },
  icon: {
    color: "#ff6600",
    fontSize: "13px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "13px",
  },
  smallFont: {
    fontSize: "14px",
    color: "#000000",
    opacity: 1,
  },
}));

function GeneralPhysicalExaminations({
  value: physicalValue,
  onChange: setPhysicalValue,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const StaffLogin = useSelector((state) => state.loginReducer.staffLogin);

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  const consultationID = useSelector(
    (state) => state.consultationReducer.patient.consultationId
  );

  const defaultValues = {
    build: "",
    pallor: "",
    icterus: "",
    cyanosis: "",
    oedema: "",
    skin: "",
    clubbing: "",
    lymphadenopathy: "",
  };

  const schema = yup.object().shape({
    treatmentType: yup.string().trim(),
    // .typeError("required")
    // .required("required"),
    treatmentName: yup.string().trim(),
    treatmentNature: yup.string().trim(),
    applicationTo: yup.string().trim(),
  });

  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  return (
    <div className={classes.root}>
      <NotificationSuccess />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={1}>
          <Typography>Built</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="build"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({ ...physicalValue, build: e.target.value });
                }}
                value={
                  physicalValue?.length !== 0 ? physicalValue?.build : value
                }
                placeholder="Built"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={1}>
          <Typography> Pallor</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="pallor"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({
                    ...physicalValue,
                    pallor: e.target.value,
                  });
                }}
                value={
                  physicalValue?.length !== 0 ? physicalValue?.pallor : value
                }
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                placeholder="Pallor"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <br /> <br />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={1}>
          <Typography>Icterus</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="icterus"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({
                    ...physicalValue,
                    icterus: e.target.value,
                  });
                }}
                value={
                  physicalValue?.length !== 0 ? physicalValue?.icterus : value
                }
                inputProps={{ maxLength: 100 }}
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                placeholder="Icterus"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={1}>
          <Typography>Cyanosis</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="cyanosis"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({
                    ...physicalValue,
                    cyanosis: e.target.value,
                  });
                }}
                value={
                  physicalValue?.length !== 0 ? physicalValue?.cyanosis : value
                }
                inputProps={{ maxLength: 100 }}
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                placeholder="Cyanosis"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <br /> <br />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={1}>
          <Typography>Clubbing</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="clubbing"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({
                    ...physicalValue,
                    clubbing: e.target.value,
                  });
                }}
                value={
                  physicalValue?.length !== 0 ? physicalValue?.clubbing : value
                }
                placeholder="Clubbing"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={1}>
          <Typography> Lymphadenopathy</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="lymphadenopathy"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({
                    ...physicalValue,
                    lymphadenopathy: e.target.value,
                  });
                }}
                value={
                  physicalValue?.length !== 0
                    ? physicalValue?.lymphadenopathy
                    : value
                }
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                placeholder="Lymphadenopathy"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <br /> <br />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={1}>
          <Typography>Oedema</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="oedema"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({
                    ...physicalValue,
                    oedema: e.target.value,
                  });
                }}
                value={
                  physicalValue?.length !== 0 ? physicalValue?.oedema : value
                }
                placeholder="Oedema"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={0} md={1}></Grid>
        <Grid item xs={12} sm={4} md={1}>
          <Typography> Skin(appearance)</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            control={control}
            name="skin"
            render={({ field: { onChange, value }, ...props }) => (
              <TextField
                style={{
                  fontSize: "5px",
                }}
                {...props}
                className={classes.customInput}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onChange(e);
                  setPhysicalValue({
                    ...physicalValue,
                    skin: e.target.value,
                  });
                }}
                value={
                  physicalValue?.length !== 0 ? physicalValue?.skin : value
                }
                onInput={
                  restrict.alphanumerichypenbracketscommaspacedotunderscore
                }
                placeholder="Skin (appearance)"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <br /> <br />
    </div>
  );
}

export default GeneralPhysicalExaminations;
