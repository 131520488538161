import { showSnackbar } from "../actions/Notification";
import axiosInstance from "../api/Instance";
import ipdOpdInstance from "../api/IpdOpdInstance";

import {
  FETCH_FEEDBACK_LIST,
  FETCH_ALL_FEEDBACK,
  FETCH_ALL_HEALTH_FACILITIES_LIST,
} from "./Type";

export const saveFeedback = (feedbackData) => async (dispatch) => {
  try {
    const url = "feedback/saveFeedback";

    const response = await ipdOpdInstance.post(url, feedbackData);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res == "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else if (res === "success") {
      responseObj.message = "Feedback added successfully";
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    } else {
      responseObj.message = res;
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchGoodFeedbacks = () => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get("feedback/fetchGoodFeedbacks");

    dispatch({
      type: FETCH_FEEDBACK_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const fetchAllFeedBack = (orgId, date) => async (dispatch) => {
  try {
    const res = await ipdOpdInstance.get(
      `feedback/fetchFeedbacksByFilter/${orgId}/${date}`
    );
    if (res.data !== "Failure") {
      dispatch({
        type: FETCH_ALL_FEEDBACK,
        payload: res.data,
      });
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchAllHealthFacilitiesList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      `organization/getAllFetchHealthFacility`
    );

    dispatch({
      type: FETCH_ALL_HEALTH_FACILITIES_LIST,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
