// var str = "This is link";
// var link = str.link("http://bhis-dev.in/RegistrationForm");
// var msg = "Hi "+link;
// // document.write(msg);
import React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Link } from "react-router-dom";

const AYURVEDA =
  "The Ministry of Ayurveda, Yoga, Naturopathy, Unani, Siddha, Sowa-Rigpa and Homoeopathy (abbreviated as AYUSH) is purposed with developing education, research and propagation of indigenous alternative medicine systems in India.";
const AYURVEDA_SUB =
  "Ayurveda is an alternative medicine system with historical roots in the Indian subcontinent.The Indian Medical Association describes Ayurvedic practitioners who claim to practice medicine as quacks";
const UNANI =
  "Unani or the science of health originated in Greece around 2500 years ago, and its foundation was laid by the Hippocrates. A tradition of Graeco-Arabic medicine, Unani medicine has a herbo-animo-mineral foundation.";
const SIDDHA =
  "Siddha is one of the oldest medicinal systems originated in Tamil Nadu. The word ‘Siddha’ is derived from a Tamil word ‘Siddhi’ which means ‘achievement’ or ‘perfection’. Siddha Medicine is a traditional medicine having its roots in Tamil Nadu, India. ";
const YOGA =
  "Yoga has been derived from the Sanskrit term ‘Yuj’ that means ‘to yoke’ or simply, ‘to unite’. It is a union of the individual spirit with the universal spirit of God. Yoga is a combination of practices, which define the way we engage with the world to create harmony. ";
const NATUROPATHY =
  "Naturopathy is the most ancient health care mechanism that amalgamates modern scientific knowledge with traditional and natural forms of medicine. Relying on the healing power of nature, Naturopathy stimulates the human body’s ability to heal itself.Yoga has been derived from the Sanskrit term ‘Yuj’ that means ‘to yoke’ or simply, ‘to unite’.";
const HOMOEOPATHY =
  "Homoeopathy is a combination of two Greek words: Homois and Pathos. Homois means similar and pathos means suffering. In other words, homoeopathy is a system of treating diseases with remedies.";
const SOWA_RIGPA =
  "Sowa-Rigpa is a system of medicine that is one of the oldest medical traditions across the world. The term Sowa-Rigpa means Knowledge of Healing and derives its meaning from the Bhoti Language. This system was reinforced in the Trans-Himalayan region and is popular in the Himalayan societies such as Ladakh, Himachal Pradesh (Spiti and Lahoul), Jammu and Kashmir, West Bengal (Darjeeling), Arunachal Pradesh, and Sikkim.";
const SORRY =
  "Sorry, I did not understand your query. Please search using the keywords like ayush, ayurveda, naturopathy, homoeopathy, sowa rigpa, unani, siddha, yoga, registration, appointment, facilities, ayush id, registration fee, medicine fee etc.";

// const regex = /(<([^>]+)>)/gi;
// const result = longText.replace(regex, "");
// const result1 = longText1.replace(regex, "");
// const result2 = longText2.replace(regex, "");
// const result3 = longText3.replace(regex, "");
// const result4 = longText4.replace(regex, "");
// const result5 = longText5.replace(regex, "");
// const result6 = longText6.replace(regex, "");
// const result7 = longText7.replace(regex, "");
// const result8 = longText8.replace(regex, "");

const component = <Link to="/RegistrationForm">Here you can get RegistrationForm</Link>;
const component1 = <Link to="/BookAppointment">Yes, Here you can get the Appointment Id</Link>;
const component2 = <Link to="/ayushFacilityList">Here you can get the contact details for Hospitals</Link>;
const component11 = <Link to="/ContactUs">Here you can get the contact details</Link>;
const component3 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {AYURVEDA}
  </ReactReadMoreReadLess>
);
const component4 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {AYURVEDA_SUB}
  </ReactReadMoreReadLess>
);

const component5 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {UNANI}
  </ReactReadMoreReadLess>
);

const component6 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {SIDDHA}
  </ReactReadMoreReadLess>
);

const component7 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {YOGA}
  </ReactReadMoreReadLess>
);

const component8 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {NATUROPATHY}
  </ReactReadMoreReadLess>
);

const component9 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {HOMOEOPATHY}
  </ReactReadMoreReadLess>
);

const component10 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {SOWA_RIGPA}
  </ReactReadMoreReadLess>
);
const component12 = (
  <ReactReadMoreReadLess
    charLimit={45}
    readMoreText={"Read more..."}
    readLessText={"Read less "}
    readMoreClassName=""
    readLessClassName=""
    readMoreStyle={{ color: "blue" }}
    readLessStyle={{ color: "blue" }}
  >
    {SORRY}
  </ReactReadMoreReadLess>
);
const ChartBot = {
  GetChatbotResponse: async (message) => {

    const text = message?.toLowerCase().trim();
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        if (text === "what is ayush" || text === "ayush" || text === "ayush" || text === "what is ayush?")
          resolve(component3);
        // else resolve("echo : " + text);
        else if (text === "what is ayurveda" || text === "ayurveda" || text === "what is ayurveda?")
          resolve(component4);
        else if (text === "what is naturopathy?" || text === "naturopathy" || text === "what is yoga & naturopathy?")
          resolve(component8);
        else if (
          text === "what is homoeopathy?" ||
          text === "homoeopathy" ||
          text === "what is homoeopathy?"
        )
          resolve(component9);
        else if (
          text === "what is sowa rigpa?" ||
          text === "sowa rigpa" ||
          text === "what is sowa rigpa?"
        )
          resolve(component10);
        else if (text === "what is unani" || text === "unani" || text === "What is Unani?") resolve(component5);
        else if (text === "what is siddha?" || text === "siddha" || text === "What is Siddha?") resolve(component6);
        else if (text === "what is yoga" || text === "yoga") resolve(component7);
        else if (text === "how to do registration" || text === "registration" || text === "registration help")
          resolve(component);
        else if (
          text === "how to get appointment id" ||
          text === "appointment" ||
          text === "appointment help" ||
          text === "is it mandate to get the appointment for consultation?" ||
          text === "how can i get my appointment for consultation?" ||
          text === "book appointment"
        )
          resolve(component1);

        if (
          text === "what are the facilities available in the ayush hospitals?" ||
          text === "facilities" ||
          text === "facilities available in the ayush hospitals"
        )
          resolve("Registration, Online Appointment, Out Patient, In-Patient, Lab Investigations, ABHA Number.");

        if (
          text === "is it mandate to get the ayush id?" ||
          text === "ayush id" ||
          text === "mandate to get the ayush id" ||
          text === "is it mandate to get the ayush id? "
        )
          resolve(component);
        if (
          text === "is there any fee for the registration?" ||
          text === "fee for the registration" ||
          text === "registration fee"
        )
          resolve("Nominal one-time fee for registration is charged.");

        if (
          text === "is there any fee for the medicine?" ||
          text === "any fee for the medicine" ||
          text === "medicine fee" ||
          text === "is there any fee for the medicine?"
        )
          resolve("Mostly, it is free but certain rare medicines are chargeable.");

        if (
          text === "are there any fees for the consultation?" ||
          text === "any fees for the consultation" ||
          text === "fee for the consultation"
        )
          resolve("No");
        if (
          text === "my ayush id is enough for consultation in another city or other stream?" ||
          text === "my ayush id is enough for consultation in another city or other stream"
        )
          resolve("Yes");
        if (text === "is there any fee for the medicine" || text === "fee for medicine" || text === "medicine")
          resolve("Yes");

        if (
          text === "is there any fee for the registration?" ||
          text === "fee for  registration" ||
          text === "registration fee" ||
          text === "is there any fee for the registration"
        )
          resolve("Nominal one-time fee for registration is charged.");

        if (
          text === "if i missed my ayush id, how can i get back it?" ||
          text === "ayush id is missed" ||
          text === "ayush id missing" ||
          text === "if i missed my ayush id, how can i get back it"
        )
          resolve("At registration desk, it can be retrieved by providing basic details.");

        if (
          text === "whether the hospitals run during the government holidays?" ||
          text === "hospitals run govt" ||
          text === "government holidays" ||
          text === "whether the hospitals run during the government holidays?" ||
          text === "holidays?" ||
          text === "holidays"
        )
          resolve("Yes, Kindly check with the specific Ayush health facility.");
        else if (["facility", "hospital list", "details for hospitals"].some((el) => text.includes(el)))
          resolve(component2);
        else if (text === "contact us" || text === "contact us details" || text === "contact") resolve(component11);

        if (
          text === "is there any fee for second and subsequent consultation?" ||
          text === "any fee for second consultation" ||
          text === "second consultation" ||
          text === "is there any fee for second and subsequent consultation"
        )
          resolve("It depends on the health facility.");

        if (
          text === "how can i report if any side effect happened?" ||
          text === "side effect" ||
          text === "side effect happened"
        )
          resolve("It can be reported in the consulted health facility.");
        else if (
          message !==
          "Welcome to A-HMIS. If you need any help simply reply to this message, we are online and ready to help.(For examples:registration,book appointment,contact us,fee..)"
        ) {
          resolve(component12);
        }
        resolve(" " + message);
      }, 300);
    });
  },
};

export default ChartBot;
