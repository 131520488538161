import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MoreIcon from "@material-ui/icons/MoreVert";
import Rating from "@material-ui/lab/Rating";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchHealthFacilityBySomId } from "../actions/AdminMasterAction";
import { fetchAllFeedBack, fetchGoodFeedbacks, saveFeedback } from "../actions/FeedbackAction";
import { fetchSom } from "../actions/SimpleMedicineAction";
import ScrollUpHomePage from "./ScrollUpHomePage";
import { inputToUpper, restrict } from "./restrict";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color: "#fff",
    fontSize: 15,
    padding: "8px 20px",
    fontWeight: 500,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
  },
  sectionMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  input: {
    height: 35,
    width: 250,
  },
  input1: {
    // height: 80,
    width: "100%",
  },
  input2: {
    height: 70,
  },
  margin: {
    // margin: "5px",
    // fontSize: "14px",
    //
    color: "#2B3B47",
  },
  center: {
    textAlign: "right",
    fontSize: "18px",

    marginTop: "5px",
  },
  button: {
    backgroundColor: theme.palette.myColor.main,
    color: "#103A4D",
    fontFamily: "unset",
    wordWrap: "break-word",
    fontSize: 14,
    fontWeight: "bold",

    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[200],
      color: "#000",
    },
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    height: 50,
  },
}));

function TopMenu2() {
  const classes = useStyles();

  let soms = useSelector((state) => state.simpleMedicineReducer.som);
  const healthFacilities = useSelector((state) => state.adminMasterReducer.healthFacilities);
  const [value, setValue] = useState(2.5);

  const [date, setDate] = React.useState("NA");
  const [selectedHealth, setSelectedHealth] = useState("0");
  const [feedbackData, setFeedbackData] = useState({
    medicineStreamId: "",
    organizationId: "",
    email: "",
    name: "",
    stars: value,
    comments: "",
    contactNo: "",
  });
  const [nameError, setNameError] = useState("");
  const [medStreamError, setMedStreamError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSom());
  }, []);

  const handleChangeInput = (event) => {
    if (event.target.name === "medicineStreamId") {
      dispatch(fetchHealthFacilityBySomId(event.target.value));
      if (event.target.value === "") {
        setMedStreamError("Medicine Stream is required");
      } else {
        setMedStreamError("");
      }
    }
    if (event.target.name === "name") {
      if (event.target.value === "") {
        setNameError("Name is required");
      } else {
        setNameError("");
      }
    }
    if (event.target.name === "organizationId") {
      if (event.target.value === "") {
        setOrganizationError("Organization is required");
      } else {
        setOrganizationError("");
      }
    }
    if (event.target.name === "contactNo") {
      if (event.target.value?.length > 0 && event.target.value?.length !== 10) {
        setContactNoError("Contact No. length must be 10 digits");
      } else {
        setContactNoError("");
      }
    }
    setFeedbackData({
      ...feedbackData,
      [event.target.name]: event.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const submitFeedback = () => {
    if (feedbackData.name === "") {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
    if (feedbackData.medicineStreamId === "") {
      setMedStreamError("Medicine Stream is required");
    } else {
      setMedStreamError("");
    }
    if (feedbackData.organizationId === "") {
      setOrganizationError("Organization is required");
    } else {
      setOrganizationError("");
    }
    if (feedbackData.contactNo.length > 0 && feedbackData.contactNo?.length !== 10) {
      setContactNoError("Contact No. length must be 10 digits");
    } else {
      setContactNoError("");
    }
    console.log("feedbackData", feedbackData);
    if (
      feedbackData.name !== "" &&
      feedbackData.medicineStreamId !== "" &&
      feedbackData.organizationId !== "" &&
      contactNoError === ""
    ) {
      dispatch(saveFeedback(feedbackData)).then((res) => {
        // setOpen(false);
        setFeedbackData({
          name: "",
          medicineStreamId: "",
          organizationId: "",
          email: "",
          stars: value,
          comments: "",
          contactNo: "",
        });
        dispatch(fetchAllFeedBack(selectedHealth, date));
        setOpen(false);
        dispatch(fetchGoodFeedbacks());
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <Button style={{ color: "black" }}>HOME</Button>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/aboutcontent" style={{ textDecoration: "none" }}>
            <Button style={{ color: "black" }}>ABOUT A-HMIS</Button>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/ayushFacilityList" style={{ textDecoration: "none" }}>
            <Button style={{ color: "black" }}>AYUSH HEALTH FACILITIES</Button>
          </Link>
        </MenuItem>
        <MenuItem>
          <Button onClick={handleClickOpen} style={{ color: "#103A4D", cursor: "pointer" }}>
            FEEDBACK
          </Button>
        </MenuItem>
        <MenuItem>
          <Link to="/ContactUs" style={{ textDecoration: "none" }}>
            <Button style={{ color: "black" }}>CONTACT US</Button>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/StaffLogin" style={{ textDecoration: "none" }}>
            <Button style={{ color: "black" }}>STAFF LOGIN</Button>
          </Link>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      {renderMobileMenu}
      {renderMenu}
      <ScrollUpHomePage />
      <Grid container alignItems="center" className={classes.appBar} style={{ position: "relative" }}>
        <Grid item className={classes.sectionDesktop}>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <Typography className={classes.menuButton}>HOME</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.sectionDesktop}>
          <Link to="/aboutcontent" style={{ textDecoration: "none" }}>
            <Typography className={classes.menuButton}>ABOUT A-HMIS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.sectionDesktop}>
          <Link to="/ayushFacilityList" style={{ textDecoration: "none" }}>
            <Typography className={classes.menuButton}>AYUSH HEALTH FACILITIES</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.sectionDesktop}>
          <Typography onClick={handleClickOpen} className={classes.menuButton} style={{ cursor: "pointer" }}>
            FEEDBACK
          </Typography>
        </Grid>
        <Grid item className={classes.sectionDesktop}>
          <Link to="/ContactUs" style={{ textDecoration: "none" }}>
            <Typography className={classes.menuButton}>CONTACT US</Typography>
          </Link>
        </Grid>

        <Grid item className={classes.sectionDesktop} style={{ position: "absolute", right: 20 }} align="right">
          <Link to="/StaffLogin" style={{ textDecoration: "none" }}>
            <Button className={classes.button}>Staff Login</Button>
          </Link>
        </Grid>

        <Grid item xs={12} className={classes.sectionMobile} align="right">
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            style={{ color: "#fff" }}
          >
            <MoreIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle>
              <Typography
                style={{
                  color: "#0B9CAB",
                  fontSize: "18px",
                }}
              >
                FEEDBACK
              </Typography>
            </DialogTitle>
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton onClick={handleClose} edge="start" color="primary" aria-label="menu">
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Box container style={{ marginBottom: 16 }}>
            <Typography>We value your feedback.</Typography>
            <Typography>
              Please complete the following form and help us to improve your experience in the future.
            </Typography>
          </Box>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography>
                Your rating <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Rating
                name="stars"
                value={value}
                precision={0.5}
                onChange={(event, newValue) => {
                  console.log("newValue1", newValue);
                  setValue(newValue);
                  setFeedbackData({
                    ...feedbackData,
                    [event.target.name]: event.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>
                Name <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                placeholder="Name"
                variant="outlined"
                size="small"
                name="name"
                value={feedbackData.name}
                fullWidth
                onChange={handleChangeInput}
                onInput={(e) => {
                  restrict.name(e);
                  inputToUpper(e);
                }}
                required
                inputProps={{ maxLength: 100 }}
                error={nameError !== ""}
                helperText={nameError}
                // InputProps={{
                //   className: classes.input,
                // }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>Contact Number</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                placeholder="Contact Number"
                variant="outlined"
                size="small"
                name="contactNo"
                value={feedbackData.contactNo}
                onChange={handleChangeInput}
                fullWidth
                inputProps={{ maxLength: 10 }}
                onInput={restrict.digits}
                error={contactNoError !== ""}
                helperText={contactNoError}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>Email</Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                placeholder="Email"
                variant="outlined"
                size="small"
                name="email"
                value={feedbackData.email}
                onChange={handleChangeInput}
                fullWidth
                onInput={restrict.email}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>
                Stream of Medicine <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl style={{ width: "100%" }} variant="outlined" size="small">
                <Select
                  native
                  id="somId"
                  onChange={handleChangeInput}
                  value={feedbackData.medicineStreamId}
                  name="medicineStreamId"
                  required
                  helperText={medStreamError}
                >
                  <option value="" disabled selected>
                    System of Medicine
                  </option>

                  {soms &&
                    soms.map((som) => {
                      return <option value={som.id}>{som.streamName}</option>;
                    })}
                </Select>
                <FormHelperText error>{medStreamError}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>
                Health Facility <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl style={{ width: "100%" }} variant="outlined" size="small">
                <Select
                  native
                  id="insitute"
                  value={feedbackData.organizationId}
                  onChange={handleChangeInput}
                  name="organizationId"
                  required
                >
                  <option value="" disabled selected>
                    Health Facility
                  </option>
                  {healthFacilities &&
                    healthFacilities.map((healthFacility) => {
                      return <option value={healthFacility.id}>{healthFacility.organisationName}</option>;
                    })}
                </Select>
                <FormHelperText error>{organizationError}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>Your Comments</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                placeholder="Your Comments"
                variant="outlined"
                size="small"
                name="comments"
                fullWidth
                required
                value={feedbackData.comments}
                onChange={handleChangeInput}
                inputProps={{ maxLength: 300 }}
                multiline
                rows={4}
                InputProps={{
                  className: classes.input1,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Tooltip
            disableInteractive
            title="Enter Name, Medicine Stream and Health facility"
            placement="left-start"
            arrow
          >
            <Button
              onClick={(e) => {
                e.preventDefault();
                submitFeedback();
              }}
              //disabled={feedbackData.name === "" || feedbackData.medicineStreamId === "" || feedbackData.organizationId === ""}
              variant="contained"
              style={{
                backgroundColor: "#0B9CAB",
                color: "white",
                marginRight: 16,
                marginBottom: 8,
              }}
            >
              Submit
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default TopMenu2;
