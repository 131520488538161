import { toast } from "react-toastify";
import axiosInstance from "../api/Instance";
import {
  TODAY_PATIENT_REGISTERED,
  DRILL_DOWN_TODAY_PATIENT_REGISTERED,
  FETCH_HEALTH_FACILITIES_LIST,
  DRILL_DOWN_TODAY_PRACTIONERS,
  DRILL_DOWN_AYUSH_HEALTH_FACILITIES,
  DRILL_DOWN_TOTAL_AYUSH_PRACTIONERS,
  DRILL_DOWN_TOTAL_PATIENT_REGISTERED,
  DRILL_DOWN_FETCH_COUNTRY,
  TODAY_PRACTIONERS,
  DRILL_DOWN_FETCH_STATE,
  FETCH_TOTAL_AYUSH_PRACTIONERS,
  FETCH_TOTAL_PATIENT_REGISTERED,
  TOTAL_AYUSH_HEALTH_FACILITIES,
  TOTAL_BED_OCCUPIED,
  DRILL_DOWN_TOTAL_BED_OCCUPIED,
} from "./Type";
import axiosIpdOpdInstance from "../api/IpdOpdInstance";

const showToasterErrorMsg = (error, msg) => {
  if (error?.response?.message) {
    toast.error(error?.response?.message);
  } else if (msg) {
    toast.error(msg);
  } else {
    toast.error("Network Error!");
  }
};

export const fetchTotalPractioners = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getAyushPractionersStat`);

    dispatch({
      type: FETCH_TOTAL_AYUSH_PRACTIONERS,
      payload: res.data,
    });
    //  console.log('================FETCH_TOTAL_AYUSH_PRACTIONERS',res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchTotalPatientRegister = () => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get(`statistics/getRegisteredPatientsStat`);

    dispatch({
      type: FETCH_TOTAL_PATIENT_REGISTERED,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchTotalHealthFacilities = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getYearlyAyushHealthFacilitiesStatistics`);
    dispatch({
      type: TOTAL_AYUSH_HEALTH_FACILITIES,
      payload: res.data,
    });
    //  console.log('================HEALTH_FACILITIES_PATIENT_REGISTERED',res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchTodayPatientRegistered = () => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get(`statistics/getRegisteredPatientsTodayStat`);
    dispatch({
      type: TODAY_PATIENT_REGISTERED,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchTodayPractioners = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getPractionersLoggedInTodayStat`);
    dispatch({
      type: TODAY_PRACTIONERS,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchTotalBedOccupied = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getBedOccupancyTodayStat`);
    dispatch({
      type: TOTAL_BED_OCCUPIED,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchAllState = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`country/101`);
    dispatch({
      type: DRILL_DOWN_FETCH_STATE,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
    // showToasterErrorMsg(error,"Failed to get State Name")
  }
};

export const fetchCitiesByStateID = (stateId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`state/${stateId}`);
    dispatch({
      type: DRILL_DOWN_FETCH_COUNTRY,
      payload: res.data,
    });
    // console.log('================DRILL_DOWN_FETCH_COUNTRY',res.data);
    return res.data;
  } catch (error) {
    showToasterErrorMsg(error, "Failed to get Cities Name");
    return [];
  }
};

export const drillDownfetchTodayPatientRegistered = (stateId, cityId) => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get(
      `statistics/getDrillDownRegisteredPatientsTodayStat/${stateId}/${cityId}`
    );
    dispatch({
      type: DRILL_DOWN_TODAY_PATIENT_REGISTERED,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const drillDownfetchTodayPractioners = (stateId, cityId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getDrillDownPractionersLoggedInTodayStat/${stateId}/${cityId}`);
    dispatch({
      type: DRILL_DOWN_TODAY_PRACTIONERS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const drillDownfetchAyushHealthFacilities = (stateId, cityId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getDrillDownYearlyAyushHealthFacilitiesStat/${stateId}/${cityId}`);
    dispatch({
      type: DRILL_DOWN_AYUSH_HEALTH_FACILITIES,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const drillDownfetchTotalAyushPractioners = (stateId, cityId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getDrillDownAyushPractionersCountStat/${stateId}/${cityId}`);
    console.log("");
    dispatch({
      type: DRILL_DOWN_TOTAL_AYUSH_PRACTIONERS,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const drillDownfetchTotalPatientRegistered = (stateId, cityId) => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get(`statistics/getDrillDownRegisteredPatientsStat/${stateId}/${cityId}`);
    dispatch({
      type: DRILL_DOWN_TOTAL_PATIENT_REGISTERED,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const drillDownfetchTotalBedOccupied = (stateId, cityId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`statistics/getDrillDownBedOccupancyTodayStat/${stateId}/${cityId}`);
    dispatch({
      type: DRILL_DOWN_TOTAL_BED_OCCUPIED,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchHealthFacilities = (stateId, citiesId, somId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      `statistics/getAyushHealthFacilitiesTableDataStat/${stateId}/${citiesId}/${somId}`
    );
    console.log("");
    dispatch({
      type: FETCH_HEALTH_FACILITIES_LIST,
      payload: res.data,
    });
    // console.log('================FETCH_HEALTH_FACILITIES_LIST',res.data);
    return res.data;
  } catch (error) {
    // showToasterErrorMsg(error,"Failed to get Cities Name")
    console.log("failed to get list");
    return [];
  }
};
