import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { fetchSom } from "../actions/SimpleMedicineAction";
import { SHOW_HOME_OVERALL_STATISTICS, SHOW_HOME_TODAY_STATISTICS } from "../actions/Type";
import Chat from "../component/ChatsNew/index";
import AboutAhmis from "./AboutAhmis";
import Ahmis from "./Ahmis";
import Appbar from "./Appbar";
import BannerPage from "./BannerPage";
import Footer from "./Footer";
import Header from "./Header2";
import MainContent from "./MainContent";
import NewFeedback from "./Newfeedback";
import NotificationSuccess from "./Notification/NotificationSuccess";

const OverallStatistics = React.lazy(() => import("./OverallStatistics"));
const TodayStatistics = React.lazy(() => import("./TodayStatistics"));

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.myColor.main,
    color: "#103A4D",
    border: `1px solid ${theme.palette.primary.dark} !important`,
    [theme.breakpoints.down("sm")]: {
      minWidth: 150,
      fontSize: 12,
    },
    minWidth: 220,
    fontFamily: "unset",
    wordWrap: "break-word",
    fontSize: 14,
    fontWeight: "bold",
    "&:hover, &:focus": {
      // backgroundColor: theme.palette.primary.light,
      color: "#000",
    },
  },
  // margins: {
  //   [theme.breakpoints.down("xs")]: {
  //     marginTop: "4% !important",
  //   },
  // },
  title: {
    backgroundColor: "#f1f1f1",
    display: "inline-block",
    padding: "8px 16px",
    borderRadius: 4,
    color: "#052657",
  },
}));

export default function BasicTextFields() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const soms = useSelector((state) => state.simpleMedicineReducer.som);
  const overall = useSelector((store) => store.homeReducer.overall);
  const today = useSelector((store) => store.homeReducer.today);

  useEffect(() => {
    if (!soms.length) {
      dispatch(fetchSom());
    }
  }, [dispatch, soms.length]);

  return (
    <>
      <NotificationSuccess />
      <Chat />
      <Header />
      <Appbar />
      <BannerPage />
      <MainContent />
      <AboutAhmis />

      {!overall ? (
        <div style={{ marginTop: 16, paddingBottom: 12, cursor: "pointer", textAlign: "center" }}>
          <Button
            onClick={() => {
              dispatch({ type: SHOW_HOME_OVERALL_STATISTICS });
            }}
            endIcon={<FontAwesomeIcon icon={faAngleDown} />}
            className={classes.button}
          >
            View Overall Statistics
          </Button>
        </div>
      ) : (
        <div style={{ margin: 40, marginTop: 10 }} className={classes.margins}>
          <div style={{ textAlign: "center" }}>
            <Typography
              style={{ marginTop: 12, marginBottom: 8 }}
              variant="h6"
              align="center"
              className={classes.title}
            >
              OVERALL STATISTICS
            </Typography>
          </div>
          <Suspense fallback={<p>Loading...</p>}>
            <OverallStatistics />
          </Suspense>
        </div>
      )}

      <div>
        <Link to="/StaffLogin" style={{ textDecoration: "none" }}>
          <div data-tip="Click here to Login" data-for="toolTip1" data-place="bottom">
            <Ahmis />
          </div>
          <ReactTooltip id="toolTip1"></ReactTooltip>
        </Link>
      </div>

      {!today ? (
        <div style={{ marginTop: 16, cursor: "pointer", textAlign: "center" }}>
          <Button
            onClick={() => {
              dispatch({ type: SHOW_HOME_TODAY_STATISTICS });
            }}
            endIcon={<FontAwesomeIcon icon={faAngleDown} />}
            className={classes.button}
          >
            View Today's Statistics
          </Button>
        </div>
      ) : (
        <div style={{ margin: 40, marginTop: 10 }} className={classes.margins}>
          <div style={{ textAlign: "center" }}>
            <Typography
              style={{ marginTop: 12, marginBottom: 8 }}
              variant="h6"
              align="center"
              className={classes.title}
            >
              TODAY'S STATISTICS
            </Typography>
          </div>
          <Suspense fallback={<p>Loading...</p>}>
            <TodayStatistics />
          </Suspense>
        </div>
      )}

      <div style={{ margin: 40 }}>
        <NewFeedback />
      </div>
      <Chat />
      <Grid container spacing={0} style={{ width: "100%", backgroundColor: "#302b2b" }}>
        <Footer />
      </Grid>
    </>
  );
}
