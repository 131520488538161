import { combineReducers } from "redux";
import RegistrationReducer from "./RegistrationReducer";
import AppointmentReducer from "./AppointmentReducer";
import NotificationReducer from "./NotificationReducer";
import AdminMasterReducer from "./AdminMasterReducer";
import MainReducer from "./MainReducer";
import BillDetailsReducer from "./BillDetailsReducer";
import PulseReducer from "./PulseReducer";
import QualificationReducer from "./QualificationReducer";
import SpecializationReducer from "./SpecializationReducer";
import AdviceReducer from "./AdviceReducer";
import DianosisICDReducer from "./DianosisICDReducer";
import DiagnosisReducer from "./DiagnosisReducer";
import SignSymptomReducer from "./SignSymptomReducer";
import BuildingReducer from "./BuildingReducer";
import ConsultationRoomReducer from "./ConsultationRoomReducer";
import ConsultationReducer from "./ConsultationReducer";
import SimpleMedicineReducer from "./SimpleMedicineReducer";
import ComplexMedicineReducer from "./ComplexMedicineReducer";
import ExternalMedicineReducer from "./ExternalMedicineReducer";
import PaidMedicineReducer from "./PaidMedicineReducer";
import LabMasterReducer from "./LabMasterReducer";
import DutyRoasterReducer from "./DutyRoasterReducer";
import ShiftDetailsReducer from "./ShiftDetailsReducer";
import CompliancePrognosisReducer from "./Compliance&PrognosisReducer";
import DashboardReducer from "./DashboardReducer";
import LoginReducer from "./LoginReducer";
import HistoryReducer from "./HistoryReducer";
import RoleMasterReducer from "./RoleMasterReducer";
import PrivilegeControlReducer from "./PrivilegeControlReducer";
import ConcomitantAllopathyMedReducer from "./ConcomitantAllopathyMedReducer";
import FeeReducer from "./FeeReducer";
import PreviousConsultation from "./PreviousConsultationReducer";
import GraphReducer from "./GraphReducer";
import PrevTendsReducer from "./PrevTendsReducer";
import FeedBackReducer from "./FeedbackReducer";
import HomeopathyReducer from "./HomeopathyReducer";
import PrognosisReducer from "./PrognosisReducer";
import PrescriptionReducer from "./PrescriptionReducer";
import ipdReducer from "./IpdReducer";
import MoaDashboardReducer from "./MoaDashboardReducer";
import ExternalGroupReportReducer from "./ExternalGroupReportReducer";
import BedReducer from "./BedReducer";
import PatientCategoryReducer from "./PatientCategoryReducer";
import DietReducer from "./DietReducer";
import DischargeNotesReducer from "./DischargeNotesReducer";
import WoundReducer from "./WoundReducer";
import { showSnackbar } from "./ShowSnackbar";
import IpdConsultation from "./IpdConsultationReducer";
import { selectedPatient } from "./SelectedPatient";
//ipd
import { PharmacologistTopBarReducer } from "./PharmalogistTopBar";
import { IpdTopBarReducer } from "./IpdTopBar";
import { adr } from "./Adr";
import { apiData } from "./ApiData";
import { homeReducer } from "./HomeReducer";
import PaymentModeReducer from "./PaymentModeReducer";
import SurgicalProcedureReducer from "./SurgicalProcedureReducer";
import selectedIcdTab from "./selectedIcdTab";

export default combineReducers({
  registrationReducer: RegistrationReducer,
  appointmentReducer: AppointmentReducer,
  snackBar: NotificationReducer,
  MainReducer: MainReducer,
  adminMasterReducer: AdminMasterReducer,
  pulseReducer: PulseReducer,
  qualificationReducer: QualificationReducer,
  specializationReducer: SpecializationReducer,
  adviceReducer: AdviceReducer,
  dianosisICDReducer: DianosisICDReducer,
  diagnosisReducer: DiagnosisReducer,
  billDetailsReducer: BillDetailsReducer,
  signSymptomReducer: SignSymptomReducer,
  buildingReducer: BuildingReducer,
  consultationRoomReducer: ConsultationRoomReducer,
  consultationReducer: ConsultationReducer,
  simpleMedicineReducer: SimpleMedicineReducer,
  complexMedicineReducer: ComplexMedicineReducer,
  externalMedicineReducer: ExternalMedicineReducer,
  paidMedicineReducer: PaidMedicineReducer,
  labMasterReducer: LabMasterReducer,
  dutyRoasterReducer: DutyRoasterReducer,
  shiftDetailsReducer: ShiftDetailsReducer,
  compliancePrognosisReducer: CompliancePrognosisReducer,
  dashboardReducer: DashboardReducer,
  graphReducer: GraphReducer,
  loginReducer: LoginReducer,
  historyReducer: HistoryReducer,
  roleMasterReducer: RoleMasterReducer,
  privilegeControlReducer: PrivilegeControlReducer,
  concomitantAllopathyMedReducer: ConcomitantAllopathyMedReducer,
  feeReducer: FeeReducer,
  previousConsultationReducer: PreviousConsultation,
  prevTrendsReducer: PrevTendsReducer,
  feedBackReducer: FeedBackReducer,
  homeoPathyReducer: HomeopathyReducer,
  prognosisReducer: PrognosisReducer,
  prescriptionReducer: PrescriptionReducer,
  ipdReducer: ipdReducer,
  moaDashboardReducer: MoaDashboardReducer,
  externalGroupReportReducer: ExternalGroupReportReducer,
  bedReducer: BedReducer,
  dietReducer: DietReducer,
  dischargeNotesReducer: DischargeNotesReducer,
  woundReducer: WoundReducer,
  showSnackbar,
  ipdConsultation: IpdConsultation,
  patientCategoryReducer: PatientCategoryReducer,
  paymentModeReducer: PaymentModeReducer,
  selectedPatient,
  // ipd
  PharmacologistTopBarReducer,
  IpdTopBarReducer,
  apiData,
  adr,
  homeReducer,
  surgicalProcedureReducer: SurgicalProcedureReducer,
  selectedIcdTab: selectedIcdTab
});
