import { ThemeProvider } from "@material-ui/styles";
import React, { lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import SockJsClient from "react-stomp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { staffLogout } from "./actions/LoginAction";
import { showSnackbar } from "./actions/Notification";
import {
  fetchInitCall,
  fetchNdhmMsg,
  fetchOTPCall,
  handleHiuOnFetchRequest,
} from "./actions/RegistrationAction";
import {
  FETCH_FHIR_DATA_PUSH,
  SET_CLIENT_ID,
  SET_HIU_ON_INIT_DTO,
} from "./actions/Type";
import ShowSnackbar from "./component/utils/Snackbar";
import configData from "./config.json";
import history from "./history";
//
import AboutAhmis from "./component/AboutAhmis";
import AboutContent from "./component/AboutContent";
import AyushFacilityList from "./component/AyushFacilityList";
import ConsultationLayout from "./component/Consultation/Layout/ConsultationLayout";
import ContactUs from "./component/ContactUs";
import Home from "./component/Home";
import StaffLogin from "./component/Login";
import InventoryCDS from "./pages/Inventory-CDS/InventoryCDS";

// const ConsultationLayout = lazy(() =>
//   import("./component/Consultation/Layout/ConsultationLayout")
// );
const MasterPharmacyReports = lazy(() =>
  import("./component/Layout/LandingMasterPharmacy")
);
const IpdOpdRoomSelector = lazy(() =>
  import("./component/Consultation/IpdOpdTab")
);
const MoAAdminDashboard = lazy(() =>
  import("./component/MoADashboard/MoAAdminDashboard")
);
const ConsultaionToken = lazy(() =>
  import("./component/Layout/LandingConsultationToken")
);
const VideoTutorials = lazy(() =>
  import("./component/Layout/LandingVideoTutorials")
);
const PatientPrescriptionDetails = lazy(() =>
  import("./pages/PrescriptionDetails/PatientPrescriptionDetails")
);
const RadiologyEntry = lazy(() =>
  import("./pages/RadiologyResultEntry/RadiologyEntry")
);
const LandingReceptionist = lazy(() =>
  import("./component/Layout/LandingReceptionist")
);
// const VideoTutorials = lazy(() =>
//   import("./component/VideoTutorials")
// );
const ViewMore = lazy(() => import("./component/ViewMore"));
const RegistrationForm = lazy(() => import("./component/RegistrationForm"));
const BookAppointment = lazy(() => import("./component/BookAppointment"));
const LandingMaster = lazy(() => import("./component/Layout/LandingMaster"));
const Report = lazy(() => import("./component/reports/Report"));
const VitalSigns = lazy(() => import("./component/Consultation/VitalSigns"));
const LandingMasterReports = lazy(() =>
  import("./component/Layout/LandingMasterReports")
);
const LabSampleCollect = lazy(() =>
  import("./pages/LabSampleCollect/LabSampleCollect")
);
const LabEntry = lazy(() => import("./pages/LabResultEntry/LabEntry"));
const Inventory = lazy(() => import("./component/MasterInventory"));
const Ipd1 = lazy(() => import("./component/MasterIPD1"));
const IpdHome = lazy(() => import("./Ipd_Layout/Components"));
const LandingIpd = lazy(() => import("./component/Layout/LandingIPD"));
const NurseDutyRoaster = lazy(() =>
  import("./component/HeadNurse/NurseDutyRoaster")
);
const HeadNureseTab = lazy(() => import("./component/HeadNurse/HeadNureseTab"));
// const FhirDisplayRoute = lazy(() => import("./pages/Ndhm/FhirDisplay"));
const Dashboard = lazy(() => import("./component/Dashboard/Dashboard"));
const TherapyRole = lazy(() => import("./Ipd_Layout/Therapy/TherapyLayout"));

export default function App() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.MainReducer.selectedTheme);
  const staffLogin = useSelector((state) => state.loginReducer.staffLogin);

  const ndhmMsg = useSelector((state) => state.registrationReducer.ndhmMsg);
  const verificationMethod = useSelector(
    (state) => state.registrationReducer.verifyMethod
  );
  const clientRef = useRef(null);
  const topic = "/app/private-message";
  const message = JSON.stringify({ messageContent: "hi" });
  const clientId = useSelector((state) => state.registrationReducer.clientId);

  const [fhirDataArr, setFhirDataArr] = useState([]);
  const [
    displayDataPushNotification,
    setDisplayDataPushNotification,
  ] = useState(false);

  useEffect(() => {
    if (displayDataPushNotification) {
      dispatch(
        showSnackbar({
          message: `Received Data from HIP`,
          messageInfoType: "success",
        })
      );
    }
  }, [displayDataPushNotification]);

  useEffect(() => {
    console.log("fhirDataArr", fhirDataArr);
  }, [fhirDataArr]);

  React.useEffect(() => {
    localStorage.clear();
    if (
      history.location.pathname.toLocaleLowerCase().includes("ContactUs") ||
      history.location.pathname
        .toLocaleLowerCase()
        .includes("ayushFacilityList") ||
      history.location.pathname
        .toLocaleLowerCase()
        .includes("BookAppointment") ||
      history.location.pathname.toLocaleLowerCase().includes("RegistrationForm")
    )
      return;

    if (staffLogin?.length === 0) {
      history.push("/home");
      dispatch(staffLogout({ userName: staffLogin.username }));
    }
  }, [dispatch, staffLogin]);

  React.useEffect(() => {
    let currentPathname = null;
    let currentSearch = null;
    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== currentPathname ||
          newLocation.search !== currentSearch
        ) {
          // Save new location
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;
          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <ShowSnackbar />

      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home"></Redirect>
            </Route>
            <Route
              exact
              path="/ipdOpdSelection"
              component={IpdOpdRoomSelector}
            />
            <Route exact path="/home" component={Home} />
            <Route
              exact
              path="/LandingMasterReports"
              component={LandingMasterReports}
            />
            <Route exact path="/Therapy" component={TherapyRole} />
            <Route exact path="/Dashboard" component={Dashboard} />
            <Route exact path="/VitalSigns" component={VitalSigns} />
            <Route exact path="/RadiologyEntry" component={RadiologyEntry} />
            {/* <Route exact path="/PreviousConsultationLayout" component={PreviousConsultationLayoutTabs} /> */}
            <Route
              exact
              path="/MoAAdminDashboard"
              component={MoAAdminDashboard}
            />
            <Route
              exact
              path="/ConsultationLayout"
              component={ConsultationLayout}
            />
            <Route
              exact
              path="/ayushFacilityList"
              component={AyushFacilityList}
            />
            <Route exact path="/report" component={Report} />
            <Route exact path="/aboutahmis" component={AboutAhmis} />
            <Route exact path="/aboutcontent" component={AboutContent} />
            <Route
              exact
              path="/LabSampleCollect"
              component={LabSampleCollect}
            />
            {/* <Route
              exact
              path="/TherapyRole"
              component={TherapyRole}
            /> */}
            <Route exact path="/LabEntry" component={LabEntry} />
            <Route exact path="/ContactUs" component={ContactUs} />
            <Route exact path="/ViewMore" component={ViewMore} />
            <Route exact path="/StaffLogin" component={StaffLogin} />
            <Route exact path="/BookAppointment" component={BookAppointment} />
            <Route
              exact
              path="/RegistrationForm"
              component={RegistrationForm}
            />
            <Route exact path="/Receptionist" component={LandingReceptionist} />
            <Route
              exact
              path="/ayushFacilityList"
              component={AyushFacilityList}
            />
            <Route exact path="/StaffLogin" component={StaffLogin} />
            <Route exact path="/BookAppointment" component={BookAppointment} />
            <Route exact path="/master" component={LandingMaster} />
            <Route
              exact
              path="/dispensary"
              component={PatientPrescriptionDetails}
            />
            <Route
              exact
              path="/dispensaryReports"
              component={MasterPharmacyReports}
            />
            <Route
              exact
              path="/consultationToken"
              component={ConsultaionToken}
            />
            <Route exact path="/inventory" component={Inventory} />
            <Route exact path="/dispensaryCDS" component={InventoryCDS} />
            {/* <Route exact path="/fhirDisplay" component={FhirDisplayRoute} /> */}

            <Route exact path="/videoTutorials" component={VideoTutorials} />

            <Route exact path="/LandingIPD" component={Ipd1} />
            <Route exact path="/IpdRegistration" component={LandingIpd} />
            <Route
              exact
              path="/NurseDutyroaster"
              component={NurseDutyRoaster}
            />
            <Route exact path="/HeadNurse" component={HeadNureseTab} />
          </Switch>

          {/* // =======================================START-IPD==================================================== */}
          <Route path="/ipd" component={IpdHome} />
          {/* // =======================================END-IPD==================================================== */}
        </Router>
        <SockJsClient
          url={configData.NDHM_SOCKET_URL}
          topics={["/user/topic/private-messages"]}
          ref={clientRef}
          onConnect={() => {
            console.log("connected");
            clientRef.current?.sendMessage(topic, message);
          }}
          onDisconnect={() => {
            console.log("Disconnected");
          }}
          onMessage={(msg) => {
            console.log("Rx msg", msg);
            if (msg.name === "onFetchModes") {
              console.log("onfetchModes");
              dispatch(fetchNdhmMsg(msg));
            } else if (msg.name === "UserId") {
              dispatch({ type: SET_CLIENT_ID, payload: msg.message });
            } else if (msg.name === "onInit") {
              console.log("onInit");
              setDisplayDataPushNotification(false);
              dispatch(fetchInitCall(msg, verificationMethod));
            } else if (msg.name === "notify") {
              console.log("notify");
              dispatch(fetchOTPCall(msg));
            } else if (msg.name === "onConfirm") {
              console.log("onconfirm");
              dispatch(fetchOTPCall(msg));
            } else if (msg.name === "hiu-on-init") {
              console.log("hiu-on-init");
              setDisplayDataPushNotification(false);
              dispatch({ type: SET_HIU_ON_INIT_DTO, payload: msg?.dataObj });
              dispatch({ type: FETCH_FHIR_DATA_PUSH, payload: [] });
              setFhirDataArr([]);
            } else if (msg.name === "hiu-notify") {
              console.log("hiu-notify");
              // dispatch({ type: FETCH_FHIR_DATA_PUSH, payload: [] });
              if (msg?.dataObj?.status === "DENIED") {
                dispatch(
                  showSnackbar({
                    message: `Consent ${msg?.dataObj?.status}`,
                    messageInfoType: "info",
                  })
                );
              }
            } else if (msg.name === "hiu-on-fetch") {
              console.log("hiu-on-fetch");
              dispatch({ type: FETCH_FHIR_DATA_PUSH, payload: [] });
              dispatch(handleHiuOnFetchRequest(msg?.dataObj, clientId));
            } else if (msg.name === "hiu-data-push") {
              console.log("hiu-data-push");
              setDisplayDataPushNotification(true);
              let fhirArr = [...fhirDataArr];
              fhirArr.push(msg);
              setFhirDataArr(fhirArr);
              dispatch({ type: FETCH_FHIR_DATA_PUSH, payload: fhirArr });
            }
          }}
        />
      </ThemeProvider>
    </>
  );
}
