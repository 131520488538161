import {  FETCH_ROLE_MASTER,DELETE_ROLE_MASTER} from "../actions/Type";

const initialState = {
  roleDetail: [],
  delRoleDetails:[]
};

const RoleMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    
      case FETCH_ROLE_MASTER:
        return {
          ...state,
          roleDetail: action.payload,
        };
        case DELETE_ROLE_MASTER:
      return {
        ...state,
        delDutyRoster: action.payload,

      };

    default:
      return state;
  }
};

export default RoleMasterReducer;
