import { 
    FETCH_BUILDING_LIST,
    SAVE_BUILDING,
    FETCH_BUILDING_LIST_BY_ORG,
    FETCH_FLOOR_LIST
  } from "../actions/Type";
  
  const initialState = {
        buildings: [],
        buildingsSaved: [],
        buildingsByOrg:[],
        floors:[]
        // uniqueCities:[],
        // departments:[],
        // apptId: String
    };

    const BuildingReducer = (state = initialState, action) => {
        switch (action.type) {
        
              case FETCH_BUILDING_LIST:
              return {
                  ...state,
                  buildings: action.payload,
                
              };
              case FETCH_FLOOR_LIST:
                return {
                    ...state,
                    floors: action.payload,
                  
                };

              case SAVE_BUILDING:
              return {
                  ...state,
                  buildingsSaved: action.payload,
                
              };

              case FETCH_BUILDING_LIST_BY_ORG:
              return {
                  ...state,
                  buildingsByOrg: action.payload,
                
              };
            //   case SAVE_BILL:
            //   return {
            //       ...state,
            //       billId: action.payload,
            //   };

              
          default:
            return state;
        }
      };
      
      export default BuildingReducer;