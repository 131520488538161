import DateFnsUtils from "@date-io/date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  Grid,
  IconButton,
  TextField
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Event } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { components } from "react-select";
import * as yup from "yup";
import NotificationSuccess from "../Notification/NotificationSuccess";
import { restrict } from "../restrict";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%%",
    marginTop: "20px",
  },
  button: {
    border: "1px solid #C4C4C4",
    borderRadius: 20,
    margin: "0px 10px 10px 0px",
    padding: "5px 10px 5px 10px",
  },
  input: {
    height: 35,
    width: 380,
  },
  input1: {
    height: 35,
    width: 50,
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  customInput: {
    [theme.breakpoints.down("lg")]: {
      width: "100% !important",
    },
  },
  icon: {
    color: "#ff6600",
    fontSize: "13px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "13px",
  },
  smallFont: {
    fontSize: "14px",
    color: "#000000",
    opacity: 1,
  },
}));
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon color="black" fontSize="small" />
    </components.DropdownIndicator>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: "14px",
    padding: "0px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Inner({ value: surgicalValue, onChange: setSurgicalValue }) {
  const classes = useStyles();

  const StaffLogin = useSelector((state) => state.loginReducer.staffLogin);

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  const consultationID = useSelector(
    (state) => state.consultationReducer.patient.consultationId
  );

  const drRoundId = useSelector((state) => state.ipdConsultation.drRoundId);

  // const surgicalProcedureList = useSelector(
  //   (state) => state?.surgicalProcedureReducer?.surgicalProcedureList
  // );
  
  const activeProcedureList= useSelector(
    (state) => state?.surgicalProcedureReducer?.activeProcedureList
  );
  // console.log("ggg",activeProcedureList );
  const defaultArray = () => ({
    id: 0,
    consultationId: consultationID,
    surgicalProcedureId: null,
    procedureTypeId: null,
    procedureDate: null,
    remarks: "",
    drRoundId: drRoundId ? drRoundId : undefined,
    lastUpdatedBy: StaffLogin?.staffId,
  });

  const defaultValues = {
    array: surgicalValue ? [] : [defaultArray()],
  };

  const arraySchema = yup.object().shape({
    surgicalProcedureId: yup.number().required("Surgical Procedure required"),
    procedureDate: yup.date().required("Procedure Date required"),
  });

  const schema = yup.object().shape({
    array: yup
      .array()
      .of(arraySchema)
      .required("Must have fields"),
  });

  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: "array",
  });

  useEffect(() => {
    if (surgicalValue) {
      setValue("array", surgicalValue);
    }
  }, []);

  const array = useWatch({
    control,
    name: "array",
  });

  React.useEffect(() => {
    setSurgicalValue(array);
    // console.log(array, "hhh")
  }, [array]);

  return (
    <div className={classes.root}>
      <NotificationSuccess />
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} style={{ marginTop: "0.5%" }}>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table
              className={`${classes.table} report-table`}
              aria-label="simple table"
            >
              <TableHead>
                <StyledTableRow
                  align="center"
                  style={{
                    backgroundColor: myOwnColor,
                  }}
                >
                  <StyledTableCell style={{ minWidth: "300px" }} align="center">
                    Surgical Procedure
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "100px" }} align="center">
                    Date
                  </StyledTableCell>

                  <StyledTableCell style={{ minWidth: "300px" }} align="center">
                    Remarks
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "100px" }} align="center">
                    Actions
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "10px" }} align="center">
                    <IconButton
                      onClick={() => {
                        append(defaultArray());
                      }}
                      style={{ color: "white", padding: 4 }}
                    >
                      <AddIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {fields.map((row, index) => {
                return (
                  <TableBody key={index}>
                    <TableRow>
                      <StyledTableCell
                        style={{ minWidth: "200px" }}
                        align="left"
                      >
                        <FormControl fullWidth>
                          <Controller
                            name={`array[${index}].surgicalProcedureId`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                fullWidth
                                size="small"
                                value={activeProcedureList?.find(
                                  (item) => item?.id === +value
                                )}
                                onChange={(e, data) => {
                                  onChange(data?.id || "");
                                  // setProcedureTypeId(data?.procedureTypeId || null);
                                  setValue(`array[${index}].procedureTypeId`, data?.procedureTypeId)
                                  // console.log(data.procedureTypeId,"eee");
                                }}
                                getOptionLabel={(option) => `${option.procedureTypeName} - ${option.name}`} // Display combined procedureTypeName and name
                                // getOptionLabel={(option) => option.name || ""}
                                options={activeProcedureList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Surgical Procedure"
                                    variant="outlined"
                                    error={!!errors.surgicalProcedureId}
                                    helperText={
                                      errors.surgicalProcedureId?.message
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell
                        style={{ minWidth: "100px", maxWidth: 100 }}
                        align="center"
                      >
                        {" "}
                        <Controller
                          name={`array[${index}].procedureDate`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                inputVariant="outlined"
                                size="small"
                                InputProps={{
                                  className: classes.input7,
                                  endAdornment: (
                                    <IconButton size="small">
                                      <Event color="primary" />
                                    </IconButton>
                                  ),
                                }}
                                format="dd/MM/yyy"
                                error={!!errors.medicine?.[index]?.startDate}
                                helperText={
                                  errors.medicine?.[index]?.startDate?.message
                                }
                                id="startDate"
                                value={value}
                                onChange={(e) => {
                                  onChange(e.toISOString());
                                }}
                                disablePast
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          )}
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{ minWidth: "200px" }}
                        align="center"
                      >
                        <TextField
                          {...register(`array[${index}].remarks`)}
                          InputProps={{
                            className: classes.input3,
                          }}
                          inputProps={{
                            maxLength: 1000,
                          }}
                          classes={{
                            root: classes.customTextField,
                          }}
                          placeholder="Remarks"
                          onInput={restrict.remarks}
                          size="small"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ minWidth: "100px" }}
                        align="center"
                      >
                        {row.message}
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <FontAwesomeIcon
                            title="Delete"
                            className={classes.icon1}
                            icon={"trash"}
                          />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

const SurgicalProcedure = React.forwardRef(Inner);
export default SurgicalProcedure;
