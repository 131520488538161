export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const FETCH_STATES_LIST = "FETCH_STATES_LIST";
export const FETCH_PERM_STATES_LIST = "FETCH_PERM_STATES_LIST";
export const FETCH_CORR_STATES_LIST = "FETCH_CORR_STATES_LIST";
export const FETCH_CITIES_LIST = "FETCH_CITIES_LIST";
export const FETCH_PERM_CITIES_LIST = "FETCH_PERM_CITIES_LIST";
export const FETCH_CORR_CITIES_LIST = "FETCH_CORR_CITIES_LIST";
export const SAVE_REGISTER_PATIENT = "SAVE_REGISTER_PATIENT";
export const FETCH_PATIENT_NAME = "FETCH_PATIENT_NAME";
export const FETCH_PATIENTCATEGORY_LIST = "FETCH_PATIENTCATEGORY_LIST";
export const FETCH_PATIENTCATEGORY_LIST_BY_ORG =
  "FETCH_PATIENTCATEGORY_LIST_BY_ORG";
export const FETCH_PAYMENTMODE_LIST = "FETCH_PAYMENTMODE_LIST";
export const FETCH__PAYMENTMODE_LIST_BY_ORG = "FETCH__PAYMENTMODE_LIST_BY_ORG ";
export const FETCH_APPT_CITY_LIST = "FETCH_APPT_CITY_LIST";
export const SAVE_APPT_BOOKING = "SAVE_APPT_BOOKING";
export const SPINNER_HIDE = "SPINNER_HIDE";
export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";
export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const SPINNER_SHOW = "SPINNER_SHOW";
export const FETCH_DEPT_LIST = "FETCH_DEPT_LIST";
export const CHANGE_THEME = "CHANGE_THEME";
export const FETCH_ORG_LIST = "FETCH_ORG_LIST";
export const FETCH_DEPT_TYPE_LIST = "FETCH_DEPT_TYPE_LIST";
export const FETCH_STAFF_TYPE_LIST = "FETCH_STAFF_TYPE_LIST";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const FETCH_INSTITUTE_LIST = "FETCH_INSTITUTE_LIST";
export const FETCH_ALL_BILL_DETAILS = "FETCH_ALL_BILL_DETAILS";
export const FETCH_TODAY_BILL_DETAILS = "FETCH_TODAY_BILL_DETAILS";
export const SAVE_BILL = "SAVE_BILL";
export const CHANGE_COLOR = "CHANGE_COLOR";
export const CHANGE_MENU_COLOR = "CHANGE_MENU_COLOR";
export const FETCH_BUILDING_LIST = "FETCH_BUILDING_LIST";
export const FETCH_PATIENTCATEGORYS_BY_ORG = "FETCH_PATIENTCATEGORYS_BY_ORG";
export const FETCH_PATIENTCATEGORYS = "FETCH_PATIENTCATEGORYS";
export const SAVE_BUILDING = "SAVE_BUILDING";
export const FETCH_ROOM_LIST = "FETCH_ROOM_LIST";
export const SAVE_ROOM = "SAVE_ROOM";
export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_DURATION_TYPE = "FETCH_DURATION_TYPE";
export const FETCH_SHIFT_DETAILS = "FETCH_SHIFT_DETAILS";
export const SAVE_SHIFT_NAME = "SAVE_SHIFT_NAME";
export const DELETE_SHIFT = "DELETE_SHIFT";
export const FETCH_ALL_DUTYROSTER = "FETCH_ALL_DUTYROSTER";
export const FETCH_STAFF = "FETCH_STAFF";
export const DELETE_DUTYROSTER = "DELETE_DUTYROSTER";
export const SAVE_DUTYROSTER = "SAVE_DUTYROSTER";
export const FETCH_ALL_VEHICLE_NAME = "FETCH_ALL_VEHICLE_NAME";
export const FETCH_UOM = "FETCH_UOM";
export const FETCH_SOM = "FETCH_SOM";
export const FETCH_MEDICINE_NAME_BY_SOM = "FETCH_MEDICINE_NAME_BY_SOM";
export const FETCH_OVERALL_STATISTICS_DASHBOARD =
  "FETCH_OVERALL_STATISTICS_DASHBOARD";
export const STAFF_LOGIN = "STAFF_LOGIN";
export const STAFF_LOGOUT = "STAFF_LOGOUT";
export const FETCH_ROLE_MASTER = "FETCH_ROLE_MASTER";
export const DELETE_ROLE_MASTER = "DELETE_ROLE_MASTER";
export const FETCH_PRIVILEGE_CONTROL = "FETCH_PRIVILEGE_CONTROL";
export const FETCH_PRIVILEGE_NAME = "FETCH_PRIVILEGE_NAME";
export const DELETE_PRIVILEGE_CONTROL = "DELETE_PRIVILEGE_CONTROL";
export const FETCH_ALL_MEDICINE_NAME_BY_SOM = "FETCH_ALL_MEDICINE_NAME_BY_SOM";
// export const FETCH_STATES_LIST = "FETCH_STATES_LIST";
// export const FETCH_CITIES_LIST = "FETCH_CITIES_LIST";
// export const SAVE_REGISTER_PATIENT = "SAVE_REGISTER_PATIENT";
// export const FETCH_PATIENT_NAME = "FETCH_PATIENT_NAME";
// export const FETCH_APPT_CITY_LIST = "FETCH_APPT_CITY_LIST";
// export const SAVE_APPT_BOOKING = "SAVE_APPT_BOOKING";
// export const SPINNER_HIDE = "SPINNER_HIDE";
// export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
// export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";
// export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const FETCH_ADMISSION_BILLS = "FETCH_ADMISSION_BILLS";
export const FETCH_NURSE_DUTY_LIST = "FETCH_NURSE_DUTY_LIST";
export const SET_PATIENT_TYPE_ID = "SET_PATIENT_TYPE_ID";
export const FETCH_ADMITTED_PATIENT_BY_ORG = "FETCH_ADMITTED_PATIENT_BY_ORG";
export const SET_IPD_MENU = "SET_IPD_MENU";
export const FETCH_TODAY_IPD_BILLS = "FETCH_TODAY_IPD_BILLS";
export const FETCH_IPD_DEPT = "FETCH_IPD_DEPT";
export const SET_UHID = "SET_UHID";
export const FETCH_NDHM_MSG = "FETCH_NDHM_MSG";
export const SET_NDHM_MODE = "SET_NDHM_MODE";
export const SET_NDHM_PURPOSE = "SET_NDHM_PURPOSE";
export const SET_NDHM_TXN_ID = "SET_NDHM_TXN_ID";
export const SET_PATIENT_DTO = "SET_PATIENT_DTO";
export const VERIFICATION_METHOD = "VERIFICATION_METHOD";
export const SET_PATIENT_IMAGE = "SET_PATIENT_IMAGE";
export const FETCH_PULSE_LIST = "FETCH_PULSE_LIST";
export const FETCH_QUALIFICATION_LIST = "FETCH_QUALIFICATION_LIST";
export const FETCH_SPECIALIZATION_LIST = "FETCH_SPECIALIZATION_LIST";
export const FETCH_ADVICE_LIST = "FETCH_ADVICE_LIST";
export const FETCH_ICD_CODE_LIST = "FETCH_ICD_CODE_LIST";
export const FETCH_DIAGNOSIS_LIST = "FETCH_DIAGNOSIS_LIST";
export const FETCH_SSR_LIST = "FETCH_SSR_LIST";
export const SET_PATIENT_DATA = "SET_PATIENT_DATA";
export const FETCH_CONSULTATION_PATIENT_DETAILS =
  "FETCH_CONSULTATION_PATIENT_DETAILS";
export const FETCH_LAB_CONSULTATION_PATIENT_DETAILS =
  "FETCH_LAB_CONSULTATION_PATIENT_DETAILS";
export const FETCH_REG_DEPT_LIST = "FETCH_REG_DEPT_LIST";
export const FETCH_INVESTIGATION_TESTS = "FETCH_INVESTIGATION_TESTS";
export const FETCH_LAB_TEST_NAME_LIST = "FETCH_LAB_TEST_NAME_LIST";
export const FETCH_ACTIVE_LAB_TEST_NAME_LIST =
  "FETCH_ACTIVE_LAB_TEST_NAME_LIST";
export const FETCH_LAB_TEST_TYPE_LIST = "FETCH_LAB_TEST_TYPE_LIST";
export const FETCH_LAB_TEST_METHOD_LIST = "FETCH_LAB_TEST_METHOD_LIST";
export const FETCH_ACTIVE_LAB_TEST_METHOD_LIST =
  "FETCH_ACTIVE_LAB_TEST_METHOD_LIST";
export const FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST =
  "FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST";
export const FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST2 =
  "FETCH_LAB_SINGLE_TEST_NORMAL_VALUE_LIST2";
export const FETCH_LAB_ACTIVE_TEST_NORMAL_VALUES =
  "FETCH_LAB_ACTIVE_TEST_NORMAL_VALUES";
export const FETCH_LAB_SAMPLE_TYPE_LIST = "FETCH_LAB_SAMPLE_TYPE_LIST";
export const FETCH_LAB_INV_SAMPLE_TEST_GROUP_LIST =
  "FETCH_LAB_INV_SAMPLE_TEST_GROUP_LIST";
export const FETCH_LAB_ACTIVE_SAMPLE_TYPE_LIST =
  "FETCH_LAB_ACTIVE_SAMPLE_TYPE_LIST";
export const FETCH_LAB_TEST_GROUP_LIST = "FETCH_LAB_TEST_GROUP_LIST";
export const FETCH_LAB_RADIOLOGY_LIST = "FETCH_LAB_RADIOLOGY_LIST";
export const FETCH_LAB_ACTIVE_RADIOLOGY_LIST =
  "FETCH_LAB_ACTIVE_RADIOLOGY_LIST";
export const FETCH_LAB_RADIOLOGY_VIEW_LIST = "FETCH_LAB_RADIOLOGY_VIEW_LIST";
export const FETCH_LAB_RADIOLOGY_ASSO_LIST = "FETCH_LAB_RADIOLOGY_ASSO_LIST";
export const FETCH_LAB_SAMPLE_ASSO_LIST = "FETCH_LAB_SAMPLE_ASSO_LIST";
export const FETCH_LAB_SAMPLE_ASSO_LIST_FOR_SAMPLE =
  "FETCH_LAB_SAMPLE_ASSO_LIST_FOR_SAMPLE";
export const FETCH_GROUP_SAMPLE_ASSO_LIST_FOR_SAMPLE =
  "FETCH_GROUP_SAMPLE_ASSO_LIST_FOR_SAMPLE";
export const FETCH_SAVED_CONCOMITANT_MEDICINE_DETAILS =
  "FETCH_SAVED_CONCOMITANT_MEDICINE_DETAILS";
export const FETCH_SAVED_ALLOPATHY_MEDICINE_DETAILS =
  "FETCH_SAVED_ALLOPATHY_MEDICINE_DETAILS";

export const FETCH_LAB_RADIOLOGY_LAB_TEST_DETAILS_LIST =
  "FETCH_LAB_RADIOLOGY_LAB_TEST_DETAILS_LIST";
export const FETCH_LAB_TEST_NAMES_AND_GROUP_NAMES_LIST =
  "FETCH_LAB_TEST_NAMES_AND_GROUP_NAMES_LIST";
export const FETCH_PATHOLOGY_LAB_TEST_NAME_DETAILS =
  "FETCH_PATHOLOGY_LAB_TEST_NAME_DETAILS";
export const FETCH_RADIOLOGY_LAB_TEST_AND_GROUP_TEST_NAME_LIST =
  "FETCH_RADIOLOGY_LAB_TEST_AND_GROUP_TEST_NAME_LIST";
export const FETCH_RADIOLOGY_LAB_TEST_NAME_DETAILS =
  "FETCH_RADIOLOGY_LAB_TEST_NAME_DETAILS";
export const FETCH_RADIOLOGY_LAB_TEST_NAME_LIST =
  "FETCH_RADIOLOGY_LAB_TEST_NAME_LIST";
export const FETCH_SPECIFIC_LAB_GROUP = "FETCH_SPECIFIC_LAB_GROUP";
export const FETCH_SPECIFIC_XRAY_LAB_GROUP = "FETCH_SPECIFIC_XRAY_LAB_GROUP";
export const FETCH_SPECIFIC_RADIOLOGY_LAB_TEST_AND_GROUP =
  "FETCH_SPECIFIC_RADIOLOGY_LAB_TEST_AND_GROUP";
export const FETCH_CONSULTAION_FOR_LAB = "FETCH_CONSULTAION_FOR_LAB";

//export const FETCH_SAVED_CONCOMITANT_MEDICINE_DETAILS = "FETCH_SAVED_CONCOMITANT_MEDICINE_DETAILS";
export const FETCH_ACTIVE_UOM = "FETCH_ACTIVE_UOM";
export const FETCH_LAB_TEST_NAME_LIST_BY_GROUP =
  "FETCH_LAB_TEST_NAME_LIST_BY_GROUP";
export const FETCH_LAB_SAMPLE_TEST_GROUP_LIST =
  "FETCH_LAB_SAMPLE_TEST_GROUP_LIST";

export const FETCH_LAB_TEST_GROUP_LIST_2 = "FETCH_LAB_TEST_GROUP_LIST_2";
export const FETCH_SPECIFIC_SAMPLE_TEST_GROUP =
  "FETCH_SPECIFIC_SAMPLE_TEST_GROUP";
export const FETCH_HEALTH_FACILITY_LIST = "FETCH_HEALTH_FACILITY_LIST";
export const FETCH_FEEDBACK_LIST = "FETCH_FEEDBACK_LIST";
export const FETCH_MEDICINE_TYPE = "FETCH_MEDICINE_TYPE";
export const FETCH_MEDICINE_NAME = "FETCH_MEDICINE_NAME";
export const FETCH_VEHICLE_NAME = "FETCH_VEHICLE_NAME";
export const FETCH_COMPLEX_MEDICINE_NAME = "FETCH_COMPLEX_MEDICINE_NAME";
export const FETCH_COMPLEX_MEDICINE_TYPE = "FETCH_COMPLEX_MEDICINE_TYPE";
export const FETCH_EXTERNAL_MEDICINE_NAME = "FETCH_COMPLEX_MEDICINE_NAME";
export const FETCH_EXTERNAL_MEDICINE_TYPE = "FETCH_EXTERNAL_MEDICINE_TYPE";
export const FETCH_PAID_MEDICINE_NAME = "FETCH_PAID_MEDICINE_NAME";
export const FETCH_PAID_MEDICINE_TYPE = "FETCH_PAID_MEDICINE_TYPE";
export const FETCH_DR_ROUND_ID = "FETCH_DR_ROUND_ID";
export const FETCH_COMPLIANCE_HISTORY = "FETCH_COMPLIANCE_HISTORY";
export const SAVE_COMPLIANCE = "SAVE_COMPLIANCE";
export const FETCH_PROGNOSIS_HISTORY = "FETCH_PROGNOSIS_HISTORY";
export const SAVE_PROGNOSIS = "SAVE_PROGNOSIS";
export const FETCH_PROGNOSIS = "FETCH_PROGNOSIS";
export const FETCH_DRUG_INTERVALS = "FETCH_DRUG_INTERVALS";
export const FETCH_PRESCRIPTION_HISTORY = "FETCH_PRESCRIPTION_HISTORY";
export const FETCH_LAB_TEST_UNITS = "FETCH_LAB_TEST_UNITS";
export const FETCH_VITALS_DETAILS = "FETCH_VITALS_DETAILS";

export const SAVE_DIAGNOSIS = "SAVE_DIAGNOSIS";
export const FETCH_DIAGNOSIS = "FETCH_DIAGNOSIS";
export const SAVE_FINAL_DIAGNOSIS = "SAVE_FINAL_DIAGNOSIS";
export const FETCH_FINAL_DIAGNOSIS = "FETCH_FINAL_DIAGNOSIS";

export const SAVE_PAST_HISTORY = "SAVE_PAST_HISTORY";
export const FETCH_PAST_HISTORY = "FETCH_PAST_HISTORY";
export const SAVE_PERSONAL_HISTORY = "SAVE_PERSONAL_HISTORY";
export const FETCH_PERSONAL_HISTORY = "FETCH_PERSONAL_HISTORY";
export const SAVE_FAMILY_HISTORY = "SAVE_FAMILY_HISTORY";
export const FETCH_FAMILY_HISTORY = "FETCH_FAMILY_HISTORY";
export const SAVE_MENSTRUAL_HISTORY = "SAVE_MENSTRUAL_HISTORY";
export const FETCH_MENSTRUAL_HISTORY = "FETCH_MENSTRUAL_HISTORY";
export const REMOVE_REGISTRATION_ID = "REMOVE_REGISTRATION_ID";
export const SET_REGISTRATION_ID = "SET_REGISTRATION_ID";
export const SET_REGISTRATION_PATIENT = "SET_REGISTRATION_PATIENT";
export const FETCH_SINGLE_MEDICINE_TYPE = "FETCH_SINGLE_MEDICINE_TYPE";
export const FETCH_DIAGNOSIS_LIST_FOR_PRES = "FETCH_DIAGNOSIS_LIST_FOR_PRES";
export const FETCH_ICD_CODE_LIST_FOR_PRES = "FETCH_ICD_CODE_LIST_FOR_PRES";

export const FETCH_FEES = "FETCH_FEES";
export const FETCH_SUB_PULSE_LIST = "FETCH_SUB_PULSE_LIST";
export const FETCH_NABZ_MURAKKAB_LIST = "FETCH_NABZ_MURAKKAB_LIST";

export const FETCH_FOOD_ITEMS = "FETCH_FOOD_ITEMS";
export const FETCH_FEES_TYPE = "FETCH_FEES_TYPE";
export const DELETE_FEES = "DELETE_FEES";
export const FETCH_DISEASE_SYMPTOM_MAP = "FETCH_DISEASE_SYMPTOM_MAP";
export const FETCH_FEES_BY_ORG = "FETCH_FEES_BY_ORG";
export const FETCH_PREVIOUS_CONSULTATION_DETAILS =
  " FETCH_PREVIOUS_CONSULTATION_DETAILS";
export const FETCH_ALL_CONSULTATION_DETAILS = " FETCH_ALL_CONSULTATION_DETAILS";

export const FETCH_PATIENT_DETAILS_BY_OPID = "FETCH_PATIENT_DETAILS_BY_OPID";
export const FETCH_TOTAL_AYUSH_PRACTIONERS = "FETCH_TOTAL_AYUSH_PRACTIONERS";
export const FETCH_TOTAL_PATIENT_REGISTERED = "FETCH_TOTAL_PATIENT_REGISTERED";
export const TOTAL_AYUSH_HEALTH_FACILITIES = "TOTAL_AYUSH_HEALTH_FACILITIES";
export const TODAY_PATIENT_REGISTERED = "TODAY_PATIENT_REGISTERED";
export const TODAY_PRACTIONERS = "TODAY_PRACTIONERS";
export const PREVIOUS_TREND_PULSE = "PREVIOUS_TREND_PULSE";
export const PREVIOUS_TREND_SPO2 = "PREVIOUS_TREND_SPO2";
export const PREVIOUS_TREND_TEMP = "PREVIOUS_TREND_TEMP";
export const PREVIOUS_TREND_BP = "PREVIOUS_TREND_BP";
export const ORG_STAFF_LIST = "ORG_STAFF_LIST";

export const DRILL_DOWN_FETCH_STATE = "DRILL_DOWN_FETCH_STATE";
export const DRILL_DOWN_FETCH_COUNTRY = "DRILL_DOWN_FETCH_COUNTRY";
export const DRILL_DOWN_TODAY_PATIENT_REGISTERED =
  "DRILL_DOWN_TODAY_PATIENT_REGISTERED";
export const FETCH_HEALTH_FACILITIES_LIST = "FETCH_HEALTH_FACILITIES_LIST";
export const DRILL_DOWN_TOTAL_PATIENT_REGISTERED =
  "DRILL_DOWN_TOTAL_PATIENT_REGISTERED";
export const DRILL_DOWN_TOTAL_AYUSH_PRACTIONERS =
  "DRILL_DOWN_TOTAL_AYUSH_PRACTIONERS";
export const DRILL_DOWN_AYUSH_HEALTH_FACILITIES =
  "DRILL_DOWN_AYUSH_HEALTH_FACILITIES";
export const DRILL_DOWN_TODAY_PRACTIONERS = "DRILL_DOWN_TODAY_PRACTIONERS";
export const FETCH_ALL_FEEDBACK = "FETCH_ALL_FEEDBACK";
export const FETCH_ALL_HEALTH_FACILITIES_LIST =
  "FETCH_ALL_HEALTH_FACILITIES_LIST";
export const FETCH_ALL_PRIVATE_STATE_INSTITUTE =
  "FETCH_ALL_PRIVATE_STATE_INSTITUTE";
export const FETCH_HOMEO_MEDICINE = "FETCH_HOMEO_MEDICINE";
export const FETCH_ALL_POTENCY = "FETCH_ALL_POTENCY";
export const SET_CLIENT_ID = "SET_CLIENT_ID";
export const FETCH_BUILDING_LIST_BY_ORG = "FETCH_BUILDING_LIST_BY_ORG";
export const FETCH_OWN_INSTITUTE_LIST = "FETCH_OWN_INSTITUTE_LIST";
export const FETCH_PATIENT_DETAILS_BY_STAFF_ID =
  "FETCH_PATIENT_DETAILS_BY_STAFF_ID";
export const FETCH_DOCTOR_GRAPH = "FETCH_DOCTOR_GRAPH";
export const FETCH_DISEASE_STATISTICS = "FETCH_DISEASE_STATISTICS";
export const FETCH_LAB_TEST_AND_GROUP = "FETCH_LAB_TEST_AND_GROUP";
export const FETCH_LAB_TEST_AND_GROUP_COMPLETED =
  "FETCH_LAB_TEST_AND_GROUP_COMPLETED";
export const FETCH_SAMPLE_LAB_TEST_AND_GROUP =
  "FETCH_SAMPLE_LAB_TEST_AND_GROUP";
export const FETCH_PROGNOSIS_BY_INSTITUTE = "FETCH_PROGNOSIS_BY_INSTITUTE";
export const FETCH_PROGNOSIS_BY_COUNCIL = "FETCH_PROGNOSIS_BY_COUNCIL";
export const FETCH_PROGNOSIS_BY_DISEASE = "FETCH_PROGNOSIS_BY_DISEASE";
export const FETCH_CONSULTATION_PATIENT_LIST =
  "FETCH_CONSULTATION_PATIENT_LIST";
export const FETCH_CONSULTATION_MEDICINE_DETAILS =
  "FETCH_CONSULTATION_MEDICINE_DETAILS";
export const FETCH_CONSULTATION_PATIENT_ID = "FETCH_CONSULTATION_PATIENT_ID";
export const FETCH_TEST_BY_CONSULTATION = "FETCH_TEST_BY_CONSULTATION";
export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION";
export const FETCH_STAFF_BY_ID = "FETCH_STAFF_BY_ID";
export const FETCH_PREV_COMPLAINTS = "FETCH_PREV_COMPLAINTS";
export const FETCH_VITAL_COUNT = " FETCH_VITAL_COUNT";
export const FETCH_TODAY_VITAL_SIGNS = "FETCH_TODAY_VITAL_SIGNS";
export const SET_STAFF_DEPT_LIST = "SET_STAFF_DEPT_LIST";
export const SET_PATIENT_LIST = "SET_PATIENT_LIST";
export const FETCH_DASHBOARD_COUNT = "FETCH_DASHBOARD_COUNT";
export const FETCH_CONSULTATION_DEPT = "FETCH_CONSULTATION_DEPT";
export const FETCH_ALL_CONSULTATION_ROOMS = "FETCH_ALL_CONSULTATION_ROOMS";
export const FETCH_WARD_TYPES = "FETCH_WARD_TYPES";
export const FETCH_ALL_CONSULTATION_ROOMS_BY_DEPID =
  "FETCH_ALL_CONSULTATION_ROOMS_BY_DEPID";
export const FETCH_MOA_STREAM_STATISTICS_DASHBOARD =
  "FETCH_MOA_STREAM_STATISTICS_DASHBOARD";
export const FETCH_ALL_CONSULTATION_ROOMS_BY_STAFFID =
  "FETCH_ALL_CONSULTATION_ROOMS_BY_STAFFID";
export const FETCH_EXTERNAL_TESTS = "FETCH_EXTERNAL_TESTS";
export const FETCH_ALL_EXTERNAL_GROUP_REPORT =
  "FETCH_ALL_EXTERNAL_GROUP_REPORT";
export const FETCH_MOA_INSTITIUTE_STATISTICS_DASHBOARD =
  "FETCH_MOA_INSTITIUTE_STATISTICS_DASHBOARD";
export const FETCH_MOA_COUNCIL_STATISTICS_DASHBOARD =
  "FETCH_MOA_COUNCIL_STATISTICS_DASHBOARD";
export const FETCH_COUNT_BY_ORGID = "FETCH_COUNT_BY_ORGID";
export const FETCH_ALL_PRIVATE_STATE_INSTITUTE_COUNCIL =
  "FETCH_ALL_PRIVATE_STATE_INSTITUTE_COUNCIL";
export const FETCH_TOP10_DISEASE = "FETCH_TOP10_DISEASE";
export const FETCH_WARD_BY_ORGID = "FETCH_WARD_BY_ORGID";
export const FETCH_BED_TYPES = "FETCH_BED_TYPES";
export const FETCH_DIET_TYPES = "FETCH_DIET_TYPES";
export const FETCH_DIET_ARTICLE = "FETCH_DIET_ARTICLE";
export const FETCH_DIET_PLAN = "FETCH_DIET_PLAN";
export const FETCH_WOUND_TYPE = "FETCH_WOUND_TYPE";
export const FETCH_BED = "FETCH_BED";
export const FETCH_DISHCHARGE_NOTES = "FETCH_DISHCHARGE_NOTES";
export const FETCH_WOUND_ODOUR = "FETCH_WOUND_ODOUR";
export const FETCH_WOUND_AREA = "FETCH_WOUND_AREA";
export const FETCH_WOUND_BED = "FETCH_WOUND_BED";
export const FETCH_WOUND_STATUS = "FETCH_WOUND_STATUS";
export const FETCH_PERI_WOUND_SKIN_TYPE = "FETCH_PERI_WOUND_SKIN_TYPE";
export const FETCH_DRAINAGE_INTENSITY = "FETCH_DRAINAGE_INTENSITY";
export const FETCH_DRAINAGE_TYPE = "FETCH_DRAINAGE_TYPE";
export const FETCH_LINEN_MANAGEMENT = "FETCH_LINEN_MANAGEMENT";

export const FETCH_EXTERNAL_THERAPY = "FETCH_EXTERNAL_THERAPY";
// new

export const FETCH_MEAL_TYPE = "FETCH_MEAL_TYPE";
export const FETCH_THERAPY_DETAILS = "FETCH_THERAPY_DETAILS";
export const FETCH_PACKAGE_DETAILS = "FETCH_PACKAGE_DETAILS";
export const FETCH_PHARMACY_PATIENT_DETAILS = "FETCH_PHARMACY_PATIENT_DETAILS";
export const FETCH_VITAL_PATIENT_DETAILS = "FETCH_VITAL_PATIENT_DETAILS";
export const FETCH_BED_STATUS = "FETCH_BED_STATUS";
export const FETCH_RECEPTIONIST_DASHBOARD = "FETCH_RECEPTIONIST_DASHBOARD";
export const FETCH_LAB_STATISTICS = "FETCH_LAB_STATISTICS";
export const FETCH_LAB_SAMPLE_STATISTICS = "FETCH_LAB_SAMPLE_STATISTICS";
export const FETCH_PATIENT_DETAILS_WITH_OPID =
  "FETCH_PATIENT_DETAILS_WITH_OPID";
export const FETCH_FLOOR_LIST = "FETCH_FLOOR_LIST";
export const FETCH_FLOOR_BY_ORGID = "FETCH_FLOOR_BY_ORGID";
export const FETCH_WARD_BY_BUILDINGID = "FETCH_WARD_BY_BUILDINGID";
export const FETCH_BED_BY_WARDID = "FETCH_BED_BY_WARDID";
export const SELECTED_AYUSH_ID = "SELECTED_AYUSH_ID";
export const FETCH_FINAL_DIAGNOSIS_BY_CONSULTATION_ID =
  "FETCH_FINAL_DIAGNOSIS_BY_CONSULTATION_ID";
export const FETCH_PHARMACY_DO_DONT = "FETCH_PHARMACY_DO_DONT";
export const FETCH_LAB_WAITING_FOR_SAMPLE = "FETCH_LAB_WAITING_FOR_SAMPLE";
export const FETCH_LAB_PARTIAL_SAMPLE_COLLECTED =
  "FETCH_LAB_PARTIAL_SAMPLE_COLLECTED";
export const FETCH_LAB_SAMPLE_COLLECTED = "FETCH_LAB_SAMPLE_COLLECTED";
export const FETCH_LAB_TEST_RESULT_ENTRY = "FETCH_LAB_TEST_RESULT_ENTRY";
export const FETCH_RADIOLOGY_WAITING_FOR_SCAN =
  "FETCH_RADIOLOGY_WAITING_FOR_SCAN";
export const FETCH_RADIOLOGY_SCAN_COMPLETED = "FETCH_RADIOLOGY_SCAN_COMPLETED";
export const FETCH_RADIOLOGY_STATISTICS = "FETCH_RADIOLOGY_STATISTICS";
export const FETCH_RADIOLOGY_BODY_PARTS = "FETCH_RADIOLOGY_BODY_PARTS";
export const FETCH_RADIOLOGY_BODY_PARTS_EDIT =
  "FETCH_RADIOLOGY_BODY_PARTS_EDIT";
export const FETCH_RADIOLOGY_INVESTIGATION = "FETCH_RADIOLOGY_INVESTIGATION";
export const FETCH_PREVIOUS_CONSULTATION_BY_PATIENTID =
  "FETCH_PREVIOUS_CONSULTATION_BY_PATIENTID";
export const FETCH_SAMPLE_LAB_TEST_AND_GROUP_EDIT =
  "FETCH_SAMPLE_LAB_TEST_AND_GROUP_EDIT";
export const FETCH_PATIENT_DETAILS_BY_OPAYUSHID =
  "FETCH_PATIENT_DETAILS_BY_OPAYUSHID";
export const FETCH_PATIENT_DETAILS_BY_PATIENT_ID =
  "FETCH_PATIENT_DETAILS_BY_PATIENT_ID";
export const SET_REGISTRATION_PATIENT_REG_ID =
  "SET_REGISTRATION_PATIENT_REG_ID";
export const SET_CARE_CONTEXT_LINKING_TOKEN = "SET_CARE_CONTEXT_LINKING_TOKEN";
export const FETCH_DEPARTMENT_DASHBOARD = "FETCH_DEPARTMENT_DASHBOARD";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const SET_DEPARTMENT = "SET_DEPARTMENT";
export const FETCH_EXTERNAL_RADIOLOGY_REPORT =
  "FETCH_EXTERNAL_RADIOLOGY_REPORT";
export const SET_LABCONSULTATION_DATE = "SET_LABCONSULTATION_DATE";
export const SELECTED_PATIENT = "SELECTED_PATIENT";
export const PHARMACOLOGIST_TOP_BAR_SELECT = "PHARMACOLOGIST_TOP_BAR_SELECT";
export const IPD_TOP_BAR_SELECT = "IPD_TOP_BAR_SELECT";
export const FETCH_ALL_DISEASES = "FETCH_ALL_DISEASES";
export const FETCH_FEES_BY_OPID = "FETCH_FEES_BY_OPID";
export const COMPLAINT_DIAGNOSIS = "COMPLAINT_DIAGNOSIS";
export const SET_HIU_ON_INIT_DTO = "SET_HIU_ON_INIT_DTO";
export const SET_HIU_ON_FETCH_DTO = "SET_HIU_ON_FETCH_DTO";
export const FETCH_ADMISSION_BED_CHARGES = "FETCH_ADMISSION_BED_CHARGES";
export const FETCH_FHIR_DATA_PUSH = "FETCH_FHIR_DATA_PUSH";
export const FETCH_FOOD_MEAL_TYPE_MAP = "FETCH_FOOD_MEAL_TYPE_MAP";
export const FETCH_FOOD_DIET_ARTICLE_MAP = "FETCH_FOOD_DIET_ARTICLE_MAP";
export const DOS_AND_DONTS = "DOS_AND_DONTS";
export const DOS_AND_DONT_STATE = "DOS_AND_DONT_STATE";
export const DISCHARGE_NOTE_STATE = "DISCHARGE_NOTE_STATE";
export const SIMPLE_PRESCRIPTION_STATE = "SIMPLE_PRESCRIPTION_STATE";
export const COMPLEX_PRESCRIPTION_STATE = "COMPLEX_PRESCRIPTION_STATE";
export const EXTERNAL_PRESCRIPTION_STATE = "EXTERNAL_PRESCRIPTION_STATE";
export const PAID_PRESCRIPTION_STATE = "PAID_PRESCRIPTION_STATE";
export const PRESCRIPTION_STATE = "PRESCRIPTION_STATE";
export const ADR_SELECTED_MEDICINE_TABLE = "ADR_SELECTED_MEDICINE_TABLE";
export const ADR_TABLE_RECORDS = "ADR_TABLE_RECORDS";
export const GET_MEDICINE_FORM_STATE = "GET_MEDICINE_FORM_STATE";
export const FETCH_AVAL_QTY = "FETCH_AVAL_QTY";
export const FETCH_PAYMENT_MODE = "FETCH_PAYMENT_MODE";
export const FETCH_SURGICALPROCEDURE_BY_ORG = "FETCH_SURGICALPROCEDURE_BY_ORG";
export const DRILL_DOWN_TOTAL_BED_OCCUPIED = "DRILL_DOWN_TOTAL_BED_OCCUPIED";
export const TOTAL_BED_OCCUPIED = "TOTAL_BED_OCCUPIED";
export const SHOW_HOME_OVERALL_STATISTICS = "SHOW_HOME_OVERALL_STATISTICS";
export const SHOW_HOME_TODAY_STATISTICS = "SHOW_HOME_TODAY_STATISTICS";
export const FETCH_TODAY_BILL_DETAILS_BY_ORGID =
  "FETCH_TODAY_BILL_DETAILS_BY_ORGID";
export const FETCH_FEE_DETAILS_BY_BILL_ID = "FETCH_FEE_DETAILS_BY_BILL_ID";
export const FETCH_ACTIVE_FEES_BY_ORG = "FETCH_ACTIVE_FEES_BY_ORG";
export const FETCH_PHARMACY_MEDICINE_DETAILS =
  "FETCH_PHARMACY_MEDICINE_DETAILS";
export const FETCH_VITAL_SIGNS_TYPE = "FETCH_VITAL_SIGNS_TYPE";
export const FETCH_HISTORY = "FETCH_HISTORY";
export const FETCH_ACTIVE_HISTORY = "FETCH_ACTIVE_HISTORY";
export const FETCH_ACTIVE_VITAL_SIGNS_TYPE = "FETCH_ACTIVE_VITAL_SIGNS_TYPE";
export const FETCH_ALL_SURGICAL_PROCEDURES = "FETCH_ALL_SURGICAL_PROCEDURES";
export const FETCH_ALL_ACTIVE_PROCEDURES = "FETCH_ALL_ACTIVE_PROCEDURES";
export const FETCH_ALL_PRESCRIPTION_DETAILS = "FETCH_ALL_PRESCRIPTION_DETAILS"
export const FETCH_DIAGNOSIS_LIST_FOR_ICD11 = "FETCH_DIAGNOSIS_LIST_FOR_ICD11";
export const SELETED_DIAGNOSIS_TAB = "SELETED_DIAGNOSIS_TAB";