import { 
    FETCH_SHIFT_DETAILS,
    SAVE_SHIFT_NAME,
    DELETE_SHIFT
  } from "../actions/Type";
  
  const initialState = {
        shiftDetails: [],
        shift:[],
        delShift:[]
        // uniqueCities:[],
        // departments:[],
        // apptId: String
    };

    const ShiftDetailsReducer = (state = initialState, action) => {
        switch (action.type) {
        
              case FETCH_SHIFT_DETAILS:
              return {
                  ...state,
                  shiftDetails: action.payload,
                
              }

              case SAVE_SHIFT_NAME:
              return {
                  ...state,
                  shift: action.payload,
              };

              case DELETE_SHIFT:
              return {
                  ...state,
                  delShift: action.payload,
                
              }

              
          default:
            return state;
        }
      };
      
      export default ShiftDetailsReducer;