import React from "react";
import { withStyles, makeStyles, alpha } from "@material-ui/core/styles";
//material-ui
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Grid, InputBase, Checkbox } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Controller, useFieldArray } from "react-hook-form";

import { useDispatch } from "react-redux";
import { restrict } from "../../../component/restrict";
import { components } from "react-select";
import moment from "moment";
//css defined
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  cell: {
    padding: "5px",
  },
  input: {
    height: 35,
    width: 130,
  },
  input2: {
    height: 35,
    width: 80,
    [theme.breakpoints.down("lg")]: {
      width: 75,
    },
  },
  input3: {
    height: 35,
    width: 105,
  },
  input5: {
    height: 35,
    width: 150,
    [theme.breakpoints.down("lg")]: {
      width: 100,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    color: "inherit",
    border: "1px solid #AAAAAA",
    borderRadius: 4,
    height: 35,
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  icon: {
    color: "#ff6600",
    fontSize: "16px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "16px",
  },
  addIcon: {
    padding: 5,
    marginTop: "-10px",
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    height: 22,
    width: 120,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "5px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    [theme.breakpoints.down("lg")]: {
      width: 100,
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon color="black" fontSize="small" />
    </components.DropdownIndicator>
  );
};

const BootstrapInput2 = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    height: 22,
    width: 50,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "5px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const StyledTableCell = withStyles((theme) => ({
  head: {
    // color: theme.palette.common.white,
    color: "111",
    // padding: "12px",
    padding: "1px",
    fontSize: "14px",
    fontWeight: "none",
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function GroupedMedicineTable({
  complexValue,
  control,
  register,
  index: mainIndex,
  errors,
  getValues,
  setValue,
  setError,
  watch,

  editData,
  viewData,
  reset,
  viewMood,
  pendingApproval,
  CheckError,
  medicine,
}) {
  const classes = useStyles();

  const [returnValue, setReturnValue] = React.useState("");
  const [errosMsg, errorMsg] = React.useState("");
  const dispatch = useDispatch();

  const rows = useFieldArray({
    control,
    name: `complex[${mainIndex}].rows`,
  });

  const ValidateQty = (e, val, index, mainIndex) => {
    e.preventDefault();
    const getApprovedQty = getValues(`complex.${mainIndex}.approvedQty`);

    const rows = getValues(`complex.${mainIndex}.rows`);

    let amt = 0;
    rows?.forEach((row, index) => {
      const rowAmount = +row.qty;
      amt += rowAmount;
    });
    console.log(amt, "amount");
    setValue(`complex.${mainIndex}.remainingQty`, getApprovedQty - amt);
    if (amt > getApprovedQty) {
      console.log("error");
      if (pendingApproval) {
        errorMsg("Must be <= pending qty");

        CheckError(e, "Must be <= pending qty");
      } else {
        errorMsg("Must be <= approved qty");

        CheckError(e, "Must be <= approved qty");
      }
      //  setError(`complex.${mainIndex}.rows.${index}.qty`, { type: 'text', message: 'Quantity should  be less than approved qty' });
    } else {
      errorMsg("");
      CheckError(e, "");
    }
  };

  const calculateNetAmount = (e, index, mainIndex) => {
    setReturnValue(e.target.value);
    const rows = getValues(`complex.${mainIndex}.rows`);

    const getSingleUnit = getValues(`complex.${mainIndex}.singleQtyPrice`);

    let amt = 0;
    rows?.forEach((row, index) => {
      const rowAmount = +row.returnedQty;
      amt += rowAmount;
    });

    setValue(`complex.${mainIndex}.price`, amt * getSingleUnit);
  };

  const changeValue = (e, index, mainIndex) => {
    const value = e.target.checked;
    if (!value) {
      setValue(`complex.${mainIndex}.rows.${index}.returnedQty`, 0);
      setValue(`complex.${mainIndex}.price`, 0);
      totalBill();
    }
    totalBill();
  };

  const totalBill = () => {
    let totalPrice = 0;
    const complex = getValues(`complex`);
    console.log(complex, "complex");
    complex.forEach((item, index) => {
      if (item.price !== undefined || item.price !== "") {
        const price = +item.price;
        totalPrice += price;
      }
    });
    setValue(`returnTotal`, totalPrice);
    console.log(totalPrice);
  };

  console.log(medicine);

  return (
    <>
      <TableBody>
        {medicine.rows.map((row, index) => (
          <TableRow key={row.id}>
            <TableCell
              align="center"
              className={classes.cell}
              style={{ borderRight: "1px solid lightgrey" }}
            >
              <Controller
                control={control}
                name={`complex.${mainIndex}.rows.${index}.isSelect`}
                render={({ field: { onChange, value } }) => {
                  console.log(value, "val");

                  return (
                    <Checkbox
                      // {...register(`complex.${mainIndex}.rows.${index}.isSelect`)}
                      color="default"
                      onChange={(e) => {
                        onChange(e);
                        changeValue(e, index, mainIndex);
                        // const value = getValues(`complex.${mainIndex}.rows.${index}.id`);
                        // const targetValue = e.target.checked;
                        // if (targetValue) {
                        //   const data = [...meds];
                        //   data[index] = value;
                        //   setMeds(data);
                        // } else {
                        //   const medArr = [...meds];

                        //   const filtered = medArr.filter((e) => e != meds[index]);
                        //   setMeds(filtered);
                        // }
                      }}
                      disabled={viewMood}
                      value={value}
                      checked={value}
                    />
                  );
                }}
              />
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl style={{ minWidth: "150px" }}>
                {index === 0 && (
                  <TextField
                    style={{ width: "150px" }}
                    disabled
                    InputProps={{
                      className: classes.inputFont,
                    }}
                    {...register(`complex.${mainIndex}.drugTypeName`)}
                    placeholder="Drug Type"
                    size="small"
                    fullWidth
                    variant="outlined"
                  />
                )}
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl style={{ minWidth: "150px" }}>
                {index === 0 && (
                  <TextField
                    disabled
                    style={{ width: "150px", fontSize: "14px" }}
                    {...register(`complex.${mainIndex}.drugName`)}
                    placeholder="Drug Type"
                    InputProps={{
                      className: classes.inputFont,
                    }}
                    size="small"
                    fullWidth
                    variant="outlined"
                  />
                )}
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  style={{ width: "80px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.uomName`)}
                  placeholder="UOM"
                  size="small"
                  fullWidth
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                  disabled
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  disabled
                  style={{ width: "120px", fontSize: "14px" }}
                  {...register(`complex.${mainIndex}.singleUnit`)}
                  onInput={restrict.number}
                  placeholder="Single Unit"
                  size="small"
                  fullWidth
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.inputFont,
                  }}
                  variant="outlined"
                />
              )}
            </TableCell>

            <TableCell className={classes.cell}>
              <TextField
                {...register(`complex.${mainIndex}.rows.${index}.batchNo`)}
                InputProps={{
                  className: classes.input2,
                }}
                classes={{
                  root: classes.customTextField,
                }}
                inputProps={{
                  className: `${classes.alignRight} ${classes.inputFont}`,
                }}
                disabled
                placeholder="Batch No"
                size="small"
                variant="outlined"
                fullWidth
              />
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl>
                <TextField
                  {...register(`complex.${mainIndex}.rows.${index}.qty`)}
                  InputProps={{
                    className: classes.input2,
                  }}
                  classes={{
                    root: classes.customTextField,
                  }}
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  onInput={restrict.number}
                  placeholder="Quantity"
                  disabled
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.cell}>
              <FormControl>
                <TextField
                  {...register(`complex.${mainIndex}.rows.${index}.stockQty`)}
                  InputProps={{
                    className: classes.input2,
                  }}
                  classes={{
                    root: classes.customTextField,
                  }}
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  placeholder="Avl Qty"
                  disabled
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.cell}>
              <FormControl>
                <TextField
                  {...register(`complex.${mainIndex}.rows.${index}.mfgDate`)}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onKeyDown={(e) => e.preventDefault()}
                  disabled
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.input2,
                  }}
                />
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              <FormControl>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  onKeyDown={(e) => e.preventDefault()}
                  {...register(`complex.${mainIndex}.rows.${index}.expDate`)}
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  InputProps={{
                    className: classes.input2,
                  }}
                />
              </FormControl>
            </TableCell>

            <TableCell className={classes.cell}>
              <Controller
                control={control}
                name={`complex.${mainIndex}.rows.${index}.returnedQty`}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    InputProps={{
                      className: classes.input2,
                    }}
                    classes={{
                      root: classes.customTextField,
                    }}
                    disabled={
                      watch(`complex.${mainIndex}.rows.${index}.isSelect`) ===
                        false || viewMood
                    }
                    onChange={(e) => {
                      onChange(e);
                      calculateNetAmount(e, index, mainIndex);
                      totalBill();
                    }}
                    value={getValues(
                      `complex.${mainIndex}.rows.${index}.returnedQty`
                    )}
                    onInput={restrict.digits}
                    placeholder="Return Qty"
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    inputProps={{
                      className: `${classes.alignRight} ${classes.inputFont}`,
                      maxLength: 7,
                    }}
                    error={!!errors?.rows?.[index]?.returnedQty}
                    helperText={errors?.rows?.[index]?.returnedQty?.message}
                  />
                )}
              />
            </TableCell>

            <TableCell className={classes.cell}>
              {index === 0 && (
                <TextField
                  {...register(`complex.${mainIndex}.singleQtyPrice`)}
                  InputProps={{
                    className: classes.input5,
                  }}
                  // onInput={(e) => {
                  //   restrict.remarks(e);
                  //   inputTrim(e);
                  // }}

                  classes={{
                    root: classes.customTextField,
                  }}
                  placeholder="Single Unit Price(₹)"
                  size="small"
                  variant="outlined"
                  disabled
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  fullWidth
                />
              )}
            </TableCell>
            <TableCell>
              {index === 0 && (
                <TextField
                  {...register(`complex.${mainIndex}.price`)}
                  InputProps={{
                    className: classes.input5,
                  }}
                  disabled
                  classes={{
                    root: classes.customTextField,
                  }}
                  placeholder="Price(₹)"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    className: `${classes.alignRight} ${classes.inputFont}`,
                  }}
                  value={getValues(`complex.${mainIndex}.price`)}
                  fullWidth
                />
              )}
            </TableCell>
            <TableCell>
              {index === 0 && (
                <Controller
                  control={control}
                  name={`complex.${mainIndex}.remark`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      InputProps={{
                        className: classes.input5,
                      }}
                      // style={{width:"200px"}}
                      classes={{
                        root: classes.customTextField,
                      }}
                      disabled={viewMood}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={getValues(
                        `complex.${mainIndex}.remark`
                      )}
                      placeholder="Remarks"
                      size="small"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        className: `${classes.alignRight} ${classes.inputFont}`,
                        maxLength: 100,
                      }}
                      
                    />
                  )}
                />
               
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

function ComplexMedicineTable(
  {
    control,
    register,
    watch,
    reset,
    setValue,
    setError,
    getValues,
    errors,
    uncheckHistory,
    complexMedicines,
    setComplexMedicines,
    editData,
    viewData,
    viewMood,
    pendingApproval,
    CheckError,
  },
  ref
) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const complexValue = useFieldArray({
    control,
    name: "complex",
  });

  React.useEffect(() => {
    if (viewData) {
      setValue(
        "complex",

        viewData?.purchaseOrderDetailsDtos?.map((el, index) => ({
          drugTypeName: el.drugTypeName,
          drugName: el.drugName,
          uomName: el.uomName,
          singleUnit: el.singleUnit,
          approvedQty: el.approvedQty,
          singleQtyPrice: el.singleQtyPrice,
          price: viewMood && el.returnAmount ? el.returnAmount : "",
          remark: viewMood && el.remarks ? el.remarks : "",
          id: el.id,
          purchaseDetId: el.entryDetailsDrugDtl[0]?.purchEntDetId,

          rows: el?.entryDetailsDrugDtl?.map((ml, index) => ({
            batchNo: ml.serialNo,
            qty: ml.receivedQty,
            stockQty: ml.stockQty,
            isSelect: false,
            mfgDate: moment(ml.manufactureDate).format("YYYY-MM-DD"),
            expDate: moment(ml.expiryDate).format("YYYY-MM-DD"),
            returnedQty: ml?.returnQty ? ml?.returnQty : "",
            id: ml.id,
          })),
        }))
      );
      setValue("gst", viewData?.vendorGstNo);
      setValue("cgst", viewData?.vendorCgst);
      setValue("sgst", viewData?.vendorSgst);
      setValue("vendorName", viewData?.vendorName);

      setValue("billNo", viewData?.billNo);
      setValue(
        "expectedDate",
        moment(viewData?.expectedDate).format("DD-MM-YYYY")
      );
    }
  }, [setValue, viewData]);

  // React.useEffect(() => {
  //   if (somId) {
  //     dispatch(getComplexMedicineTypes(somId));
  //     dispatch(fetchVehicleName(somId));
  //     dispatch(fetchDrugInterval());
  //   }
  // }, [somId]);

  // const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={11}></Grid>

        <Grid item xs={12}>
          {/* <input {...register(`complex.${index}.history`)} type="hidden" /> */}
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <StyledTableRow
                  align="center"
                  style={{ backgroundColor: "#caf7fb" }}
                >
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">Drug Type</StyledTableCell>
                  <StyledTableCell align="center">Drug Name</StyledTableCell>
                  <StyledTableCell align="center">UoM</StyledTableCell>
                  <StyledTableCell align="center">Single Unit</StyledTableCell>
                  <StyledTableCell align="center">Batch No.</StyledTableCell>
                  <StyledTableCell align="center">Received Qty</StyledTableCell>
                  <StyledTableCell align="center">Avl Qty</StyledTableCell>
                  <StyledTableCell align="center">Mfg. Date</StyledTableCell>
                  <StyledTableCell align="center">Exp. Date</StyledTableCell>
                  <StyledTableCell align="center">Return Qty</StyledTableCell>
                  <StyledTableCell align="center">
                    Single Unit Price(₹)
                  </StyledTableCell>
                  <StyledTableCell align="center">Price(₹)</StyledTableCell>
                  <StyledTableCell align="center">Remark</StyledTableCell>

                  {/* <StyledTableCell align="center"> </StyledTableCell> */}
                </StyledTableRow>
              </TableHead>

              {complexValue.fields.map((medicine, index) => (
                <GroupedMedicineTable
                  errors={errors?.[index]}
                  {...{
                    index,
                    complexValue,
                    control,
                    register,
                    watch,
                    reset,
                    medicine,

                    complexMedicines,

                    getValues,
                    setValue,
                    setError,
                    viewMood,
                    pendingApproval,
                    CheckError,
                    viewData,
                  }}
                />
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <br />
    </>
  );
}

const PurchaseReturnForm = React.forwardRef(ComplexMedicineTable);
export default PurchaseReturnForm;
