import {
  FETCH_DIAGNOSIS_LIST,
  FETCH_DIAGNOSIS,
  FETCH_FINAL_DIAGNOSIS,
  FETCH_DIAGNOSIS_LIST_FOR_PRES,
  FETCH_PREV_COMPLAINTS,
  FETCH_FINAL_DIAGNOSIS_BY_CONSULTATION_ID
} from "../actions/Type";

const initialState = {
  diagnosisDetails: [],
  diagnosisDetailsForPres: [],
  diagnosis: [],
  finalDiagnosis: [],
  prevcomplaints: null,
  finalDiagnosisByConsultationId:[]
};

const DiagnosisReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DIAGNOSIS_LIST:
      return {
        ...state,
        diagnosisDetails: action.payload,
      };
    case FETCH_DIAGNOSIS:
      return {
        ...state,
        diagnosis: action.payload,
      };
    case FETCH_FINAL_DIAGNOSIS:
      return {
        ...state,
        finalDiagnosis: action.payload,
      };
    case FETCH_DIAGNOSIS_LIST_FOR_PRES:
      return {
        ...state,
        diagnosisDetailsForPres: action.payload,
      };      
    case FETCH_PREV_COMPLAINTS:
      return {
        ...state,
        prevcomplaints: action.payload,
      };
      case FETCH_FINAL_DIAGNOSIS_BY_CONSULTATION_ID:
        return {
          ...state,
          finalDiagnosisByConsultationId: action.payload,
        };

    default:
      return state;
  }
};

export default DiagnosisReducer;
