import { showSnackbar } from "../actions/Notification";
import {
  FETCH_VITALS_DETAILS,
  FETCH_VITAL_COUNT,
  FETCH_TODAY_VITAL_SIGNS,
  FETCH_VITAL_SIGNS_TYPE,
  FETCH_ACTIVE_VITAL_SIGNS_TYPE,
} from "./Type";
import axiosIpdOpdInstance from "../api/IpdOpdInstance";
import axiosInstance from "../api/Instance";

import { saveVitalSignType, getVitalSignType, getActiveVitalSignType } from "../api/url";

export const saveVitalSigns = (vitalSigns) => async (dispatch) => {
  try {
    const url = "vitalSign/saveVitalSign";

    const response = await axiosIpdOpdInstance.post(url, vitalSigns);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else if (res == "success") {
      if (vitalSigns.id === 0) {
        responseObj.message = "Vital signs recorded successfully";
      } else {
        responseObj.message = "Vital signs records updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchSavedVitals = (consultationId) => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get("vitalSign/fetchSavedVitals/" + consultationId);

    dispatch({
      type: FETCH_VITALS_DETAILS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchVitalCount = (orgId) => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get("vitalSign/getVitalStatasticsByOrgId/" + orgId);

    dispatch({
      type: FETCH_VITAL_COUNT,
      payload: res.data,
    });
    console.log("count", res.data);
  } catch (error) {
    console.log(error);
  }
};

export const fetchTodayVitalSigns = (orgId) => async (dispatch) => {
  try {
    const res = await axiosIpdOpdInstance.get("vitalSign/getTodayVitalSign/" + orgId);

    dispatch({
      type: FETCH_TODAY_VITAL_SIGNS,
      payload: res.data,
    });
    console.log("FETCH_TODAY_VITAL_SIGNS", res.data);
  } catch (error) {
    console.log(error);
  }
};

export const saveVitalSignsType = (vitalSigns) => async (dispatch) => {
  try {
    // const url = "vitalSign/saveVitalSign";

    const response = await axiosInstance.post(saveVitalSignType, vitalSigns);

    const res = response.data;

    let responseObj = {
      message: "",
      messageInfoType: "",
    };
    if (res === "failure") {
      responseObj.message = "Request failed. Plz try again.";
      responseObj.messageInfoType = "error";
      dispatch(showSnackbar(responseObj));
    } else if (res == "success") {
      if (vitalSigns.id === 0) {
        responseObj.message = "Vital signs Type recorded successfully";
      } else {
        responseObj.message = "Vital signs Type records updated successfully";
      }
      responseObj.messageInfoType = "success";
      dispatch(showSnackbar(responseObj));
    }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchVitalSignType = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(getVitalSignType);

    dispatch({
      type: FETCH_VITAL_SIGNS_TYPE,
      payload: res.data,
    });

    console.log("FETCH_VITAL_SIGNS_TYPE", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchActiveVitalSignType = async () => {
  try {
    const res = await axiosInstance.get(getActiveVitalSignType);

    console.log("active vst", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
