import { FETCH_SPECIALIZATION_LIST } from "../actions/Type";

const initialState = {
  specializations: [],
};

const SpecializationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPECIALIZATION_LIST:
      return {
        ...state,
        specializations: action.payload,
      };

    default:
      return state;
  }
};

export default SpecializationReducer;
