import {
  FETCH_ORG_LIST,
  FETCH_DEPT_TYPE_LIST,
  FETCH_STAFF_TYPE_LIST,
  FETCH_INSTITUTE_LIST,
  FETCH_DEPT_LIST,
  FETCH_USER_LIST,
  FETCH_HEALTH_FACILITY_LIST,
  FETCH_ALL_PRIVATE_STATE_INSTITUTE,
  FETCH_OWN_INSTITUTE_LIST,
  FETCH_STAFF_BY_ID,
  FETCH_ALL_PRIVATE_STATE_INSTITUTE_COUNCIL
} from "../actions/Type";

const initialState = {
  orgDetails: [],
  deptTypes: [],
  departments: [],
  staffNames: [],
  users: [],
  institutes: [],
  healthFacilities: [],
  moaStateOrPrivate:[],
  ownInstitutues:[],
  staffDetails:[],
  pipu:[]
};

const AdminMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORG_LIST:
      return {
        ...state,
        orgDetails: action.payload,
      };
      case FETCH_ALL_PRIVATE_STATE_INSTITUTE:
        return {
          ...state,
          moaStateOrPrivate: action.payload,
        };
        case FETCH_ALL_PRIVATE_STATE_INSTITUTE_COUNCIL:
          return {
            ...state,
            pipu: action.payload,
          };
    case FETCH_DEPT_TYPE_LIST:
      return {
        ...state,
        deptTypes: action.payload,
      };

    case FETCH_DEPT_LIST:
      return {
        ...state,
        departments: action.payload,
      };
    case FETCH_STAFF_TYPE_LIST:
      return {
        ...state,
        staffNames: action.payload,
      };
      case FETCH_OWN_INSTITUTE_LIST:
      return {
        ...state,
        ownInstitutues: action.payload,
      };
    case FETCH_USER_LIST:
      return {
        ...state,
        users: action.payload,
      };
    case FETCH_INSTITUTE_LIST:
      return {
        ...state,
        institutes: action.payload,
      };
    case FETCH_HEALTH_FACILITY_LIST:
      return {
        ...state,
        healthFacilities: action.payload,
      };
      case FETCH_STAFF_BY_ID:
        return {
          ...state,
          staffDetails: action.payload,
        };
    default:
      return state;
  }
};

export default AdminMasterReducer;
