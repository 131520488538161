import {
  FETCH_COUNTRY_LIST,
  FETCH_STATES_LIST,
  FETCH_PERM_STATES_LIST,
  FETCH_CORR_STATES_LIST,
  FETCH_CITIES_LIST,
  FETCH_PERM_CITIES_LIST,
  FETCH_CORR_CITIES_LIST,
  SAVE_REGISTER_PATIENT,
  FETCH_PATIENT_NAME,
  FETCH_NDHM_MSG,
  SET_NDHM_MODE,
  SET_NDHM_PURPOSE,
  SET_NDHM_TXN_ID,
  SET_PATIENT_DTO,
  SET_UHID,
  VERIFICATION_METHOD,
  SET_PATIENT_IMAGE,
  SET_IPD_MENU,
  SET_PATIENT_TYPE_ID,
  FETCH_REG_DEPT_LIST,
  FETCH_IPD_DEPT,
  FETCH_PATIENT_DETAILS_BY_OPID,
  SET_CLIENT_ID,
  SET_CARE_CONTEXT_LINKING_TOKEN,
  SET_HIU_ON_FETCH_DTO,
  SET_HIU_ON_INIT_DTO,
  FETCH_FHIR_DATA_PUSH,
} from "../actions/Type";

const initialState = {
  permCountries: [],
  corrCountries: [],
  permStates: [],
  corrStates: [],
  permCities: [],
  corrCities: [],
  ayushId: String,
  patientData: {},
  ndhmMsg: {},
  ndhmModes: [],
  ndhmPurpose: "",
  ndhmTrasactionId: "",
  careContextLinkingToken: "",
  ipdMenu: "",
  patientDetailsNDHM: {},
  uhid: "",
  verifyMethod: "",
  patientImage: null,
  deptList: [],
  ipdDept: [],
  patientDetails: {},
  clientId: "",
  patientTypeId: "",
  hiuOnInitDto: {},
  hiuOnFetchDto: {},
  fhirData: [],
};

const CuntryGrpReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRY_LIST:
      return {
        ...state,
        permCountries: [action.payload],
        corrCountries: [action.payload],
      };

    case FETCH_STATES_LIST:
      return {
        ...state,
        permStates: [action.payload],
        corrStates: [action.payload],
      };

    case FETCH_PERM_STATES_LIST:
      return {
        ...state,
        permStates: [action.payload],
      };
    case FETCH_CORR_STATES_LIST:
      return {
        ...state,
        corrStates: [action.payload],
      };

    case FETCH_CITIES_LIST:
      return {
        ...state,
        permCities: [action.payload],
        corrCities: [action.payload],
      };
    case FETCH_PERM_CITIES_LIST:
      return {
        ...state,
        permCities: [action.payload],
      };
    case FETCH_CORR_CITIES_LIST:
      return {
        ...state,
        corrCities: [action.payload],
      };

    case FETCH_PATIENT_NAME:
      return {
        ...state,
        patientData: action.payload,
      };

    case SAVE_REGISTER_PATIENT:
      return {
        ...state,
        ayushId: [action.payload],
      };

    case FETCH_NDHM_MSG:
      return {
        ...state,
        ndhmMsg: action.payload,
      };
    case SET_NDHM_MODE:
      return {
        ...state,
        ndhmModes: action.payload,
      };

    case SET_NDHM_PURPOSE:
      return {
        ...state,
        ndhmPurpose: action.payload,
      };

    case SET_NDHM_TXN_ID:
      return {
        ...state,
        ndhmTrasactionId: action.payload,
      };

    case SET_PATIENT_DTO:
      return {
        ...state,
        patientDetailsNDHM: action.payload,
      };

    case SET_CARE_CONTEXT_LINKING_TOKEN:
      return {
        ...state,
        careContextLinkingToken: action.payload,
      };

    case SET_UHID:
      return {
        ...state,
        uhid: action.payload,
      };
    case VERIFICATION_METHOD:
      return {
        ...state,
        verifyMethod: action.payload,
      };
    case SET_PATIENT_IMAGE:
      return {
        ...state,
        patientImage: action.payload,
      };

    case FETCH_REG_DEPT_LIST:
      return {
        ...state,
        deptList: action.payload,
      };

    case FETCH_PATIENT_DETAILS_BY_OPID:
      return {
        ...state,
        patientDetails: action.payload,
      };

    case SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.payload,
      };
    case FETCH_IPD_DEPT:
      return {
        ...state,
        ipdDept: action.payload,
      };
    case SET_IPD_MENU:
      return {
        ...state,
        ipdMenu: action.payload,
      };
    case SET_PATIENT_TYPE_ID:
      return {
        ...state,
        patientTypeId: action.payload,
      };

    case SET_HIU_ON_INIT_DTO:
      return {
        ...state,
        hiuOnInitDto: action.payload,
      };

    case SET_HIU_ON_FETCH_DTO:
      return {
        ...state,
        hiuOnFetchDto: action.payload,
      };

    case FETCH_FHIR_DATA_PUSH:
      return {
        ...state,
        fhirData: action.payload,
      };

    default:
      return state;
  }
};

export default CuntryGrpReducer;
