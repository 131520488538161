import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popper,
  Select,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAdviceDetails } from "../../actions/AdviceAction";
import { fetchDiagnosisDetailsForPres } from "../../actions/DiagnosisAction";
import { saveUnaniPulse } from "../../actions/PulseAction";
import PatientDetails from "./Layout/PatientDetails";
import "./print.css";
// import { savePatientLabTestAndLabGroups } from "../../actions/LabActions/LabRadiologyAssoAction";
// import { saveComplaintsDetails, saveComplaints } from "../../actions/ConsultationAction";
// import { saveHomeopathy } from "../../actions/HomeopathyAction";
import { fetchLatestComplaints } from "../../actions/Compliance&PrognosisAction";
import {
  // saveMedicines,
  fetchAllMedicineVehicle,
  fetchDrugInterval,
  fetchMedicineName,
  fetchSom,
  fetchUom,
} from "../../actions/SimpleMedicineAction";
// import { saveComplexMedicines } from "../../actions/ComplexMedicineAction";
import ReactToPrint from "react-to-print";
import { fetchInvestigationSummary } from "../../actions/LabActions/LabTestUnitsAction";
import {
  careContextNotify,
  savePreviewDetail,
  sendMsgToPharmacist,
  updateConsultation,
} from "../../actions/PreviewAction";
import { REMOVE_REGISTRATION_ID } from "../../actions/Type";
import ahmis from "../../assets/ahmis.png";
import leaf from "../../assets/leaf.png";
import { CloseButton } from "../utils/Button";
// import { handlePrintButtonClick } from "./DownloadPreviewPdf";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { fetchRadiologyInvestigationSummary } from "../../actions/RadiologyInvAction";
import RadioInvestigation from "../../component/RadioInvestigation";
import InvestigationSummary from "../InvestigationSummary";
// import axiosInstance from "../../api/Instance";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  fetchHealthFacilityBySomId,
  saveOPDTransferDetails,
  saveTranferIPAdmissionDetailsNew,
} from "../../actions/AdminMasterAction";
import { fetchRegDeptListByOrgId } from "../../actions/RegistrationAction";
import { showSnackbar } from "../../actions/Notification";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const fontSize8 = 8;
const fontSize10 = 10;
const fontSize12 = 12;
const fontSize14 = 14;
const fontSize15 = 15;

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const CheckCircleComponent = () => (
  <CheckCircle color="primary" fontSize="small" style={{ marginBottom: -5 }} />
);
const BlockCircleComponent = () => (
  <Cancel color="error" fontSize="small" style={{ marginBottom: -5 }} />
);

export default function Preview(props) {
  let componentRef = useRef();
  let serialNo = 0;
  const [textSizeFactor, setTextSizeFactor] = React.useState(0);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    center: {
      justifyContent: "center",
    },
    icon: {
      color: "white",
    },
    text: {
      fontSize: fontSize12 + textSizeFactor,
      [theme.breakpoints.down("md")]: {
        fontSize: fontSize12 + textSizeFactor,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize12 + textSizeFactor,
      },
    },
    text1: {
      fontSize: fontSize12 + textSizeFactor,
      [theme.breakpoints.down("md")]: {
        fontSize: fontSize14 + textSizeFactor,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize8,
      },
    },
    text2: {
      fontSize: fontSize12 + textSizeFactor,
      [theme.breakpoints.down("md")]: {
        fontSize: fontSize14 + textSizeFactor,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize14 + textSizeFactor,
      },
    },
    header1: {
      fontSize: fontSize12 + textSizeFactor,
      fontWeight: "bold",
      marginLeft: "1%",
      // marginTop: "1%",
      color: "#07A0B0",
      [theme.breakpoints.down("md")]: {
        fontSize: fontSize14 + textSizeFactor,
        fontWeight: "bold",
        marginLeft: "1%",
        color: "#07A0B0",
        marginTop: "1%",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize14 + textSizeFactor,
        fontWeight: "bold",
        marginLeft: "1%",
        color: "#07A0B0",
        marginTop: "1%",
      },
    },
    chip: {
      backgroundColor: "#CAF7FB",
      fontWeight: "bold",
      fontSize: fontSize10 + textSizeFactor,
      // textTransform: "lowercase",
      [theme.breakpoints.down("md")]: {
        fontSize: fontSize10 + textSizeFactor,
        // textTransform: "lowercase",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize8 + textSizeFactor,
        // textTransform: "lowercase",
      },
    },
    tableCell: {
      fontSize: fontSize12 + textSizeFactor,
      border: "1px solid lightgrey",
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize14 + textSizeFactor,
        padding: "0",
        border: "1.5px solid lightgrey",
      },
    },
    header: {
      fontSize: "16px",
      textDecoration: "underline",
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize14 + textSizeFactor,
      },
    },
    org: {
      fontSize: "20px",
      fontWeight: "bold",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize12 + textSizeFactor,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    list: {
      [theme.breakpoints.down("md")]: {
        border: "1px solid black",
        padding: "0 !important",
      },
    },
    tableGrid: {
      maxWidth: "100%",
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        minWidth: 500,
      },
    },
    table: {
      minWidth: 500,
      maxWidth: "100%",
      margin: "auto",

      [theme.breakpoints.down("md")]: {
        border: "1.5px solid black",
      },
      [theme.breakpoints.down("sm")]: {
        border: "1.5px solid black",
      },
    },
    dosLabel: {
      "& span": {
        fontSize: fontSize12 + textSizeFactor,
        [theme.breakpoints.down("md")]: {
          fontSize: fontSize14 + textSizeFactor,
          margin: 0,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: fontSize14 + textSizeFactor,
          margin: 0,
        },
      },
    },
    ahmisLogo: {
      width: "50%",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "45%",
        height: "auto",
      },
    },
    ayushLogo: {
      width: "50%",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "45%",
        height: "auto",
      },
    },
    doList: {
      padding: 0,
    },
    previewCard: {
      // borderRadius: "20px",
      padding: "5px",
      border: "1px solid #07A0B0",
      // boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
      transition: "all 0.2s",
    },
    cardHeading: {
      fontWeight: "bold",
      fontSize: fontSize14 + textSizeFactor,
      marginLeft: "1%",
      padding: "5px",
      color: "#07A0B0",
      [theme.breakpoints.down("md")]: {
        fontSize: fontSize12 + textSizeFactor,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize12 + textSizeFactor,
      },
    },
    content: {
      backgroundColor: "white",
      color: "black",
      textAlign: "left",
      padding: "5px 0px 10px 0px !important",
      margin: "0px 10px 0px 10px",
    },
    padding: {
      padding: "5px 0px 5px 0px",
    },
    label: {
      fontSize: fontSize12 + textSizeFactor,
    },
    margin: {
      marginTop: "10px",
    },
  }));
  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
      fontSize: fontSize12 + textSizeFactor,
      padding: "0px",
      [theme.breakpoints.down("md")]: {
        fontSize: fontSize12 + textSizeFactor,
        padding: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: fontSize14 + textSizeFactor,
        padding: "0px",
      },
    },
    body: {
      fontSize: fontSize15 + textSizeFactor,
    },
  }))(TableCell);

  const { data, open, onClose, onEdit, openTransfer, closeTransferFn } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [pulseData, setPulseData] = useState(null);
  const [cdData, setCdData] = useState(null);
  const [concomitantMed, setConcomitantMed] = useState(null);
  const [dos, setDos] = useState(null);
  const [donts, setDonts] = useState(null);
  const [dontsRemarks, setDontsRemarks] = useState(null);
  const [dosRemarks, setDosRemarks] = useState(null);
  const [diagnosisData, setDiagnosisData] = useState(null);
  const [familyData, setFamilyData] = useState(null);
  const [simple, setSimple] = useState(null);
  const [external, setExternal] = useState(null);
  const [homeo, setHomeo] = useState(null);
  const [paid, setPaid] = useState(null);
  const [complex, setComplex] = useState(null);
  const [labDetails, setLabDetails] = useState(null);
  const [candPData, setCandPData] = useState(null);
  const [pastData, setPastData] = useState(null);
  const [allopath, setAllopath] = useState(null);
  const [concomData, setConcomData] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  const [treatmentData, setTreatmentData] = useState(null);
  const [systemicData, setSystemicData] = useState(null);
  const [physicalData, setPhysicalData] = useState(null);
  const [menstrualHistory, setMenstrualHistory] = useState(null);
  // const [signSymptom, setSignSymptom] = useState(null);
  const [pulseUnani, setPulseUnani] = useState(null);
  const [nmcCodeTerm, setNmcCodeTerm] = React.useState(null);
  const [complexData, setComplexData] = useState(null);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openGroupSummary, setOpenGroupSummary] = useState(false);
  const [openRadioSummary, setOpenRadioSummary] = useState(false);
  const [showReport, setReportShow] = React.useState(false);
  const [show, setShow] = useState(false);
  const [savingConsultation, setSavingConsultation] = React.useState(false);

  const [som, setSom] = React.useState("");
  const [hospitalValue, setHospitalValue] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [selectedDept, setSelectedDept] = React.useState(null);

  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);

  const healthFacilities = useSelector(
    (state) => state.adminMasterReducer.healthFacilities
  );
  const departments = useSelector(
    (state) => state.registrationReducer.deptList
  );
  const ipdDept = useSelector((state) => state.registrationReducer.ipdDept);

  const pulseDetails = useSelector((state) => state.pulseReducer.pulseDetails);
  const diagnosisList = useSelector(
    (state) => state.diagnosisReducer.diagnosisDetailsForPres
  );

  const selectedICDTAB = useSelector((state) => state.selectedIcdTab);
  const consultationID = useSelector(
    (state) => state.consultationReducer.patient.consultationId
  );

  const ayushID = useSelector(
    (state) => state.consultationReducer.patient.ayushId
  );
  const somId = useSelector((state) => state.loginReducer.staffLogin?.somId);
  let soms = useSelector((state) => state.simpleMedicineReducer.som);
  let uoms = useSelector((state) => state.simpleMedicineReducer.uom);
  let allMedicineVehicleNames = useSelector(
    (state) => state.simpleMedicineReducer.allVehicleName
  );
  let drugIntervals = useSelector(
    (state) => state.simpleMedicineReducer.drugIntervals
  );

  const homeoMedtypes = useSelector(
    (state) => state.homeoPathyReducer.homeoMedicines
  );

  const homeoPotency = useSelector(
    (state) => state.homeoPathyReducer.homeoPotency
  );

  let complexMedicineTypes = useSelector(
    (state) => state.complexMedicineReducer.complexMedicineTypes
  );
  // const fetch = async () => {
  //   try {
  //     const symptomResponse = await axiosInstance.get(
  //       "signsSymptoms/getSignSymptomBySomId/" + somId
  //     );
  //     // const data = symptomResponse.data;
  //     // setSignSymptom([...data]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const StaffLogin = useSelector((state) => state.loginReducer.staffLogin);
  const orgId = StaffLogin?.orgId;
  const registrationPatient = useSelector(
    (state) => state.consultationReducer.registrationPatient
  );
  let regId = registrationPatient?.registrationId;
  const streamNames = soms.filter(
    (d) => d.id != 3 && d.id != StaffLogin?.somId
  );
  const selectedPatient = useSelector(
    (state) => state.consultationReducer.patient
  );
  const registrationID = useSelector(
    (state) => state.consultationReducer.registrationId
  );

  const clientId = useSelector((state) => state.registrationReducer.clientId);

  const simpleMedicineTypes = useSelector(
    (state) => state.simpleMedicineReducer.singleMedicineTypes
  );
  const externalMedicineTypes = useSelector(
    (state) => state.externalMedicineReducer.externalMedicineTypes
  );

  const paidMedicineTypes = useSelector(
    (state) => state.paidMedicineReducer.paidMedicineTypes
  );

  const vehicleNames = useSelector(
    (state) => state.simpleMedicineReducer.vehicleName
  );

  // const surgicalProcedureList = useSelector(
  //   (state) => state?.surgicalProcedureReducer?.surgicalProcedureList
  // );
  const activeProcedureList= useSelector(
    (state) => state?.surgicalProcedureReducer?.activeProcedureList
  );

  const [transferData, setTransferData] = useState({
    staffId: StaffLogin?.staffId,
    ayushId: ayushID,
    consultationId: consultationID,
    organizationId: "",
    departmentId: "",
    ownOrganizationId: StaffLogin?.orgId,
  });
  const [ipAdmissiontransferData, setIpAdmissiontransferData] = useState({
    organizationId: StaffLogin?.orgId,
    consultationId: consultationID,
    staffId: StaffLogin?.staffId,
    departmentId: "",
    remarks: "",
    admissionDate: new Date(),
  });

  const handleMouseEnter = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleMouseLeave = (event) => {
    setAnchorEl(null);
  };

  const handleChangeSom = (event) => {
    setSom(event.target.value);
    dispatch(fetchHealthFacilityBySomId(event.target.value));
  };

  const handleChangeOrg = (v) => {
    setHospitalValue(v?.id);
    v && dispatch(fetchRegDeptListByOrgId(v?.id));
    setTransferData({
      ...transferData,
      organizationId: v?.id,
    });
  };

  const handleChangeDept = (event) => {
    setSelectedDept(event.target.value);
    setTransferData({
      ...transferData,
      departmentId: event.target.value,
    });
  };

  const investigationGroupSummary = async () => {
    setOpenGroupSummary(true);
    if (consultationID) {
      await dispatch(fetchInvestigationSummary(consultationID)).then((res) => {
        if (res !== "EMPTY") {
          setShow(true);
        } else {
          setShow(false);
        }
      });
    }
  };

  const investigationRadioSummary = async () => {
    setOpenRadioSummary(true);
    if (consultationID) {
      await dispatch(fetchRadiologyInvestigationSummary(consultationID)).then(
        (res) => {
          if (res !== "EMPTY") {
            setReportShow(true);
          } else {
            setReportShow(false);
          }
        }
      );
    }
  };

  React.useEffect(() => {
    dispatch(fetchAdviceDetails(somId));
    dispatch(fetchDiagnosisDetailsForPres(somId));
    // fetch(); //Not Required
    dispatch(fetchAllMedicineVehicle());
    dispatch(fetchUom());
    dispatch(fetchSom());
    dispatch(fetchDrugInterval());
    if (regId !== null && regId !== undefined) {
      dispatch(fetchLatestComplaints(regId));
    }
    if (somId === 5) {
      dispatch(fetchMedicineName(80, orgId));
    }
    if (somId != 0) {
      if (somId == 4) {
        setNmcCodeTerm("NSMC");
      } else if (somId == 2) {
        setNmcCodeTerm("NUMC");
      } else if (somId == 1) {
        setNmcCodeTerm("NAMC");
      }
    }
  }, [somId]);

  const getLabRadiologyTests = (radio, lab) => {
    let result = [];
    if (radio && lab) {
      for (let i = 0; i < radio?.length; i++) {
        if (lab[i]?.labTests?.map((el) => el.id)?.includes(radio[i][0])) {
          result.push({ ...lab[i], labTests: radio[i] });
        }
      }
    }

    return result;
  };

  const handleClose = () => {
    onClose();
    closeTransferFn();
  };

  const previewObject = () => {
    const preview = {};
    setSavingConsultation(true);
    let consultationStatus = "C";
    if (data && data.pulse && data.pulse.pulseTypeId !== 0) {
      const data = { ...props.data.pulse };
      preview.pulseDto = { ...data, consultationId: consultationID };
    }
    if (pulseUnani && pulseUnani.pulseData !== null) {
      dispatch(saveUnaniPulse(pulseUnani));
    }
    if (cdData !== null) {
      const complaintData =
        data.candD &&
        data?.candD?.array
          ?.filter((el) => el.signSymptomId !== "")
          .map((d) => ({
            ...d,
            consultationId: consultationID,
          }));
      const complaintsDetails = {
        chiefComplaintNotes:
          complaintData?.map((el) => el?.symptomName)?.join(", ") +
          ", " +
          data?.candD?.chiefComplaintNotes,
        consultationId: consultationID,
        // currentTreatment: data.candD?.currentTreatment,
        diagnosisId: !!data.candD?.diagnosisId
          ? data.candD?.diagnosisId
          : undefined, //To remove the property we are passing undefiled
        examinationNotes: data.candD?.examinationNotes,
        // id: data.candD?.id,
        // response: data.candD?.response,
        // signSymptomId: data.candD?.signSymptomId,
      };
      const checkboxState = {
        isAppetiteNormal: data.candD?.isAppetiteNormal,
        isBladderNormal: data.candD?.isBladderNormal,
        isBowelNormal: data.candD?.isBowelNormal,
        isSleepNormal: data.candD?.isSleepNormal,
      };
      // dispatch(saveComplaintsDetails({ ...complaintsDetails, checkboxState }));
      //preview.complaintsDtos = complaintData
      // dispatch(saveComplaints(complaintData));
      preview.complaintsDetailsDto = { ...complaintsDetails, checkboxState };
      preview.complaintsDtos = complaintData;
    }
    if (pastData?.length !== 0) {
      const past = {
        ayushId: ayushID,
        remarks: data?.history?.past?.remarks,
        vaccinationHistory: data?.history?.past?.vaccinationHistory,
        surgicalHistory: data?.history?.past?.surgicalHistory,
        historyDtos: pastData,
      };
      preview.pastHistory = { ...past, consultationId: consultationID };
    }
    if (personalData?.length !== 0) {
      const personal = {
        ayushId: ayushID,
        remarks: data?.history?.personal?.remarks,
        vegNonveg: data?.history?.personal?.vegNonveg,
        historyDtos: personalData,
      };
      preview.personalHistory = { ...personal, consultationId: consultationID };
    }

    if (somId === 3 && treatmentData?.length !== 0 && treatmentData !== null) {
      preview.ninTreatmentPlanDto = {
        ...treatmentData,
        consultationId: consultationID,
      };
    }
    if (somId === 3 && physicalData?.length !== 0 && physicalData !== null) {
      preview.genPhysicalExaminationDto = {
        ...physicalData,
        consultationId: consultationID,
      };
    }

    if (somId === 3 && systemicData?.length !== 0 && systemicData !== null) {
      preview.systemicExaminationDto = {
        ...systemicData,
        consultationId: consultationID,
      };
    }

    if (familyData !== null) {
      const family = {
        ayushId: ayushID,
        remarks: data?.history?.family?.remarks,
        consanguineousMarriage: data?.history?.family?.consanguineousMarriage,
        historyDtos: familyData,
      };
      preview.familyHistory = { ...family, consultationId: consultationID };
    }
    if (menstrualHistory) {
      const data = {
        ...menstrualHistory,
        consultationId: consultationID,
      };
      preview.menstHistory = { ...data, consultationId: consultationID };
    }
    if (
      data?.history?.obstetric &&
      (data?.history?.obstetric?.gynaecHistory ||
        data?.history?.obstetric?.gynaecExamination ||
        data?.history?.obstetric?.obsHistory)
    ) {
      const obstetric = {
        ...data?.history?.obstetric,
      };
      preview.obGynHistoryDto = {
        ...obstetric,
        consultationId: consultationID,
      };
    }
    if (
      candPData &&
      (candPData?.alignment1 || candPData?.alignment2 || candPData?.alignment3)
    ) {
      const compliance = candPData;
      const drugIntake = {
        consultationId: consultationID,
        complianceTypeId: 1,
        condition:
          compliance && compliance.alignment1 == "Regular" ? true : false,
      };
      const hospitalVisit = {
        consultationId: consultationID,
        complianceTypeId: 2,
        condition:
          compliance && compliance.alignment2 == "Regular" ? true : false,
      };
      const advice = {
        consultationId: consultationID,
        complianceTypeId: 3,
        condition:
          compliance && compliance.alignment3 == "Regular" ? true : false,
      };
      let complianceObj = [drugIntake, hospitalVisit, advice];
      preview.complianceDtos = complianceObj;
      if (
        candPData &&
        candPData.progObject &&
        candPData.progObject.length !== 0
      ) {
        const progObject = candPData && candPData.progObject;
        preview.prognosisDtos = progObject;
      }
    }
    if (data?.labData !== null || data?.labData !== {}) {
      const labDto = {
        consultationId: consultationID,
        labPathologyTests: labDetails?.selected?.lab?.flatMap((v) => v?.lab),
        labGroups: labDetails?.selected?.lab?.flatMap((v) => v?.group),
        // labRadiologyTests: labDetails?.selected?.radio?.flat(),
        labRadiologyTests: getLabRadiologyTests(
          labDetails?.selected?.radio,
          labDetails?.lab?.radio
        ),
      };
      if (
        labDetails?.selected?.lab?.flatMap((v) => v?.lab)?.length > 0 ||
        labDetails?.selected?.lab?.flatMap((v) => v?.group)?.length > 0 ||
        labDetails?.selected?.radio?.flat()?.length > 0
      ) {
        consultationStatus = "L";
        preview.patientLabTestDto = labDto;
        // dispatch(savePatientLabTestAndLabGroups(labDto));
      }
    }
    if (allopath && allopath?.length !== 0) {
      preview.allopathyMedDtos = allopath;
      // dispatch(saveAllopathyDetails(allopath));
    }
    if (concomData && concomData?.length !== 0) {
      // dispatch(saveConcomintantDetails(concomData));
      preview.concomitantMedDtos = concomData;
    }
    if (diagnosisData !== null) {
      const nmscList =
        diagnosisData &&
        diagnosisData.selectednsmc &&
        diagnosisData.selectednsmc.map((d) => d.id);
      const icdList =
        diagnosisData &&
        diagnosisData.selectedIcd &&
        diagnosisData.selectedIcd.map((d) => d.id);
      const data = {
        consultationId: consultationID,
        diagnosisIds: nmscList,
        diagnosisICDIds: icdList,
        followUpVisitDay: diagnosisData?.followUpVisitDay,
      };
      preview.diagnosisDto = data;
      if (somId === 5) {
        const aIcdList =
          diagnosisData &&
          diagnosisData.additionalIcd &&
          diagnosisData.additionalIcd.map((d) => d.id);
        const finalData = {
          diagnosisIds: [],
          diagnosisICDIds: [diagnosisData?.finalIcd?.id],
          diagnosisTypeDet: [
            {
              diagnosisType: "A",
              diseaseIds: [],
              diseaseICDIds: aIcdList?.length > 0 ? aIcdList : [],
            },
          ],
          consultationId: consultationID,
          isFinal: true,
        };
        preview.finalDiagnosis = finalData;
      } else {
        const aNsmcList =
          diagnosisData &&
          diagnosisData.additionalNsmc &&
          diagnosisData.additionalNsmc.map((d) => d.id);
        const aIcdList =
          diagnosisData &&
          diagnosisData.additionalIcd &&
          diagnosisData.additionalIcd.map((d) => d.id);
        const finalData = {
          diagnosisIds: diagnosisData?.finalNsmc
            ? [diagnosisData?.finalNsmc?.id]
            : [],
          diagnosisICDIds: diagnosisData?.finalIcd
            ? [diagnosisData?.finalIcd?.id]
            : [],
          diagnosisTypeDet: [
            {
              diagnosisType: "A",
              diseaseIds: aNsmcList?.length > 0 ? aNsmcList : [],
              diseaseICDIds: aIcdList?.length > 0 ? aIcdList : [],
            },
          ],
          consultationId: consultationID,
          isFinal: true,
        };
        preview.finalDiagnosis = finalData;
      }
    }
    if (somId !== 5) {
      let simpleData, externalData, paidData, complexDetails;
      if (simple?.length > 0) {
        simpleData = simple?.map((d) => ({
          ...d,
          medicineVehicle: !!d?.medicineVehicle ? d?.medicineVehicle : -1,
          consultationId: consultationID,
          prescriptionType: "S",
        }));
        // dispatch(saveMedicines(simpleData));
      }
      if (external?.length > 0) {
        externalData = external?.map((d) => ({
          ...d,
          consultationId: consultationID,
          totalQuantity: +d.totalQuantity,
          prescriptionType: "E",
        }));
        // dispatch(saveMedicines(externalData));
      }
      if (paid?.length > 0) {
        paidData = paid?.map((d) => ({
          ...d,
          medicineVehicle: !!d?.medicineVehicle ? d?.medicineVehicle : -1,
          consultationId: consultationID,
          prescriptionType: "P",
        }));
        // dispatch(saveMedicines(paidData));
      }
      if (complex?.length > 0) {
        complexDetails = data?.prescription?.value?.complex?.map((d) => ({
          ...d,
          medicineVehicle: !!d?.medicineVehicle ? d?.medicineVehicle : -1,
          consultationId: consultationID,
          prescriptionType: "C",
        }));
        // dispatch(saveComplexMedicines(complexDetails)).then((res) => {
        //   console.log(res);
        // });
      }
      preview.prescriptionList = {
        singleMedicine: simpleData,
        externalMedicine: externalData,
        complexMedicine: complexDetails,
        paidMedicine: paidData,
      };
    } else {
      if (
        (data?.prescription &&
          data?.prescription?.value?.homeo?.length !== 0) ||
        data?.prescription?.value?.homeoMed?.length !== 0
      ) {
        const singleMed = simple?.map((d) => ({
          ...d,
          medicineVehicle: !!d?.medicineVehicle ? d?.medicineVehicle : -1,
          consultationId: consultationID,
          prescriptionType: "S",
        }));
        // const singleData = singleMed?.[0];
        const multiMed = homeo?.map((d) => ({
          ...d,
          medicineVehicle: !!d?.medicineVehicle ? d?.medicineVehicle : -1,
          consultationId: consultationID,
          prescriptionType: "H", //for HOMEO - Single
          potencyTypeId:
            d.decimal !== "" && d.decimal !== 0
              ? d.decimal
              : d.centesimal !== "" && d.centesimal !== 0
              ? d.centesimal
              : d.millesimal !== "" && d.millesimal !== 0
              ? d.millesimal
              : null,
        }));
        const externalMed = data?.prescription?.value?.external?.map((d) => ({
          ...d,
          consultationId: consultationID,
          totalQuantity: +d.totalQuantity,
          prescriptionType: "E",
        }));
        const paidMed = data?.prescription?.value?.paid?.map((d) => ({
          ...d,
          medicineVehicle: !!d?.medicineVehicle ? d?.medicineVehicle : -1,
          consultationId: consultationID,
          prescriptionType: "P",
        }));
        preview.prescriptionList = {
          singleMedicine: singleMed,
          multipleMedicine: multiMed,
          externalMedicine: externalMed,
          paidMedicine: paidMed,
        };
        // preview.homoeoPrescriptionList = {
        //   consultationId: consultationID,
        //   prescriptionList: {
        //     singleMedicine: singleData,
        //     multipleMedicine: multiMed,
        //     externalMedicine: externalMed,
        //     paidMedicine: paidMed,
        //   },
        // };
        // preview.homoeo = true;
        // dispatch(
        //   saveHomeopathy({
        //     singleMedicine: singleData,
        //     multipleMedicine: multiMed,
        //     externalMedicine: externalMed,
        //     paidMedicine: paidMed,
        //   })
        // );
      }
    }
    if (data && data.doDont) {
      const doDont = data && data.doDont;
      const doList = doDont.selectedDos?.map((d) => d?.id);
      const dontList = doDont.selectedDonts?.map((d) => d?.id);
      const doDontData = {
        consultationId: consultationID,
        dos: doList,
        donts: dontList,
      };
      preview.dosDontsDto = doDontData;
      //dispatch(saveAdvicesForPatient(doDontData));
    }
    if (openTransfer || data?.prescription?.followUpVisitDay) {
      const followupData = {
        id: consultationID,
        followUpDay: data?.prescription?.followUpVisitDay,
        prescriptionRemarks: data?.prescription?.prescriptionRemarks,
        dosRemarks: dosRemarks,
        dontsRemarks: dontsRemarks,
      };
      preview.consultation = followupData;
    }
    if (data?.surgicalProceduresDtos) {
      preview.surgicalProceduresDtos = data?.surgicalProceduresDtos;
    }

    preview.consultation.id = consultationID;
    preview.consultation.consultationStatus = consultationStatus;

    return preview;
  };

  //====== handle Preview Save ==========
  const savePreview = async () => {
    const preview = previewObject();
    console.log("ppp", preview);
    dispatch(savePreviewDetail(preview))
      .then((res) => {
        setSavingConsultation(false);
        dispatch(updateConsultation(consultationID, StaffLogin?.staffId));
        const linkContextNotificationDto = {
          patient: {
            id: selectedPatient?.uhid,
          },
          careContext: {
            patientReference: selectedPatient?.uhid,
            careContextReference: registrationID,
          },
          hiTypes: ["OPConsultation", "Prescription"],
          date: new Date().toISOString(),
          hip: {
            id: "MOA_001",
          },
        };
        dispatch(
          careContextNotify(
            linkContextNotificationDto,
            selectedPatient?.phone,
            clientId
          )
        );
        if (res.toLowerCase().includes("success")) {
          setOpenSaveDialog(false);
          dispatch(sendMsgToPharmacist(StaffLogin?.orgId));
          dispatch({
            type: REMOVE_REGISTRATION_ID,
            payload: registrationPatient.id,
          });
          history.push("/Dashboard");
        }
      })
      .catch((e) => {
        setSavingConsultation(false);
      });
  };
  //=======Saving -Ends======

  //========Saving OPD Transfer================
  const handleOPDTransfer = () => {
    const transferReqBody = {
      ...transferData,
      consultationPreview: { ...previewObject() },
    };
    console.log("OPD transferReqBody", transferReqBody);
    dispatch(saveOPDTransferDetails(transferReqBody))
      .then((res) => {
        let responseObj = {
          message: "",
          messageInfoType: "",
        };
        if (res === "failure") {
          responseObj.message = "Request failed. Plz try again.";
          responseObj.messageInfoType = "error";
          dispatch(showSnackbar(responseObj));
        } else {
          responseObj.message = "Patient transferred successfully.";
          responseObj.messageInfoType = "success";
          dispatch(showSnackbar(responseObj));
          history.push("/Dashboard");
          handleClose();
        }
        setSom(StaffLogin.somId);
      })
      .catch((error) => console.log("saveTransferDetails error", error));
  };

  //========Saving IPD Transfer================
  const handleIPDTransfer = () => {
    if (ipAdmissiontransferData.departmentId){
      const transferReqBody = {
        ...ipAdmissiontransferData,
        staffId: StaffLogin?.staffId,
        organizationId: StaffLogin.orgId,
        consultationId: consultationID,
        consultationPreview: { ...previewObject() },
      };
      console.log("IPD transferReqBody", transferReqBody);
      dispatch(saveTranferIPAdmissionDetailsNew(transferReqBody)).then((res) => {
        let responseObj = {
          message: "",
          messageInfoType: "",
        };
        if (res === "failure") {
          responseObj.message = "Request failed. Plz try again.";
          responseObj.messageInfoType = "error";
          dispatch(showSnackbar(responseObj));
        } else {
          responseObj.message = "Patient transferred successfully.";
          responseObj.messageInfoType = "success";
          dispatch(showSnackbar(responseObj));
          history.push("/Dashboard");
          handleClose();
        }
        setSom(StaffLogin.somId);
      });
    }
    else {
      dispatch(showSnackbar({message:'Please Select Department',messageInfoType:'error'}));
    }
  };

  React.useEffect(() => {
    if (data && data.pulse) {
      const dataPulse = { ...props.data.pulse };
      const pulse = pulseDetails.filter((d) => d.id == dataPulse.pulseTypeId);
      setPulseData(pulse);
    }
    if (data && data.pulseUnani && data.pulseUnani.pulseData) {
      const unaniPulse = { ...props.data.pulseUnani.pulseData };
      const nabz = unaniPulse && unaniPulse?.selectedNabz;
      const list = nabz.map((d) => ({
        ...d,
        consultationId: consultationID,
      }));
      const pulseUnaniData = {
        ...unaniPulse,
        consultationId: consultationID,
        selectedNabz: list,
      };
      const selectedNabz =
        unaniPulse && unaniPulse?.nabzDtos?.some((e) => e.pulseTypeId !== "");
      if (selectedNabz || unaniPulse?.nabzMurakkabNames || unaniPulse?.mizaz) {
        setPulseUnani(pulseUnaniData);
      }
    }
    if (data && data.candD) {
      const dataComplaints = { ...props.data.candD };
      setCdData(dataComplaints);
    }
    if (data && data.prescription && data.prescription.value) {
      const data = { ...props.data.prescription.value };
      console.log("prescription data:", data);
      const complexData = data && data.complex;
      if (complexData?.length !== 0) {
        const list = [].concat.apply([], complexData);
        setComplexData(list);
        const complexRows = [];
        const rows = list?.forEach((s) => {
          complexRows.push(s.rows);
        });
        const complexDetails = [].concat.apply([], complexRows);
        setComplex(complexDetails);
      }
      if (data?.external?.length !== 0) {
        setExternal(data?.external);
      }
      if (data?.paid?.length !== 0) {
        setPaid(data?.paid);
      }
      if (data?.homeoMed) {
        setHomeo(data?.homeoMed);
      }
      if (somId === 5) {
        if (data?.homeo) {
          setSimple(data?.homeo);
        }
      } else {
        if (data?.simple) {
          setSimple(data?.simple);
        }
      }
    }
    if (
      data &&
      data.concomitantMedicines &&
      data.concomitantMedicines.medicineData
    ) {
      const data = { ...props.data.concomitantMedicines };
      const medNames = data && data.medicineNames;
      setConcomitantMed(medNames);
      const conData =
        data && data.medicineData && data.medicineData.concomitant;
      const allopathy = data && data.medicineData && data.medicineData.medicine;
      setAllopath(allopathy);
      setConcomData(conData);
    }
    if (data && data.diagnosis) {
      const diagnosis = { ...props.data.diagnosis };
      setDiagnosisData(diagnosis);
    }
    if (data && data.labData) {
      const lab = { ...props.data.labData };
      setLabDetails(lab);
    }
    if (data && data.compliancePrognosisValue) {
      const candP = { ...props.data.compliancePrognosisValue };
      setCandPData(candP);
    }
    if (data && data.doDont) {
      const doDont = { ...props.data.doDont };
      console.log("ddd", doDont);
      setDos(doDont?.selectedDos);
      setDonts(doDont?.selectedDonts);
      setDosRemarks(doDont?.dosRemarks);
      setDontsRemarks(doDont?.dontsRemarks);
    }

    if (data && data.treatment) {
      const treatmentVal = { ...props.data.treatment };

      setTreatmentData(treatmentVal);
    }
    if (data && data.physical) {
      const physicalValue = { ...props.data.physical };
      setPhysicalData(physicalValue);
    }
    if (data && data.systemic) {
      const systemicValue = { ...props.data.systemic };
      setSystemicData(systemicValue);
    }
    if (data && data.history) {
      const history = { ...props.data.history };

      const pastDetail = history && history.past;
      const pastRows = [];
      pastDetail?.rows?.map((e) =>
        e.historyValue == 1 || e.historyValue == 0 ? pastRows.push(e) : null
      ); // Changed Because past Services are failing. (2 coming from the Service Side)
      setPastData(pastRows);

      const familyDetail = history && history.family;
      const familyRows = [];
      familyDetail?.rows?.map((e) =>
        (e.fatherValue && e.motherValue) !== "" ? familyRows.push(e) : null
      );
      setFamilyData(familyRows);

      const personal = history && history.personal;
      const personalRows = [];
      personal?.rows?.map((e) =>
        e.historyValue !== "" ? personalRows.push(e) : null
      );
      setPersonalData(personalRows);

      const menstrual = history && history.menstrual;
      if (menstrual) {
        const durationVal = menstrual?.durationVal2
          ? "-" + menstrual?.durationVal2
          : "";
        const intervalVal = menstrual?.intervalVal2
          ? "-" + menstrual?.intervalVal2
          : "";
        const data = {
          ...menstrual,
          duration: `${menstrual?.durationVal1}${durationVal?.trimEnd()}`,
          interval: `${menstrual?.intervalVal1}${intervalVal?.trimEnd()}`,
        };
        setMenstrualHistory(data);
      }
    }
  }, [props]);

  const handleCloseDialog = () => {
    setOpenSaveDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenSaveDialog(true);
  };

  const handleTextSizeIncrease = () => {
    setTextSizeFactor((previousTextSizeFactor) => {
      if (previousTextSizeFactor <= 2) {
        return previousTextSizeFactor + 1;
      } else {
        return previousTextSizeFactor;
      }
    });
  };
  const handleTextSizeDecrease = () => {
    setTextSizeFactor((previousTextSizeFactor) => {
      if (previousTextSizeFactor >= -1) {
        return previousTextSizeFactor - 1;
      } else {
        return previousTextSizeFactor;
      }
    });
  };

  const handleRowCount = (i) => {
    return (serialNo = serialNo + 1);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popper" : undefined;

  return (
    <>
      {/* Main preview Dialog */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        PaperComponent={PaperComponent}
        open={open || openTransfer}
        maxWidth="lg"
        fullWidth
        disableBackdropClick
      >
        <Grid container spacing={0}>
          <Grid item xs={11} align="left">
            <DialogTitle
              style={{ cursor: "move" }}
              className={classes.padding}
              id="draggable-dialog-title"
            >
              <Typography
                style={{ color: myOwnColor }}
                className={classes.cardHeading}
              >
                Preview
                <span style={{ marginLeft: 40 }}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleTextSizeIncrease}
                  >
                    A+
                  </Button>
                  <Button size="small" onClick={() => setTextSizeFactor(0)}>
                    A
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleTextSizeDecrease}
                  >
                    A-
                  </Button>
                </span>
              </Typography>
            </DialogTitle>
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton
              onClick={handleClose}
              edge="start"
              color="primary"
              arialabel="menu"
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>

        <div
          id="previewPrint"
          className="print-sources"
          ref={(el) => (componentRef = el)}
        >
          <DialogContent dividers>
            <div className={classes.root}>
              <div>
                <Grid
                  container
                  spacing={0}
                  className="pageHeader"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid sm={1}>
                    <img
                      className={classes.ahmisLogo}
                      style={{ height: 60, width: 60 }}
                      src={ahmis}
                      alt="ahmis"
                    />
                  </Grid>
                  <Grid sm={8}>
                    <Typography align="center" className={classes.org}>
                      {StaffLogin?.orgName}
                    </Typography>
                  </Grid>
                  <Grid sm={1}>
                    <img
                      className={classes.ayushLogo}
                      src={leaf}
                      style={{ height: 60, width: 60 }}
                      alt="leaf"
                    />
                  </Grid>
                </Grid>

                <div>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <PatientDetails />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid container spacing={1}>
                {somId === 2 ? (
                  <Grid
                    items
                    xs={12}
                    sm={12}
                    align="center"
                    className={classes.margin}
                  >
                    {pulseUnani ? (
                      <Card className={classes.previewCard}>
                        <Grid container>
                          <Grid item xs={11} align="left">
                            <Typography
                              style={{ color: myOwnColor }}
                              className={classes.cardHeading}
                            >
                              Pulse Unani
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ textAlign: "right" }}
                            className="print-source"
                          >
                            <IconButton
                              aria-label="Edit"
                              component="a"
                              size="small"
                              onClick={() => onEdit(0)}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: myOwnColor }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <CardContent className={classes.content}>
                          <Grid container spacing={1}>
                            {pulseUnani &&
                            pulseUnani?.nabzDtos?.some(
                              (e) => e.pulseTypeId !== ""
                            ) ? (
                              <Grid item xs={12}>
                                <Typography className={classes.text}>
                                  <b>Nabz : </b>
                                  {pulseUnani &&
                                    pulseUnani.nabzDtos &&
                                    pulseUnani.nabzDtos
                                      ?.map((d) => d.nabz)
                                      ?.join(", ")}
                                </Typography>
                              </Grid>
                            ) : null}
                            {pulseUnani && pulseUnani?.nabzMurakkabNames ? (
                              <Grid item xs={12}>
                                <Typography className={classes.text}>
                                  <b>Nabz MurakkabIds : </b>
                                  {pulseUnani && pulseUnani?.nabzMurakkabNames}
                                </Typography>
                              </Grid>
                            ) : null}
                            {pulseUnani && pulseUnani?.mizaz ? (
                              <Grid item xs={12}>
                                <Typography className={classes.text}>
                                  <b>Mizaj : </b>
                                  {pulseUnani && pulseUnani?.mizaz}
                                </Typography>
                              </Grid>
                            ) : null}
                            {pulseUnani && pulseUnani?.remarks ? (
                              <Grid item xs={12}>
                                <Typography className={classes.text}>
                                  <b>Remarks : </b>
                                  {pulseUnani && pulseUnani?.remarks}
                                </Typography>
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : null}
                  </Grid>
                ) : (
                  <Grid
                    items
                    xs={12}
                    sm={12}
                    align="center"
                    className={classes.margin}
                  >
                    {pulseData && pulseData.length !== 0 ? (
                      <Card className={classes.previewCard}>
                        <Grid container>
                          <Grid item xs={11} align="left">
                            <Typography
                              style={{ color: myOwnColor }}
                              className={classes.cardHeading}
                            >
                              Pulse
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ textAlign: "right" }}
                            className="print-source"
                          >
                            <IconButton
                              aria-label="Edit"
                              component="a"
                              size="small"
                              onClick={() => onEdit(0)}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: myOwnColor }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <CardContent className={classes.content}>
                          <Grid container spacing={1}>
                            <Grid item xs={5}>
                              {pulseData &&
                                pulseData.map((d) => (
                                  <Chip
                                    className={classes.chip}
                                    label={d.pulseCode}
                                  ></Chip>
                                ))}
                            </Grid>
                            {data && data.pulse && data.pulse.remarks ? (
                              <Grid item xs={7}>
                                <Typography className={classes.text}>
                                  <b>Remarks : </b>
                                  {data && data.pulse && data.pulse.remarks}
                                </Typography>
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : null}
                  </Grid>
                )}

                <Grid
                  items
                  xs={12}
                  sm={12}
                  align="center"
                  className={classes.margin}
                >
                  {cdData && cdData.array && cdData.array.length ? (
                    <Card className={classes.previewCard}>
                      <Grid container>
                        <Grid item xs={11} align="left">
                          <Typography
                            style={{ color: myOwnColor }}
                            className={classes.cardHeading}
                          >
                            Complaints & Duration
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ textAlign: "right" }}
                          className="print-source"
                        >
                          <IconButton
                            aria-label="Edit"
                            component="span"
                            size="small"
                            onClick={() => onEdit(1)}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: myOwnColor }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <CardContent className={classes.content}>
                        <Grid container spacing={1}>
                          {cdData &&
                          cdData.array &&
                          cdData.array.filter((el) => el.signSymptomId !== "")
                            .length !== 0 ? (
                            <Grid item xs={12} className={classes.tableGrid}>
                              <TableContainer>
                                <Table
                                  aria-label="simple table"
                                  size="small"
                                  className={classes.table}
                                >
                                  <TableHead>
                                    <StyledTableRow
                                      align="center"
                                      style={{ backgroundColor: myOwnColor }}
                                    >
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        S.No
                                      </StyledTableCell>
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        Present Complaints
                                      </StyledTableCell>
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        Duration
                                      </StyledTableCell>
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        Units
                                      </StyledTableCell>
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        Remarks
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {cdData &&
                                      cdData?.array?.map((row, id) => (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {id + 1}.
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.symptomName}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.duration}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.durationTypeId == "1"
                                              ? "Days"
                                              : row.durationTypeId == "2"
                                              ? "Weeks"
                                              : row.durationTypeId == "3"
                                              ? "Months"
                                              : null}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.remarks}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          ) : null}
                          {cdData && cdData.diagnosisId ? (
                            <Grid item xs={12}>
                              <Typography className={classes.text2}>
                                <b>Provisional Diagnosis : </b>
                                {diagnosisList &&
                                  diagnosisList
                                    ?.filter((d) => d.id == cdData.diagnosisId)
                                    ?.map((e) => e.diseaseNameWithCode)}
                              </Typography>
                            </Grid>
                          ) : null}
                          {cdData && cdData?.chiefComplaintNotes ? (
                            <Grid item xs={12}>
                              <Typography className={classes.text2}>
                                <b>Present Complaint Notes : </b>
                                {cdData && cdData?.chiefComplaintNotes}
                              </Typography>
                            </Grid>
                          ) : null}
                          {cdData && cdData?.examinationNotes ? (
                            <Grid item xs={12}>
                              <Typography className={classes.text2}>
                                <b>Examination Notes : </b>
                                {cdData && cdData?.examinationNotes}
                              </Typography>
                            </Grid>
                          ) : null}
                          {cdData && cdData?.isBladderNormal && (
                            <Grid item xs={3}>
                              <Typography className={classes.text2}>
                                <b>Bladder Habits Normal : </b>
                                {cdData && cdData?.isBladderNormal ? (
                                  <CheckCircleComponent />
                                ) : (
                                  <BlockCircleComponent />
                                )}
                              </Typography>
                            </Grid>
                          )}
                          {cdData && cdData?.isBowelNormal && (
                            <Grid item xs={3}>
                              <Typography className={classes.text2}>
                                <b>Bowel Habits Normal : </b>
                                {cdData && cdData?.isBowelNormal ? (
                                  <CheckCircleComponent />
                                ) : (
                                  <BlockCircleComponent />
                                )}
                              </Typography>
                            </Grid>
                          )}
                          {cdData && cdData?.isAppetiteNormal && (
                            <Grid item xs={3}>
                              <Typography className={classes.text2}>
                                <b>Appetite Normal : </b>
                                {cdData && cdData?.isAppetiteNormal ? (
                                  <CheckCircleComponent />
                                ) : (
                                  <BlockCircleComponent />
                                )}
                              </Typography>
                            </Grid>
                          )}
                          {cdData && cdData?.isSleepNormal && (
                            <Grid item xs={3}>
                              <Typography className={classes.text2}>
                                <b>Sleep Normal : </b>
                                {cdData && cdData?.isSleepNormal ? (
                                  <CheckCircleComponent />
                                ) : (
                                  <BlockCircleComponent />
                                )}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>

                {somId === 3 ? (
                  <Grid
                    items
                    xs={12}
                    sm={12}
                    align="center"
                    className={classes.margin}
                  >
                    {treatmentData?.length !== 0 && treatmentData !== null ? (
                      <Card className={classes.previewCard}>
                        <Grid container>
                          <Grid item xs={11} align="left">
                            <Typography
                              style={{ color: myOwnColor }}
                              className={classes.cardHeading}
                            >
                              Treatment
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ textAlign: "right" }}
                            className="print-source"
                          >
                            <IconButton
                              aria-label="Edit"
                              component="span"
                              size="small"
                              onClick={() => onEdit(2)}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: myOwnColor }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <CardContent className={classes.content}>
                          {treatmentData?.length !== 0 &&
                          treatmentData !== null ? (
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                Treatment Type: {treatmentData?.treatmentType}
                              </Grid>
                              <Grid item xs={12}>
                                Treatment Name: {treatmentData?.treatmentName}
                              </Grid>
                              <Grid item xs={12}>
                                Treatment Nature:{" "}
                                {treatmentData?.treatmentNature}
                              </Grid>
                              <Grid item xs={12}>
                                Application To: {treatmentData?.applicationTo}
                              </Grid>
                            </Grid>
                          ) : null}
                        </CardContent>
                      </Card>
                    ) : null}
                  </Grid>
                ) : null}

                {somId === 3 ? (
                  <Grid
                    items
                    xs={12}
                    sm={12}
                    align="center"
                    className={classes.margin}
                  >
                    {physicalData?.length !== 0 && physicalData !== null ? (
                      <Card className={classes.previewCard}>
                        <Grid container>
                          <Grid item xs={11} align="left">
                            <Typography
                              style={{ color: myOwnColor }}
                              className={classes.cardHeading}
                            >
                              General Physical Examinations
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ textAlign: "right" }}
                            className="print-source"
                          >
                            <IconButton
                              aria-label="Edit"
                              component="span"
                              size="small"
                              onClick={() => onEdit(3)}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: myOwnColor }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <CardContent className={classes.content}>
                          {physicalData?.length !== 0 &&
                          physicalData !== null ? (
                            <Grid container spacing={1}>
                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  Built: {physicalData?.build}
                                </Grid>
                                <Grid item xs={4}>
                                  Pallor: {physicalData?.pallor}
                                </Grid>
                                <Grid item xs={4}>
                                  Icterus: {physicalData?.icterus}
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  Cyanosis: {physicalData?.cyanosis}
                                </Grid>

                                <Grid item xs={4}>
                                  Clubbing: {physicalData?.clubbing}
                                </Grid>
                                <Grid item xs={4}>
                                  Lymphadenopathy:{" "}
                                  {physicalData?.lymphadenopathy}
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  Oedema: {physicalData?.oedema}
                                </Grid>
                                <Grid item xs={4}>
                                  Skin(appearance): {physicalData?.skin}
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                        </CardContent>
                      </Card>
                    ) : null}
                  </Grid>
                ) : null}

                {somId === 3 ? (
                  <Grid
                    items
                    xs={12}
                    sm={12}
                    align="center"
                    className={classes.margin}
                  >
                    {systemicData?.length !== 0 && systemicData !== null ? (
                      <Card className={classes.previewCard}>
                        <Grid container>
                          <Grid item xs={11} align="left">
                            <Typography
                              style={{ color: myOwnColor }}
                              className={classes.cardHeading}
                            >
                              Systemic Examinations
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ textAlign: "right" }}
                            className="print-source"
                          >
                            <IconButton
                              aria-label="Edit"
                              component="span"
                              size="small"
                              onClick={() => onEdit(4)}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: myOwnColor }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <CardContent className={classes.content}>
                          {systemicData?.length !== 0 &&
                          systemicData !== null ? (
                            <Grid container spacing={1}>
                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  Cardio Vascular System:{" "}
                                  {systemicData?.cardioVascularSystem}
                                </Grid>
                                <Grid item xs={4}>
                                  Respiratory System:{" "}
                                  {systemicData?.respiratorySystem}
                                </Grid>
                                <Grid item xs={4}>
                                  Gastro-intestinal System:{" "}
                                  {systemicData?.gastroIntestinalSytem}
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  Central Nervous System:{" "}
                                  {systemicData?.centralNervousSystem}
                                </Grid>

                                <Grid item xs={4}>
                                  Motor System: {systemicData?.motorSystem}
                                </Grid>
                                <Grid item xs={4}>
                                  Facial Diagnosis / Encumbrance:{" "}
                                  {systemicData?.facialDiagEncumbrance}
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  Tongue Diagnosis:{" "}
                                  {systemicData?.tongueDiagnosis}
                                </Grid>
                                <Grid item xs={4}>
                                  Left Iris Diagnosis:{" "}
                                  {systemicData?.irisDiagLeft}
                                </Grid>
                                <Grid item xs={4}>
                                  Right Iris Diagnosis:{" "}
                                  {systemicData?.irisDiagRight}
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                        </CardContent>
                      </Card>
                    ) : null}
                  </Grid>
                ) : null}

                <Grid
                  items
                  xs={12}
                  sm={12}
                  align="center"
                  className={classes.margin}
                >
                  {pastData?.length > 0 ||
                  personalData?.length !== 0 ||
                  familyData?.length !== 0 ||
                  data?.history?.menstrual ||
                  data?.history?.obstetric ? (
                    <Card className={classes.previewCard}>
                      <Grid container>
                        <Grid item xs={11} align="left">
                          <Typography
                            style={{ color: myOwnColor }}
                            className={classes.cardHeading}
                          >
                            Clinical History
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ textAlign: "right" }}
                          className="print-source"
                        >
                          <IconButton
                            aria-label="Edit"
                            component="span"
                            size="small"
                            onClick={() =>
                              somId === 3 ? onEdit(5) : onEdit(2)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: myOwnColor }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <CardContent className={classes.content}>
                        <Grid container>
                          {pastData && pastData?.length !== 0 ? (
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography className={classes.header1}>
                                  Past History
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{ backgroundColor: myOwnColor }}
                                      >
                                        {[
                                          "Disease",
                                          "Status",
                                          "Month",
                                          "Year",
                                          "Remarks",
                                        ].map((header) => (
                                          <StyledTableCell
                                            align="center"
                                            className={classes.text1}
                                          >
                                            {header}
                                          </StyledTableCell>
                                        ))}
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {pastData?.map((row, id) => (
                                        <TableRow key={row.id}>
                                          {row.historyValue === 1 ||
                                          row.historyValue === 0 ? (
                                            <>
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {row.diseaseTypeName}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {row.historyValue === 1
                                                  ? "Yes"
                                                  : "No"}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {row.historyValue === 1 ? (
                                                  <>
                                                    {
                                                      months?.find(
                                                        (s) =>
                                                          s.id === row.month
                                                      )?.name
                                                    }
                                                  </>
                                                ) : (
                                                  "-"
                                                )}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {row.historyValue === 1
                                                  ? row.year
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {row.remarks}
                                              </TableCell>
                                            </>
                                          ) : null}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <Grid container>
                                    {data?.history?.past?.remarks && (
                                      <Grid item xs={4}>
                                        <Typography className={classes.text2}>
                                          <span style={{ fontWeight: 500 }}>
                                            Remarks:&nbsp;
                                          </span>
                                          {data?.history?.past?.remarks}
                                        </Typography>
                                      </Grid>
                                    )}
                                    {!!data?.history?.past?.surgicalHistory && (
                                      <Grid item xs={4}>
                                        <Typography className={classes.text2}>
                                          <span style={{ fontWeight: 500 }}>
                                            {" "}
                                            Surgical History:&nbsp;
                                          </span>

                                          {data.history.past.surgicalHistory}
                                        </Typography>
                                      </Grid>
                                    )}
                                    {!!data?.history?.past
                                      ?.vaccinationHistory && (
                                      <Grid item xs={4}>
                                        <Typography className={classes.text2}>
                                          <span style={{ fontWeight: 500 }}>
                                            Vaccination History:&nbsp;
                                          </span>
                                          {data.history.past.vaccinationHistory}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          ) : null}
                          {/* =================================Personal History==================================== */}
                          {personalData && personalData?.length !== 0 ? (
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography className={classes.header1}>
                                  Personal History
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{ backgroundColor: myOwnColor }}
                                      >
                                        <StyledTableCell
                                          rowSpan={2}
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Habits
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Month
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Year
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Consumption
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {personalData?.map((row, id) => (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.diseaseTypeName}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.historyValue === 1 ? (
                                              <>
                                                {
                                                  months?.find(
                                                    (s) => s.id === row.month
                                                  )?.name
                                                }
                                              </>
                                            ) : (
                                              "-"
                                            )}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.historyValue === 1
                                              ? row.year
                                              : "-"}
                                          </TableCell>
                                          {row.diseaseTypeId === 2 ? (
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.historyValue === 1
                                                ? `${row.consumption} ml/ ${
                                                    row.durationTypeId === 1
                                                      ? "Days"
                                                      : row.durationTypeId === 2
                                                      ? "Weeks"
                                                      : row.durationTypeId === 3
                                                      ? "Months"
                                                      : null
                                                  }`
                                                : "-"}
                                            </TableCell>
                                          ) : (
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.historyValue === 1
                                                ? `${row.consumption} ${
                                                    row.durationTypeId === 1
                                                      ? "Days"
                                                      : row.durationTypeId === 2
                                                      ? "Weeks"
                                                      : row.durationTypeId === 3
                                                      ? "Months"
                                                      : null
                                                  }`
                                                : "-"}
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <Grid container>
                                    {data?.history?.personal?.vegNonveg ? (
                                      <Grid item xs={5}>
                                        <Typography className={classes.text2}>
                                          <b>Vegetarian : </b>
                                          {data?.history?.personal?.vegNonveg}
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                    {data?.history?.personal?.remarks ? (
                                      <Grid item xs={7}>
                                        <Typography className={classes.text2}>
                                          <b>Remarks : </b>
                                          {data?.history?.personal?.remarks}
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                  </Grid>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          ) : null}

                          {/* =======================Family History============================= */}
                          {familyData && familyData?.length !== 0 ? (
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography className={classes.header1}>
                                  Family History
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{ backgroundColor: myOwnColor }}
                                      >
                                        <StyledTableCell
                                          rowSpan={2}
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Disease
                                        </StyledTableCell>
                                        <StyledTableCell
                                          rowSpan={2}
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Father
                                        </StyledTableCell>
                                        <StyledTableCell
                                          rowSpan={2}
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Mother
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {familyData?.map((row, id) => (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.diseaseTypeName}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.fatherValue === 1
                                              ? "Yes"
                                              : row.fatherValue === 0
                                              ? "No"
                                              : row.fatherValue === 2
                                              ? "Not Known"
                                              : "-"}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.motherValue === 1
                                              ? "Yes"
                                              : row.motherValue === 0
                                              ? "No"
                                              : row.motherValue === 2
                                              ? "Not Known"
                                              : "-"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <Grid container>
                                    {data?.history?.family
                                      ?.consanguineousMarriage ? (
                                      <Grid item xs={5}>
                                        <Typography className={classes.text2}>
                                          <b>Consanguineous Marriage : </b>
                                          {
                                            data?.history?.family
                                              ?.consanguineousMarriage
                                          }
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                    {data?.history?.family?.remarks ? (
                                      <Grid item xs={7}>
                                        <Typography className={classes.text2}>
                                          <b>Remarks : </b>
                                          {data?.history?.family?.remarks}
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                  </Grid>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          ) : null}
                          {menstrualHistory && menstrualHistory !== null ? (
                            <Grid item xs={12}>
                              <Grid item xs={11} align="left">
                                <Typography className={classes.header1}>
                                  Menstrual History
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{ backgroundColor: myOwnColor }}
                                      >
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          LMP Date
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Regular
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Duration
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Interval
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Clot
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Color
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Menopause Date
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Hysterectomy Date
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          Remarks
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow key={menstrualHistory.id}>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.lmpDate
                                            ? moment(
                                                menstrualHistory?.lmpDate
                                              ).format("DD-MM-YYYY")
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.isRegular}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.duration}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.interval}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.isClot}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.color}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.menopauseDateStr
                                            ? String(
                                                menstrualHistory?.menopauseDateStr
                                              )?.length > 4
                                              ? moment(
                                                  menstrualHistory?.menopauseDateStr
                                                ).format("DD MM YYYY")
                                              : moment(
                                                  menstrualHistory?.menopauseDateStr
                                                ).format("YYYY")
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.hysterectomyDateStr
                                            ? String(
                                                menstrualHistory?.hysterectomyDateStr
                                              )?.length > 4
                                              ? moment(
                                                  menstrualHistory?.hysterectomyDateStr
                                                ).format("DD MM YYYY")
                                              : moment(
                                                  menstrualHistory?.hysterectomyDateStr
                                                ).format("YYYY")
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {menstrualHistory?.remarks}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          ) : null}
                          {data?.history?.obstetric &&
                          (data?.history?.obstetric?.gynaecHistory ||
                            data?.history?.obstetric?.gynaecExamination ||
                            data?.history?.obstetric?.obsHistory) ? (
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography className={classes.header1}>
                                  Obstetric & Gynaecological History
                                </Typography>
                              </Grid>
                              {data?.history?.obstetric?.gynaecHistory && (
                                <Grid item xs={12}>
                                  <Typography className={classes.text2}>
                                    <b>Gynaecological History:&nbsp;</b>
                                    {data?.history?.obstetric?.gynaecHistory}
                                  </Typography>
                                </Grid>
                              )}
                              {!!data?.history?.obstetric
                                ?.gynaecExamination && (
                                <Grid item xs={12}>
                                  <Typography className={classes.text2}>
                                    <b>Gynaecological Examination:&nbsp;</b>
                                    {data.history.obstetric.gynaecExamination}
                                  </Typography>
                                </Grid>
                              )}

                              {!!data?.history?.obstetric?.obsHistory && (
                                <Grid item xs={12}>
                                  <Typography className={classes.text2}>
                                    <b> Obstetric History:&nbsp;</b>

                                    {data.history.obstetric.obsHistory}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          ) : null}
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>
                <Grid
                  items
                  xs={12}
                  sm={12}
                  align="center"
                  className={classes.margin}
                >
                  {data?.compliancePrognosisValue?.modified ? (
                    <Card className={classes.previewCard}>
                      <Grid container>
                        <Grid item xs={11} align="left">
                          <Typography
                            style={{ color: myOwnColor }}
                            className={classes.cardHeading}
                          >
                            Compliance & prognosis
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ textAlign: "right" }}
                          className="print-source"
                        >
                          <IconButton
                            aria-label="Edit"
                            component="span"
                            size="small"
                            onClick={() =>
                              somId === 3 ? onEdit(6) : onEdit(3)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: myOwnColor }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <CardContent className={classes.content}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} className={classes.margin}>
                            <Paper style={{ padding: "2px" }}>
                              <Typography
                                style={{ color: myOwnColor }}
                                className={classes.header1}
                              >
                                Compliance
                              </Typography>
                              {candPData?.alignment1 &&
                              candPData?.alignment1 !== "" ? (
                                <Grid
                                  container
                                  spacing={1}
                                  className={classes.label}
                                >
                                  {/* <Grid item xs={2}></Grid> */}
                                  <Grid item xs={3} align="right">
                                    <Typography className={classes.text2}>
                                      <b> Drug Intake : </b>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7} align="left">
                                    <Typography className={classes.text2}>
                                      {candPData?.alignment1}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                              {candPData?.alignment2 &&
                              candPData?.alignment2 !== "" ? (
                                <Grid container spacing={1}>
                                  {/* <Grid item xs={2}></Grid> */}
                                  <Grid item xs={3} align="right">
                                    <Typography className={classes.text2}>
                                      <b> Regular Visit to Hospital : </b>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7} align="left">
                                    <Typography className={classes.text2}>
                                      {candPData?.alignment2}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                              {candPData?.alignment3 &&
                              candPData?.alignment3 !== "" ? (
                                <Grid container spacing={1}>
                                  <Grid item xs={3} align="right">
                                    <Typography className={classes.text2}>
                                      <b> Advice : </b>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7} align="left">
                                    <Typography className={classes.text2}>
                                      {candPData?.alignment3}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </Paper>
                          </Grid>
                          {candPData?.toggle &&
                          candPData?.toggle?.length !== 0 ? (
                            <Grid item xs={12} className={classes.margin}>
                              <Paper style={{ padding: "2px" }}>
                                <Typography
                                  style={{ color: myOwnColor }}
                                  className={classes.header1}
                                >
                                  Prognosis
                                </Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs={3} align="right">
                                    {candPData?.progObject?.map((v) => (
                                      <Typography className={classes.text2}>
                                        <b>{`${v.complaintName} : `}</b>
                                      </Typography>
                                    ))}
                                  </Grid>
                                  <Grid item xs={7} align="left">
                                    {candPData?.toggle?.map((v) => (
                                      <Typography className={classes.text2}>
                                        {v === 1
                                          ? "Cured"
                                          : v === 2
                                          ? "Improving"
                                          : v === 3
                                          ? "SQA"
                                          : v === 4
                                          ? "Deteriorating"
                                          : null}
                                      </Typography>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          ) : null}
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>
                <Grid
                  items
                  xs={12}
                  sm={12}
                  align="center"
                  className={classes.margin}
                >
                  {labDetails?.selected?.lab?.flatMap((v) => v?.group)?.length >
                    0 ||
                  labDetails?.selected?.lab?.flatMap((v) => v?.lab)?.length >
                    0 ? (
                    <Card className={classes.previewCard}>
                      <Grid container>
                        <Grid item xs={11} align="left">
                          {labDetails?.selected?.lab?.flatMap((v) => v?.group)
                            ?.length > 0 ||
                          labDetails?.selected?.lab?.flatMap((v) => v?.lab)
                            ?.length > 0 ? (
                            <Grid container>
                              <Grid item xs={9}>
                                <Typography
                                  style={{ color: myOwnColor }}
                                  className={classes.cardHeading}
                                >
                                  Laboratory
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                align="right"
                                className="print-source"
                              >
                                <Button
                                  variant="contained"
                                  onClick={investigationGroupSummary}
                                  style={{
                                    backgroundColor: myOwnColor,
                                    color: "white",
                                    fontSize: fontSize12 + textSizeFactor,
                                  }}
                                >
                                  Investigation Summary
                                </Button>
                              </Grid>
                            </Grid>
                          ) : null}
                          {labDetails &&
                            [
                              ...labDetails?.lab?.lab?.flatMap((a, index) =>
                                a.labGroups?.filter(
                                  (v) =>
                                    labDetails?.selected?.lab[index] &&
                                    labDetails?.selected?.lab[
                                      index
                                    ]?.group?.indexOf(v.id) !== -1
                                )
                              ),
                              ...labDetails.lab?.lab?.flatMap((a, index) =>
                                a.labTests?.filter(
                                  (v) =>
                                    labDetails?.selected?.lab[index] &&
                                    labDetails?.selected?.lab[
                                      index
                                    ]?.lab?.indexOf(v.id) !== -1
                                )
                              ),
                            ]
                              ?.filter((el) => el)
                              ?.map((v) => {
                                return (
                                  <Chip
                                    className={classes.chip}
                                    label={v?.name}
                                  ></Chip>
                                );
                              })}
                          {labDetails?.selected?.radio?.flat()?.length > 0 ? (
                            <>
                              <Grid container>
                                <Grid item xs={9}>
                                  <Typography
                                    style={{ color: myOwnColor }}
                                    className={classes.cardHeading}
                                  >
                                    Radiology
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  align="right"
                                  className="print-source"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={investigationRadioSummary}
                                    style={{
                                      backgroundColor: myOwnColor,
                                      color: "white",
                                      fontSize: fontSize12 + textSizeFactor,
                                    }}
                                  >
                                    Investigation Summary
                                  </Button>
                                </Grid>
                              </Grid>
                              {labDetails?.lab?.radio
                                ?.flatMap((v, index) =>
                                  v.labTests?.filter(
                                    (v) =>
                                      labDetails?.selected?.radio[index] &&
                                      labDetails?.selected?.radio[
                                        index
                                      ]?.indexOf(v.id) !== -1
                                  )
                                )
                                .map((v) => (
                                  <Chip
                                    className={classes.chip}
                                    label={v.name}
                                  ></Chip>
                                ))}
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ textAlign: "right" }}
                          className="print-source"
                        >
                          <IconButton
                            aria-label="Edit"
                            component="span"
                            size="small"
                            onClick={() =>
                              somId === 3 ? onEdit(7) : onEdit(4)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: myOwnColor }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  ) : null}
                </Grid>
                <Grid
                  items
                  xs={12}
                  sm={12}
                  align="center"
                  className={classes.margin}
                >
                  {data?.surgicalProceduresDtos &&
                  data?.surgicalProceduresDtos?.some(
                    (item) => item.surgicalProcedureId !== null
                  ) ? (
                    <Card className={classes.previewCard}>
                      <Grid container>
                        <Grid item xs={11} align="left">
                          <Typography
                            style={{ color: myOwnColor }}
                            className={classes.cardHeading}
                          >
                            Surgical Procedures
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ textAlign: "right" }}
                          className="print-source"
                        >
                          <IconButton
                            aria-label="Edit"
                            component="span"
                            size="small"
                            onClick={() => onEdit(5)}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: myOwnColor }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <CardContent className={classes.content}>
                        <Grid container spacing={1}>
                          {data?.surgicalProceduresDtos && (
                            <Grid item xs={12} className={classes.tableGrid}>
                              <TableContainer>
                                <Table
                                  aria-label="simple table"
                                  size="small"
                                  className={classes.table}
                                >
                                  <TableHead>
                                    <StyledTableRow
                                      align="center"
                                      style={{ backgroundColor: myOwnColor }}
                                    >
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        S.No
                                      </StyledTableCell>
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        Surgical Procedure
                                      </StyledTableCell>
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        Procedure Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        rowSpan={2}
                                        align="center"
                                        className={classes.text1}
                                      >
                                        Remarks
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {data?.surgicalProceduresDtos &&
                                      data?.surgicalProceduresDtos?.map(
                                        (row, id) => (
                                          <TableRow key={row.id}>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {id + 1}.
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                (activeProcedureList?.find(
                                                  (item) =>
                                                    item?.id === row.surgicalProcedureId
                                                )?.procedureTypeName)
                                                  + " - " + (activeProcedureList?.find(
                                                    (item) =>
                                                      item?.id ===
                                                      row.surgicalProcedureId
                                                  )?.name )
                                              }
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {moment(
                                                row.procedureDate
                                              )?.format("DD-MM-YYYY hh:mm A")}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.remarks}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>
                <Grid items xs={12} align="center" style={{ marginTop: "2%" }}>
                  {data?.concomitantMedicines?.medicineData &&
                  (data?.concomitantMedicines?.medicineData?.concomitant
                    ?.length !== 0 ||
                    data?.concomitantMedicines?.medicineData?.medicine
                      ?.length !== 0) ? (
                    <Card className={classes.previewCard}>
                      <Grid container>
                        <Grid item xs={11} align="left">
                          <Typography
                            style={{ color: myOwnColor }}
                            className={classes.cardHeading}
                          >
                            Concomitant Medicine
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ textAlign: "right" }}
                          className="print-source"
                        >
                          <IconButton
                            aria-label="Edit"
                            component="span"
                            size="small"
                            onClick={() =>
                              somId === 3 ? onEdit(8) : onEdit(5)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: myOwnColor }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <CardContent className={classes.content}>
                        <Grid container>
                          {allopath?.length !== 0 ? (
                            <Grid item xs={12} align="left">
                              <Typography className={classes.header1}>
                                Allopathic Medicine
                              </Typography>
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.tableGrid}
                                >
                                  <TableContainer>
                                    <Table
                                      aria-label="simple table"
                                      size="small"
                                      className={classes.table}
                                    >
                                      <TableHead>
                                        <StyledTableRow
                                          align="center"
                                          style={{
                                            backgroundColor: myOwnColor,
                                          }}
                                        >
                                          {[
                                            "S.No",
                                            "Medicine Name",
                                            "Dosage",
                                            "Unit",
                                            "Frequency",
                                            "Action",
                                            "Dispensary/ Remarks",
                                            "Allergies Known",
                                            "Start Date",
                                            "End Date",
                                          ].map((header) => (
                                            <StyledTableCell
                                              align="center"
                                              className={classes.text1}
                                            >
                                              {header}
                                            </StyledTableCell>
                                          ))}
                                        </StyledTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {allopath?.map((row, id) => (
                                          <TableRow key={row.id}>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {id + 1}.
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.medicineName}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.quantity}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                uoms?.find(
                                                  (d) => d.id === +row.uomId
                                                )?.uomName
                                              }
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                drugIntervals?.find(
                                                  (d) =>
                                                    d.id === row.drugIntervalId
                                                )?.shortName
                                              }
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.actionStatus}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.remarks}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.allergies}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.startDate === null ? (
                                                <Typography
                                                  className={classes.text2}
                                                >
                                                  NA
                                                </Typography>
                                              ) : (
                                                <>
                                                  {moment(row.startDate).format(
                                                    "DD-MM-YYYY"
                                                  )}
                                                </>
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.endDate === null ? (
                                                <Typography
                                                  className={classes.text2}
                                                >
                                                  NA
                                                </Typography>
                                              ) : (
                                                <>
                                                  {moment(row.endDate).format(
                                                    "DD-MM-YYYY"
                                                  )}
                                                </>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                          <br />
                          {/* ============================================================================================= */}
                          {concomData?.length !== 0 ? (
                            <Grid
                              item
                              xs={12}
                              align="left"
                              className={classes.margin}
                            >
                              <Typography className={classes.header1}>
                                Ayush Medicine
                              </Typography>
                              {/* </Card> */}
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.tableGrid}
                                >
                                  <TableContainer>
                                    <Table
                                      aria-label="simple table"
                                      size="small"
                                      className={classes.table}
                                    >
                                      <TableHead>
                                        <StyledTableRow
                                          align="center"
                                          style={{
                                            backgroundColor: myOwnColor,
                                          }}
                                        >
                                          {[
                                            "S.No",
                                            "Medicine Stream",
                                            "Medicine Name",
                                            "Dosage",
                                            "Unit",
                                            "Medicine Vehicle",
                                            "Frequency",
                                            "Action",
                                            "Dispensary/ Remarks",
                                            "Allergies Known",
                                            "Start Date",
                                            "End Date",
                                          ].map((header) => (
                                            <StyledTableCell
                                              align="center"
                                              className={classes.text1}
                                            >
                                              {header}
                                            </StyledTableCell>
                                          ))}
                                        </StyledTableRow>
                                      </TableHead>

                                      <TableBody>
                                        {concomData?.map((row, id) => (
                                          <TableRow key={row.id}>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {id + 1}.
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                streamNames?.find(
                                                  (d) =>
                                                    d.id ===
                                                    row.medicineStreamId
                                                )?.streamName
                                              }
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {concomitantMed &&
                                                concomitantMed[
                                                  row?.medicineStreamId?.toString()
                                                ]?.find(
                                                  (d) =>
                                                    d.id === +row.medicineNameId
                                                )?.MedicineName}
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.quantity}
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.uom}
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                vehicleNames?.find(
                                                  (s) =>
                                                    s.id ===
                                                    row.medicineVehicleId
                                                )?.vehicleName
                                              }
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                drugIntervals?.find(
                                                  (d) =>
                                                    d.id === +row.drugIntervalId
                                                )?.shortName
                                              }
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.actionStatus}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.remarks}
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.allergies}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.startDate === null ? (
                                                <Typography
                                                  className={classes.text2}
                                                >
                                                  NA
                                                </Typography>
                                              ) : (
                                                <>
                                                  {moment(row.startDate).format(
                                                    "DD-MM-YYYY"
                                                  )}
                                                </>
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {row.endDate === null ? (
                                                <Typography
                                                  className={classes.text2}
                                                >
                                                  NA
                                                </Typography>
                                              ) : (
                                                <>
                                                  {moment(row.endDate).format(
                                                    "DD-MM-YYYY"
                                                  )}
                                                </>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </Grid>

                              <Grid
                                items
                                sm={8}
                                xs={6}
                                align="right"
                                style={{
                                  marginTop: "1.5%",
                                  marginLeft: "29.7%",
                                }}
                              ></Grid>
                            </Grid>
                          ) : null}
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>
                <Grid
                  items
                  xs={12}
                  sm={12}
                  align="center"
                  className={classes.margin}
                >
                  {data &&
                  data?.diagnosis &&
                  (data?.diagnosis?.finalIcd ||
                    data?.diagnosis?.finalNsmc ||
                    data?.diagnosis?.selectednsmc ||
                    data?.diagnosis?.selectedIcd ||
                    data?.diagnosis?.additionalNsmc ||
                    data?.diagnosis?.additionalIcd) ? (
                    <Card className={classes.previewCard}>
                      <Grid container>
                        <Grid item xs={11} align="left">
                          <Typography
                            style={{ color: myOwnColor }}
                            className={classes.cardHeading}
                          >
                            Diagnosis with Differential Diagnosis
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ textAlign: "right" }}
                          className="print-source"
                        >
                          <IconButton
                            aria-label="Edit"
                            component="span"
                            size="small"
                            onClick={() =>
                              somId === 3 ? onEdit(9) : onEdit(6)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: myOwnColor }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <CardContent className={classes.content}>
                        {diagnosisData &&
                        (diagnosisData?.selectednsmc?.length > 0 ||
                          diagnosisData?.selectedIcd?.length > 0) ? (
                          <>
                            <Typography className={classes.header1}>
                              Differential Diagnosis
                            </Typography>
                            <Card style={{ padding: "5px" }}>
                              <Grid container spacing={1}>
                                {somId !== 5 &&
                                diagnosisData?.selectednsmc?.length > 0 ? (
                                  <Grid item xs={6}>
                                    <b className={classes.text2}>
                                      Diagnosis in {nmcCodeTerm} :
                                    </b>
                                    <List dense>
                                      {diagnosisData?.selectednsmc?.map(
                                        (v, idx) => (
                                          <ListItem>
                                            <ListItemText
                                              className={classes.dosLabel}
                                              primary={`Differential Diagnosis ${idx +
                                                1} : ${v?.diseaseNameWithCode}`}
                                            />
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Grid>
                                ) : null}
                                {diagnosisData?.selectedIcd?.length > 0 ? (
                                  <Grid item xs={6}>
                                    <b className={classes.text2}>
                                      Diagnosis in{" "}
                                      {selectedICDTAB === "icd 10"
                                        ? "ICD 10"
                                        : "ICD 11"}{" "}
                                      :
                                    </b>
                                    <List dense>
                                      {diagnosisData?.selectedIcd?.map(
                                        (v, idx) => (
                                          <ListItem>
                                            <ListItemText
                                              className={classes.dosLabel}
                                              primary={`Differential Diagnosis ${idx +
                                                1} : ${v?.diagTermWithCode}`}
                                            />
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Card>
                          </>
                        ) : null}
                        {diagnosisData &&
                        (diagnosisData?.finalNsmc ||
                          diagnosisData?.finalIcd) ? (
                          <>
                            <Typography className={classes.header1}>
                              Final Diagnosis
                            </Typography>
                            <Card style={{ padding: "5px" }}>
                              <Grid container spacing={1}>
                                {somId !== 5 && data?.diagnosis?.finalNsmc ? (
                                  <Grid item xs={6} className={classes.text2}>
                                    <b className={classes.text2}>
                                      Diagnosis in {nmcCodeTerm} :
                                    </b>
                                    {
                                      diagnosisData?.finalNsmc
                                        ?.diseaseNameWithCode
                                    }
                                  </Grid>
                                ) : null}
                                {data?.diagnosis?.finalIcd ? (
                                  <Grid item xs={6} className={classes.text2}>
                                    <b className={classes.text2}>
                                      Diagnosis in{" "}
                                      {selectedICDTAB === "icd 10"
                                        ? "ICD 10"
                                        : "ICD 11"}{" "}
                                      :
                                    </b>
                                    {diagnosisData?.finalIcd?.diagTermWithCode}
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Card>
                          </>
                        ) : null}
                        {diagnosisData &&
                        (diagnosisData?.additionalNsmc?.length > 0 ||
                          diagnosisData?.additionalIcd?.length > 0) ? (
                          <>
                            <Typography className={classes.header1}>
                              Additional Diagnosis
                            </Typography>
                            <Card style={{ padding: "5px" }}>
                              <Grid container spacing={1}>
                                {somId !== 5 &&
                                diagnosisData?.additionalNsmc?.length > 0 ? (
                                  <Grid item xs={6}>
                                    <b className={classes.text2}>
                                      Additional Diagnosis in {nmcCodeTerm} :
                                    </b>
                                    <List dense>
                                      {diagnosisData?.additionalNsmc?.map(
                                        (v, idx) => (
                                          <ListItem>
                                            <ListItemText
                                              className={classes.dosLabel}
                                              primary={`Additional Diagnosis ${idx +
                                                1} : ${v?.diseaseNameWithCode}`}
                                            />
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Grid>
                                ) : null}
                                {diagnosisData?.additionalIcd?.length > 0 ? (
                                  <Grid item xs={6}>
                                    <b className={classes.text2}>
                                      Additional Diagnosis in{" "}
                                      {selectedICDTAB === "icd 10"
                                        ? "ICD 10"
                                        : "ICD 11"}{" "}
                                      :
                                    </b>
                                    <List dense>
                                      {diagnosisData?.additionalIcd?.map(
                                        (v, idx) => (
                                          <ListItem>
                                            <ListItemText
                                              className={classes.dosLabel}
                                              primary={`Additional Diagnosis ${idx +
                                                1} : ${v?.diagTermWithCode}`}
                                            />
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Card>
                          </>
                        ) : null}
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                items
                xs={12}
                sm={12}
                align="center"
                className={classes.margin}
              >
                {data?.prescription?.followUpDateRadio ||
                simple !== null ||
                complex !== null ||
                external !== null ||
                paid !== null ? (
                  <Card className={classes.previewCard}>
                    <Grid container>
                      <Grid item xs={11} align="left">
                        <Typography
                          style={{ color: myOwnColor }}
                          className={classes.cardHeading}
                        >
                          Prescription
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: "right" }}
                        className="print-source"
                      >
                        <IconButton
                          aria-label="Edit"
                          component="span"
                          size="small"
                          onClick={() => (somId === 3 ? onEdit(11) : onEdit(8))}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ color: myOwnColor }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <CardContent className={classes.content}>
                      <Grid container spacing={0}>
                        <Grid container spacing={0}>
                          {data?.prescription?.value &&
                          data?.prescription?.value?.complex?.length !== 0 &&
                          somId !== 5 ? (
                            <Grid item xs={12} className={classes.padding}>
                              <Grid item xs={11} align="left">
                                <Typography className={classes.header1}>
                                  Mixture Medicine
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{ backgroundColor: myOwnColor }}
                                      >
                                        {[
                                          "S.No",
                                          "Medicine Type",
                                          "Medicine Name",
                                          "Dosage",
                                          "Units",
                                          "No Of Days",
                                          "Interval",
                                          "Vehicle",
                                          "AF/ BF",
                                          "Total Quantity",
                                          "Remarks",
                                        ].map((header) => (
                                          <StyledTableCell
                                            align="center"
                                            className={classes.text1}
                                          >
                                            {header}
                                          </StyledTableCell>
                                        ))}
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody
                                      style={{ border: "1px solid lightgrey" }}
                                    >
                                      {complexData?.map((row, rowIndex) => (
                                        <>
                                          {row?.rows?.map((x, i) => (
                                            <TableRow
                                              key={row.id}
                                              style={{
                                                border: "1px solid lightgrey",
                                              }}
                                            >
                                              {i + 1 === 1 ? (
                                                <TableCell
                                                  align="center"
                                                  className={classes.tableCell}
                                                  rowSpan={
                                                    i + 1 === 1
                                                      ? row?.rows?.length
                                                      : null
                                                  }
                                                >
                                                  {handleRowCount(rowIndex)}.
                                                </TableCell>
                                              ) : null}
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {
                                                  complexMedicineTypes?.find(
                                                    (s) =>
                                                      s.id === x.medicineType
                                                  )?.typeName
                                                }
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {
                                                  data?.prescription?.complexMedicines[
                                                    x?.medicineType?.toString()
                                                  ]?.find(
                                                    (d) =>
                                                      d.id === x.medicineName
                                                  )?.MedicineName
                                                }
                                              </TableCell>

                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {x.dosage}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {x.uomName}
                                              </TableCell>
                                              {i + 1 === 1 ? (
                                                <TableCell
                                                  align="center"
                                                  className={classes.tableCell}
                                                  rowSpan={
                                                    i + 1 === 1
                                                      ? row?.rows?.length
                                                      : null
                                                  }
                                                >
                                                  {row.noDays}
                                                </TableCell>
                                              ) : null}
                                              {i + 1 === 1 ? (
                                                <TableCell
                                                  align="center"
                                                  className={classes.tableCell}
                                                  rowSpan={
                                                    i + 1 === 1
                                                      ? row?.rows?.length
                                                      : null
                                                  }
                                                >
                                                  {
                                                    drugIntervals?.find(
                                                      (d) =>
                                                        d.id ===
                                                        row.drugIntervalId
                                                    )?.shortName
                                                  }
                                                </TableCell>
                                              ) : null}
                                              {i + 1 === 1 ? (
                                                <TableCell
                                                  align="center"
                                                  className={classes.tableCell}
                                                  rowSpan={
                                                    i + 1 === 1
                                                      ? row?.rows?.length
                                                      : null
                                                  }
                                                >
                                                  {
                                                    vehicleNames?.find(
                                                      (s) =>
                                                        s.id ===
                                                        row.medicineVehicle
                                                    )?.vehicleName
                                                  }
                                                </TableCell>
                                              ) : null}

                                              {i + 1 === 1 ? (
                                                <TableCell
                                                  align="center"
                                                  className={classes.tableCell}
                                                  rowSpan={
                                                    i + 1 === 1
                                                      ? row?.rows?.length
                                                      : null
                                                  }
                                                >
                                                  {row.isBf === "true"
                                                    ? "AF"
                                                    : "BF"}
                                                </TableCell>
                                              ) : null}
                                              <TableCell
                                                align="center"
                                                className={classes.tableCell}
                                              >
                                                {x.totalQuantity}
                                              </TableCell>
                                              {i + 1 === 1 ? (
                                                <TableCell
                                                  align="center"
                                                  className={classes.tableCell}
                                                  rowSpan={
                                                    i + 1 === 1
                                                      ? row?.rows?.length
                                                      : null
                                                  }
                                                >
                                                  {row.remarks}
                                                </TableCell>
                                              ) : null}
                                            </TableRow>
                                          ))}
                                        </>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          ) : null}
                        </Grid>

                        {/* =================================================single========================================== */}
                        <Grid item xs={12} sm={12} align="left">
                          <Grid container spacing={0}>
                            <Grid item xs={11} align="left">
                              {(!!simple?.length || !!homeo?.length) && (
                                <Typography className={classes.header1}>
                                  Single Medicine
                                </Typography>
                              )}
                            </Grid>
                            {!!simple?.length ? (
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{
                                          backgroundColor: myOwnColor,
                                        }}
                                      >
                                        {[
                                          "S.No",
                                          "Medicine Type",
                                          "Medicine Name",
                                          "Dosage",
                                          "Units",
                                          "No Of Days",
                                          "Interval",
                                          "Vehicle",
                                          "AF/ BF",
                                          "Total Quantity",
                                          "Remarks",
                                        ].map((header) => (
                                          <StyledTableCell
                                            align="center"
                                            className={classes.text1}
                                          >
                                            {header}
                                          </StyledTableCell>
                                        ))}
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {simple?.map((row, id) => (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {handleRowCount(id)}.
                                          </TableCell>
                                          {somId === 5 ? (
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                homeoMedtypes?.find(
                                                  (s) =>
                                                    s.id === row.medicineType
                                                )?.typeName
                                              }
                                            </TableCell>
                                          ) : (
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {
                                                simpleMedicineTypes?.find(
                                                  (s) =>
                                                    s.id === row.medicineType
                                                )?.typeName
                                              }
                                            </TableCell>
                                          )}
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              data?.prescription?.simpleMedicines[
                                                row?.medicineType?.toString()
                                              ]?.find(
                                                (d) => d.id === row.medicineName
                                              )?.MedicineName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.dosage}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.uomName}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.noDays}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              drugIntervals?.find(
                                                (d) =>
                                                  d.id === row.drugIntervalId
                                              )?.shortName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              vehicleNames?.find(
                                                (s) =>
                                                  s.id === row.medicineVehicle
                                              )?.vehicleName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.isBf === "true" ? "AF" : "BF"}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.totalQuantity}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.remarks}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                        {!!homeo?.length ? (
                          <Grid item xs={12} className={classes.padding}>
                            <Grid container spacing={0}>
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{ backgroundColor: myOwnColor }}
                                      >
                                        {[
                                          "S.No",
                                          "Medicine Type",
                                          "Medicine Name",
                                          "Dosage",
                                          "Potency",
                                          "Units",
                                          "No Of Days",
                                          "Interval",
                                          "Vehicle",
                                          "AF/ BF",
                                          "Total Quantity",
                                          "Remarks",
                                        ].map((header) => (
                                          <StyledTableCell
                                            align="center"
                                            className={classes.text1}
                                          >
                                            {header}
                                          </StyledTableCell>
                                        ))}
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {homeo?.map((row, id) => (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {handleRowCount(id)}.
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              homeoMedtypes?.find(
                                                (s) => s.id === row.medicineType
                                              )?.typeName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              data?.prescription?.simpleMedicines[
                                                row.medicineType.toString()
                                              ]?.find(
                                                (d) => d.id === row.medicineName
                                              )?.MedicineName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.dosage}
                                          </TableCell>
                                          {row.decimal !== "" &&
                                          row.decimal !== 0 ? (
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {`${
                                                homeoPotency?.Decimal?.find(
                                                  (s) => s.id === row.decimal
                                                )?.typeName
                                              } Decimal`}
                                            </TableCell>
                                          ) : null}
                                          {row.centesimal !== "" &&
                                          row.centesimal !== 0 ? (
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {`${
                                                homeoPotency?.Centesimal?.find(
                                                  (s) => s.id === row.centesimal
                                                )?.typeName
                                              } Centesimal`}
                                            </TableCell>
                                          ) : null}
                                          {row.millesimal !== "" &&
                                          row.millesimal !== 0 ? (
                                            <TableCell
                                              align="center"
                                              className={classes.tableCell}
                                            >
                                              {`${
                                                homeoPotency?.Millesimal?.find(
                                                  (s) => s.id === row.millesimal
                                                )?.typeName
                                              } Millesimal`}
                                            </TableCell>
                                          ) : null}
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.uomName}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.noDays}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              drugIntervals?.find(
                                                (d) =>
                                                  d.id === row.drugIntervalId
                                              )?.shortName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              vehicleNames?.find(
                                                (s) =>
                                                  s.id === row.medicineVehicle
                                              )?.vehicleName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.isBf === "true" ? "AF" : "BF"}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.totalQuantity}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.remarks}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}

                        {/* =====================================================paid===================================== */}
                        {data?.prescription?.value &&
                        data?.prescription?.value?.paid?.length !== 0 ? (
                          <Grid
                            container
                            spacing={0}
                            className={classes.padding}
                          >
                            <Grid item xs={11} align="left">
                              <Typography className={classes.header1}>
                                Paid Medicine
                              </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.tableGrid}>
                              <TableContainer>
                                <Table
                                  aria-label="simple table"
                                  size="small"
                                  className={classes.table}
                                >
                                  <TableHead>
                                    <StyledTableRow
                                      align="center"
                                      style={{ backgroundColor: myOwnColor }}
                                    >
                                      {[
                                        "S.No",
                                        "Medicine Type",
                                        "Medicine Name",
                                        "Dosage",
                                        "Units",
                                        "No Of Days",
                                        "Interval",
                                        "Vehicle",
                                        "AF/ BF",
                                        "Total Quantity",
                                        "Remarks",
                                      ].map((header) => (
                                        <StyledTableCell
                                          align="center"
                                          className={classes.text1}
                                        >
                                          {header}
                                        </StyledTableCell>
                                      ))}
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {paid?.map((row, id) => (
                                      <TableRow key={row.id}>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {handleRowCount(id)}.
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {
                                            paidMedicineTypes?.find(
                                              (s) => s.id === row.medicineType
                                            )?.typeName
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {
                                            data?.prescription?.paidMedicines[
                                              row?.medicineType?.toString()
                                            ]?.find(
                                              (d) => d.id === row.medicineName
                                            )?.MedicineName
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {row.dosage}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {row.uomName}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {row.noDays}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {
                                            drugIntervals?.find(
                                              (d) => d.id === row.drugIntervalId
                                            )?.shortName
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {
                                            vehicleNames?.find(
                                              (s) =>
                                                s.id === row.medicineVehicle
                                            )?.vehicleName
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {row.isBf === "true" ? "AF" : "BF"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {row.totalQuantity}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableCell}
                                        >
                                          {row.remarks}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        ) : null}

                        {/* ==================================================================external====================================== */}

                        {data?.prescription?.value &&
                        data?.prescription?.value?.external?.length !== 0 ? (
                          <Grid item xs={12} className={classes.padding}>
                            <Grid container spacing={0}>
                              <Grid item xs={11} align="left">
                                <Typography className={classes.header1}>
                                  External Medicine
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.tableGrid}>
                                <TableContainer>
                                  <Table
                                    aria-label="simple table"
                                    size="small"
                                    className={classes.table}
                                  >
                                    <TableHead>
                                      <StyledTableRow
                                        align="center"
                                        style={{ backgroundColor: myOwnColor }}
                                      >
                                        {[
                                          "S.No",
                                          "Medicine Type",
                                          "Medicine Name",
                                          "Dosage",
                                          "Units",
                                          "No Of Days",
                                          "Interval",
                                          "Mix With",
                                          "Total Quantity",
                                          "Remarks",
                                        ].map((header) => (
                                          <StyledTableCell
                                            align="center"
                                            className={classes.text1}
                                          >
                                            {header}
                                          </StyledTableCell>
                                        ))}
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {external?.map((row, id) => (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {handleRowCount(id)}.
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              externalMedicineTypes?.find(
                                                (s) => s.id === row.medicineType
                                              )?.typeName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {
                                              data?.prescription?.externalMedicines[
                                                row?.medicineType?.toString()
                                              ]?.find(
                                                (d) => d.id === row.medicineName
                                              )?.MedicineName
                                            }
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.dosage}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.uomName}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.noDays}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.drugIntervalId
                                              ? drugIntervals?.find(
                                                  (d) =>
                                                    d.id === row.drugIntervalId
                                                )?.shortName
                                              : "-"}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.mixwith}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.totalQuantity}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {row.remarks}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}

                        <Grid container>
                          {data?.prescription?.prescriptionRemarks ? (
                            <Grid item xs={6} className={classes.text2}>
                              <Typography className={classes.text2}>
                                <b> Prescription Remarks : </b>
                                <span>
                                  {data?.prescription?.prescriptionRemarks}
                                </span>
                              </Typography>
                            </Grid>
                          ) : null}
                          {data?.prescription &&
                          data?.prescription?.followUpVisitDay ? (
                            <Grid item xs={6} className={classes.text2}>
                              <Typography className={classes.text2}>
                                <b>Follow - Up in : </b>
                                <span>
                                  {data?.prescription?.followUpVisitDay} Days
                                </span>
                              </Typography>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>
              <Grid
                items
                xs={12}
                sm={12}
                align="center"
                className={classes.margin}
              >
                {data?.doDont?.selectedDos &&
                data?.doDont?.selectedDos?.length > 0 ? (
                  <Card className={classes.previewCard}>
                    <Grid container>
                      <Grid item xs={11} align="left">
                        <Typography
                          style={{ color: myOwnColor }}
                          className={classes.cardHeading}
                        >
                          Do's
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: "right" }}
                        className="print-source"
                      >
                        <IconButton
                          aria-label="Edit"
                          component="span"
                          size="small"
                          onClick={() => (somId === 3 ? onEdit(11) : onEdit(8))}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ color: myOwnColor }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <CardContent className={classes.content}>
                      <List dense className={classes.doList}>
                        {dos?.map((v) => (
                          <ListItem>
                            <ListItemText
                              primary={v?.advice}
                              className={classes.dosLabel}
                            />
                          </ListItem>
                        ))}
                      </List>
                      {data?.doDont?.dosRemarks ? (
                        <Grid item xs={6} className={classes.text2}>
                          <Typography className={classes.text2}>
                            <b> Do's Remarks : </b>
                            <span>{data?.doDont?.dosRemarks}</span>
                          </Typography>
                        </Grid>
                      ) : null}
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>
              <Grid
                items
                xs={12}
                sm={12}
                align="center"
                className={classes.margin}
              >
                {data?.doDont?.selectedDonts &&
                data?.doDont?.selectedDonts?.length > 0 ? (
                  <Card className={classes.previewCard}>
                    <Grid container>
                      <Grid item xs={11} align="left">
                        <Typography
                          style={{ color: myOwnColor }}
                          className={classes.cardHeading}
                        >
                          Don'ts
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: "right" }}
                        className="print-source"
                      >
                        <IconButton
                          aria-label="Edit"
                          component="span"
                          size="small"
                          onClick={() => (somId === 3 ? onEdit(9) : onEdit(8))}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ color: myOwnColor }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <CardContent className={classes.content}>
                      <List dense className={classes.doList}>
                        {donts?.map((v) => (
                          <ListItem>
                            <ListItemText
                              primary={v?.advice}
                              className={classes.dosLabel}
                            />
                          </ListItem>
                        ))}
                      </List>
                      {data?.doDont?.dontsRemarks ? (
                        <Grid item xs={6} className={classes.text2}>
                          <Typography className={classes.text2}>
                            <b> Don'ts Remarks : </b>
                            <span>{data?.doDont?.dontsRemarks}</span>
                          </Typography>
                        </Grid>
                      ) : null}
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>
              <div>
                <Grid container spacing={0} className={classes.margin}></Grid>
              </div>
            </div>
            {openTransfer && (
              <Card className={classes.previewCard}>
                <Grid container>
                  <Grid item xs={11} align="left">
                    <Typography
                      style={{ color: myOwnColor }}
                      className={classes.cardHeading}
                    >
                      TRANSFER
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={0}>
                  <Grid item xs={11} align="left">
                    <Typography variant="h6" style={{ marginTop: 10 }}>
                      TRANSFER
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={1} align="right">
                <IconButton onClick={handleClose} edge="start" color="primary">
                  <CloseIcon style={{ color: "black" }} />
                </IconButton>
              </Grid> *
                </Grid> */}

                <Accordion
                  className={classes.accordion1}
                  defaultExpanded="true"
                >
                  <AccordionSummary
                    style={{
                      backgroundColor: myOwnColor,
                      minHeight: 40,
                      maxHeight: 40,
                      // padding:16
                    }}
                    expandIcon={<ExpandMoreIcon className={classes.icon} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.icon}>Referral</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.content}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} sm={4}>
                        <Typography>Stream</Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <FormControl size="small" fullWidth>
                          <Select
                            native
                            variant="outlined"
                            value={som}
                            onChange={handleChangeSom}
                          >
                            <option value="" disabled selected>
                              Stream
                            </option>

                            <option value={1}>Ayurveda</option>
                            <option value={2}>Unani</option>
                            <option value={3}>Yoga & Naturopathy</option>
                            <option value={4}>Siddha</option>
                            <option value={5}>Homoeopathy</option>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Typography>Hospital</Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        {hospitalValue !== "" && hospitalValue !== undefined ? (
                          <Popper
                            placement="top"
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            style={{ zIndex: 9999 }}
                          >
                            <Paper>
                              <Typography style={{ padding: 12 }}>
                                {inputValue}
                              </Typography>
                            </Paper>
                          </Popper>
                        ) : null}
                        <Autocomplete
                          aria-describedby={id}
                          options={healthFacilities}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          // className={classes.input2}
                          getOptionLabel={(option) => option.organisationName}
                          // filterSelectedOptions
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          onChange={(e, v) => {
                            handleChangeOrg(v);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              placeholder="Search Hospital"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Typography>Department</Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <FormControl size="small" fullWidth>
                          <Select
                            variant="outlined"
                            native
                            // id="department"
                            onChange={handleChangeDept}
                            value={selectedDept}
                          >
                            <option value="" disabled selected>
                              Department
                            </option>

                            {departments &&
                              departments.length > 0 &&
                              departments.map((dept) => {
                                return (
                                  <option value={dept.id}>
                                    {dept.deptName}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} align="right">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            // handleSubmit();
                            handleOPDTransfer();
                          }}
                          style={{
                            backgroundColor: myOwnColor,
                            color: "white",
                          }}
                        >
                          Transfer
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{ marginTop: "2%" }}
                  className={classes.accordion1}
                >
                  <AccordionSummary
                    style={{
                      backgroundColor: myOwnColor,
                      minHeight: 40,
                      maxHeight: 40,
                    }}
                    expandIcon={<ExpandMoreIcon className={classes.icon} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.icon}>
                      IP Admission
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.content}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} sm={4}>
                        <Typography>Date</Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            inputVariant="outlined"
                            size="small"
                            format="dd/MM/yyy"
                            id="date-picker-inline"
                            value={ipAdmissiontransferData.admissionDate}
                            onChange={(date) => {
                              setIpAdmissiontransferData({
                                ...ipAdmissiontransferData,
                                admissionDate: date,
                              });
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Typography>Department</Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <FormControl fullWidth size="small">
                          <Select
                            native
                            variant="outlined"
                            onChange={(e) => {
                              setIpAdmissiontransferData({
                                ...ipAdmissiontransferData,
                                departmentId: e.target.value,
                              });
                            }}
                            value={ipAdmissiontransferData.departmentId}
                          >
                            <option value=""  selected>
                              Select Department
                            </option>

                            {ipdDept &&
                              ipdDept.length > 0 &&
                              ipdDept.map((dept) => {
                                return (
                                  <option value={dept.id}>
                                    {dept.deptName}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography>Reason for Transfer</Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <TextField
                          onChange={(e) => {
                            setIpAdmissiontransferData({
                              ...ipAdmissiontransferData,
                              remarks: e.target.value,
                            });
                          }}
                          placeholder="Remarks"
                          size="small"
                          variant="outlined"
                          name="pulserate"
                          fullWidth
                          rows={3}
                          multiline
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            // handleSubmitIPAdmission();
                            handleIPDTransfer();
                          }}
                          style={{
                            backgroundColor: myOwnColor,
                            color: "white",
                            fontSize: "12px",
                            float: "right",
                          }}
                        >
                          Admission
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Card>
            )}
          </DialogContent>
        </div>
        <DialogActions>
          {!openTransfer && (
            <Button
              onClick={handleOpenDialog}
              id="gerarPDF"
              autoFocus
              style={{ backgroundColor: myOwnColor, color: "white" }}
            >
              Submit
            </Button>
          )}
          <CloseButton onClick={handleClose}>Close</CloseButton>
          {Boolean(
            diagnosisData &&
              (diagnosisData?.finalNsmc || diagnosisData?.finalIcd)
          ) && Boolean(data?.prescription?.followUpVisitDay) ? (
            <ReactToPrint
              trigger={() => (
                <Button style={{ backgroundColor: "#0C51B8", color: "white" }}>
                  Print
                </Button>
              )}
              content={() => componentRef}
            />
          ) : null}
          {/* <Button
            style={{ color: "white" }}
            variant="contained"
            onClick={(e) =>
              handlePrintButtonClick(
                e,
                selectedPatient,
                streamNames,
                concomitantMed,
                allMedicineVehicleNames,
                drugIntervals,
                uoms,
                data,
                StaffLogin,
                pulseData,
                cdData,
                personalData,
                pastData,
                familyData,
                menstrualHistory,
                candPData,
                labDetails,
                concomData,
                allopath,
                diagnosisData
              )
            }
            // disabled={true}
            startIcon={<GetApp />}
            color="secondary"
            disabled={
              !(
                (diagnosisData?.finalNsmc || diagnosisData?.finalIcd) &&
                data?.prescription?.followUpVisitDay
              )
            }
          >
            PDF
          </Button> */}
        </DialogActions>
      </Dialog>
      {/* confirmation or Alert Dialog */}
      <Dialog open={openSaveDialog} onClose={handleCloseDialog}>
        <DialogContent>
          {!Boolean(
            data?.diagnosis &&
              (data?.diagnosis?.finalNsmc || data?.diagnosis?.finalIcd)
          ) || !Boolean(data?.prescription?.followUpVisitDay) ? (
            <>
              <Typography style={{ color: "red" }}>
                Please Enter Final Diagnosis and Follow Up Date
              </Typography>
            </>
          ) : (
            <Typography variant="h6">Are you sure you want to save?</Typography>
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.center }}>
          {Boolean(
            diagnosisData &&
              (diagnosisData?.finalNsmc || diagnosisData?.finalIcd)
          ) && Boolean(data?.prescription?.followUpVisitDay) ? (
            <>
              <Button
                disabled={savingConsultation}
                onClick={savePreview}
                color="secondary"
                variant="outlined"
                size="small"
              >
                {savingConsultation && <CircularProgress size="24px" />} Yes
              </Button>
              <Button
                disabled={savingConsultation}
                onClick={handleCloseDialog}
                style={{ color: "red" }}
                variant="outlined"
                size="small"
              >
                No
              </Button>
            </>
          ) : (
            <Button
              disabled={savingConsultation}
              onClick={handleCloseDialog}
              color="primary"
              variant="outlined"
              size="small"
            >
              <Typography align="center">Ok</Typography>
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {openGroupSummary ? (
        <InvestigationSummary
          open={openGroupSummary}
          onClose={setOpenGroupSummary}
          show={show}
          setShow={setShow}
          data={selectedPatient}
        />
      ) : null}

      {openRadioSummary ? (
        <RadioInvestigation
          open={openRadioSummary}
          onClose={setOpenRadioSummary}
          show={showReport}
          setShow={setReportShow}
          data={selectedPatient}
        />
      ) : null}
    </>
  );
}
