//+++++++++++++++++++++++++++++++BED-ALLOCATION+++++++++++++++++++++++++++++++++++++++++++
export const getIpdPatientListByOrgId = `bill/getIpdPatientListByOrgId`;
export const getIpdAdmittedPatientListByOrgId = "bill/getIpdAdmittedPatientListByOrgId";
export const getAllBuildingsByOrg = `building/getAllBuildingsByOrg`;
export const fetchActiveWardByBuildingId = `ward/fetchActiveWardByBuildingId`;
export const fetchWardTypeByBuildingId = `wardType/getWardTypesByBuildingId`;
export const fatchBedByWardId = `bed/fatchBedByWardId`;
export const saveIpdAdmission = "ipdAdmissionDetails/saveIpdAdmission";
export const getBedStatByOrgId = "bed/getBedStatByOrgId";
export const getWardTypeByOrgId = "wardType/getWardTypeByOrgId";
export const fetchBedByBuildingIdWardTypeId = "bed/fetchBedByBuildingIdWardTypeId";

//
export const getAdmittedPatientByOrgId = "ipdAdmissionDetails/getAdmittedPatientByOrgId";
export const getAdmittedPatientByWardId = "ipdAdmissionDetails/getAdmittedPatientByWardId";
export const fetchBloodSugerChartByDetailsId = "bloodSugerChart/fetchBloodSugerChartByDetailsId";
export const getBloodSugarTypes = "bloodSugerType/getType";
export const saveBloodSugerChart = "bloodSugerChart/saveBloodSugerChart";
//Kardex-FORM
export const fetchKardexDetailsByDetailsId = "kardexDetails/fetchKardexDetailsByDetailsId";
export const saveKardexDetails = "kardexDetails/saveKardexDetails";
//Vital Signs
export const getIpdVitalSignByRegId = "vitalSign/getIpdVitalSignByRegId";
export const saveVitalSign = "vitalSign/saveIpdVitalSign";
//Wound Assessment And Care
export const fetchWoundType = "woundType/fetchWoundType";
export const fetchWoundStatus = "woundStatus/fetchWoundStatus";
export const fetchWoundArea = "woundArea/fetchWoundArea";
export const fetchWoundBed = "woundBed/fetchWoundBed";
export const fetchWoundOdour = "woundOdour/fetchWoundOdour";
export const fetchDrainageIntensity = "drainageIntensity/fetchDrainageIntensity";
export const fetchDrainageType = "drainageType/fetchDrainageType";
export const fetchPeriwoundSkinType = "periwoundSkinType/fetchPeriwoundSkinType";
//
export const saveWoundAssessment = "woundAssessment/saveWoundAssessment";
export const fetchWoundAssessmentByDetailsId = "woundAssessment/fetchWoundAssessmentByDetailsId";
//NurseRecord
export const saveNurseRecord = "nurseRecord/saveNurseRecord";
export const fetchNurseRecordByDetailsId = "nurseRecord/fetchNurseRecordByDetailsId";
//DietSheet
export const saveDietSheet = "dietSheet/saveDietSheet";
export const fetchDietSheetByDetailsId = "dietSheet/fetchDietSheetByDetailsId";
export const getFoodsByTypeId = "foodItem/getFoodsByTypeId";
export const fetchMealTypeByOrgId = "mealType/fetchMealTypeByOrgId";
export const fetchAllFoodItems = "foodItem/getAllFoodItems";
export const fetchMealTypeByMealTypeId = "foodMealTypeMapping/getFoodItemsByMealTypeId";
export const saveFoodMealTypeMapping = "foodMealTypeMapping/saveFoodMealTypeMap";
export const saveFoodDietArticleMapUrl = "foodDietArticleMapping/saveFoodDietArticleMap";
export const editFoodDietArticleMapNew = "foodDietArticleMapping/editFoodDietArticleMapNew/";
export const editFoodMealtypeMapNew = "foodMealTypeMapping/editFoodMealtypeMapNew";
// Save Concomitant medicine
export const saveConcomitantMedication = "concomitantMedication/saveConcomitantMedication";
export const fetchConcomitantMedicationByDetailsId = "concomitantMedication/fetchConcomitantMedicationByDetailsId";
// Medication Chart
export const getAllMedVehicle = "medicineVehicle/getAllMedVehicle";
export const saveMedicationChart = "medicationChart/saveMedicationChart";
export const fetchMedicationChartByDetailsId = "medicationChart/fetchMedicationChartByDetailsId";

//Linen-Management (Nurse Care Module)
// export const fetchLinenManagement = "linenManagement/fetchLinenManagement";
export const fetchLinenManagementByOrgId = (orgId) => `linenManagement/fetchLinenManagementByOrgId/${orgId}`;
export const fetchActiveLinenManagementByOrgId = "linenManagement/fetchActiveLinenManagementByOrgId/";

export const saveLinenManagementDetails = "linenManagementDetails/saveLinenManagementDetails";
export const fetchLinenManagementDetailsByDetailsId = "linenManagementDetails/fetchLinenManagementDetailsByDetailsId";

//medication Chart---
export const fetchSavedFinalDiagnosis = "diagnosis/fetchSavedFinalDiagnosis";
export const fetchSavedDiagnosis = "diagnosis/fetchSavedDiagnosis";
export const getIpdPrescriptionByConsId = "prescription/getIpdPrescriptionByConsId";

export const getPrescriptionDateByConsId = "prescription/getPrescriptionDateByConsId";
export const getPrescriptionByDateConsId = "prescription/getPrescriptionByDateConsId";

export const getIPDDiagnosisList = "diagnosis/getIPDDiagnosisList";

// getIpdVitalSignByRegId/{regId}
// fetchSavedFinalDiagnosis{con_id}
// fetchSavedDiagnosis{con_id}
// getIpdPrescriptionByConsId{con_id}

//------------------------- IPD- Consultation - (jrDoctor,Chief-Doctor)-------------------------------
//complains
export const getAllComplaints = "complaints/getAllComplaints";
export const getAllComplaintsIpd = "complaints/getAllIpdComplaintsByConsultationId";

//Allopathy
export const saveAllopathyMed = "/allopathy/saveAllopathyMed";
export const getAllopathyMedDetailsByConsultationId = "allopathy/getAllopathyMedDetailsByConsultationId";
//Ayush
export const saveConcomitantMed = "concomitantMed/saveConcomitantMed";
export const getConcomitantMedDetailsByConsultationId = "concomitantMed/getConcomitantMedDetailsByConsultationId";
//prescription
export const getPrescriptionListByConsultationId = "prescription/getIPDPrescriptionListbyConIdDateWise";
export const savePrescriptionData = "prescription/savePrescriptionData";
//Provisional Diagnosis
export const saveIPDDiagnosis = "diagnosis/saveIPDDiagnosis";
//Saving CaseSheet
export const saveCaseSheet = "drRounds/saveCaseSheet";
export const getDrRoundByConsultationId = "drRounds/getDrRoundByConsultationId";
export const saveDischargeDetails = "dischargeDetails/saveDischargeDetails";

//Medical Certificate
export const saveCertificateForLeave = "certificate/saveCertificateForLeave";
export const saveCertificateForFitness = "certificate/saveCertificateForFitness";
export const saveCertificateForCauseOfDeath = "certificate/saveCertificateForCauseOfDeath";
export const saveCertificateForReferralLetter = "certificate/saveCertificateForReferralLetter";
export const getPreviousAdmissionsOfaPatientByAyushId = "ipdAdmission/getPreviousAdmissionsOfaPatientByAyushId";

// success
// failure
// data already exists
// http://172.16.68.214:8765/ahmis-ipdopd-services/certificate/getListOfCertificatesforLeaveofApatient/{patientID}

// [
//     {
//         "consultaionId": 38,
//         "Date": "2022-06-13T06:51:25.646+00:00",
//         "doctorName": "DR.B.CHITRA",
//         "orgName": "NA"
//     }
// ]

//Discharge Note:
export const saveDischargeData = "dischargeDetails/saveDischargeData";
// export const fetchDischargeNote = "dischargeNote/fetchDischargeNote";
export const fetchDischargeNoteByOrgId = (orgId) => `dischargeNote/fetchDischargeNoteByOrgId/${orgId}`;
// =====External Therapy====
export const fetchTherapyDetailsByOrgId = (orgId) => `therapyDetails/fetchTherapyDetailsByOrgId/${orgId}`;
export const fetchPackageExistingTherapyDetails = "packageDetails/fetchPackageExistingTherapyDetails";
export const fetchPackageDetailsOfTherapy = "packageDetails/fetchPackageDetailsofaTherapy";
export const getStaffByRoleAndOrg = "staff/getStaffByRoleAndOrg";
export const fetchPackageDetailsOfaTherapyList = "packageDetails/fetchPackageDetailsofaTherapyList";
export const saveTherapyReferralDetails = "therarpyReferral/saveTherapyReferralDetails";
export const getTherapyReferralDetailsByPatient = "therarpyReferral/getTherapyReferralDetailsByPatient";

//TherapyISt
export const getTherapyDetailsByAyushId = "therapyDetails/gettherapydetailsbyayushid";
export const saveStatusOfTherapy = "therarpyReferral/savestatusoftherapy";
//HEAD NURSE
export const getDutyRosterDetailsOfStaff = "dutyRoster/getDutyRosterDetailsOfStaff";
export const saveNurseDutyRosterMap = "nurseDutyRosterMap/saveNurseDutyRosterMap";

//ADR

// Description of reaction:
export const getActiveSignSymptomBySomId = "signsSymptoms/getactiveSignSymptomBySomId";

// Duration:
// same as previous one
export const getAllDurationTypes = "durationType/getAllDurationTypes";
// Get Duration wise Medicines:
// POST
export const getPrescriptionOfPatientDurationWise = "adr/getPrescriptionOfPatientDurationWise";
// Body:
// startDate
// endDate
// ayushId
// orgId

// {
// "startDate":"2022-07-06T00:00:00.000Z",
// "endDate":"2022-08-08T00:00:00.000Z",
// "ayushId":"172022002030",
// "orgId":8
// }

// Chronic disorders:
export const getAllIcdCodesForPres = "icdCode/getAllIcdCodesForPres";
export const saveAdrofPatient = "adr/saveAdrofPatient";
export const getAdrResult = "adr/getAdrResult";

//vital sign

export const saveVitalSignType = "vitalSignType/saveVitalSignType";
export const getVitalSignType = "vitalSignType/getAllVitalSignTypeInDto";
export const getActiveVitalSignType = "/vitalSignType/getAllActiveVitalSignType";
export const kardexDetailsSaveAskConsultant="kardexDetails/saveAskConsultant";