import {
  FETCH_OVERALL_STATISTICS_DASHBOARD,
  FETCH_PATIENT_DETAILS_BY_STAFF_ID,
  FETCH_DOCTOR_GRAPH,
  FETCH_DISEASE_STATISTICS,
  SET_PATIENT_LIST,
  FETCH_DASHBOARD_COUNT,
  FETCH_RECEPTIONIST_DASHBOARD,
  FETCH_DEPARTMENT_DASHBOARD,
} from "../actions/Type";

const initialState = {
  dashboardStatistics: [],
  patientDetailsByStaffId: [],
  doctorGraph: [],
  diseaseStatistics: [],
  billedPatientList: [],
  dashboardCount:[],
  receptionistDashboard: [],
  departmentDashboard: [],
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OVERALL_STATISTICS_DASHBOARD:
      return {
        ...state,
        dashboardStatistics: action.payload,
      };
    case FETCH_PATIENT_DETAILS_BY_STAFF_ID:
      return {
        ...state,
        patientDetailsByStaffId: action.payload,
      };
    case FETCH_DOCTOR_GRAPH:
      return {
        ...state,
        doctorGraph: action.payload,
      };
    case FETCH_DISEASE_STATISTICS:
      return {
        ...state,
        diseaseStatistics: action.payload,
      };
    case SET_PATIENT_LIST:
      return {
        ...state,
        billedPatientList: action.payload,
      };
      case FETCH_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCount: action.payload,
      };      
      case FETCH_RECEPTIONIST_DASHBOARD:
      return {
        ...state,
        receptionistDashboard: action.payload,
      };
      case FETCH_DEPARTMENT_DASHBOARD:
      return {
        ...state,
        departmentDashboard: action.payload,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
