import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider1 from "../assets/banner1.webp";
import Slider2 from "../assets/banner2.webp";
import Slider3 from "../assets/banner3.webp";
import Slider4 from "../assets/banner4.webp";
import Slider5 from "../assets/banner5.webp";
import Slider6 from "../assets/banner6.webp";
import Slider7 from "../assets/banner7.webp";

export default function BannerPage() {
  return (
    <div>
      <Carousel
        // className="header-carousel"
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <img src={Slider1} alt="slider1" />
        </div>

        <div>
          <img src={Slider2} alt="slider2" />
        </div>

        <div>
          <img src={Slider3} alt="silder3" />
        </div>
        <div>
          <img src={Slider4} alt="silder4" />
        </div>
        <div>
          <img src={Slider5} alt="silder5" />
        </div>
        <div>
          <img src={Slider6} alt="silder6" />
        </div>
        <div>
          <img src={Slider7} alt="silder7" />
        </div>
      </Carousel>
    </div>
  );
}
