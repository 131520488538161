import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.common.white,
  },
});

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    padding: "4px 6px",
  },
  body: {
    fontSize: 13,
    padding: "4px 6px",
  },
  stickyHeader: {
    backgroundColor: theme.palette.primary.dark + "!important",
  },
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    padding: "4px 6px",
  },
}))(TableRow);

export const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, id,...other } = props;
  return (
    <MuiDialogTitle id={id} disableTypography className={classes.root} {...other}>
      <Typography align="left" style={{fontSize:"1.15rem"}}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    color: "white",
    minHeight: "40px !important",
    maxHeight:"40px !important",
  },

}))(MuiAccordionSummary);

export const SUBMIT_COLOR = "tomato";
export const drawerWidth = 350;
export const drawerTopMargin = 60;
