import { Grid, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import configData from "../../../config.json";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
  },
  text: {
    textAlign: "left",
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      textAlign: "left",
    },
  },
  profiles:{
    width: 60,
    height: 60,
    borderRadius: "20px ",
    align: "right",
    [theme.breakpoints.down("md")]: {
      width: 45,
      height: 45,
      borderRadius: "20px ",
      align: "right",
    },
    [theme.breakpoints.down("sm")]: {
      width: 45,
      height: 45,
    borderRadius: "20px ",
    align: "right",
    },
  }
}));

export default function CenteredGrid() {
  const classes = useStyles();
  const selectedPatient = useSelector(
    (state) => state.consultationReducer.patient
  );
  const [base64PatientPhoto, setBase64PatientPhoto] = React.useState(null);
  useEffect(() => {
    const patientPhoto = selectedPatient.photo;
    if (patientPhoto != undefined) {
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(selectedPatient.photo))
      );
      setBase64PatientPhoto("data:image/png;base64," + base64String);
    }
  }, [selectedPatient]);

  console.log("selectedPatient", selectedPatient);

  const registrationID = useSelector(
    (state) => state.consultationReducer.registrationId
  );

  const vitalSigns = useSelector((state) => state.pulseReducer.vitalsDetails);

  const [pulseRate, setPulseRate] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [temperature, setTemperature] = useState("");
  const [spo2, setSpo2] = useState("");
  const [respiratoryRate, setRespiratoryRate] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [age, setAge] = useState("");

  let vitals = { ...vitalSigns };

  React.useEffect(() => {
    if (vitals && vitals.vitalsignDtos !== undefined) {
      vitals.vitalsignDtos.map((vitalSignData) => {
        if (vitalSignData.vitalSignTypeId === 2) {
          setPulseRate(vitalSignData.vitalSignValue);
        }
        if (vitalSignData.vitalSignTypeId === 3) {
          setSpo2(vitalSignData.vitalSignValue);
        }
        if (vitalSignData.vitalSignTypeId === 4) {
          setTemperature(vitalSignData.vitalSignValue);
        }
        if (vitalSignData.vitalSignTypeId === 5) {
          setBloodPressure(vitalSignData.vitalSignValue);
        }
        if (vitalSignData.vitalSignTypeId === 6) {
          setHeartRate(vitalSignData.vitalSignValue);
        }
        if (vitalSignData.vitalSignTypeId === 7) {
          setRespiratoryRate(vitalSignData.vitalSignValue);
        }
      });
    }
  }, [vitalSigns]);

  React.useEffect(() => {
    (async () => {
      const date = moment(selectedPatient?.dob).format("YYYY-MM-DD");
      const res = await Axios.get(
        configData.MASTER_SERVICE_URL + `age/calculate/${date}`
      );

      if (res.data[0] === 0) {
        const finalAge = `${res.data[0]} Years ${res.data[1]} Months ${res.data[2]} Days`;
        console.log("finalAge", finalAge);
        setAge(finalAge);
      } else {
        const finalAge = `${res.data[0]} Years`;
        console.log("finalAge", finalAge);
        setAge(finalAge);
      }
    })();
  }, [selectedPatient]);

  return (
    <div>
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <Avatar
            // style={{
            //   width: 70,
            //   height: 70,
            //   borderRadius: "20px ",
            //   align: "right",
            // }}
            className={classes.profiles}
            alt="Profile Picture"
            src={base64PatientPhoto}
          />
        </Grid>
        <Grid xs={2} sm={2} md={2} lg={2} >
          <Typography className={classes.text}>Name: {selectedPatient.patientName}</Typography>
          <Typography className={classes.text}>
            Date of Birth: {moment(selectedPatient?.dob).format("DD-MM-YYYY")}
          </Typography>
          <Typography className={classes.text}>Age: {age}</Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
          <Typography className={classes.text}>Ayush ID: {selectedPatient.ayushId}</Typography>
          <Typography className={classes.text}>ABHA Number: {selectedPatient.uhid}</Typography>
          <Typography className={classes.text}>OP ID: {registrationID}</Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
          <Typography className={classes.text}>Pulse Rate: {pulseRate} bpm</Typography>
          <Typography className={classes.text}>Blood Pressure: {bloodPressure} mmhg</Typography>
          <Typography className={classes.text}>Temperature: {temperature} °F</Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} className={classes.text}>
          <Typography className={classes.text}>
            SpO<sub>2</sub>: {spo2}%
          </Typography>
          <Typography className={classes.text}>Respiratory Rate: {respiratoryRate} rpm</Typography>
          <Typography className={classes.text}>Heart Rate: {heartRate} bpm</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
